import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Divider, Grid, List, ListItem, Pagination, Stack, Tab, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
    isInvitedUser: boolean
}

export const useUserConnectionApplyPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const tabValue = 'apply'
    const page = 1
    const limit = 10
    const [queryParams, setQueryParams] = useState<QueryParameters>({ page, limit, isInvitedUser: true })

    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await refetch()
    }

    // initial fetch
    const { data: inviteList, refetch } = useQuerySuspense(
        [`inviteList`, queryParams],
        async () => {
            const r = await apiClient.clientInviteGetList({ parameter: { ...queryParams } })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const approvalHandler = async (invite: Schemas.InviteEntities, isApproval: boolean) => {
        try {
            const inviteStatus = isApproval ? 1 : 2
            const inviteTitle = isApproval ? '申請を承認しますか？' : '申請を辞退しますか？'
            const inviteMessage = isApproval
                ? 'つながり申請を承認すると元に戻せません。よろしいですか？'
                : 'つながり申請を辞退すると元に戻せません。よろしいですか？'
            const confirm = await queueDialog({
                type: 'confirm',
                title: inviteTitle,
                text: inviteMessage,
            })
            if (confirm) {
                await apiClient.clientInviteApproval({
                    parameter: { inviteCode: invite.invite },
                    requestBody: { status: inviteStatus },
                })
                await refetch()
            }
        } catch (e) {
            let message = '承認/辞退に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        navigate,
        tabValue,
        queryParams,
        handlePageChange,
        list: inviteList?.list || [],
        count: inviteList?.count || 0,
        approvalHandler,
    }
}

export const UserConnectionApplyPage: FC = () => {
    const { navigate, tabValue, queryParams, handlePageChange, list, count, approvalHandler } = useUserConnectionApplyPage()
    return (
        <DefaultLayout title={''} breadcrumbList={[]}>
            <TabContext value={tabValue}>
                <TabList variant="fullWidth">
                    <Tab label="申請されたつながり" value="apply" onClick={() => navigate('/user/connection/apply')} />
                    <Tab label="申請したつながり" value="invite" onClick={() => navigate('/user/connection/invite')} />
                </TabList>
                <TabPanel value={'apply'}>
                    <Stack spacing={2}>
                        {list && list.length > 0 ? (
                            <>
                                <Typography variant="body2" textAlign={'center'}>
                                    つながりの申請・辞退ができます。申請を承認すると相手に通知されますが、辞退した場合は相手に通知されることはありません。
                                </Typography>

                                <List sx={{ background: '#EFEAE6', borderRadius: '8px' }}>
                                    {list.map((invite: Schemas.InviteEntities, index: number) => (
                                        <Stack key={invite.invite}>
                                            <ListItem>
                                                <Stack
                                                    spacing={1}
                                                    direction={'column'}
                                                    alignContent={'center'}
                                                    justifyContent={'center'}
                                                    width={'100%'}>
                                                    <Typography textAlign={'left'}>
                                                        {invite.getUserName} さんからつながりの申請が届きました
                                                    </Typography>
                                                    <Stack direction={'row'} justifyContent={'center'} spacing={3}>
                                                        <LoadingButton
                                                            variant={'text'}
                                                            startIcon={<RemoveCircleIcon />}
                                                            color={'error'}
                                                            onClick={() => approvalHandler(invite, false)}
                                                            sx={{ textDecorationLine: 'underline', textUnderlineOffset: '2px' }}>
                                                            辞退
                                                        </LoadingButton>
                                                        <LoadingButton
                                                            variant={'contained'}
                                                            onClick={() => approvalHandler(invite, true)}>
                                                            承認
                                                        </LoadingButton>
                                                    </Stack>
                                                </Stack>
                                            </ListItem>
                                            {index < list.length - 1 && <Divider variant={'middle'} />}
                                        </Stack>
                                    ))}
                                </List>

                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Pagination
                                        count={Math.floor(count / queryParams.limit) + 1}
                                        page={queryParams.page}
                                        onChange={(_, value) => handlePageChange(value)}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Typography>申請されたつながりはありません</Typography>
                        )}
                    </Stack>
                </TabPanel>
            </TabContext>
        </DefaultLayout>
    )
}
