import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import React, { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientGraveCreateDtoSchema, clientGraveCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { queueDialog } = useConfirmationDialog()

    const { data: imageMasters } = useQuerySuspense(
        [`/grave/master`],
        async () => {
            return await apiClient.clientImageMasterIndex()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const getMasterToIMage = (): Schemas.FileEntities | undefined => {
        if (imageMasters) {
            if (!textDirection) {
                const yoko = imageMasters.find((master: Schemas.ImageMasterEntities) => master.code === 'graveYoko')
                return yoko?.file
            } else {
                const tate = imageMasters.find((master: Schemas.ImageMasterEntities) => master.code === 'graveTate')
                return tate?.file
            }
        }
        return undefined
    }

    const { data: grave } = useQuerySuspense(
        [`/grave`],
        async () => {
            return await apiClient.clientGraveGet({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const {
        formState: { isSubmitting, errors, isValid },
        reset,
        register,
        setValue,
        getValues,
    } = useForm<ClientGraveCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientGraveCreateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: grave?.name || '',
            posthumousName: grave?.posthumousName || '',
            denomination: grave?.denomination || '',
            fileUuid: grave?.file?.uuid || undefined,
            vertical: grave?.vertical || false,
        })
        setTextDirection(grave?.vertical || false)
    }, [grave])

    // 編集モーダルの表示状態
    const editDialogSubmitHandler = async (dto: Schemas.ClientGraveCreateDto) => {
        try {
            await apiClient.clientGraveUpdate({ requestBody: dto })
            navigate('/grave')
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)

    const getContext = () => {
        const tempCanvas = canvasRef.current as HTMLCanvasElement | null
        if (!tempCanvas) return
        setCanvas(tempCanvas)
        const tempContext = tempCanvas.getContext('2d')
        if (!tempContext) return
        tempContext.imageSmoothingEnabled = false
        setContext(tempContext)
    }

    const handleChangeCanvas = (options?: { posthumousName?: string; denomination?: string }) => {
        if (!context) getContext()

        let posthumousName = getValues('posthumousName') || ''
        if (typeof options !== 'undefined' && typeof options.posthumousName !== 'undefined')
            posthumousName = options.posthumousName
        let denomination = getValues('denomination') || ''
        if (typeof options !== 'undefined' && typeof options.denomination !== 'undefined') denomination = options.denomination

        if (context !== null) {
            const img = new Image()
            img.src = mediaUrl(getMasterToIMage())
            img.crossOrigin = 'anonymous'
            img.onload = () => {
                context.beginPath()
                context.fillStyle = '#fafafa'
                context.fillRect(0, 0, canvas!.width, canvas!.height)
                context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas!.width, canvas!.height)

                if (!posthumousName && !denomination) return

                const centerWidthPoint = 327 / 2
                if (!textDirection) {
                    // 横書き
                    if (posthumousName && !denomination) {
                        context.textAlign = 'center'
                        context.fillStyle = '#ffffff'
                        context.font = 'bold 18px serif'
                        context.fillText(posthumousName || '', centerWidthPoint, 163 + 18)
                    } else {
                        context.fillStyle = '#ffffff'
                        context.textAlign = 'center'
                        context.font = 'bold 18px serif'
                        context.fillText(posthumousName || '', centerWidthPoint, 145 + 18)
                        context.font = `bold 12px serif`
                        context.fillText(denomination || '', centerWidthPoint, 182 + 12)
                    }
                } else {
                    // 縦書き
                    context.textAlign = 'left'
                    context.fillStyle = '#333333'
                    const mainTitleFontSize = 18
                    const subTitleFontSize = 12
                    const subTitleFontSizeOver15 = 11
                    if (posthumousName && !denomination) {
                        context.font = `bold ${mainTitleFontSize}px serif`
                        posthumousName.split('').map((char, index) => {
                            context.fillText(
                                char,
                                centerWidthPoint - mainTitleFontSize / 2,
                                index * mainTitleFontSize * 1.2 + mainTitleFontSize + 57,
                            )
                        })
                    } else {
                        context.font = `bold ${mainTitleFontSize}px serif`
                        posthumousName.split('').map((char, index) => {
                            context.fillText(char, 163, index * mainTitleFontSize * 1.1 + mainTitleFontSize + 57)
                        })

                        if (denomination.length <= 18) {
                            context.font = `bold ${subTitleFontSize}px serif`
                            denomination.split('').map((char, index) => {
                                context.fillText(char, 140, index * subTitleFontSize * 1.1 + subTitleFontSize + 62)
                            })
                        } else {
                            context.font = `bold ${subTitleFontSizeOver15}px serif`
                            denomination.split('').map((char, index) => {
                                context.fillText(char, 140, index * subTitleFontSizeOver15 * 1.1 + subTitleFontSizeOver15 + 62)
                            })
                        }
                    }
                }
            }
        }
    }

    // canvas 保存
    const handlerSaveCanvas = async () => {
        if (!canvas) return
        canvas.toBlob(
            async (blob) => {
                if (blob) {
                    const formData = new FormData()
                    formData.append('files', blob)

                    try {
                        // @ts-expect-error
                        const uploadedFiles = await apiClient.clientFileUploadUpload({ requestBody: formData })
                        if (Array.isArray(uploadedFiles)) {
                            setValue('fileUuid', uploadedFiles[0].uuid)
                            await editDialogSubmitHandler({
                                fileUuid: uploadedFiles[0].uuid,
                                name: getValues('name'),
                                posthumousName: getValues('posthumousName'),
                                denomination: getValues('denomination'),
                                vertical: textDirection,
                            })
                            // await handleSubmit((dto) => onSubmit(dto))
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
            },
            'image/png',
            1,
        )
    }

    const handleMouseDownCanvas = (event: MouseEvent) => {
        const rect = canvas!.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top
        console.log(x, y)
    }

    const [textDirection, setTextDirection] = useState(grave?.vertical || false)
    const handleChangeDirection = (event: ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value
        let changeValue: 'true' | 'false' = 'false'
        if (value === 'true') changeValue = 'true'
        setTextDirection(changeValue === 'true')
    }

    useEffect(() => {
        if (grave && imageMasters) handleChangeCanvas()
    }, [context, grave, imageMasters, textDirection])

    return {
        navigate,
        register,
        errors,
        isValid,
        handleChangeCanvas,
        canvasRef,
        isSubmitting,
        handlerSaveCanvas,
        handleMouseDownCanvas,
        textDirection,
        handleChangeDirection,
    }
}

export const GraveEditPage: FC = () => {
    const {
        navigate,
        register,
        errors,
        isValid,
        handleChangeCanvas,
        canvasRef,
        isSubmitting,
        handlerSaveCanvas,
        handleMouseDownCanvas,
        textDirection,
        handleChangeDirection,
    } = usePage()
    return (
        <>
            <DefaultLayout title={'名前の登録'} breadcrumbList={[]}>
                <Stack spacing={2}>
                    <Typography variant={'body2'} textAlign={'center'}>
                        自分のお墓に彫る名前を編集できます。
                    </Typography>

                    <Stack spacing={0.5}>
                        <CInputLabel label="向き" required />
                        <Stack spacing={2} direction={'row'} component={'div'}>
                            <FormControl>
                                <RadioGroup row name={'reserve'} value={textDirection} onChange={handleChangeDirection}>
                                    <FormControlLabel value={false} control={<Radio />} label={'横書き'} />
                                    <FormControlLabel value={true} control={<Radio />} label={'縦書き'} />
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label="氏名" required />
                            <Typography variant="caption">全角10文字以内</Typography>
                            <TextField
                                variant={'outlined'}
                                id={'posthumousName'}
                                required={true}
                                sx={{ width: '100%' }}
                                {...register('posthumousName')}
                                InputLabelProps={{ shrink: true }}
                                error={!!errors.posthumousName}
                                helperText={errors.posthumousName?.message}
                                onChange={(e) => handleChangeCanvas({ posthumousName: e.target.value })}
                            />
                            <Typography variant="caption">※絵文字や特殊文字は使えません</Typography>
                        </Stack>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <canvas
                            ref={canvasRef}
                            width="327"
                            height="400"
                            style={{
                                width: '327px',
                                height: '400px',
                                border: `1px solid #cccccc`,
                                backgroundColor: '#eee',
                            }}
                            onMouseDown={(e) => {
                                handleMouseDownCanvas(e)
                            }}
                        />
                    </Box>

                    <Stack spacing={2} direction="row" justifyContent={'center'}>
                        <LoadingButton variant="outlined" fullWidth onClick={() => navigate('/grave')}>
                            キャンセル
                        </LoadingButton>
                        <LoadingButton
                            variant={'contained'}
                            fullWidth
                            loading={isSubmitting}
                            onClick={handlerSaveCanvas}
                            disabled={!isValid}>
                            この内容で保存
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DefaultLayout>
        </>
    )
}
