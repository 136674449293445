import { zodResolver } from '@hookform/resolvers/zod'
import { TabContext, TabList } from '@mui/lab'
import { AppBar, Container, Stack, Tab, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import sendImage from '~/assets/image/memory/send.svg'
import { CCommentDelete } from '~/components/functional/cCommentDelete/CCommentDelete'
import { CCommentLeft } from '~/components/functional/cCommentLeft/CCommentLeft'
import { CCommentRight } from '~/components/functional/cCommentRight/CCommentRight'
import { CMemoryBaseInfo } from '~/components/functional/memory/cMemoryBaseInfo/CMemoryBaseInfo'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientMemoryCommentCreateDtoSchema, clientMemoryCommentCreateDtoSchema } from '~/types/zodScheme'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useMemoryShareMemoryUuidCommentPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const memoryUuid = params.memoryUuid
    const navigate = useNavigate()
    const scrollBottomRef = useRef<HTMLDivElement>(null)

    // initial fetch
    const { data: memory } = useQuerySuspense(
        [`/memory/share/${memoryUuid}/comment`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientMemoryGetShare({ parameter: { memoryUuid: memoryUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const [commentList, setCommentList] = useState<Schemas.MemoryCommentEntities[]>([])
    const { data: commentResponse, refetch: fetchMemoryComment } = useQuery(
        [`/memory/share/${memoryUuid}/comment`, memoryUuid],
        async () => {
            if (!memoryUuid) new Error()
            const comments = await apiClient.clientMemoryCommentGetList({
                parameter: {
                    memoryUuid: memoryUuid!,
                },
            })
            setCommentList(comments.list?.reverse() || [])
            return comments
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    useLayoutEffect(() => {
        scrollBottomRef?.current?.scrollIntoView()
    }, [])

    // コメント制御
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientMemoryCommentCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientMemoryCommentCreateDtoSchema),
    })

    useEffect(() => {
        reset({
            comment: '',
        })
        scrollBottomRef?.current?.scrollIntoView()
    }, [isSubmitting])

    const sendCommentHandler = async (dto: Schemas.ClientGraveCommentCreateDto) => {
        if (!memory) return
        try {
            await apiClient.clientMemoryCommentCreate({
                parameter: { memoryUuid: memory.uuid },
                requestBody: dto,
            })
            await fetchMemoryComment()
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    const deleteMemoryCommentHandler = async (commentUuid: string) => {
        try {
            const message = '削除します。よろしいでしょうか？'
            if (!memoryUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryCommentDelete({ parameter: { memoryUuid: memoryUuid, commentUuid: commentUuid } })
                await fetchMemoryComment()
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const tabHandler = (value: string) => {
        if (!memoryUuid) return
        navigate(`/memory/share/${memoryUuid}/${value}`, { replace: true })
    }

    return {
        scrollBottomRef,

        memory,

        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendCommentHandler,
        deleteMemoryCommentHandler,
        tabHandler,
        commentList,
        commentCount: commentResponse?.count || 0,
    }
}

export const MemoryShareMemoryUuidCommentPage: FC = () => {
    const {
        scrollBottomRef,

        memory,

        register,
        handleSubmit,
        sendCommentHandler,
        deleteMemoryCommentHandler,
        tabHandler,
        commentList,
    } = useMemoryShareMemoryUuidCommentPage()
    return (
        <>
            <DefaultLayout title="" breadcrumbList={[]} tabValue={'memory'}>
                <CMemoryBaseInfo memory={memory!} />
                <Stack spacing={2}>
                    <TabContext value={'comment'}>
                        <TabList centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                            <Tab
                                label={<Typography variant={'body1'}>写真</Typography>}
                                value={''}
                                onClick={() => tabHandler('')}
                            />
                            <Tab
                                label={<Typography variant={'body1'}>コメント</Typography>}
                                value={'comment'}
                                onClick={() => tabHandler('comment')}
                            />
                        </TabList>
                    </TabContext>
                    <Stack direction={'column'} spacing={2}>
                        {commentList &&
                            commentList.map((comment: Schemas.MemoryCommentEntities) => (
                                <Stack key={comment.uuid}>
                                    {comment.deletedAt ? (
                                        <CCommentDelete />
                                    ) : (
                                        <>
                                            {comment.user ? (
                                                <CCommentRight
                                                    comment={comment}
                                                    deleteMemoryCommentHandler={deleteMemoryCommentHandler}
                                                />
                                            ) : (
                                                <CCommentLeft
                                                    comment={comment}
                                                    deleteMemoryCommentHandler={deleteMemoryCommentHandler}
                                                />
                                            )}
                                        </>
                                    )}
                                </Stack>
                            ))}
                    </Stack>
                </Stack>
            </DefaultLayout>
            <div ref={scrollBottomRef} />
            <AppBar
                position="fixed"
                sx={{
                    top: 'auto',
                    bottom: 74,
                    bgcolor: '#B188CA',
                    boxShadow: 'none',
                    display: 'flex',
                    direction: 'row',
                    justifyContent: 'center',
                }}>
                <Container maxWidth={'sm'}>
                    <Stack direction={'row'} justifyContent={'center'} margin={'8px 12px'} spacing={2}>
                        <TextField
                            id={'content'}
                            {...register('comment')}
                            variant={'outlined'}
                            sx={{ backgroundColor: 'white', borderRadius: '100px', padding: '-8px' }}
                            fullWidth
                        />
                        <img src={sendImage} onClick={handleSubmit(sendCommentHandler)} style={{ cursor: 'pointer' }} />
                    </Stack>
                </Container>
            </AppBar>
        </>
    )
}
