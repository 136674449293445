import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientUserMobilePhoneUpdateActivateDtoSchema, clientUserMobilePhoneUpdateActivateDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

export const useUserMobilePhoneCredentialPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    const params = useParams()
    const credential = params.credential
    const [isResponse, setIsResponse] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientUserMobilePhoneUpdateActivateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMobilePhoneUpdateActivateDtoSchema),
    })

    useEffect(() => {
        reset({
            mobilePhone: '',
            newMobilePhone: '',
        })
    }, [])

    const sendHandler = async (dto: Schemas.ClientUserMobilePhoneActiveUpdateDto) => {
        if (!credential) return
        try {
            await apiClient.clientUserActivateMobilePhone({ parameter: { token: credential }, requestBody: dto })
            setIsResponse(true)
        } catch (e) {
            let message = 'エラーが発生しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        isResponse,
        sendHandler,
    }
}

export const UserMobilePhoneCredentialPage: FC = () => {
    const { register, handleSubmit, errors, isSubmitting, isValid, isResponse, sendHandler } = useUserMobilePhoneCredentialPage()
    return (
        <DefaultLayout title={'携帯電話番号変更確認'} breadcrumbList={[]} isTab={false}>
            {isResponse ? (
                <>
                    <Stack>
                        <Typography variant="body2" textAlign={'center'}>
                            携帯電話番号を変更しました
                            <br />
                            ご変更した携帯電話番号でのログインになりますのでご注意下さい
                        </Typography>
                    </Stack>
                </>
            ) : (
                <>
                    <Stack spacing={2}>
                        <Typography variant="body2" textAlign={'center'}>
                            古い携帯電話番号と新しい携帯電話番号を入力してください
                        </Typography>

                        <Stack spacing={0.5}>
                            <CInputLabel label="古い携帯電話番号" required />
                            <TextField
                                id={'name'}
                                required={true}
                                {...register('mobilePhone')}
                                placeholder={'携帯電話番号'}
                                error={!!errors.mobilePhone}
                                helperText={errors.mobilePhone?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label="新しい携帯電話番号" required />
                            <TextField
                                id={'name'}
                                required={true}
                                {...register('newMobilePhone')}
                                placeholder={'携帯電話番号'}
                                error={!!errors.newMobilePhone}
                                helperText={errors.newMobilePhone?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <LoadingButton
                            variant={'contained'}
                            disabled={!isValid}
                            loading={isSubmitting}
                            onClick={handleSubmit(sendHandler)}>
                            この内容を送信
                        </LoadingButton>
                    </Stack>
                </>
            )}
        </DefaultLayout>
    )
}
