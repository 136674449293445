import { FormControl, FormControlLabel, Radio, Stack } from '@mui/material'
import { FC } from 'react'

export type CGraveSelectProps = {
    graveFile: string
    graveName: string
    selected?: boolean
    click: (name: string) => void
}

const getJpLabel = (label: string) => {
    if (label === 'tate') return '縦書き'
    else if (label === 'yoko') return '横書き'
}

export const CGraveSelect: FC<CGraveSelectProps> = ({ graveFile, graveName, selected = false, click }) => {
    return (
        <>
            <Stack alignItems="center">
                <img
                    src={graveFile}
                    style={{
                        width: selected ? '132px' : '128px',
                        height: '160px',
                        objectFit: 'contain',
                        borderRadius: '10px',
                        border: selected ? '2px solid #AF985A' : '',
                    }}
                    alt={getJpLabel(graveName)}
                    onClick={() => click(graveName)}
                />
                <FormControl>
                    <FormControlLabel
                        value={graveName}
                        control={<Radio checked={selected} name="grave-select" onChange={() => click(graveName)} />}
                        label={getJpLabel(graveName)}
                        labelPlacement="end"
                    />
                </FormControl>
            </Stack>
        </>
    )
}
