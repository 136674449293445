import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import type { FC } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { StateTransferDtoType } from '~/pages/sponsor/form/index'
import { mediaUrl } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useSponsorFormConfirmPage = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    const values = (state as { dto: StateTransferDtoType })?.dto

    const handleSubmitButton = async () => {
        try {
            await apiClient.clientSponsorCreateSponsor({
                requestBody: {
                    ...values,
                    bannerImageUuid: values.bannerImage?.uuid,
                    categoriesUuids: values.categoryUuids,
                    coveredPrefectureUuids: values.prefectureUuids,
                },
            })
            navigate('/sponsor/form/registered')
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const handleReturnButton = () => {
        navigate('/sponsor/form', { state })
    }

    return { handleSubmitButton, handleReturnButton, values }
}

export const SponsorFormConfirmPage: FC = () => {
    const { handleSubmitButton, handleReturnButton, values } = useSponsorFormConfirmPage()
    return (
        <DefaultLayout title={''} breadcrumbList={[]} isTab={false}>
            <Stack spacing={2}>
                <Stack spacing={2} textAlign={'center'}>
                    <Typography variant="h4">事業者登録フォーム</Typography>
                    <Typography variant="body1">
                        以下の内容で登録します。
                        <br />
                        登録後、内容を変更される場合は弊社へお問い合わせください。
                    </Typography>
                </Stack>
                <Box
                    sx={{ display: 'flex', bgcolor: 'white', alignSelf: 'center', width: '100%', p: 3, flexDirection: 'column' }}
                    maxWidth={'528px'}>
                    <Stack spacing={2} mb={3}>
                        {values.bannerImage && (
                            <Box marginX={'auto'}>
                                <img
                                    alt={''}
                                    src={mediaUrl(values.bannerImage)}
                                    width="240px"
                                    style={{
                                        objectFit: 'cover',
                                        alignSelf: 'center',
                                    }}
                                />
                            </Box>
                        )}

                        <Stack spacing={0.5}>
                            <TextField
                                focused
                                id="sponsorForm-name"
                                label="事業者名 (ふりがな)"
                                value={`${values.name} (${values.nameKana})`}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    backgroundColor: 'white',
                                }}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <TextField
                                focused
                                id="sponsorForm-email"
                                label="メールアドレス"
                                value={values.email}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    backgroundColor: 'white',
                                }}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <TextField
                                focused
                                id="sponsorForm-address"
                                label="代表者住所 ※サイト上に公開されることはありません。"
                                value={values.address}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    backgroundColor: 'white',
                                }}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <TextField
                                focused
                                id="sponsorForm-phoneNumber"
                                label="代表者電話番号 ※サイト上に公開されることはありません。"
                                value={values.phoneNumber}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    backgroundColor: 'white',
                                }}
                            />
                        </Stack>
                        {values.siteUrl && (
                            <Stack spacing={0.5}>
                                <TextField
                                    focused
                                    id="sponsorForm-url"
                                    label="サイトURL"
                                    color="secondary"
                                    value={values.siteUrl}
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{
                                        backgroundColor: 'white',
                                    }}
                                />
                            </Stack>
                        )}
                        <Stack spacing={0.5}>
                            <TextField
                                focused
                                id="sponsorForm-category"
                                label="カテゴリ"
                                value={values.categories.join(', ')}
                                variant="standard"
                                multiline
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    backgroundColor: 'white',
                                }}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <TextField
                                focused
                                id="sponsorForm-prefecture"
                                label="都道府県"
                                value={values.prefectures.join(', ')}
                                variant="standard"
                                multiline
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    backgroundColor: 'white',
                                }}
                            />
                        </Stack>
                    </Stack>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant={'outlined'} sx={{ width: '232px' }} onClick={handleReturnButton}>
                            ＜ 戻る
                        </Button>
                        <LoadingButton variant={'contained'} sx={{ width: '232px' }} onClick={handleSubmitButton}>
                            この内容で登録
                        </LoadingButton>
                    </Box>
                </Box>
            </Stack>
        </DefaultLayout>
    )
}
