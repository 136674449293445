import { AppBar, Box, Stack, styled, Tab, Tabs, Typography } from '@mui/material'
import { FC, ReactElement, ReactNode } from 'react'
import { useNavigate } from 'react-router'

import ConnectionIcon from '~/assets/image/icon/menu/connection_heart.svg'
import ConnectionOutlinedIcon from '~/assets/image/icon/menu/connection_heart_outlined.svg'
import MemoriesIcon from '~/assets/image/icon/menu/memories.svg'
import MemoriesOutlinedIcon from '~/assets/image/icon/menu/memories_outlined.svg'
import PersonIcon from '~/assets/image/icon/menu/person.svg'
import PersonOutlinedIcon from '~/assets/image/icon/menu/person_outlined.svg'

interface StyledTabProps {
    label: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: ReactElement<any, any>
    value: string
    onClick: () => void
}

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: '#77618B',
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#77618B',
        fontWeight: theme.typography.fontWeightBold,
        borderTop: '2px solid #77618B',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}))

export type BreadcrumbListType = { title: string; link: string }[]

export type DefaultLayoutContentProps = {
    children: ReactNode
    title?: string
    titleHelperText?: string
    breadcrumbList: BreadcrumbListType
    tabValue?: string
    isTab?: boolean
}

export const useDefaultLayoutContent = () => {
    const navigate = useNavigate()

    const handlePageChange = (path: string) => {
        navigate(path)
    }

    return {
        handlePageChange,
        navigate,
    }
}

export const DefaultLayoutContent: FC<DefaultLayoutContentProps> = ({
    children,
    title,
    tabValue = 'myPage',
    isTab = true,
    titleHelperText,
}) => {
    const { handlePageChange, navigate } = useDefaultLayoutContent()
    return (
        <>
            <Box sx={{ mb: 2 }}>
                {title ? (
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={'8px'}>
                        <Typography variant="h3" sx={{ textAlign: 'center', paddingY: '32px' }}>
                            {title || ''}
                        </Typography>
                        {titleHelperText && (
                            <Typography sx={{ textAlign: 'center', paddingY: '32px' }}>{titleHelperText || ''}</Typography>
                        )}
                    </Stack>
                ) : (
                    <></>
                )}
            </Box>

            {children}
            <Stack direction={'column'} justifyContent={'start'} alignItems={'center'} spacing={2} sx={{ my: 5, mx: 3 }}>
                <Box onClick={() => navigate('/sponsorList')} sx={{ cursor: 'pointer' }}>
                    <Typography sx={{ textDecoration: 'underline', fontSize: '0.75rem', fontWeight: '300' }}>
                        協賛事業者一覧
                    </Typography>
                </Box>
                <Typography variant={'caption'} sx={{ fontSize: '12px' }}>
                    Ⓒ KORESHU. All Rights Reserved.
                </Typography>
            </Stack>
            {isTab ? (
                <>
                    <AppBar
                        position="fixed"
                        sx={{
                            top: 'auto',
                            bottom: 0,
                            bgcolor: 'white',
                            boxShadow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'end'} sx={{ mx: 3 }}>
                            <Tabs value={tabValue} TabIndicatorProps={{ hidden: true, sx: { display: 'none' } }}>
                                <AntTab
                                    icon={tabValue === 'myPage' ? <img src={PersonIcon} /> : <img src={PersonOutlinedIcon} />}
                                    label={'マイページ'}
                                    value={'myPage'}
                                    onClick={() => handlePageChange('/user')}
                                />
                                <AntTab
                                    icon={
                                        tabValue === 'connectList' ? (
                                            <img src={ConnectionIcon} />
                                        ) : (
                                            <img src={ConnectionOutlinedIcon} />
                                        )
                                    }
                                    label={'つながり'}
                                    value={'connectList'}
                                    onClick={() => handlePageChange('/connectList')}
                                />
                                <AntTab
                                    icon={tabValue === 'memory' ? <img src={MemoriesIcon} /> : <img src={MemoriesOutlinedIcon} />}
                                    label={'思い出'}
                                    value={'memory'}
                                    onClick={() => handlePageChange('/memory/self')}
                                />
                            </Tabs>
                        </Stack>
                    </AppBar>
                </>
            ) : (
                <></>
            )}
        </>
    )
}
