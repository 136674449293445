import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { ClientUserMobilePhoneTokenRequestDtoSchema, clientUserMobilePhoneTokenRequestDtoSchema } from '~/types/zodScheme'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useInviteInviteCodePage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const { me } = useAuthState()
    const params = useParams()
    const inviteCode = params.inviteCode

    const [isSend, setIsSend] = useState(false)
    const [approval, setApproval] = useState('承認')

    // initial fetch
    const { data: clientCheckInviteResponseDto } = useQuerySuspense(
        [inviteCode, me],
        async () => {
            if (!me) return
            if (!inviteCode) return
            return await apiClient.clientUserGetInvite({ parameter: { inviteCode: inviteCode } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
            enabled: !!inviteCode && !!me,
        },
    )

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientUserMobilePhoneTokenRequestDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMobilePhoneTokenRequestDtoSchema),
    })

    useEffect(() => {
        reset({
            mobilePhone: '',
            inviteCode: inviteCode,
        })
    }, [isSubmitting])

    const sendHandler = async (dto: Schemas.ClientUserMobilePhoneTokenRequestDto) => {
        try {
            await apiClient.clientUserCreateMobilePhoneToken({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: 'メッセージを送信',
                text: '入力された携帯電話へメッセージを送信しました',
            })
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const approvalHandler = async (isApprovalValue: boolean) => {
        try {
            if (!inviteCode) return
            const isApprovalMessage = isApprovalValue ? '承認' : '否認'
            const confirm = await queueDialog({
                type: 'confirm',
                title: `${isApprovalMessage}確認`,
                text: `${isApprovalMessage}します。よろしいでしょうか`,
            })
            if (confirm) {
                if (isApprovalValue) await apiClient.clientUserApprovalInvite({ parameter: { inviteCode: inviteCode } })

                await queueDialog({
                    type: 'alert',
                    title: `${isApprovalMessage}しました`,
                    text: `${isApprovalMessage}しました`,
                })

                setIsSend(true)
                setApproval(isApprovalValue ? '承認' : '否認')
            }
        } catch (e) {
            let message = 'つながりの承認／否認に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        me,
        clientCheckInviteResponseDto,
        isSend,
        approval,

        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendHandler,
        approvalHandler,
    }
}

export const InviteInviteCodePage: FC = () => {
    const {
        me,
        clientCheckInviteResponseDto,
        isSend,
        approval,

        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendHandler,
        approvalHandler,
    } = useInviteInviteCodePage()
    return (
        <>
            <DefaultLayout title={me ? 'つながりの承諾/否認' : 'ユーザー登録 電話番号の確認'} breadcrumbList={[]}>
                <Container maxWidth={'sm'} sx={{ mt: 3, mb: 6 }}>
                    {me ? (
                        <>
                            {clientCheckInviteResponseDto ? (
                                <>
                                    {clientCheckInviteResponseDto.isInvite ? (
                                        <>
                                            {!isSend ? (
                                                <>
                                                    <Typography component={'p'}>
                                                        {clientCheckInviteResponseDto.inviteUserName}
                                                        さんからつながりの申請を受け付けました。承諾／否認してください
                                                    </Typography>
                                                    <Stack spacing={3} direction={'row'} justifyContent={'center'} sx={{ pt: 3 }}>
                                                        <Button
                                                            variant={'outlined'}
                                                            color={'error'}
                                                            onClick={() => approvalHandler(false)}>
                                                            否認
                                                        </Button>

                                                        <Button variant={'contained'} onClick={() => approvalHandler(true)}>
                                                            承認
                                                        </Button>
                                                    </Stack>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography component={'p'}>{approval}しました</Typography>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Typography component={'p'}>
                                                {clientCheckInviteResponseDto.inviteUserName}さんとのつながりはすでにあります
                                            </Typography>
                                        </>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography variant={'body2'} textAlign={'center'}>
                                既に会員の方は右上のログインからログインした後、もう一度リンクを開いてください。
                                <br />
                                まだ会員登録をお済みで無い方は携帯電話番号を入力の上、送信ボタンをタップしてください。
                            </Typography>
                            <Stack spacing={3}>
                                <TextField
                                    {...register('mobilePhone')}
                                    placeholder={'携帯電話'}
                                    type={'text'}
                                    error={!!errors.mobilePhone}
                                    helperText={errors.mobilePhone?.message}
                                    variant={'outlined'}
                                />

                                <LoadingButton
                                    variant={'contained'}
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                    onClick={handleSubmit(sendHandler)}>
                                    送信
                                </LoadingButton>
                            </Stack>
                        </>
                    )}
                </Container>
            </DefaultLayout>
        </>
    )
}
