import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import type { FC } from 'react'

import { Schemas } from '~/apis/types'
import { mediaUrl } from '~/utils/common'

type COfferingMasterDescriptionProps = {
    descriptions: Schemas.OfferingMasterDescription[]
}

export const COfferingMasterDescription: FC<COfferingMasterDescriptionProps> = ({ descriptions }) => {
    return (
        <Grid container direction={'row'} justifyContent={'center'}>
            {descriptions.map((description, index) => (
                <Grid
                    key={description.type + index.toString()}
                    xs={12}
                    md={description.type === 'image' ? 6 : 12}
                    justifyContent={description.type === 'image' ? 'center' : ''}
                    sx={{ py: 1, px: 2 }}>
                    {description.type === 'image' && (
                        <img
                            src={mediaUrl(description.file)}
                            style={{
                                height: 255,
                                display: 'block',
                                maxWidth: 400,
                                overflow: 'hidden',
                                width: '100%',
                                objectFit: 'contain',
                            }}
                            alt="画像"
                        />
                    )}
                    {description.type === 'title' && <Typography variant="h6">{description.value}</Typography>}
                    {description.type === 'body' && (
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                            {description.value}
                        </Typography>
                    )}
                </Grid>
            ))}
        </Grid>
    )
}
