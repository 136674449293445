import { useDisclosure } from '@chakra-ui/hooks'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    MenuList,
    Pagination,
    Stack,
    Tab,
    Typography,
} from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import helpIcon from '~/assets/image/connect/helpIcon.svg'
import memoryAdd from '~/assets/image/memory/addMemory.svg'
import moreHorizontal from '~/assets/image/memory/moreHorizontal.svg'
import noImage from '~/assets/image/memory/noImage.svg'
import { CMemoryFileDialog } from '~/components/functional/memory/cMemoryFileDialog/CMemoryFileDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export const useMemorySelfIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const [menuUuid, setMenuUuid] = useState<string | undefined>(undefined)
    const menuButtonHandler = (e: HTMLElement, uuid: string) => {
        setMenuAnchorEl(e)
        setMenuUuid(uuid)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }
    const handleMenuItem = () => {
        navigate(`/memory/self/${menuUuid}/edit`)
    }

    const memoryTabValue = 'self' // self, share

    const page = 1
    const limit = 10
    const [queryParams, setQueryParams] = useState<QueryParameters>({ page, limit })

    // initial fetch
    const { data: listResponse, refetch: fetchList } = useQuerySuspense(
        ['/memory/self'],
        async () => {
            return await apiClient.clientMemoryGetList({ parameter: { ...queryParams, category: 'memory' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: imageMaster } = useQuerySuspense(
        ['/memory/self/imageMaster'],
        async () => {
            return await apiClient.clientImageMasterShow({ parameter: { code: 'memoryEveryday' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await fetchList()
    }
    const handleNavigateDetail = async (memory: Schemas.MemoryEntities) => {
        navigate(`/memory/self/${memory.uuid}`)
    }

    const deleteHandler = async () => {
        try {
            menuCloseHandler()
            const message = '削除します。よろしいでしょうか？'
            if (!menuUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryDelete({ parameter: { memoryUuid: menuUuid } })
                await fetchList()
                setMenuUuid(undefined)
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    // 画像モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.FileEntities>()
    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
        menuCloseHandler()
    }
    const editDialogSubmitHandler = async (dto: Schemas.ClientMemoryAssetCreateDto, uuid?: string) => {
        try {
            if (!menuUuid) return
            if (!uuid) await apiClient.clientMemoryAssetCreate({ parameter: { memoryUuid: menuUuid! }, requestBody: dto })
            // else await apiClient.clientMemoryUpdate({ parameter: { memoryUuid }, requestBody: dto })

            editDialogOnClose()
            setMenuUuid(undefined)
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const memoryFileDialog = (
        <CMemoryFileDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        imageMaster,
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,
        navigate,
        memoryTabValue,
        queryParams,
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        handlePageChange,
        handleNavigateDetail,
        deleteHandler,
        addButtonHandler,
        memoryFileDialog,
    }
}

export const MemorySelfIndexPage: FC = () => {
    const {
        imageMaster,
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,
        navigate,
        memoryTabValue,
        queryParams,
        list,
        count,
        handlePageChange,
        deleteHandler,
        addButtonHandler,
        memoryFileDialog,
    } = useMemorySelfIndexPage()
    return (
        <>
            <DefaultLayout maxWidth={'md'} breadcrumbList={[]} tabValue={'memory'}>
                <Stack spacing={2}>
                    <Container maxWidth={'sm'}>
                        <Stack direction={'row'} justifyContent={'end'} sx={{ pb: 2 }}>
                            <IconButton component={Link} to="/help?source=memory">
                                <img src={helpIcon} />
                            </IconButton>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <Typography variant="h3" sx={{ textAlign: 'center', paddingY: '32px' }}>
                                思い出
                            </Typography>
                        </Stack>
                    </Container>
                </Stack>

                <Container maxWidth={'sm'}>
                    <Stack spacing={2}>
                        <TabContext value={memoryTabValue}>
                            <TabList centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                                <Tab
                                    label={<Typography variant={'body1'}>自分の思い出</Typography>}
                                    value="self"
                                    onClick={() => navigate('/memory/self', { replace: true })}
                                />
                                <Tab
                                    label={<Typography variant={'body1'}>共有された思い出</Typography>}
                                    value="share"
                                    onClick={() => navigate('/memory/share', { replace: true })}
                                />
                            </TabList>

                            <TabPanel value={'self'} sx={{ p: 0 }}>
                                <Stack spacing={1}>
                                    <Box onClick={() => navigate('/memory/self/new/edit')}>
                                        <Stack direction={'row'}>
                                            <img
                                                src={memoryAdd}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    objectFit: 'contain',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer',
                                                }}
                                                alt=""
                                            />
                                            <Stack
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignItems={'flex-start'}
                                                pl={1}
                                                sx={{ cursor: 'pointer' }}>
                                                <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-wrap' }}>
                                                    思い出アルバムを作成
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    {list.map((memory: Schemas.MemoryEntities) => (
                                        <Box key={memory.uuid}>
                                            <Stack direction={'row'}>
                                                {memory.dailyLife ? (
                                                    <img
                                                        onClick={() => navigate(`/memory/self/${memory.uuid}`)}
                                                        src={imageMaster?.file ? mediaUrl(imageMaster.file) : noImage}
                                                        style={{
                                                            minWidth: '100px',
                                                            minHeight: '100px',
                                                            maxWidth: '100px',
                                                            maxHeight: '100px',
                                                            objectPosition: 'center',
                                                            objectFit: 'cover',
                                                            borderRadius: '10px',
                                                            cursor: 'pointer',
                                                        }}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <img
                                                        onClick={() => navigate(`/memory/self/${memory.uuid}`)}
                                                        src={memory.file ? mediaUrl(memory.file) : noImage}
                                                        style={{
                                                            minWidth: '100px',
                                                            minHeight: '100px',
                                                            maxWidth: '100px',
                                                            maxHeight: '100px',
                                                            objectPosition: 'center',
                                                            objectFit: 'cover',
                                                            borderRadius: '10px',
                                                            cursor: 'pointer',
                                                        }}
                                                        alt=""
                                                    />
                                                )}
                                                <Stack
                                                    direction={'column'}
                                                    justifyContent={'center'}
                                                    alignItems={'flex-start'}
                                                    pl={1}
                                                    width={'100%'}
                                                    onClick={() => navigate(`/memory/self/${memory.uuid}`)}
                                                    sx={{ cursor: 'pointer' }}>
                                                    <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-wrap' }}>
                                                        {memory.name}
                                                    </Typography>
                                                </Stack>
                                                {!memory.dailyLife && (
                                                    <Stack justifyContent={'flex-end'} alignItems={'end'}>
                                                        <IconButton
                                                            onClick={(e) => menuButtonHandler(e.currentTarget, memory.uuid)}>
                                                            <img
                                                                src={moreHorizontal}
                                                                style={{ cursor: 'pointer', width: '24px' }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                )}
                                            </Stack>
                                        </Box>
                                    ))}
                                </Stack>
                                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pt: 1 }}>
                                    <Pagination
                                        count={Math.floor(count / queryParams.limit)}
                                        page={queryParams.page}
                                        onChange={(_, value) => handlePageChange(value)}
                                    />
                                </Grid>
                            </TabPanel>
                        </TabContext>
                        <Menu
                            open={!!menuAnchorEl}
                            onClose={menuCloseHandler}
                            anchorEl={menuAnchorEl}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    boxShadow: 1,
                                    px: 1,
                                    placement: 'bottom-end',
                                },
                            }}>
                            <Typography variant={'body2'} textAlign={'center'} sx={{ borderBottom: 1, pb: 1 }}>
                                操作を選択してください
                            </Typography>
                            <MenuList dense>
                                <MenuItem
                                    divider
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                    onClick={() => addButtonHandler()}>
                                    <Button variant={'text'} color={'primary'}>
                                        写真を追加
                                    </Button>
                                </MenuItem>
                                <MenuItem
                                    divider
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                    onClick={() => handleMenuItem()}>
                                    <Button variant={'text'} color={'primary'}>
                                        アルバムを編集
                                    </Button>
                                </MenuItem>
                                <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => deleteHandler()}>
                                    <Button variant={'text'} color={'error'}>
                                        アルバムを削除
                                    </Button>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Stack>
                </Container>
            </DefaultLayout>
            {memoryFileDialog}
        </>
    )
}
