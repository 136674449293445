import { Box, Container, Grid, Pagination, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import arrow from '~/assets/image/gift/arrow.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export const useOfferingOrderHistoryIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const [queryParams, setQueryParams] = useState<QueryParameters>({ page: 1, limit: 10 })

    // initial fetch
    const { data: listResponse, refetch: fetchList } = useQuerySuspense(
        ['/offeringOrder/history/index', queryParams],
        async () => {
            const r = await apiClient.clientOfferingOrderGetHistoryList({ parameter: queryParams })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await fetchList()
    }
    const handleNavigateDetail = async (order: Schemas.OfferingOrderEntities) => {
        navigate(`/offeringOrder/history/${order.uuid}`)
    }

    return {
        queryParams,
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        handlePageChange,
        handleNavigateDetail,
    }
}

export const OfferingOrderHistoryIndexPage: FC = () => {
    const { queryParams, list, count, handlePageChange, handleNavigateDetail } = useOfferingOrderHistoryIndexPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title={'お供え物 購入履歴'} breadcrumbList={[]}>
                <Stack spacing={2}>
                    {list.map((order: Schemas.OfferingOrderEntities) => (
                        <Box key={order.uuid} bgcolor={'white'} p={2} onClick={() => handleNavigateDetail(order)}>
                            <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                <Stack direction={'row'} justifyContent={'start'} spacing={2}>
                                    <Stack>
                                        <img
                                            src={mediaUrl(order.details[0].offeringMaster.file)}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                objectFit: 'contain',
                                                borderRadius: '10px',
                                            }}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Typography variant="h5">{order.details[0].offeringMaster.name}</Typography>
                                        <Typography
                                            sx={{
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                lineHeight: '130%',
                                                color: '#8E8E93',
                                            }}>
                                            {datetimeFormat(order.createdAt)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <img src={arrow} />
                            </Stack>
                        </Box>
                    ))}
                </Stack>
                <Container>
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pt: 3 }}>
                        <Pagination
                            count={Math.floor(count / queryParams.limit) + 1}
                            page={queryParams.page}
                            onChange={(_, value) => handlePageChange(value)}
                        />
                    </Grid>
                </Container>
            </DefaultLayout>
        </>
    )
}
