import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

export const CCommentDelete: FC = () => {
    return (
        <Stack justifyContent={'center'}>
            <Box borderRadius={'18px'} bgcolor={'#EFEAE6'} sx={{ padding: '6px 12px' }}>
                <Typography variant={'body2'} textAlign={'center'}>
                    コメントが削除されました
                </Typography>
            </Box>
        </Stack>
    )
}
