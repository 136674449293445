import { LoadingButton } from '@mui/lab'
import {
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { Schemas } from '~/apis/types'

export const useCGiftMemberDialog = (isOpen: boolean, uuids: string[]) => {
    const [connectUuids, setConnectUuids] = useState<string[]>(uuids || [])
    const handleSelected = (uuid: string) => {
        const index = connectUuids.findIndex((c) => c === uuid)
        if (index > -1) {
            connectUuids.splice(index, 1)
            setConnectUuids([...connectUuids])
        } else setConnectUuids([...connectUuids, uuid])
    }
    useEffect(() => {
        setConnectUuids(uuids)
    }, [isOpen, uuids])
    return {
        connectUuids,
        handleSelected,
    }
}

export type CGiftMemberDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (uuids: string[], uuid?: string) => void
    connects: Schemas.ConnectEntities[]
    uuids: string[]
    title: string
    detailUuid?: string
}

export const CGiftMemberDialog: FC<CGiftMemberDialogProps> = ({
    isOpen,
    onClose,
    onSubmit,
    connects,
    uuids,
    title,
    detailUuid,
}) => {
    const { connectUuids, handleSelected } = useCGiftMemberDialog(isOpen, uuids)
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle textAlign={'center'}>
                    <Typography variant={'subtitle2'}>{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant="body2">
                            つながりリストからギフトのお届け先を選択してください。住所が登録されていない人にギフトを送ることはできません。
                        </Typography>
                        <List sx={{ bgcolor: 'background.paper' }}>
                            {connects &&
                                connects.map((connect: Schemas.ConnectEntities) => (
                                    <ListItem key={connect.uuid} disablePadding>
                                        <ListItemButton role={undefined} onClick={() => handleSelected(connect.uuid)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={!!connectUuids.find((c) => c === connect.uuid)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={`${connect.name}${connect.sei ? `(${connect.sei})` : ''}`} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                        </List>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Container maxWidth={'xs'}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Button variant="outlined" fullWidth onClick={onClose}>
                                キャンセル
                            </Button>
                            <LoadingButton variant={'contained'} fullWidth onClick={() => onSubmit(connectUuids, detailUuid)}>
                                確定
                            </LoadingButton>
                        </Stack>
                    </Container>
                </DialogActions>
            </Dialog>
        </>
    )
}
