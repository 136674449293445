import { LoadingButton } from '@mui/lab'
import { Grid, Stack, Typography } from '@mui/material'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import tombImageYoko from '~/assets/image/grave/ohaka_boseki_yougata.png'
import tombImageTate from '~/assets/image/grave/osoushiki_ohaka.png'
import { CGraveSelect } from '~/components/functional/cGraveSelect/CGraveSelect'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useUserAccountTokenGravePage = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { queueDialog } = useConfirmationDialog()
    const { me } = useAuthState()

    const [loading, setLoading] = useState<boolean>(false)
    const [textDirection, setTextDirection] = useState<string>('tate') // yoko , tate
    const [graveList, setGraveList] = useState([
        {
            file: tombImageTate,
            value: 'tate',
        },
        {
            file: tombImageYoko,
            value: 'yoko',
        },
    ])

    const { data: imageMasters } = useQuerySuspense(
        [`/user/account/grave/master`],
        async () => {
            return await apiClient.clientImageMasterIndex()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    useEffect(() => {
        if (!imageMasters) return
        const tate = imageMasters.find((master) => master.code === 'graveTate')
        const yoko = imageMasters.find((master) => master.code === 'graveYoko')
        setGraveList([
            {
                file: tate && tate.file ? mediaUrl(tate.file) : tombImageTate,
                value: 'tate',
            },
            {
                file: yoko && yoko.file ? mediaUrl(yoko.file) : tombImageYoko,
                value: 'yoko',
            },
        ])
    }, [imageMasters])

    // 編集モーダルの表示状態
    const saveHandler = async () => {
        setLoading(true)
        try {
            // let res: AxiosResponse
            // if (textDirection === 'yoko') res = await axios.get(tombImageYoko, { responseType: 'blob' })
            // else res = await axios.get(tombImageTate, { responseType: 'blob' })
            // const formData = new FormData()
            // formData.append('files', res.data)
            // // @ts-expect-error
            // const uploadedFiles = await apiClient.clientFileUploadUpload({ requestBody: formData })
            // console.log(uploadedFiles)
            //
            // if (uploadedFiles.length < 1) {
            //     setLoading(false)
            //     return
            // }
            let selectImage: Schemas.FileEntities | undefined
            if (imageMasters) {
                if (textDirection === 'tate') {
                    const tate = imageMasters.find((master) => master.code === 'graveTate')
                    if (tate) selectImage = tate.file
                } else {
                    const yoko = imageMasters.find((master) => master.code === 'graveYoko')
                    if (yoko) selectImage = yoko.file
                }
            }
            if (!selectImage) {
                let res: AxiosResponse
                if (textDirection === 'yoko') res = await axios.get(tombImageYoko, { responseType: 'blob' })
                else res = await axios.get(tombImageTate, { responseType: 'blob' })
                const formData = new FormData()
                formData.append('files', res.data)
                // @ts-expect-error
                const uploadedFiles = await apiClient.clientFileUploadUpload({ requestBody: formData })
                console.log(uploadedFiles)

                if (uploadedFiles.length === 0) {
                    setLoading(false)
                    return
                }
                selectImage = uploadedFiles[0]
            }

            const dto = {
                fileUuid: selectImage.uuid,
                name: me?.name ?? 'のお墓',
                posthumousName: '',
                denomination: '',
            }
            await apiClient.clientGraveUpdate({ requestBody: dto })
            navigate('/user', { replace: true })
            setLoading(false)
        } catch (e) {
            setLoading(false)
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const clickImg = (value: string) => {
        setTextDirection(value)
    }

    return {
        graveList,
        textDirection,
        loading,
        clickImg,
        saveHandler,
    }
}

export const UserAccountTokenGravePage: FC = () => {
    const { graveList, textDirection, loading, clickImg, saveHandler } = useUserAccountTokenGravePage()
    return (
        <DefaultLayout title={'終活のはじまりはお墓から'} breadcrumbList={[]} isTab={false}>
            <Stack spacing={3}>
                <Typography variant="body2" textAlign={'center'}>
                    会員情報の入力が完了しました。
                    <br />
                    これ終でお墓を構えて、終活をはじめましょう。
                </Typography>
                <Stack>
                    <Grid container spacing={1} direction={'row'} justifyContent={'center'}>
                        {graveList &&
                            graveList.map((grave) => (
                                <Grid key={grave.file} item xs={6} textAlign={'center'} alignItems="center">
                                    {textDirection === grave.value ? (
                                        <CGraveSelect graveFile={grave.file} graveName={grave.value} click={clickImg} selected />
                                    ) : (
                                        <CGraveSelect graveFile={grave.file} graveName={grave.value} click={clickImg} />
                                    )}
                                </Grid>
                            ))}
                    </Grid>
                </Stack>

                <LoadingButton variant={'contained'} loading={loading} onClick={() => saveHandler()}>
                    終活をはじめる
                </LoadingButton>
            </Stack>
        </DefaultLayout>
    )
}
