import { Container, Typography } from '@mui/material'
import { FC } from 'react'

import { DefaultLayout } from '~/components/layout/Default'

export const SpecifiedCommercialTransactionIndexPage: FC = () => {
    return (
        <>
            <DefaultLayout title={'特定商取引法表記'} breadcrumbList={[]} isTab={false}>
                <Container maxWidth={'sm'}>
                    <Typography variant={'body1'} component={'p'}>
                        販売事業者名
                        <br />
                        株式会社アステール
                        <br />
                        <br />
                        運営責任者名
                        <br />
                        齋藤 早苗
                        <br />
                        <br />
                        問い合わせ窓口(メールアドレス)
                        <br />
                        koichi@gift-masuya.co.jp
                        <br />
                        <br />
                        問い合わせ窓口(電話番号)
                        <br />
                        06-6958-4488
                        <br />
                        <br />
                        本社所在地
                        <br />
                        大阪市鶴見区今津南2-5-4
                        <br />
                        <br />
                        商品代金以外の必要料金
                        <br />
                        振込手数料
                        <br />
                        <br />
                        お支払い方法
                        <br />
                        クレジットカード決済,銀行振込
                        <br />
                        <br />
                        代金の支払い時期
                        <br />
                        クレジットカード決済：購入時
                        <br />
                        銀行振込：注文後24時間以内
                        <br />
                        <br />
                        商品の引渡し時期
                        <br />
                        購入手続き完了後または利用者の指定時期
                        <br />
                        <br />
                        返品・交換不良品・解約について
                        <br />
                        デジタルコンテンツについては、商品の特性上、返品・キャンセルは行えません。
                        <br />
                        ギフトについての返品・キャンセルについてはお問い合わせください。
                        <br />
                        解約についてもお問い合わせよりご依頼ください。
                        <br />
                        不良品の場合は14日以内にお電話またはサイト内のお問い合わせよりご連絡ください。送料は当社負担で良品と交換させていただきます。
                        <br />
                    </Typography>
                </Container>
            </DefaultLayout>
        </>
    )
}
