import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'

import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'

import { CQRCode } from '../../cQRCode/CQRCode'

export const useCUserQrCodeDialog = (text: string) => {
    const { queueDialog } = useConfirmationDialog()
    const copyHandler = async () => {
        navigator.clipboard.writeText(text)
        await queueDialog({
            type: 'alert',
            title: '招待URLをクリップボードにコピーしました',
            text: '',
        })
    }
    return {
        copyHandler,
    }
}

export type CUserQrCodeDialogProps = {
    isOpen: boolean
    onClose: () => void
    text: string
}

export const CUserQrCodeDialog: FC<CUserQrCodeDialogProps> = ({ isOpen, onClose, text }) => {
    const { copyHandler } = useCUserQrCodeDialog(text)
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle />
                <DialogContent>
                    <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                        <CQRCode text={text} />
                    </Stack>
                    <Stack spacing={2} sx={{ pt: 2 }}>
                        <Typography variant="body2" component={'p'}>
                            すでにアカウントをお持ちの方はログインしてから読み込んでください
                        </Typography>
                        <Button variant={'contained'} disableElevation onClick={copyHandler}>
                            招待URLをコピー
                        </Button>
                        <Button variant={'outlined'} onClick={onClose}>
                            閉じる
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
