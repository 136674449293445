import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Button, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import { Schemas } from '~/apis/types'
import { mediaUrl } from '~/utils/common'

export type CGraveOfferingCardCardProps = {
    detail: Schemas.ClientOfferingOrderPreviewDto
    handleRemove?: (index: number) => void
    index: number
    isDeleteIcon?: boolean
}

export const useCGraveOfferingCardCard = (handleRemove?: (index: number) => void) => {
    const handleRemoveOffering = (index: number) => {
        handleRemove!(index)
    }
    return {
        handleRemoveOffering,
    }
}

export const CGraveOfferingCardCard: FC<CGraveOfferingCardCardProps> = ({
    detail,
    handleRemove,
    index,
    isDeleteIcon = false,
}) => {
    const { handleRemoveOffering } = useCGraveOfferingCardCard(handleRemove)
    return (
        <>
            <Stack direction={'row'} justifyContent={'start'} spacing={2}>
                <Stack>
                    <img
                        src={mediaUrl(detail.offeringMaster.file)}
                        style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'contain',
                            borderRadius: '10px',
                        }}
                    />
                </Stack>
                <Stack direction={'column'} alignItems={'start'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                    <Typography variant={'h5'}>{detail.offeringMaster.name}</Typography>
                    <Typography variant={'h6'}>¥{detail.offeringMaster.price.toLocaleString()}</Typography>

                    {isDeleteIcon && (
                        <Stack direction={'row'} justifyContent={'end'} sx={{ width: '100%' }}>
                            <Typography textAlign={'end'}>
                                <Button
                                    variant={'text'}
                                    color={'error'}
                                    onClick={() => handleRemoveOffering(index)}
                                    startIcon={<RemoveCircleIcon />}>
                                    削除
                                </Button>
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </>
    )
}
