import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Link, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import arrowIcon from '~/assets/image/help/mypage/arrowIcon.png'

import { CHelpCommonLink } from '../cHelpCommonLink/CHelpCommonLink'

type LinkItems = {
    linkName: string
    onLinkClick: (sectionName?: string, pathName?: string, newTab?: boolean) => void
}

export type CHelpProps = {
    title: string
    content: string
    navLinkItems: LinkItems[]
    linkNavigate: (path?: string) => void
}

export const CHelp: FC<CHelpProps> = ({ navLinkItems, linkNavigate, title, content }) => {
    return (
        <>
            <Box data-testid="c-help">
                <Box display={'flex'} flexDirection={'row'} justifyContent={'start'} alignItems={'center'}>
                    <Link underline={'none'} color={'inherit'} variant={'body2'} onClick={() => linkNavigate()}>
                        <Stack
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'start'}
                            alignItems={'center'}
                            sx={{ cursor: 'pointer' }}>
                            <ArrowBackIcon />
                            戻る
                        </Stack>
                    </Link>
                </Box>
                <Stack display={'flex'} flexDirection={'column'}>
                    <Typography my={'0.688rem'} variant={'subtitle2'}>
                        {title}
                    </Typography>
                    <Typography variant={'body2'} fontFamily={'Noto Sans JP'}>
                        {content}
                    </Typography>
                </Stack>
                <Box p={'1rem'} borderRadius={'0.625rem'} mt={'2.5rem'} sx={{ backgroundColor: '#EFEAE6' }}>
                    {navLinkItems.map((it, idx) => (
                        <Stack key={idx} flexDirection={'row'} justifyContent={'start'} alignItems={'center'} py={'2px'}>
                            <img
                                src={arrowIcon}
                                style={{ width: 9, height: 11, objectFit: 'cover', borderRadius: '10px', marginRight: '6px' }}
                            />
                            <CHelpCommonLink onClick={() => it.onLinkClick()}>{it.linkName}</CHelpCommonLink>
                        </Stack>
                    ))}
                </Box>
            </Box>
        </>
    )
}
