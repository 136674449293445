import { useDisclosure } from '@chakra-ui/hooks'
import { TabContext, TabList } from '@mui/lab'
import { Box, Button, Grid, Menu, MenuItem, MenuList, Pagination, Stack, Tab, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import addImage from '~/assets/image/memory/addImage.svg'
import { CMemoryBaseInfo } from '~/components/functional/memory/cMemoryBaseInfo/CMemoryBaseInfo'
import { CMemoryDisplayFileDialog } from '~/components/functional/memory/cMemoryDisplayFileDialog/CMemoryDisplayFileDialog'
import { CMemoryFileDialog } from '~/components/functional/memory/cMemoryFileDialog/CMemoryFileDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export const useMemorySelfMemoryUuidPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const memoryUuid = params.memoryUuid
    const navigate = useNavigate()

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const menuButtonHandler = (e: HTMLElement) => {
        setMenuAnchorEl(e)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }
    const handleMenuItem = () => {
        if (!memoryUuid) return
        navigate(`/memory/self/${memoryUuid}/edit`)
    }

    const pageAsset = 1
    const limitAsset = 30
    const [queryParamsAsset, setQueryParamsAsset] = useState<QueryParameters>({ page: pageAsset, limit: limitAsset })
    // initial fetch
    const { data: memory, refetch: fetchMemory } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/detail`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientMemoryGet({ parameter: { memoryUuid: memoryUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: listResponse } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/connectList`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientConnectGetConnectListNotLimit({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: assetResponse, refetch: fetchMemoryAsset } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/asset`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientMemoryAssetGetList({
                parameter: {
                    ...queryParamsAsset,
                    memoryUuid: memoryUuid!,
                },
            })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: imageMaster } = useQuerySuspense(
        ['/memory/share/imageMaster'],
        async () => {
            return await apiClient.clientImageMasterShow({ parameter: { code: 'memoryEveryday' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const deleteMemoryHandler = async () => {
        try {
            const message = '削除します。よろしいでしょうか？'
            if (!memoryUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryDelete({ parameter: { memoryUuid: memoryUuid } })
                navigate('/memory/self', { replace: true })
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const handlePageChangeAsset = async (page: number) => {
        queryParamsAsset.page = page
        setQueryParamsAsset(queryParamsAsset)
        await fetchMemoryAsset()
    }

    const tabHandler = (value: string) => {
        if (!memoryUuid) return
        navigate(`/memory/self/${memoryUuid}/${value}`, { replace: true })
    }

    // 編集モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.FileEntities>()
    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
        menuCloseHandler()
    }
    const editDialogSubmitHandler = async (dto: Schemas.ClientMemoryAssetCreateDto, uuid?: string) => {
        try {
            if (!uuid) await apiClient.clientMemoryAssetCreate({ parameter: { memoryUuid: memoryUuid! }, requestBody: dto })
            // else await apiClient.clientMemoryUpdate({ parameter: { memoryUuid }, requestBody: dto })

            await fetchMemory()
            await fetchMemoryAsset()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const memoryFileDialog = (
        <CMemoryFileDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={editTarget}
        />
    )

    const [displayTarget, setDisplayTarget] = useState<Schemas.MemoryAssetEntities>()
    const { isOpen: displayDialogIsOpen, onOpen: updateDisplayModalOnOpen, onClose: displayDialogOnClose } = useDisclosure()
    const displayFileHandler = (asset: Schemas.MemoryAssetEntities) => {
        if (!asset.file) return
        setDisplayTarget(asset)
        updateDisplayModalOnOpen()
    }
    const deleteHandler = async (asset: Schemas.MemoryAssetEntities) => {
        try {
            const message = '削除すると元に戻すことはできません。この写真を削除しますか？削除します。よろしいでしょうか？'
            if (!asset) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '写真を削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryAssetDelete({ parameter: { memoryUuid: memoryUuid!, assetUuid: asset.uuid } })
                await fetchMemoryAsset()
                displayDialogOnClose()
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const cMemoryDisplayFileDialog = (
        <CMemoryDisplayFileDialog
            isOpen={displayDialogIsOpen}
            onClose={displayDialogOnClose}
            deleteHandler={deleteHandler}
            entity={displayTarget}
        />
    )

    return {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,

        memory,
        imageMaster,

        queryParamsAsset,
        deleteMemoryHandler,
        handlePageChangeAsset,
        tabHandler,
        assetList: assetResponse?.list || [],
        assetCount: assetResponse?.count || 0,

        addButtonHandler,
        memoryFileDialog,

        list: listResponse,

        displayFileHandler,
        cMemoryDisplayFileDialog,
    }
}

export const MemorySelfMemoryUuidPage: FC = () => {
    const {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,

        memory,
        imageMaster,

        queryParamsAsset,
        deleteMemoryHandler,
        handlePageChangeAsset,
        tabHandler,
        assetList,
        assetCount,

        addButtonHandler,
        memoryFileDialog,

        displayFileHandler,
        cMemoryDisplayFileDialog,
    } = useMemorySelfMemoryUuidPage()
    return (
        <>
            <DefaultLayout title="" breadcrumbList={[]} tabValue={'memory'}>
                <CMemoryBaseInfo memory={memory!} menuButtonHandler={menuButtonHandler} imageMaster={imageMaster} />
                <Stack spacing={2}>
                    <TabContext value={''}>
                        <TabList centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                            <Tab
                                label={<Typography variant={'body1'}>写真</Typography>}
                                value={''}
                                onClick={() => tabHandler('')}
                            />
                            <Tab
                                label={<Typography variant={'body1'}>コメント</Typography>}
                                value={'comment'}
                                onClick={() => tabHandler('comment')}
                            />
                            {!memory!.dailyLife && (
                                <Tab
                                    label={<Typography variant={'body1'}>メンバー</Typography>}
                                    value={'member'}
                                    onClick={() => tabHandler('member')}
                                />
                            )}
                        </TabList>
                    </TabContext>
                    <Stack direction={'column'} spacing={1}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Box
                                    component={'img'}
                                    src={addImage}
                                    loading="lazy"
                                    alt="add"
                                    onClick={() => addButtonHandler()}
                                    sx={{
                                        padding: 0.5,
                                        width: { xs: '30vmin', sm: '30vmin' },
                                        maxWidth: '240px',
                                        height: { xs: '30vmin', sm: '30vmin' },
                                        maxHeight: '240px',
                                        objectPosition: 'center',
                                        objectFit: 'cover',
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Grid>
                            {assetList.map((asset: Schemas.MemoryAssetEntities) => (
                                <Grid item xs={4} key={asset.uuid}>
                                    <Box
                                        component={'img'}
                                        src={mediaUrl(asset.file)}
                                        loading="lazy"
                                        alt={asset.file?.filename}
                                        sx={{
                                            padding: 0.5,
                                            width: { xs: '30vmin', sm: '30vmin' },
                                            maxWidth: '240px',
                                            height: { xs: '30vmin', sm: '30vmin' },
                                            maxHeight: '240px',
                                            objectPosition: 'center',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => displayFileHandler(asset)}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pt: 2 }}>
                            <Pagination
                                count={Math.floor(assetCount / queryParamsAsset.limit) + 1}
                                page={queryParamsAsset.page}
                                onChange={(_, value) => handlePageChangeAsset(value)}
                            />
                        </Grid>
                    </Stack>
                </Stack>
            </DefaultLayout>
            {memoryFileDialog}
            {cMemoryDisplayFileDialog}
            <Menu
                open={!!menuAnchorEl}
                onClose={menuCloseHandler}
                anchorEl={menuAnchorEl}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        boxShadow: 1,
                        px: 1,
                        placement: 'bottom-end',
                    },
                }}>
                <Typography variant={'body2'} textAlign={'center'} sx={{ borderBottom: 1, pb: 1 }}>
                    操作を選択してください
                </Typography>
                <MenuList dense>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => addButtonHandler()}>
                        <Button variant={'text'} color={'primary'}>
                            写真を追加
                        </Button>
                    </MenuItem>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleMenuItem()}>
                        <Button variant={'text'} color={'primary'}>
                            アルバムを編集
                        </Button>
                    </MenuItem>
                    <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => deleteMemoryHandler()}>
                        <Button variant={'text'} color={'error'}>
                            アルバムを削除
                        </Button>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}
