import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { CProfileBox } from '~/components/functional/cProfileBox/CProfileBox'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { ClientUserMailUpdateDtoSchema, clientUserMailUpdateDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

export const useUserMailIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const { me } = useAuthState()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        setValue,
    } = useForm<ClientUserMailUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMailUpdateDtoSchema),
    })

    useEffect(() => {
        setValue('email', '')
    }, [])

    const [isSend, setIsSend] = useState<boolean>(false)
    const registerHandler = async (dto: Schemas.ClientUserMailUpdateDto) => {
        try {
            await apiClient.clientUserUpdateMail({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: 'メールを送信しました',
                text: 'メールアドレス登録変更のURLを送信しました。メールをご確認ください。',
            })
            setIsSend(true)
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    const resendHandler = async (dto: Schemas.ClientUserMailUpdateDto) => {
        try {
            await apiClient.clientUserUpdateMail({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: 'メールを再送信しました',
                text: 'メールアドレス登録変更のURLを再送信しました。メールをご確認ください。',
            })
            setIsSend(true)
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    return {
        me,
        register,
        errors,
        handleSubmit,
        registerHandler,
        isSubmitting,
        isValid,
        isSend,
        resendHandler,
    }
}

export const UserMailIndexPage: FC = () => {
    const { me, register, errors, handleSubmit, registerHandler, isSubmitting, isValid, isSend, resendHandler } =
        useUserMailIndexPage()
    return (
        <DefaultLayout title={'メールアドレス登録'} breadcrumbList={[]}>
            {!isSend ? (
                <Stack spacing={2}>
                    <Typography variant="body2" textAlign={'center'}>
                        メールアドレスの登録を行います。入力したメールアドレスに送信されたメールに記載されているリンクにアクセスしてください。
                        <br />
                        メールアドレスはお問合せの際や、これ終からの通知などを受け取る際に必要となります。
                    </Typography>

                    <Stack>
                        <CProfileBox label={'現在のメールアドレス'} value={[me?.email ?? '']} />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label="メールアドレス" required={true} />
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('email')}
                            placeholder={'メールアドレス'}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <LoadingButton
                        variant={'contained'}
                        disabled={!isValid}
                        loading={isSubmitting}
                        onClick={handleSubmit(registerHandler)}>
                        この内容で登録
                    </LoadingButton>
                </Stack>
            ) : (
                <Stack spacing={3}>
                    <Stack>
                        <Typography variant="body2" textAlign={'center'}>
                            メールアドレス登録のURLを送信しました。メールをご確認ください。
                            <br />
                            ログインして、メールに記載しているURLにアクセスしてください。
                        </Typography>
                    </Stack>
                    <LoadingButton variant={'outlined'} onClick={handleSubmit(resendHandler)}>
                        ご登録いただいたメールへ再送信
                    </LoadingButton>
                </Stack>
            )}
        </DefaultLayout>
    )
}
