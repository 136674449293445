import { Box, Link, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import { Schemas } from '~/apis/types'
import trash from '~/assets/image/memory/trash.svg'
import { datetimeFormat } from '~/utils/common'

export type CCommentRightProps = {
    comment: Schemas.MemoryCommentEntities
    deleteMemoryCommentHandler: (commentUuid: string) => Promise<void>
}

export const CCommentRight: FC<CCommentRightProps> = ({ comment, deleteMemoryCommentHandler }) => {
    return (
        <Stack justifyContent={'end'} pl={6}>
            <Box borderRadius={'18px'} bgcolor={'#DFCCEF'} sx={{ padding: '6px 12px' }}>
                <Typography variant={'body2'} sx={{ whiteSpace: 'pre-wrap' }}>
                    {comment.comment}
                </Typography>
            </Box>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                spacing={1}
                sx={{ padding: '0 12px' }}>
                <Typography variant={'caption'} color={'rgba(60, 60, 67, 0.6)'}>
                    {datetimeFormat(comment.createdAt)}
                </Typography>
                <Link
                    color={'#77618B'}
                    sx={{ textDecorationLine: 'underline', padding: '0 12px' }}
                    onClick={() => deleteMemoryCommentHandler(comment.uuid)}>
                    <img src={trash} style={{ position: 'relative', top: '4px', left: '-4px' }} />
                    削除
                </Link>
            </Stack>
        </Stack>
    )
}
