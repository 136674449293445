import { useDisclosure } from '@chakra-ui/hooks'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { CConnectLabel } from '~/components/functional/connect/cConnectLabel/CConnectLabel'
import { CGiftCardCard } from '~/components/functional/gift/cGiftCardCard/CGiftCardCard'
import { CGiftConnectUpdateDialog } from '~/components/functional/gift/cGiftConnectUpdateDialog/CGiftConnectUpdateDialog'
import { CGiftMemberDialog } from '~/components/functional/gift/cGiftMemberDialog/CGiftMemberDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOrderRequestState } from '~/hooks/useOrderRequestState'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useGiftOrderOrderPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const { fetchRequestDto, updateRequestOrderDto } = useOrderRequestState()

    const { data: connectList } = useQuerySuspense(
        [`/gift/order/order/connect`],
        async () => {
            return await apiClient.clientConnectGetConnectListNotLimit({ params: {} })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: requestOrderDto, refetch: refetchRequestDto } = useQuerySuspense(
        [`/gift/order/order`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handleBack = () => {
        navigate(-1)
    }

    const handleNextStep = async () => {
        try {
            if (!requestOrderDto) return
            if (!requestOrderDto.request) return
            if (!requestOrderDto.request.details || requestOrderDto.request.details.length < 1) return
            if (
                !requestOrderDto.request.details.every(
                    (detail: Schemas.ClientGiftDetailCreateDto) => detail.connectUuids && detail.connectUuids.length > 0,
                )
            ) {
                await queueDialog({
                    type: 'alert',
                    title: 'お届け先を指定してください',
                    text: '',
                })
                return
            }

            if (!requestOrderDto.preview) return
            if (!requestOrderDto.preview) return
            if (
                !requestOrderDto.preview.every((preview: Schemas.ClientGiftOrderPreviewDto) => {
                    return preview.connectList.every(
                        (connect: Schemas.ConnectEntities) =>
                            connect?.isConnectUser ||
                            (
                                (connect?.postalCode ?? '') +
                                (connect?.pref ?? '') +
                                (connect?.city ?? '') +
                                (connect?.address ?? '')
                            ).length > 0,
                    )
                })
            ) {
                await queueDialog({
                    type: 'alert',
                    title: 'お届け先の住所を入力してください',
                    text: '',
                })
                return
            }

            await updateRequestOrderDto(requestOrderDto!.request)
            navigate(`/gift/order/specified`)
        } catch (e) {
            let text = 'エラーが発生しました'
            if (e instanceof AxiosError) text = e.response?.data.message || e.message || text
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: text,
            })
        }
    }

    // お届け先モーダルの表示状態
    const { isOpen: editDialogIsOpen, onOpen: editUpdateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const [memberUuids, setMemberUuids] = useState<string[]>([])
    const [detailUuid, setDetailUuid] = useState<string | undefined>(undefined)
    const editButtonHandler = (uuid: string) => {
        const detailDto = requestOrderDto?.request.details!.find((d: Schemas.ClientGiftDetailCreateDto) => d.uuid === uuid)
        if (!detailDto) return
        setDetailUuid(uuid)
        setMemberUuids(detailDto.connectUuids?.map((uuid: string) => uuid) ?? [])
        editUpdateModalOnOpen()
    }
    const dialogSubmitHandler = async (uuids: string[], uuid?: string) => {
        if (!uuid) return
        if (!requestOrderDto?.request) return
        const detailDto = requestOrderDto?.request.details!.find((d: Schemas.ClientGiftDetailCreateDto) => d.uuid === uuid)
        if (!detailDto) return
        detailDto.connectUuids = uuids
        await updateRequestOrderDto(requestOrderDto?.request)
        await refetchRequestDto()
        editDialogOnClose()
    }
    const MemberDialog = (
        <CGiftMemberDialog
            title={'ギフトのお届け先を選択'}
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={dialogSubmitHandler}
            connects={connectList ?? []}
            uuids={memberUuids}
            detailUuid={detailUuid}
        />
    )

    // 住所変更モーダル
    const {
        isOpen: editAddressDialogIsOpen,
        onOpen: editAddressUpdateModalOnOpen,
        onClose: editAddressDialogOnClose,
    } = useDisclosure()
    const [connectEntity, setConnectEntity] = useState<Schemas.ConnectEntities | undefined>(undefined)
    const editAddressButtonHandler = (entity: Schemas.ConnectEntities) => {
        setConnectEntity(entity)
        editAddressUpdateModalOnOpen()
    }
    const handlerUpdateAddress = async (addressDto: Schemas.ClientConnectAddressUpdateDto, conncetUuid?: string) => {
        if (!conncetUuid) return
        await apiClient.clientConnectUpdateAddress({ parameter: { connectUuid: conncetUuid }, requestBody: addressDto })
        await refetchRequestDto()
        editAddressDialogOnClose()
    }
    const ConnectAddressUpdateDialog = (
        <CGiftConnectUpdateDialog
            isOpen={editAddressDialogIsOpen}
            onClose={editAddressDialogOnClose}
            onSubmit={handlerUpdateAddress}
            entity={connectEntity}
        />
    )

    const handleRemoveConnect = async (connectUuid: string, uuid: string) => {
        if (!requestOrderDto?.request) return
        const detailDto = requestOrderDto?.request.details!.find((d: Schemas.ClientGiftDetailCreateDto) => d.uuid === uuid)
        if (!detailDto) return

        const confirm = await queueDialog({
            type: 'confirm',
            title: '選択を解除',
            text: 'お届け先から選択を解除します。よろしいでしょうか？',
        })
        if (confirm) {
            const index = detailDto.connectUuids!.findIndex((c) => c === connectUuid)
            if (index < 0) return
            detailDto.connectUuids!.splice(index, 1)
            await updateRequestOrderDto(requestOrderDto?.request)
            await refetchRequestDto()
        }
    }

    return {
        preview: requestOrderDto!.preview,
        handleBack,
        handleNextStep,
        editButtonHandler,
        MemberDialog,
        editAddressButtonHandler,
        ConnectAddressUpdateDialog,
        handleRemoveConnect,
    }
}

export type GiftOrderOrderDetailProps = {
    previewDto: Schemas.ClientGiftOrderPreviewDto
    editButtonHandler: (uuid: string) => void
    editAddressButtonHandler: (entity: Schemas.ConnectEntities) => void
    handleRemoveConnect: (connectUuid: string, uuid: string) => Promise<void>
}
export const GiftOrderOrderDetail: FC<GiftOrderOrderDetailProps> = ({
    previewDto,
    editButtonHandler,
    editAddressButtonHandler,
    handleRemoveConnect,
}) => {
    return (
        <>
            <Stack key={previewDto.uuid}>
                <Box maxWidth={'mb'} bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                    <CGiftCardCard detail={previewDto} />
                </Box>
                <Box maxWidth={'mb'} bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                    <Stack spacing={2}>
                        <Button variant={'contained'} fullWidth onClick={() => editButtonHandler(previewDto.uuid)}>
                            お届け先を選択
                        </Button>
                        <Typography variant={'body2'}>
                            つながりリストからギフトのお届け先を選択してください。複数のお届け先を選択することでギフトの数量に反映されます。
                        </Typography>
                        <Stack spacing={2}>
                            {previewDto.connectList.map((c: Schemas.ConnectEntities, index: number) => (
                                <Stack key={`${previewDto.uuid}-${c.uuid}`} spacing={1}>
                                    {c.isConnectUser ? (
                                        <>
                                            <Typography variant="subtitle1">現在選択中のお届け先 {index + 1}：</Typography>
                                            <Stack direction={'row'}>
                                                <CConnectLabel connect={c} />
                                            </Stack>
                                            <Typography variant={'body2'}>{`${c.name}${c.sei ? `(${c.sei})` : ''}`}</Typography>
                                            {c.isConnectUserAddress ? (
                                                <>
                                                    <Typography variant={'body2'}>ご本人の登録住所に届けられます</Typography>
                                                    <Stack direction={'row'} justifyContent={'end'} spacing={2}>
                                                        <Link
                                                            color={'error'}
                                                            onClick={() => handleRemoveConnect(c.uuid, previewDto.uuid)}>
                                                            選択を解除
                                                        </Link>
                                                    </Stack>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography variant={'body2'}>{c.postalCode || ''}</Typography>
                                                    <Typography variant={'body2'}>
                                                        {c.pref}
                                                        {c.city}
                                                        {c.address}
                                                        {c.building}
                                                    </Typography>
                                                    <Stack direction={'row'} justifyContent={'end'} spacing={2}>
                                                        <Link color={'#77618B'} onClick={() => editAddressButtonHandler(c)}>
                                                            住所を変更
                                                        </Link>
                                                        <Link
                                                            color={'error'}
                                                            onClick={() => handleRemoveConnect(c.uuid, previewDto.uuid)}>
                                                            選択を解除
                                                        </Link>
                                                    </Stack>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="subtitle1">現在選択中のお届け先 {index + 1}：</Typography>
                                            <Typography variant={'body2'}>{`${c.name}${c.sei ? `(${c.sei})` : ''}`}</Typography>
                                            <Typography variant={'body2'}>{c.postalCode || ''}</Typography>
                                            <Typography variant={'body2'}>
                                                {c.pref}
                                                {c.city}
                                                {c.address}
                                                {c.building}
                                            </Typography>
                                            <Stack direction={'row'} justifyContent={'end'} spacing={2}>
                                                <Link color={'#77618B'} onClick={() => editAddressButtonHandler(c)}>
                                                    住所を変更
                                                </Link>
                                                <Link
                                                    color={'error'}
                                                    onClick={() => handleRemoveConnect(c.uuid, previewDto.uuid)}>
                                                    選択を解除
                                                </Link>
                                            </Stack>
                                        </>
                                    )}
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </Box>
                <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="body1">数量</Typography>
                        <Typography variant="body1">{previewDto.connectList.length.toLocaleString()}</Typography>
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}

export const GiftOrderOrderPage: FC = () => {
    const {
        preview,
        handleBack,
        handleNextStep,
        editButtonHandler,
        MemberDialog,
        editAddressButtonHandler,
        ConnectAddressUpdateDialog,
        handleRemoveConnect,
    } = useGiftOrderOrderPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="お届け先指定" breadcrumbList={[]}>
                <Stack spacing={2}>
                    {preview &&
                        preview.map((previewDto: Schemas.ClientGiftOrderPreviewDto) => (
                            <GiftOrderOrderDetail
                                key={previewDto.uuid}
                                previewDto={previewDto}
                                editButtonHandler={editButtonHandler}
                                editAddressButtonHandler={editAddressButtonHandler}
                                handleRemoveConnect={handleRemoveConnect}
                            />
                        ))}
                </Stack>
                <Container>
                    <Stack spacing={2} sx={{ pt: 2 }}>
                        <LoadingButton variant="contained" onClick={handleNextStep}>
                            お届け日を指定
                        </LoadingButton>
                        <LoadingButton variant="outlined" onClick={handleBack}>
                            カートに戻る
                        </LoadingButton>
                    </Stack>
                </Container>
            </DefaultLayout>
            {MemberDialog}
            {ConnectAddressUpdateDialog}
        </>
    )
}
