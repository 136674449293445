import { Typography } from '@mui/material'
import { FC } from 'react'

import { DefaultLayout } from '~/components/layout/Default'

export const NotFoundPage: FC = () => {
    return (
        <>
            <DefaultLayout breadcrumbList={[]}>
                <Typography>指定されたページは存在しません</Typography>
            </DefaultLayout>
        </>
    )
}
