import { AxiosError } from 'axios'
import type { FC } from 'react'
import { useParams } from 'react-router'

import { CHistoryList } from '~/components/functional/cHistoryList/CHistoryList'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type ConnectHistoryIndexPageViewProps = {}

export const ConnectHistoryIndexPageView: FC<ConnectHistoryIndexPageViewProps> = () => {
    const apiClient = createApiClient()

    const { connectUuid } = useParams()
    const { queueDialog } = useConfirmationDialog()
    const { data: connect } = useQuerySuspense(
        [`/connectList/${connectUuid}`],
        async () => {
            if (!connectUuid) throw new Error()
            return await apiClient.clientConnectGetConnect({
                parameter: { connectUuid },
            })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: historyList } = useQuerySuspense(
        ['/connectList/history'],
        async () => {
            if (!connectUuid) throw new Error()

            return await apiClient.clientConnectGetConnectPersonalHistoryList({
                parameter: { connectUuid },
            })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    return (
        <CHistoryList
            title={`${connect?.name}の自分史`}
            histories={historyList?.list}
            isConnect={true}
            connectUuid={connect?.uuid ?? ''}
            tabValue={'connectList'}
        />
    )
}

export const ConnectHistoryIndexPage: FC = () => {
    return <ConnectHistoryIndexPageView />
}

export default ConnectHistoryIndexPage
