import '~/assets/css/top.css'

import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router'

import contentTitle from '~/assets/image/top/contentTitle.svg'
import contentTitleSp from '~/assets/image/top/contentTitleSp.svg'
import subBodyImage1 from '~/assets/image/top/desktop_01.png'
import subBodyImage2 from '~/assets/image/top/desktop_02.png'
import subBodyImage3 from '~/assets/image/top/desktop_03.png'
import mainImage from '~/assets/image/top/main.svg'
import subBodyImageSP1 from '~/assets/image/top/sp_01.png'
import subBodyImageSP2 from '~/assets/image/top/sp_02.png'
import subBodyImageSP3 from '~/assets/image/top/sp_03.png'
import topLeft from '~/assets/image/top/topLeft.png'
import topRight from '~/assets/image/top/topRight.png'
import { useAuthState } from '~/store/auth'

export const useTopPage = () => {
    const navigate = useNavigate()
    const { me } = useAuthState()

    // initial fetch

    return {
        user: me,
        navigate,
    }
}

export const TopPage: FC = () => {
    const { user, navigate } = useTopPage()
    return (
        <>
            <div className="main-background-image" style={{ backgroundColor: '#F9F5F2' }}>
                <div className="header-1" />
                <div className="header-2" />
                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                    <img src={topLeft} className={'top-left'} />
                    <img src={topRight} className={'top-right'} />
                </Stack>

                <Stack direction={'row'} justifyContent={'center'} sx={{ paddingTop: { xs: '44px', sm: '144px' } }}>
                    <img src={mainImage} className={'main-image'} />
                </Stack>

                <Container maxWidth={'md'}>
                    <div className={'container-button'}>
                        {user ? (
                            <>
                                <Container maxWidth={'sm'}>
                                    <Button variant={'contained'} fullWidth onClick={() => navigate('/user')}>
                                        マイページ
                                    </Button>
                                </Container>
                            </>
                        ) : (
                            <>
                                <Container maxWidth={'sm'}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} py={1} sx={{ px: { sm: 1 } }}>
                                            <Button variant={'contained'} fullWidth onClick={() => navigate('/user/account')}>
                                                新規会員登録
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} py={1} sx={{ px: { sm: 1 } }}>
                                            <Button variant={'contained'} fullWidth onClick={() => navigate('/login')}>
                                                ログイン
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </>
                        )}
                    </div>
                </Container>
                <div className="block" />

                <Container maxWidth={'lg'}>
                    <Grid container sx={{ paddingTop: { xs: '80px', sm: '180px' } }}>
                        <Grid item xs={12}>
                            <Stack direction={'column'} justifyContent={'center'}>
                                <img className={'content-title-image'} src={contentTitle} alt={'これからの終活を、これ終で'} />
                                <img
                                    className={'content-title-image-sp'}
                                    src={contentTitleSp}
                                    alt={'これからの終活を、これ終で'}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} textAlign={'center'}>
                            <p className={'content-body'}>
                                自分自身や仲間との思い出に浸り、大切な人に苦労を残さないように
                                <br />
                                お墓の準備からスタートするこれから終活をはじめる人むけのサービスです。
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ paddingTop: { xs: '80px', sm: '180px' } }}>
                        <Grid item xs={12} sm={6} sx={{ pt: { sm: 8 } }}>
                            <img
                                src={subBodyImage2}
                                alt="つながりの記録"
                                style={{
                                    height: 'auto',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                                className={'content-sub-body-image'}
                            />
                            <img
                                src={subBodyImageSP2}
                                alt="つながりの記録"
                                style={{
                                    height: 'auto',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                                className={'content-sub-body-image-sp'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pl: { sm: 8 }, pt: { sm: 8 } }}>
                            <p className={'content-sub-title'}>つながりの記録</p>
                            <p className={'content-sub-body'}>
                                つながりを大切に。いままでつながりのあったご家族や友人などをはじめとする様々な人を登録し管理できます。不慮の場合のアカウントの代理管理者も指定できます。
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ paddingTop: { xs: '80px', sm: '180px' } }}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            pt={4}
                            sx={{ px: { sm: 1 }, display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' }, pt: { sm: 8 } }}>
                            <img
                                src={subBodyImage1}
                                alt="思い出の記録"
                                style={{
                                    height: 'auto',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                                className={'content-sub-body-image'}
                            />
                            <img
                                src={subBodyImageSP1}
                                alt="思い出の記録"
                                style={{
                                    height: 'auto',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                                className={'content-sub-body-image-sp'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pr: { sm: 8 }, pt: { sm: 8 } }}>
                            <p className={'content-sub-title'}>思い出の記録</p>
                            <p className={'content-sub-body'}>
                                思い出話に花を咲かせる。さまざまな思い出のアルバムを作成し、共有した人とアルバムを中心にメッセージを送りあえます。
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ px: { sm: 1 }, display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' }, pt: { sm: 8 } }}>
                            <img
                                src={subBodyImage1}
                                alt="思い出の記録"
                                style={{
                                    height: 'auto',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                                className={'content-sub-body-image'}
                            />
                            <img
                                src={subBodyImageSP1}
                                alt="思い出の記録"
                                style={{
                                    height: 'auto',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                                className={'content-sub-body-image-sp'}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ paddingTop: { xs: '80px', sm: '180px' } }}>
                        <Grid item xs={12} sm={6} sx={{ px: { sm: 1 }, pt: { sm: 8 } }}>
                            <img
                                src={subBodyImage3}
                                alt="没後に届くギフト"
                                style={{
                                    height: 'auto',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                                className={'content-sub-body-image'}
                            />
                            <img
                                src={subBodyImageSP3}
                                alt="没後に届くギフト"
                                style={{
                                    height: 'auto',
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                                className={'content-sub-body-image-sp'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pl: { sm: 8 }, pt: { sm: 8 } }}>
                            <p className={'content-sub-title'}>没後に届くギフト</p>
                            <p className={'content-sub-body'}>
                                生前には気恥ずかしさでプレゼントを送れなかった方へ。いままでお世話になったつながりのある人に万が一の場合にギフトを贈ることができます。
                            </p>
                        </Grid>
                    </Grid>
                    <Stack direction={'column'} justifyContent={'start'} alignItems={'center'} spacing={2} sx={{ pt: 10, pb: 3 }}>
                        <Box onClick={() => navigate('/sponsorList')} sx={{ cursor: 'pointer' }}>
                            <Typography sx={{ textDecoration: 'underline', fontSize: '0.75rem', fontWeight: '300' }}>
                                協賛事業者一覧
                            </Typography>
                        </Box>
                        <Typography variant={'caption'} sx={{ fontSize: '12px' }}>
                            Ⓒ KORESHU. All Rights Reserved.
                        </Typography>
                    </Stack>
                </Container>
            </div>
        </>
    )
}
