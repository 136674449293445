import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import { Schemas } from '~/apis/types'
import moreHorizontal from '~/assets/image/memory/moreHorizontal.svg'
import noImage from '~/assets/image/memory/noImage.svg'
import { mediaUrl } from '~/utils/common'

export type CMemoryBaseInfoProps = {
    memory: Schemas.MemoryEntities
    menuButtonHandler?: (e: HTMLElement) => void
    imageMaster?: Schemas.ImageMasterEntities
}

export const CMemoryBaseInfo: FC<CMemoryBaseInfoProps> = ({ memory, menuButtonHandler, imageMaster }) => {
    return (
        <>
            <Stack direction={'column'} alignItems={'center'}>
                {memory.dailyLife && imageMaster ? (
                    <img
                        alt={''}
                        src={imageMaster.file ? mediaUrl(imageMaster.file) : noImage}
                        style={{ width: 216, height: 216, objectFit: 'cover', borderRadius: '10px' }}
                    />
                ) : (
                    <img
                        alt={''}
                        src={memory.file ? mediaUrl(memory.file) : noImage}
                        style={{ width: 216, height: 216, objectFit: 'cover', borderRadius: '10px' }}
                    />
                )}
                {menuButtonHandler && !memory.dailyLife ? (
                    <>
                        <Stack alignItems={'end'} width={'100%'} sx={{ position: 'relative', top: 32, right: -8 }}>
                            <img
                                src={moreHorizontal}
                                onClick={(e) => menuButtonHandler(e.currentTarget)}
                                style={{ cursor: 'pointer' }}
                            />
                        </Stack>
                    </>
                ) : (
                    <></>
                )}
                <Box width={'sm'} pt={memory.sharedConnectUserName ? 2 : 'none'}>
                    {memory.sharedConnectUserName && (
                        <Typography variant={'caption'} sx={{ pt: 1, mx: 4, whiteSpace: 'pre-wrap' }}>
                            {memory.sharedConnectUserName} さんから
                        </Typography>
                    )}
                    <Typography sx={{ pt: 2, mx: 4, whiteSpace: 'pre-wrap' }} variant={'h6'}>
                        {memory.name}
                    </Typography>
                    <Typography sx={{ py: 2 }} variant="body2" width={'100%'}>
                        {memory.content}
                    </Typography>
                </Box>
            </Stack>
        </>
    )
}
