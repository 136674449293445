import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useNavigate } from 'react-router'

import { DefaultLayout } from '~/components/layout/Default'

export const useSponsorFormRegisteredPage = () => {
    const navigate = useNavigate()

    return { navigate }
}

export const SponsorFormRegisteredPage: FC = () => {
    const { navigate } = useSponsorFormRegisteredPage()
    return (
        <DefaultLayout title={''} breadcrumbList={[]}>
            <Stack spacing={2} textAlign={'center'}>
                <Typography variant="h4">ご登録ありがとうございました。</Typography>
                <Typography variant="body1">
                    登録内容を確認し、こちらからご連絡する場合がございます。なお、協賛事業者一覧の反映には数日かくる場合がございます。
                </Typography>
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <LoadingButton variant={'contained'} sx={{ width: '232px', height: '48px' }} onClick={() => navigate('/')}>
                    これ終TOPへ
                </LoadingButton>
            </Box>
        </DefaultLayout>
    )
}
