import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Container, MobileStepper, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { v4 } from 'uuid'

import { Schemas } from '~/apis/types'
import noImage from '~/assets/image/noImage.png'
import { CGiftMasterDescription } from '~/components/functional/cGiftMasterDescription/CGiftMasterDescription'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOrderRequestState } from '~/hooks/useOrderRequestState'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

export const useGiftGiftUuidPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const giftUuid = params.giftUuid

    const { fetchRequestDto, updateRequestOrderDto } = useOrderRequestState()
    // initial fetch
    const { data: gift } = useQuerySuspense(
        [`/giftDetail/${giftUuid}/master`],
        async () => {
            return await apiClient.clientGiftGet({ parameter: { giftUuid: giftUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: requestOrderDto, refetch: refetchRequestOrderDto } = useQuerySuspense(
        [`/giftDetail/${giftUuid}/requestOrderDto`],
        async () => {
            const requestDto = await fetchRequestDto()
            console.log(requestDto)
            return requestDto!.request
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const [isAddCartDisabled, setIsAddCartDisabled] = useState(
        requestOrderDto && requestOrderDto.details
            ? !!requestOrderDto.details.find((g: Schemas.ClientGiftDetailCreateDto) => g.giftMasterUuid === giftUuid)
            : false,
    )

    const handleAddCart = async (giftMaster: Schemas.GiftMasterEntities) => {
        console.log(requestOrderDto)

        if (!requestOrderDto || !requestOrderDto.details) {
            const newRequestDto: Schemas.ClientGiftCreateDto = {
                details: [
                    {
                        uuid: v4(),
                        giftMasterUuid: giftMaster.uuid,
                        connectUuids: [],
                    },
                ],
                paymentType: 0,
                isLastGift: true,
                deliveredAt: null,
            }
            console.log('newRequestDto', newRequestDto)
            await updateRequestOrderDto(newRequestDto)
            navigate(`/gift/order/cart`)
        } else {
            const findGift = requestOrderDto.details.find(
                (g: Schemas.ClientGiftDetailCreateDto) => gift!.uuid === g.giftMasterUuid,
            )
            if (!findGift) {
                const dto: Schemas.ClientGiftDetailCreateDto = {
                    uuid: v4(),
                    giftMasterUuid: giftMaster.uuid,
                    connectUuids: [],
                }
                requestOrderDto.details.push(dto)
                if (!requestOrderDto.isLastGift) requestOrderDto.isLastGift = true
                console.log('updateRequestDto', requestOrderDto)
                await updateRequestOrderDto(requestOrderDto)
                navigate(`/gift/order/cart`)
            }
        }
        setIsAddCartDisabled(true)
        await refetchRequestOrderDto()
    }

    const handleCartPage = async () => {
        navigate(`/gift/order/cart`)
    }

    const [activeStep, setActiveStep] = useState(0)
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    const handleStepChange = (step: number) => {
        setActiveStep(step)
    }

    return {
        gift,
        handleAddCart,
        handleCartPage,
        isAddCartDisabled,

        activeStep,
        handleNext,
        handleBack,
        handleStepChange,
    }
}

export const GiftGiftUuidPage: FC = () => {
    const {
        gift,
        handleAddCart,
        isAddCartDisabled,

        activeStep,
        handleNext,
        handleBack,
        handleStepChange,
    } = useGiftGiftUuidPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="" breadcrumbList={[]}>
                <Stack spacing={2}>
                    <Grid container>
                        <Grid xs={12} sm={6}>
                            {gift && gift.files && gift.files.length > 0 ? (
                                <>
                                    <AutoPlaySwipeableViews
                                        axis={'x'}
                                        index={activeStep}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents>
                                        {gift.files.map((file: Schemas.FileEntities, index: number) => (
                                            <div key={file.uuid}>
                                                {Math.abs(activeStep - index) <= 2 ? (
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: 255,
                                                            display: 'block',
                                                            maxWidth: 400,
                                                            overflow: 'hidden',
                                                            width: '100%',
                                                            objectFit: 'contain',
                                                        }}
                                                        src={mediaUrl(file)}
                                                    />
                                                ) : null}
                                            </div>
                                        ))}
                                    </AutoPlaySwipeableViews>
                                    <MobileStepper
                                        steps={gift.files.length}
                                        position="static"
                                        activeStep={activeStep}
                                        nextButton={
                                            <Button
                                                size="small"
                                                onClick={handleNext}
                                                disabled={activeStep === gift.files.length - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <Box
                                        component="img"
                                        sx={{
                                            display: 'block',
                                            overflow: 'hidden',
                                            width: '100%',
                                        }}
                                        src={noImage}
                                    />
                                </>
                            )}
                        </Grid>

                        <Grid xs={12} sm={6} sx={{ p: 2 }}>
                            <Stack spacing={1}>
                                <Typography variant="h5">{gift!.name}</Typography>
                                <Typography variant="h6">¥{gift!.price.toLocaleString()}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Container>
                        <LoadingButton
                            variant={'contained'}
                            onClick={() => handleAddCart(gift!)}
                            disabled={isAddCartDisabled}
                            fullWidth>
                            カートに入れる
                        </LoadingButton>
                    </Container>

                    {gift && gift.description && <CGiftMasterDescription descriptions={gift.description} />}
                </Stack>
            </DefaultLayout>
        </>
    )
}
