import { Button, Container, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'

export type TutorialData = {
    imageTitleSrc: string
    text: string
    imageSrc: string
    imageAlt: string
    href?: string
    buttonText: string
}

export type CTutorialDialogProps = {
    isOpen: boolean
    onClose: (value?: string) => void
    tutorialDataList: TutorialData[]
}

export const useCTutorialDialog = (tutorialDataList: TutorialData[]) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentData, setCurrentData] = useState<TutorialData | undefined>(
        tutorialDataList.length > 0 ? tutorialDataList[0] : undefined,
    )
    const [isLast, setIsLast] = useState(
        tutorialDataList.length === 0 || (tutorialDataList.length && currentIndex === tutorialDataList.length - 1),
    )
    const nextHandle = (value: number) => {
        if (tutorialDataList.length < 1) return

        setCurrentIndex(value + 1)

        setCurrentData(tutorialDataList[value + 1])
        if (value + 1 === tutorialDataList.length - 1) setIsLast(true)
    }

    return {
        isLast,
        currentIndex,
        currentData,
        nextHandle,
    }
}

export const CTutorialDialog: FC<CTutorialDialogProps> = ({ isOpen, onClose, tutorialDataList }) => {
    const { isLast, currentIndex, currentData, nextHandle } = useCTutorialDialog(tutorialDataList)
    return (
        <>
            <Dialog open={isOpen} onClose={() => onClose(currentData?.href)} fullWidth={true} maxWidth={'sm'}>
                <DialogContent sx={{ backgroundColor: '#F9F5F2' }}>
                    <Stack spacing={2} direction={'column'} alignItems={'center'}>
                        {currentData && (
                            <Stack spacing={2} direction={'column'} alignItems={'center'}>
                                <img src={currentData?.imageTitleSrc} style={{ maxWidth: '90%' }} />
                                <Typography variant="body1" component={'p'} sx={{ whiteSpace: 'pre-wrap' }}>
                                    {currentData.text}
                                </Typography>
                                <img
                                    src={currentData.imageSrc}
                                    style={{
                                        objectFit: 'contain',
                                        borderRadius: '10px',
                                        maxWidth: '90%',
                                    }}
                                    alt={currentData.imageAlt}
                                />
                            </Stack>
                        )}
                        <Container maxWidth={'xs'}>
                            {isLast ? (
                                <Button variant={'contained'} fullWidth onClick={() => onClose(currentData?.href)}>
                                    {currentData?.buttonText}
                                </Button>
                            ) : (
                                <Button variant={'contained'} fullWidth onClick={() => nextHandle(currentIndex)}>
                                    {currentData?.buttonText}
                                </Button>
                            )}
                        </Container>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
