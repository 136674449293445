import { Stack, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'

import arrowIcon from '~/assets/image/help/mypage/arrowIcon.png'

import { CHelpImage } from '../cHelpImage/CHelpImage'

export type CHelpLayoutViewProps = {
    children?: ReactNode
} & CHelpLayoutProps

export const useCHelpLayout = () => {
    return {}
}

export type CHelpLayoutProps = {
    id?: string
    title: ReactNode
    body?: ReactNode
    children?: ReactNode
}

export const CHelpLayout: FC<CHelpLayoutProps> = ({ id, title, body, children }) => {
    return (
        <Stack spacing={'1rem'} id={id} data-testid={'c-help-layout'}>
            <Stack spacing={'0.625rem'}>
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'start'} alignItems={'center'}>
                    <CHelpImage src={arrowIcon} styles={{ paddingRight: '4px' }} />
                    <Typography variant={'subtitle2'}>{title}</Typography>
                </Stack>
                {body}
                {children}
            </Stack>
        </Stack>
    )
}
