import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientConnectAddressUpdateDtoSchema, clientConnectAddressUpdateDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

export const useCGiftConnectUpdateDialog = (isOpen: boolean, entity?: Schemas.ConnectEntities) => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<ClientConnectAddressUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientConnectAddressUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            sei: entity?.sei || '',
            postalCode: entity?.postalCode || '',
            pref: entity?.pref || '',
            city: entity?.city || '',
            address: entity?.address || '',
            building: entity?.building || null,
        })
        setPostalCode(entity?.postalCode || '')
    }, [isOpen, entity])

    const [findZipLoading, setFindZipLoading] = useState(false)
    const getZipCodeAddress = async () => {
        if (!postalCode || postalCode === '') {
            await queueDialog({
                type: 'alert',
                title: '郵便番号を入力してください',
                text: '',
            })
            return
        }
        setFindZipLoading(true)
        try {
            const result: Schemas.ClientZipCodeDto[] = await apiClient.clientCommonGetZipCodeAddress({
                parameter: { zipCode: postalCode },
            })
            if (result.length > 0) {
                setValue('pref', result[0].prefecture_name)
                setValue('city', result[0].city_name)
                setValue('address', result[0].town_name)
            } else {
                await queueDialog({
                    type: 'alert',
                    title: '一致する郵便番号が見つかりませんでした',
                    text: '',
                })
            }
        } catch (e) {
            let message = '取得に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        } finally {
            setFindZipLoading(false)
        }
    }
    const [postalCode, setPostalCode] = useState(entity?.postalCode || '')

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        findZipLoading,
        getZipCodeAddress,
        postalCode,
        setPostalCode,
    }
}

export type CGiftConnectUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.ClientConnectAddressUpdateDto, uuid?: string) => void
    entity?: Schemas.ConnectEntities
}

export const CGiftConnectUpdateDialog: FC<CGiftConnectUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        findZipLoading,
        getZipCodeAddress,
        postalCode,
        setPostalCode,
    } = useCGiftConnectUpdateDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>住所を{entity?.uuid ? '編集' : '追加'}</DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'宛名'} required />
                            <TextField
                                id={'sei'}
                                {...register('sei')}
                                placeholder={'宛名'}
                                error={!!errors?.sei}
                                helperText={errors?.sei?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'郵便番号'} required />
                            <Typography variant={'caption'}>※ ハイフン(-)は除いて入力してください</Typography>
                            <Stack direction={'row'} justifyContent={'start'} width={'100%'} spacing={1}>
                                <TextField
                                    id={'postalCode'}
                                    {...register('postalCode')}
                                    label={'郵便番号'}
                                    error={!!errors.postalCode}
                                    helperText={errors.postalCode?.message}
                                    variant={'outlined'}
                                    sx={{ backgroundColor: 'white', maxWidth: '50%' }}
                                    value={postalCode}
                                    onChange={(e) => setPostalCode(e.target.value)}
                                />
                                <LoadingButton
                                    variant={'contained'}
                                    loading={findZipLoading}
                                    onClick={getZipCodeAddress}
                                    sx={{ width: '160px', p: 0 }}>
                                    郵便番号検索
                                </LoadingButton>
                            </Stack>
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'都道府県'} required />
                            <TextField
                                id={'pref'}
                                {...register('pref')}
                                placeholder={'都道府県'}
                                error={!!errors?.pref}
                                helperText={errors?.pref?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'市区町村'} required />
                            <TextField
                                id={'city'}
                                {...register('city')}
                                placeholder={'市区町村'}
                                error={!!errors?.city}
                                helperText={errors?.city?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'番地・その他'} required />
                            <TextField
                                id={'address'}
                                {...register('address')}
                                placeholder={'番地・その他'}
                                error={!!errors?.address}
                                helperText={errors?.address?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'ビル名・部屋番号'} />
                            <TextField
                                id={'building'}
                                {...register('building')}
                                placeholder={'ビル名・部屋番号'}
                                error={!!errors?.building}
                                helperText={errors?.building?.message}
                                variant={'outlined'}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Container maxWidth={'xs'}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Button variant="outlined" fullWidth onClick={onClose}>
                                キャンセル
                            </Button>
                            <LoadingButton
                                variant={'contained'}
                                fullWidth
                                loading={isSubmitting}
                                disabled={!isValid}
                                onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                                保存
                            </LoadingButton>
                        </Stack>
                    </Container>
                </DialogActions>
            </Dialog>
        </>
    )
}
