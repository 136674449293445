import { InputLabel, Typography } from '@mui/material'
import type { FC } from 'react'

import { CRequiredChip } from '../cRequiredChip/CRequiredChip'

export type CInputLabelProps = {
    label: string
    required?: boolean
    variant?: 'h4' | 'h5' | 'h6'
}

export const CInputLabel: FC<CInputLabelProps> = ({ label, required = false, variant }) => {
    return (
        <>
            <InputLabel>
                <Typography variant={variant || 'subtitle2'}>
                    {label}
                    {required && <CRequiredChip />}
                </Typography>
            </InputLabel>
        </>
    )
}
