import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Button, Divider, List, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC } from 'react'

import { Schemas } from '~/apis/types'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useUserCardIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    // initial fetch
    const { data: listResponse, refetch: fetchList } = useQuerySuspense(
        ['/userCard'],
        async () => {
            return await apiClient.clientUserPaymentGetAllList({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const deleteHandler = async (card: Schemas.UserPaymentEntities) => {
        try {
            const message = 'クレジットカード情報を削除しても商品購入時に再登録することができます'
            if (!card) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: 'カードを削除しますか？',
                    text: message,
                })
            ) {
                await apiClient.clientUserPaymentDelete({ parameter: { uuid: card.uuid } })
                await fetchList()
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        list: listResponse || [],

        deleteHandler,
    }
}

export const UserCardIndexPage: FC = () => {
    const { list, deleteHandler } = useUserCardIndexPage()
    return (
        <>
            <DefaultLayout title={'クレジットカード情報'} breadcrumbList={[]}>
                <Stack spacing={2}>
                    <Typography variant={'body2'} textAlign={'center'}>
                        登録したクレジットカード情報の管理ができます。新規カードの登録は商品購入時に可能です。
                    </Typography>
                    <Stack spacing={1}>
                        <List sx={{ background: '#EFEAE6', borderRadius: '8px' }}>
                            {list.map((card: Schemas.UserPaymentEntities, index: number) => (
                                <Stack key={card.uuid}>
                                    <Stack spacing={2} sx={{ p: 2, pb: 1 }}>
                                        <Stack spacing={1}>
                                            <Stack direction={'row'} spacing={1}>
                                                <Typography variant="body1">{card.brand}</Typography>
                                                <Typography variant="body1">下4桁</Typography>
                                                <Typography variant="body1">{card.cardNum}</Typography>
                                            </Stack>
                                            <Typography>{`有効期限 ${card.month}/${card.year}`}</Typography>
                                        </Stack>
                                        <Button
                                            variant="text"
                                            startIcon={<RemoveCircleIcon />}
                                            color={'error'}
                                            onClick={() => deleteHandler(card)}
                                            sx={{ textDecorationLine: 'underline', textUnderlineOffset: '2px' }}>
                                            このカードを削除する
                                        </Button>
                                    </Stack>
                                    {index < list.length - 1 && <Divider variant={'middle'} />}
                                </Stack>
                            ))}
                            {list.length < 1 && (
                                <Stack spacing={2} sx={{ p: 2, pb: 1 }}>
                                    <Typography variant="body1">ご登録しているカードはありません</Typography>
                                </Stack>
                            )}
                        </List>
                    </Stack>
                </Stack>
            </DefaultLayout>
        </>
    )
}
