import { LoadingButton } from '@mui/lab'
import { Box, Container, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { CGiftCardCard } from '~/components/functional/gift/cGiftCardCard/CGiftCardCard'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOrderRequestState } from '~/hooks/useOrderRequestState'
import { useQuerySuspense } from '~/utils/common'

export const useGiftOrderCartPage = () => {
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const { fetchRequestDto, updateRequestOrderDto, clearRequestOrder } = useOrderRequestState()

    // initial fetch
    const { data: requestOrderDto, refetch: fetchRequest } = useQuerySuspense(
        [`/gift/oder/cart`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handleRemoveGift = async (giftMasterUuid: string) => {
        if (!requestOrderDto || !requestOrderDto.request || !requestOrderDto.request.details) return
        const findIndex: number = requestOrderDto.request.details.findIndex(
            (d: Schemas.ClientGiftDetailCreateDto) => d.giftMasterUuid === giftMasterUuid,
        )
        if (findIndex > -1) {
            requestOrderDto?.request?.details?.splice(findIndex, 1)
            await updateRequestOrderDto(requestOrderDto.request)
        }
        await fetchRequest()
    }

    // カートクリアが必要になったら true に変える
    const showClearCart = false
    const handleClearCart = async () => {
        const confirm = await queueDialog({
            type: 'confirm',
            title: 'カートクリア確認',
            text: 'カートをクリアしますか？',
        })
        if (confirm) {
            await clearRequestOrder()
            await fetchRequest()
        }
    }

    const handleNextStep = async () => {
        navigate(`/gift/order/order`)
    }

    const handleGift = async () => {
        navigate(`/gift`)
    }

    return {
        handleRemoveGift,
        handleNextStep,
        handleGift,
        preview: requestOrderDto!.preview,
        handleClearCart,
        showClearCart,
    }
}

export const GiftOrderCartPage: FC = () => {
    const { handleRemoveGift, handleNextStep, handleGift, preview, handleClearCart, showClearCart } = useGiftOrderCartPage()
    return (
        <>
            <DefaultLayout maxWidth={'md'} title="カート" breadcrumbList={[]}>
                <Stack spacing={2}>
                    {preview && preview.length > 0 ? (
                        <>
                            {preview &&
                                preview.map((detail: Schemas.ClientGiftOrderPreviewDto) => (
                                    <Box key={detail.uuid} bgcolor={'white'} p={2}>
                                        <CGiftCardCard
                                            key={detail.giftMaster.uuid}
                                            detail={detail}
                                            handleRemove={handleRemoveGift}
                                            isDeleteIcon
                                        />
                                    </Box>
                                ))}
                            <Container>
                                <Typography variant={'body1'}>
                                    各ギフトには熨斗がつきます。ご不要の方は注文後にお問い合わせからご連絡ください。
                                </Typography>
                            </Container>
                        </>
                    ) : (
                        <Container>
                            <Typography variant="body1">選択したギフトがありません</Typography>
                        </Container>
                    )}

                    <Container>
                        <Stack spacing={2}>
                            <LoadingButton
                                variant="contained"
                                disabled={!(preview && preview.length > 0)}
                                onClick={handleNextStep}>
                                お届き先を指定
                            </LoadingButton>
                            <LoadingButton variant="contained" onClick={handleGift}>
                                ほかのギフトを探す
                            </LoadingButton>

                            {showClearCart && (
                                <LoadingButton variant="outlined" disabled={preview.length === 0} onClick={handleClearCart}>
                                    カートをクリアする
                                </LoadingButton>
                            )}
                        </Stack>
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
