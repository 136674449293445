/**
 * 確認ダイアログ グローバル設置用
 */

import { useDisclosure } from '@chakra-ui/react'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { Stack } from '@mui/system'
import type { FC } from 'react'
import { useEffect } from 'react'

import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'

const useGConfirmationDialog = () => {
    // ダイアログ表示内容
    // 表示するときは中身(ConfirmationDialogQueueTypeImpl)が入る
    // 表示しないときはundefinedになる
    const { currentItem, dequeueDialog } = useConfirmationDialog()

    // currentItemが差し替わったらダイアログの表示を切り替える
    useEffect(() => {
        if (!currentItem) onClose()
        else onOpen()
    }, [currentItem])

    // ダイアログの表示状態管理
    const { isOpen, onOpen, onClose } = useDisclosure()

    // ダイアログが閉じるときの共通処理
    const dialogCloseProcess = (result: boolean) => {
        // guard
        if (!currentItem) return

        // resolveを実行
        currentItem.callback(result)
        // キューの削除
        dequeueDialog()
    }

    // 確認ボタンが押されたとき
    const confirmButtonHandler = () => {
        dialogCloseProcess(true)
    }

    // キャンセルボタンが押されたとき
    const cancelButtonHandler = () => {
        dialogCloseProcess(false)
    }

    const closeButtonHandler = () => {
        cancelButtonHandler()
    }

    return {
        currentItem,
        isOpen,
        confirmButtonHandler,
        cancelButtonHandler,
        onClose,
        closeButtonHandler,
    }
}

export const GConfirmationDialog: FC = () => {
    const { currentItem, isOpen, confirmButtonHandler, cancelButtonHandler, closeButtonHandler } = useGConfirmationDialog()

    return (
        <>
            <Dialog open={isOpen} onClose={closeButtonHandler}>
                <DialogTitle>{currentItem?.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{currentItem?.text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Container maxWidth={'xs'}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            {currentItem?.type === 'confirm' ? (
                                <Button variant={'outlined'} fullWidth onClick={cancelButtonHandler}>
                                    キャンセル
                                </Button>
                            ) : (
                                <></>
                            )}
                            <Button variant={'contained'} fullWidth onClick={confirmButtonHandler}>
                                OK
                            </Button>
                        </Stack>
                    </Container>
                </DialogActions>
            </Dialog>
        </>
    )
}
