import type { FC } from 'react'

import { useQRCode } from '~/hooks/useQRCode'

export type CQRCodeProps = {
    text: string
}

export const CQRCode: FC<CQRCodeProps> = ({ text }) => {
    const { Canvas } = useQRCode()

    return (
        <Canvas
            text={text}
            options={{
                type: 'image/png',
                quality: 0.3,
                level: 'M',
                margin: 3,
                scale: 4,
                width: 200,
            }}
        />
    )
}
