import { TabContext, TabList } from '@mui/lab'
import { Container, Stack, Tab, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router'

import connectImage from '~/assets/image/connect/connectImage.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type ConnectListConnectUuidReceivedMessagePageProps = {}

export const useConnectListConnectUuidReceivedMessagePage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const navigate = useNavigate()
    const connectUuid = params.connectUuid

    // initial fetch
    const { data: connect } = useQuerySuspense(
        [`/connectList/${connectUuid}/receivedMessage`],
        async () => {
            if (!connectUuid) throw new Error()

            return await apiClient.clientConnectGetConnect({ parameter: { connectUuid: connectUuid } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: receivedMessageResponse } = useQuerySuspense(
        [`/connectList/${connectUuid}/receivedMessage/message`],
        async () => {
            if (!connectUuid) throw new Error()

            return await apiClient.clientConnectGetConnectReceivedMessage({ parameter: { connectUuid: connectUuid } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const tabHandler = (value: string) => {
        if (!connectUuid) return
        navigate(`/connectList/${connectUuid}/${value}`, { replace: true })
    }

    return {
        connect,
        receivedMessageResponse,
        tabHandler,
    }
}

export const ConnectListConnectUuidReceivedMessagePage: FC<ConnectListConnectUuidReceivedMessagePageProps> = () => {
    const { connect, receivedMessageResponse, tabHandler } = useConnectListConnectUuidReceivedMessagePage()
    return (
        <>
            <DefaultLayout title={''} maxWidth={'md'} breadcrumbList={[]} tabValue={'connectList'}>
                <Stack spacing={3}>
                    <TabContext value={'receivedMessage'}>
                        <TabList variant={'fullWidth'} centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                            <Tab
                                label={<Typography variant={'caption'}>私からのメッセージ</Typography>}
                                value={'message'}
                                onClick={() => tabHandler('message')}
                            />
                            <Tab
                                label={<Typography variant={'caption'}>相手からのメッセージ</Typography>}
                                value={'receivedMessage'}
                                onClick={() => tabHandler('receivedMessage')}
                            />
                        </TabList>
                    </TabContext>
                    <Container>
                        {connect && receivedMessageResponse && receivedMessageResponse.receivedMessage.length > 0 ? (
                            <>
                                <Typography variant={'body2'} component={'p'} sx={{ whiteSpace: 'pre' }}>
                                    {receivedMessageResponse.receivedMessage}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={4}
                                    px={6}
                                    py={'174px'}>
                                    <img src={connectImage} />
                                    <Typography variant="subtitle1" textAlign={'center'} sx={{ color: '#77618B' }}>
                                        メッセージはお相手の没後に表示されます
                                    </Typography>
                                </Stack>
                            </>
                        )}
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
