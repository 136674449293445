import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormHelperText, Typography } from '@mui/material'
import type { ChangeEvent, FC } from 'react'
import { useState } from 'react'

import { Schemas } from '~/apis/types'
import { createApiClient } from '~/utils/createApiClient'

const useCIconUpload = (completionHandler: (value?: Schemas.FileEntities, error?: unknown) => void) => {
    const apiClient = createApiClient()

    const [uploading, setUploading] = useState(false)

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (!files || files.length === 0) return

        setUploading(true)

        const formData = new FormData()
        formData.append('files', files[0] as Blob)

        try {
            // @ts-expect-error
            const uploadedFiles = await apiClient.clientFileUploadUpload({ requestBody: formData })
            completionHandler(uploadedFiles.at(0))
        } catch (e) {
            completionHandler(undefined, e)
        }

        setUploading(false)
    }

    return {
        onChange,
        uploading,
    }
}

export type CIconUploadProps = {
    label: string
    error?: boolean
    helperText?: string
    completionHandler: (value?: Schemas.FileEntities, error?: unknown) => void
    required?: boolean
}

export const CIconUpload: FC<CIconUploadProps> = ({ label, error, completionHandler, required }) => {
    const { onChange, uploading } = useCIconUpload(completionHandler)

    return (
        <>
            <FormControl error={error}>
                <Typography>{`${label}${required ? '*' : ''}`}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <FormHelperText>{error ? '画像が選択されていません' : ''}</FormHelperText>
                    <LoadingButton loading={uploading} component={'label'} startIcon={<AddCircleSharpIcon />}>
                        写真を追加
                        <input hidden type={'file'} onChange={onChange} />
                    </LoadingButton>
                </Box>
            </FormControl>
        </>
    )
}
