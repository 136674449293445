import { QueryFunction, QueryKey } from '@tanstack/query-core'
import { useQuery } from '@tanstack/react-query'
import { UseQueryOptions } from '@tanstack/react-query/src/types'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { v4 as uuidV4 } from 'uuid'

import { Schemas } from '~/apis/types'
import noImage from '~/assets/image/noImage.png'
import nowPrinting from '~/assets/image/now-printing/now-printing.png'
import userNoImage from '~/assets/image/userNoImage.svg'

export const sleep = (ms: number): Promise<void> =>
    new Promise<void>((resolve) =>
        setTimeout(() => {
            resolve()
        }, ms),
    )

const checkArgument = (value: unknown, name: string) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!value) throw new Error(`The argument "${name}" cannot be empty`)
}

export const filterAsync = async <T>(
    array: readonly T[],
    callback: (value: T, index: number) => Promise<boolean>,
): Promise<T[]> => {
    checkArgument(array, 'array')
    checkArgument(callback, 'callback')

    const results: boolean[] = await Promise.all(array.map((value, index) => callback(value, index)))
    return array.filter((_, i) => results[i])
}

export const generateUUIDv4 = (): string => {
    return uuidV4()
}

export const mediaUrl = (media?: Schemas.FileEntities | null, isProFile = false): string => {
    if (!media) return !isProFile ? noImage : userNoImage

    return `${process.env.BASE_URL}/api/client/media/${media.path}`
}

export const sponsorMediaUrl = (media?: Schemas.FileEntities | null): string => {
    if (!media) return nowPrinting

    return `${process.env.BASE_URL}/api/client/media/${media.path}`
}

export const calcPageLength = (count: number, limit: number) => {
    return Math.floor(count / limit) + 1
}

export const useQueryString = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

export const useQuerySuspense = <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    // TODO ESLint vs Prettier
    // eslint-disable-next-line indent
    queryKey: TQueryKey,
    // eslint-disable-next-line indent
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    // eslint-disable-next-line indent
    options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & {
        initialData?: () => undefined
    },
    // eslint-disable-next-line indent
) => {
    const query = useQuery(queryKey, queryFn, { suspense: true, refetchOnWindowFocus: false, retry: false, ...options })
    if (query.data) return { ...query, data: query.data }
    else if (query.error) throw query.error
    else if (options && typeof options.enabled !== 'undefined' && !options.enabled) return { ...query, data: undefined }
    else throw new Error()
}

export const dateFormat = (val?: string | undefined | null): string => {
    if (!val) return ''
    try {
        return dayjs(val).format('YYYY-MM-DD')
    } catch (e) {
        return ''
    }
}
export const dateFormatTime = (val?: string | undefined | null): string => {
    if (!val) return ''
    try {
        return dayjs(val).format('MM月DD日')
    } catch (e) {
        return ''
    }
}
export const dateFormatMmDd = (val?: string | undefined | null): string => {
    if (!val) return ''
    try {
        return dayjs(val).format('MMDD')
    } catch (e) {
        return ''
    }
}

export const datetimeFormat = (val?: string | undefined | null): string => {
    if (!val) return ''
    try {
        return dayjs(val).format('YYYY-MM-DD HH:mm')
    } catch (e) {
        return ''
    }
}
export const getPaymentType = (paymentType: number) => {
    switch (paymentType) {
        case 0:
            return 'クレジットカード'
        case 1:
            return 'PayPay決済'
        case 2:
            return 'コンビニ決済'
        case 3:
            return '銀行振込'
    }
    return ''
}
export const getPaymentStatus = (paymentStatus: number) => {
    switch (paymentStatus) {
        case 0:
            return '未決済'
        case 1:
            return '確認中'
        case 2:
            return '決済済み'
        case 9:
            return 'キャンセル'
    }
    return ''
}
export const getShippingStatus = (shippingStatus: number) => {
    // { value: 0, label: '未出荷' },
    // { value: 1, label: '配送準備中' },
    // { value: 2, label: '配送済み' },
    // { value: 3, label: '配送キャンセル' },
    // { value: 4, label: '配送出戻り' },
    // { value: 9, label: '注文キャンセル' },

    switch (shippingStatus) {
        case 0:
            return '未出荷'
        case 1:
            return '配送準備中'
        case 2:
            return '配送済み'
        case 3:
            return '配送キャンセル'
        case 4:
            return '配送出戻り'
        case 9:
            return '注文キャンセル'
    }
    return ''
}

export const displayTimeFormat = (val: number): string => {
    if (!val) return ''
    const days = Math.floor(val / (60 * 24))
    const valDiffDay = val - days * (60 * 24)
    const hours = Math.floor(valDiffDay / 60)
    const minutes = valDiffDay - hours * 60
    const dayMessage = days === 0 ? '' : `${days}日`
    const hourMessage = hours === 0 ? '' : `${hours}時間`
    const minutesMessage = minutes === 0 ? '' : `${minutes}分`
    return dayMessage + hourMessage + minutesMessage
}

export const JapanesePeriod = [
    { value: 0, text: '明治' },
    { value: 1, text: '大正' },
    { value: 2, text: '昭和' },
    { value: 3, text: '平成' },
    { value: 4, text: '令和' },
] as const
