import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Typography } from '@mui/material'
import type { FC } from 'react'
import { useNavigate } from 'react-router'

export type CProfileBoxProps = {
    label: string
    value: string[]
    toPath?: string
}
const useCProfileBox = () => {
    const navigate = useNavigate()

    const clickPageChange = (path: string) => {
        navigate(path)
    }
    return {
        clickPageChange,
    }
}

export const CProfileBox: FC<CProfileBoxProps> = ({ label, value, toPath }) => {
    const { clickPageChange } = useCProfileBox()
    return (
        <>
            <Box
                borderRadius={'8px'}
                padding={1}
                sx={{ backgroundColor: '#EFEAE6' }}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box>
                    <Typography variant="body2">{label}</Typography>
                    {value.map((text: string, index: number) => (
                        <Typography variant="body2" key={index}>
                            {text}
                        </Typography>
                    ))}
                </Box>
                {toPath && (
                    <IconButton onClick={() => clickPageChange(toPath)}>
                        <EditIcon color={'primary'} />
                    </IconButton>
                )}
            </Box>
        </>
    )
}
