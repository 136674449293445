import 'dayjs/locale/ja'

import { LoadingButton } from '@mui/lab'
import { Paper, Stack, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOfferingOrderRequestState } from '~/hooks/useOfferingOrderRequestState'
import { useQuerySuspense } from '~/utils/common'

export const useGraveOfferingOrderCommentPage = () => {
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid
    const graveUuid = params.graveUuid
    const { fetchRequestDto, updateRequestOrderDto } = useOfferingOrderRequestState(connectUuid!)

    // initial fetch
    const { data: requestOrderDto } = useQuerySuspense(
        [`/grave/offering/reserve/requestOrderDto`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const [comment, setComment] = useState<string>(requestOrderDto?.request.comment || '')

    // 初期化
    useEffect(() => {
        setComment(requestOrderDto!.request.comment || '')
    }, [requestOrderDto])

    // next step
    const handleNextStep = async () => {
        requestOrderDto!.request.comment = comment
        await updateRequestOrderDto(requestOrderDto!.request)
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/payment`)
    }

    const handlePrevStep = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/reserve`)
    }

    return {
        handleNextStep,
        handlePrevStep,
        comment,
        setComment,
    }
}

export const GraveOfferingOrderCommentPage: FC = () => {
    const { handleNextStep, handlePrevStep, comment, setComment } = useGraveOfferingOrderCommentPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="お供えコメント登録" breadcrumbList={[]}>
                <Stack spacing={3}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Stack spacing={2}>
                            <Stack spacing={0.5}>
                                <CInputLabel label={'コメント'} />
                                <TextField
                                    id={'comment'}
                                    placeholder={'コメント'}
                                    value={comment}
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Stack>
                            <Typography variant={'caption'}>
                                お供えと一緒にお気持ちのメッセージ伝えることができます。
                                <br />
                                ※スキップ可能です。
                            </Typography>
                        </Stack>
                    </Paper>

                    <Container maxWidth={'sm'} component={'div'}>
                        <Stack spacing={2}>
                            <LoadingButton disableElevation variant="contained" onClick={handleNextStep}>
                                お支払い方法を選択
                            </LoadingButton>

                            <LoadingButton variant="outlined" onClick={handlePrevStep}>
                                お供え日指定に戻る
                            </LoadingButton>
                        </Stack>
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
