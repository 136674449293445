import { useDisclosure } from '@chakra-ui/hooks'
import { ListItem } from '@material-ui/core'
import { TabContext, TabList } from '@mui/lab'
import {
    Avatar,
    Button,
    List,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    Tab,
    Typography,
} from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import addIcon from '~/assets/image/memory/addIcon.svg'
import { CMemberDialog } from '~/components/functional/cMember/CMemberDialog'
import { CMemoryBaseInfo } from '~/components/functional/memory/cMemoryBaseInfo/CMemoryBaseInfo'
import { CMemoryFileDialog } from '~/components/functional/memory/cMemoryFileDialog/CMemoryFileDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useMemorySelfMemoryUuidMemberPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const memoryUuid = params.memoryUuid
    const navigate = useNavigate()

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const menuButtonHandler = (e: HTMLElement) => {
        setMenuAnchorEl(e)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }
    const handleMenuItem = () => {
        if (!memoryUuid) return
        navigate(`/memory/self/${memoryUuid}/edit`)
    }

    // initial fetch
    const { data: memory, refetch: fetchMemory } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/member`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientMemoryGet({ parameter: { memoryUuid: memoryUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: connectList } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/connectList`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientConnectGetConnectListNotLimit({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: imageMaster } = useQuerySuspense(
        ['/memory/share/imageMaster'],
        async () => {
            return await apiClient.clientImageMasterShow({ parameter: { code: 'memoryEveryday' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const deleteMemoryHandler = async () => {
        try {
            const message = '削除します。よろしいでしょうか？'
            if (!memoryUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryDelete({ parameter: { memoryUuid: memoryUuid } })
                navigate('/memory/self', { replace: true })
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const tabHandler = (value: string) => {
        if (!memoryUuid) return
        navigate(`/memory/self/${memoryUuid}/${value}`, { replace: true })
    }

    // 画像モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.FileEntities>()
    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
        menuCloseHandler()
    }
    const editDialogSubmitHandler = async (dto: Schemas.ClientMemoryAssetCreateDto, uuid?: string) => {
        try {
            if (!uuid) await apiClient.clientMemoryAssetCreate({ parameter: { memoryUuid: memoryUuid! }, requestBody: dto })
            // else await apiClient.clientMemoryUpdate({ parameter: { memoryUuid }, requestBody: dto })

            await fetchMemory()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const memoryFileDialog = (
        <CMemoryFileDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={editTarget}
        />
    )

    // メンバーモーダルの表示状態
    const [connectUuids, setConnectUuids] = useState<string[]>(memory?.connects.map((c: Schemas.ConnectEntities) => c.uuid) || [])
    const { isOpen: editMemberDialogIsOpen, onOpen: updateMemberModalOnOpen, onClose: editMemberDialogOnClose } = useDisclosure()
    const memberButtonHandler = () => {
        updateMemberModalOnOpen()
    }
    const memberDialogSubmitHandler = async (uuids: string[]) => {
        setConnectUuids(uuids)
        if (!memoryUuid) return
        try {
            const dto: Schemas.ClientMemoryMemberUpdateDto = { connectUuids: uuids }
            await apiClient.clientMemoryUpdateMember({
                parameter: { memoryUuid: memoryUuid },
                requestBody: dto,
            })
            await fetchMemory()
            editMemberDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const MemberDialog = (
        <CMemberDialog
            isOpen={editMemberDialogIsOpen}
            onClose={editMemberDialogOnClose}
            onSubmit={memberDialogSubmitHandler}
            connects={connectList || []}
            uuids={connectUuids}
            title={'共有メンバーを選択'}
        />
    )

    return {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,

        memory,
        imageMaster,
        deleteMemoryHandler,
        tabHandler,
        memberList: memory?.connects || [],

        addButtonHandler,
        memoryFileDialog,

        memberButtonHandler,
        MemberDialog,
    }
}

export const MemorySelfMemoryUuidMemberPage: FC = () => {
    const {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,

        memory,
        imageMaster,
        deleteMemoryHandler,
        tabHandler,
        memberList,

        addButtonHandler,
        memoryFileDialog,

        memberButtonHandler,
        MemberDialog,
    } = useMemorySelfMemoryUuidMemberPage()
    return (
        <>
            <DefaultLayout title="" breadcrumbList={[]} tabValue={'memory'}>
                <CMemoryBaseInfo memory={memory!} menuButtonHandler={menuButtonHandler} imageMaster={imageMaster} />
                <Stack spacing={2}>
                    <TabContext value={'member'}>
                        <TabList centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                            <Tab
                                label={<Typography variant={'body1'}>写真</Typography>}
                                value={''}
                                onClick={() => tabHandler('')}
                            />
                            <Tab
                                label={<Typography variant={'body1'}>コメント</Typography>}
                                value={'comment'}
                                onClick={() => tabHandler('comment')}
                            />
                            {!memory!.dailyLife && (
                                <Tab
                                    label={<Typography variant={'body1'}>メンバー</Typography>}
                                    value={'member'}
                                    onClick={() => tabHandler('member')}
                                />
                            )}
                        </TabList>
                    </TabContext>
                    <Stack direction={'column'} spacing={1}>
                        <Button variant={'contained'} startIcon={<img src={addIcon} />} onClick={() => memberButtonHandler()}>
                            共有メンバー編集
                        </Button>
                        <Typography variant={'h6'}>{`${memberList.length}人のメンバー`}</Typography>
                        <List>
                            {memberList &&
                                memberList.map((connect: Schemas.ConnectEntities) => (
                                    <ListItem key={connect.uuid} divider>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={connect!.name}
                                                src={mediaUrl(connect!.file, true)}
                                                sx={{ width: 40, height: 40 }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={connect.name} />
                                    </ListItem>
                                ))}
                        </List>
                    </Stack>
                </Stack>
            </DefaultLayout>
            {memoryFileDialog}
            {MemberDialog}
            <Menu
                open={!!menuAnchorEl}
                onClose={menuCloseHandler}
                anchorEl={menuAnchorEl}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        boxShadow: 1,
                        px: 1,
                        placement: 'bottom-end',
                    },
                }}>
                <Typography variant={'body2'} textAlign={'center'} sx={{ borderBottom: 1, pb: 1 }}>
                    操作を選択してください
                </Typography>
                <MenuList dense>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => addButtonHandler()}>
                        <Button variant={'text'} color={'primary'}>
                            写真を追加
                        </Button>
                    </MenuItem>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleMenuItem()}>
                        <Button variant={'text'} color={'primary'}>
                            アルバムを編集
                        </Button>
                    </MenuItem>
                    <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => deleteMemoryHandler()}>
                        <Button variant={'text'} color={'error'}>
                            アルバムを削除
                        </Button>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}
