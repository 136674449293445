import { Container, Typography } from '@mui/material'
import { FC } from 'react'

import { DefaultLayout } from '~/components/layout/Default'

export const PrivacyIndexPage: FC = () => {
    return (
        <>
            <DefaultLayout title={'プライバシーポリシー'} breadcrumbList={[]} isTab={false}>
                <Container maxWidth={'sm'}>
                    <Typography variant={'body1'} component={'p'}>
                        第1条（個人情報）
                        <br />
                        <br />
                        1.
                        「個人情報」とは、個人に関する情報であり、生存する個人を特定できる情報を指します。氏名、生年月日、住所、電話番号、メールアドレス、その他特定の個人を識別することができる情報を含みます。
                        <br />
                        <br />
                        第2条（個人情報の収集方法）
                        <br />
                        <br />
                        1.
                        当社は、お客様が自らの意志で提供した情報を収集します。その収集方法は、お客様が当社の提供するサービスに登録する際、問い合わせや意見を送信する際などに限られます。
                        <br />
                        <br />
                        第3条（個人情報を収集・利用する目的）
                        <br />
                        <br />
                        1. 当社が個人情報を収集・利用する目的は、以下のとおりです。
                        <br />
                        (1)当社サービスの提供・運営のため
                        <br />
                        (2)お客様からのお問い合わせに対する回答のため
                        <br />
                        (3)お客様が利用中のサービスの新機能、更新情報、キャンペーン等といった情報の提供のため
                        <br />
                        (4)利用規約に違反したお客様や、不正・不当な目的でサービスを利用しようとするお客様の特定をし、利用をお断りまたは強制終了するため
                        <br />
                        (5)お客様に自身の登録情報の閲覧や修正、削除、利用停止等を行ってもらうため
                        <br />
                        <br />
                        第4条（個人情報の第三者提供）
                        <br />
                        <br />
                        1. 当社は、以下の場合を除き、個人情報をお客様の事前の同意を得ることなく第三者に提供しません。
                        <br />
                        (1)法令に基づく場合
                        <br />
                        (2)人命、身体または財産の保護のために必要がある場合で、本人の同意を得ることが困難である場合
                        <br />
                        (3)公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合で、本人の同意を得ることが困難である場合
                        <br />
                        (4)国の機関もしくは地方公共団体またはその委託を受けた者が法令に定める事務を遂行することに対して協力する必要がある場合で、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                        <br />
                        <br />
                        第5条（個人情報の安全対策）
                        <br />
                        <br />
                        1.
                        当社は、個人情報の正確性及び安全性確保のために、セキュリティシステムの維持、管理体制の整備、社員教育の実施等により、個人情報への不正アクセス・紛失・破壊・改ざん・漏洩等を防止します。
                        <br />
                        <br />
                        第6条（セキュリティについて）
                        <br />
                        <br />
                        1.
                        ユーザーの登録情報はユーザーのプライバシー保護及びセキュリティの為、パスワードで保護されています。また本サイトでは、お客様のプライバシー保護のため、「個人情報入力ページ」においてSSL暗号化通信を実現しています。尚、SSLで暗号化されたページ(URLがhttps://で始まるページ）をご利用いただくには、ブラウザの設定をSSLが利用できるように設定してください。
                        <br />
                        ※ご注意：SSLを利用するためのブラウザ設定方法はご利用のブラウザによって異なります。
                        <br />
                        <br />
                        第7条（Cookieの使用について）
                        <br />
                        <br />
                        1.
                        本サイトでは広告配信やアクセス解析のためにCookieを使用しています。Cookieによりブラウザを識別していますが、特定の個人の識別はできない状態で匿名性が保たれています。Cookieの使用を望まない場合、ブラウザからCookieを無効に設定できます。
                        <br />
                        <br />
                        第8条（このプライバシーの考え方の改訂について）
                        <br />
                        <br />
                        1. 今後、本プライバシーの考え方の全部または一部を改訂することがあります。
                        <br />
                        <br />
                        第9条（お客様の個人情報の取扱いに関するお問い合わせ先）
                        <br />
                        <br />
                        株式会社アステール
                        <br />
                        電話：06-6958-4488
                        <br />
                        住所：〒538-0043大阪市鶴見区今津南2-5-4
                        <br />
                    </Typography>
                </Container>
            </DefaultLayout>
        </>
    )
}
