import { Box, Link, Typography } from '@mui/material'
import { FC } from 'react'

import { Schemas } from '~/apis/types'
import { dateFormat } from '~/utils/common'

const useCInformationItem = () => {
    const getNavigateUrl = (info: Schemas.NotificationBoardEntities): string | undefined => {
        let linkString: string | undefined = undefined
        switch (info.boardType) {
            case 'connectApproval':
            case 'deathDay':
            case 'offeringOrder':
                if (info.options && info.options.uuid) linkString = `/connectList/${info.options.uuid}`
                break
            case 'giftOrderShipped':
            case 'giftOrder':
                if (info.options && info.options.uuid) linkString = `/gift/history/${info.options.uuid}`
                break
            case 'associateUser':
                linkString = `/user/connection/apply`
                break
        }
        return linkString
    }

    return { getNavigateUrl }
}

type CInformationItemProps = {
    item: Schemas.NotificationBoardEntities
}

export const CInformationItem: FC<CInformationItemProps> = ({ item }) => {
    const { getNavigateUrl } = useCInformationItem()
    return (
        <Box sx={{ mx: 2, py: 1, borderTop: '1px solid #C6C6C8' }}>
            {getNavigateUrl(item) ? (
                <Link href={getNavigateUrl(item)}>
                    <Typography variant={'body2'}>{item.content}</Typography>
                </Link>
            ) : (
                <Typography variant={'body2'}>{item.content}</Typography>
            )}

            <Typography sx={{ textAlign: 'right' }} variant={'body1'}>
                {dateFormat(item.createdAt)}
            </Typography>
        </Box>
    )
}
