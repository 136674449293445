import { useDisclosure } from '@chakra-ui/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { TabContext, TabList } from '@mui/lab'
import { AppBar, Button, Container, Menu, MenuItem, MenuList, Stack, Tab, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import sendImage from '~/assets/image/memory/send.svg'
import { CCommentDelete } from '~/components/functional/cCommentDelete/CCommentDelete'
import { CCommentLeft } from '~/components/functional/cCommentLeft/CCommentLeft'
import { CCommentRight } from '~/components/functional/cCommentRight/CCommentRight'
import { CMemoryBaseInfo } from '~/components/functional/memory/cMemoryBaseInfo/CMemoryBaseInfo'
import { CMemoryFileDialog } from '~/components/functional/memory/cMemoryFileDialog/CMemoryFileDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientMemoryCommentCreateDtoSchema, clientMemoryCommentCreateDtoSchema } from '~/types/zodScheme'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useMemorySelfMemoryUuidCommentPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const memoryUuid = params.memoryUuid
    const navigate = useNavigate()
    const scrollBottomRef = useRef<HTMLDivElement>(null)

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const menuButtonHandler = (e: HTMLElement) => {
        setMenuAnchorEl(e)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }
    const handleMenuItem = () => {
        if (!memoryUuid) return
        navigate(`/memory/self/${memoryUuid}/edit`)
    }

    // initial fetch
    const { data: memory, refetch: fetchMemory } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/comment`],
        async () => {
            if (!memoryUuid) return
            return await apiClient.clientMemoryGet({ parameter: { memoryUuid: memoryUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
            enabled: !!memoryUuid,
        },
    )

    const { data: commentResponse, refetch: fetchMemoryComment } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/comment`, memoryUuid],
        async () => {
            if (!memoryUuid) return
            const comments = await apiClient.clientMemoryCommentGetList({
                parameter: {
                    memoryUuid: memoryUuid!,
                },
            })
            return comments
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
            enabled: !!memoryUuid,
        },
    )

    const { data: imageMaster } = useQuerySuspense(
        ['/memory/share/imageMaster'],
        async () => {
            return await apiClient.clientImageMasterShow({ parameter: { code: 'memoryEveryday' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    useLayoutEffect(() => {
        scrollBottomRef?.current?.scrollIntoView()
    }, [])

    const deleteMemoryHandler = async () => {
        try {
            const message = '削除します。よろしいでしょうか？'
            if (!memoryUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryDelete({ parameter: { memoryUuid: memoryUuid } })
                navigate('/memory/self', { replace: true })
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    // コメント制御
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientMemoryCommentCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientMemoryCommentCreateDtoSchema),
    })

    useEffect(() => {
        reset({
            comment: '',
        })
        scrollBottomRef?.current?.scrollIntoView()
    }, [isSubmitting])

    const sendCommentHandler = async (dto: Schemas.ClientGraveCommentCreateDto) => {
        if (!memory) return
        try {
            await apiClient.clientMemoryCommentCreate({
                parameter: { memoryUuid: memory.uuid },
                requestBody: dto,
            })
            await fetchMemoryComment()
            scrollBottomRef?.current?.scrollIntoView()
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    const deleteMemoryCommentHandler = async (commentUuid: string) => {
        try {
            const message = '削除します。よろしいでしょうか？'
            if (!memoryUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryCommentDelete({ parameter: { memoryUuid: memoryUuid, commentUuid: commentUuid } })
                await fetchMemoryComment()
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const tabHandler = (value: string) => {
        if (!memoryUuid) return
        navigate(`/memory/self/${memoryUuid}/${value}`, { replace: true })
    }

    // 画像モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.FileEntities>()
    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
        menuCloseHandler()
    }
    const editDialogSubmitHandler = async (dto: Schemas.ClientMemoryAssetCreateDto, uuid?: string) => {
        try {
            if (!uuid) await apiClient.clientMemoryAssetCreate({ parameter: { memoryUuid: memoryUuid! }, requestBody: dto })
            // else await apiClient.clientMemoryUpdate({ parameter: { memoryUuid }, requestBody: dto })

            await fetchMemory()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const memoryFileDialog = (
        <CMemoryFileDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,
        scrollBottomRef,

        memory,
        imageMaster,

        deleteMemoryHandler,
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendCommentHandler,
        deleteMemoryCommentHandler,
        tabHandler,
        commentList: commentResponse?.list || [],
        commentCount: commentResponse?.count || 0,

        addButtonHandler,
        memoryFileDialog,
    }
}

export const MemorySelfMemoryUuidCommentPage: FC = () => {
    const {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,
        scrollBottomRef,

        memory,
        imageMaster,

        deleteMemoryHandler,
        register,
        handleSubmit,
        sendCommentHandler,
        deleteMemoryCommentHandler,
        tabHandler,
        commentList,

        addButtonHandler,
        memoryFileDialog,
    } = useMemorySelfMemoryUuidCommentPage()
    return (
        <>
            <DefaultLayout title="" breadcrumbList={[]} tabValue={'memory'}>
                <CMemoryBaseInfo memory={memory!} menuButtonHandler={menuButtonHandler} imageMaster={imageMaster} />
                <Stack spacing={2}>
                    <TabContext value={'comment'}>
                        <TabList centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                            <Tab
                                label={<Typography variant={'body1'}>写真</Typography>}
                                value={''}
                                onClick={() => tabHandler('')}
                            />
                            <Tab
                                label={<Typography variant={'body1'}>コメント</Typography>}
                                value={'comment'}
                                onClick={() => tabHandler('comment')}
                            />
                            {!memory!.dailyLife && (
                                <Tab
                                    label={<Typography variant={'body1'}>メンバー</Typography>}
                                    value={'member'}
                                    onClick={() => tabHandler('member')}
                                />
                            )}
                        </TabList>
                    </TabContext>
                    <Stack direction={'column'} spacing={2}>
                        {commentList.map((comment: Schemas.MemoryCommentEntities) => (
                            <Stack key={comment.uuid}>
                                {comment.deletedAt ? (
                                    <CCommentDelete />
                                ) : (
                                    <>
                                        {comment.user ? (
                                            <CCommentRight
                                                comment={comment}
                                                deleteMemoryCommentHandler={deleteMemoryCommentHandler}
                                            />
                                        ) : (
                                            <CCommentLeft
                                                comment={comment}
                                                deleteMemoryCommentHandler={deleteMemoryCommentHandler}
                                            />
                                        )}
                                    </>
                                )}
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
                <div ref={scrollBottomRef} />
            </DefaultLayout>
            {memoryFileDialog}
            <Menu
                open={!!menuAnchorEl}
                onClose={menuCloseHandler}
                anchorEl={menuAnchorEl}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        boxShadow: 1,
                        px: 1,
                        placement: 'bottom-end',
                    },
                }}>
                <Typography variant={'body2'} textAlign={'center'} sx={{ borderBottom: 1, pb: 1 }}>
                    操作を選択してください
                </Typography>
                <MenuList dense>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => addButtonHandler()}>
                        <Button variant={'text'} color={'primary'}>
                            写真を追加
                        </Button>
                    </MenuItem>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleMenuItem()}>
                        <Button variant={'text'} color={'primary'}>
                            アルバムを編集
                        </Button>
                    </MenuItem>
                    <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => deleteMemoryHandler()}>
                        <Button variant={'text'} color={'error'}>
                            アルバムを削除
                        </Button>
                    </MenuItem>
                </MenuList>
            </Menu>
            <AppBar
                position="fixed"
                sx={{
                    top: 'auto',
                    bottom: 74,
                    bgcolor: '#B188CA',
                    boxShadow: 'none',
                    display: 'flex',
                    direction: 'row',
                    justifyContent: 'center',
                }}>
                <Container maxWidth={'sm'}>
                    <Stack direction={'row'} justifyContent={'center'} margin={'8px 12px'} spacing={2}>
                        <TextField
                            id={'content'}
                            {...register('comment')}
                            variant={'outlined'}
                            sx={{ backgroundColor: 'white', borderRadius: '100px', padding: '-8px' }}
                            fullWidth
                        />
                        <img src={sendImage} onClick={handleSubmit(sendCommentHandler)} style={{ cursor: 'pointer' }} />
                    </Stack>
                </Container>
            </AppBar>
        </>
    )
}
