import { List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type ConnectListManagementPageProps = {}

export const useConnectListManagementPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    // initial fetch
    const { data: connectList } = useQuerySuspense(
        ['/connect/list'],
        async () => {
            return await apiClient.clientConnectGetConnectManagementList({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const detailHandler = async (connect: Schemas.ConnectEntities) => {
        navigate(`/connectList/${connect.uuid}`)
    }

    return {
        connectList: connectList || [],
        detailHandler,
    }
}

export const ConnectListManagementPage: FC<ConnectListManagementPageProps> = () => {
    const { connectList, detailHandler } = useConnectListManagementPage()
    return (
        <>
            <DefaultLayout title={''} breadcrumbList={[]} tabValue={'connectList'}>
                <Stack spacing={2}>
                    <Typography variant="body2">あなたに没後の管理を託されています</Typography>

                    <Stack>
                        {connectList.length > 0 ? (
                            <List sx={{ bgcolor: 'white' }}>
                                {connectList.map((connect: Schemas.ConnectEntities) => (
                                    <ListItemButton key={connect.uuid} onClick={() => detailHandler(connect)}>
                                        <ListItem divider>
                                            <ListItemText primary={<Typography variant="body2">{connect.name}</Typography>} />
                                        </ListItem>
                                    </ListItemButton>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body1">託されているつながりがありません</Typography>
                        )}
                    </Stack>
                </Stack>
            </DefaultLayout>
        </>
    )
}
