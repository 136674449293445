import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Box, Button, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import { Schemas } from '~/apis/types'
import { mediaUrl } from '~/utils/common'

export type CGiftCardCardProps = {
    detail: Schemas.ClientGiftOrderPreviewDto
    handleRemove?: (giftMasterUuid: string) => void
    isDeleteIcon?: boolean
}

export const useCGiftCardCard = (handleRemove?: (giftMasterUuid: string) => void) => {
    const handleRemoveGift = (uuid: string) => {
        handleRemove!(uuid)
    }
    return {
        handleRemoveGift,
    }
}

export const CGiftCardCard: FC<CGiftCardCardProps> = ({ detail, handleRemove, isDeleteIcon = false }) => {
    const { handleRemoveGift } = useCGiftCardCard(handleRemove)
    return (
        <>
            <Stack direction={'row'} justifyContent={'start'} spacing={2}>
                <Stack>
                    <Box
                        component={'img'}
                        src={mediaUrl(detail.giftMaster.files[0])}
                        loading="lazy"
                        alt={detail.giftMaster.files[0]?.filename}
                        sx={{
                            width: { xs: '30vmin', sm: '30vmin' },
                            maxWidth: '240px',
                            height: { xs: '30vmin', sm: '30vmin' },
                            maxHeight: '240px',
                            objectPosition: 'center',
                            objectFit: 'cover',
                            borderRadius: '8px',
                        }}
                    />
                </Stack>
                <Stack
                    direction={'column'}
                    alignItems={'start'}
                    justifyContent={'space-between'}
                    sx={{ height: '100%', width: '100%' }}>
                    <Stack direction={'column'} alignItems={'start'} sx={{ width: '100%' }}>
                        <Typography variant="h5">{detail.giftMaster.name}</Typography>
                        <Typography variant="h6">¥{detail.giftMaster.price.toLocaleString()}</Typography>
                    </Stack>

                    {isDeleteIcon && (
                        <Stack direction={'row'} justifyContent={'end'} sx={{ width: '100%' }}>
                            <Typography textAlign={'end'}>
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={() => handleRemoveGift(detail.giftMaster.uuid)}
                                    startIcon={<RemoveCircleIcon />}>
                                    削除
                                </Button>
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </>
    )
}
