import { LoadingButton } from '@mui/lab'
import { Link, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router'

import giftImage from '~/assets/image/gift/gift.svg'
import { DefaultLayout } from '~/components/layout/Default'

export const useGraveOfferingOrderFinishPage = () => {
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid

    // initial fetch

    const handleMoveToGraveTop = () => {
        navigate(`/connectList/${connectUuid!}/grave`)
    }

    return {
        handleMoveToGraveTop,
    }
}

export const GraveOfferingOrderFinishPage: FC = () => {
    const { handleMoveToGraveTop } = useGraveOfferingOrderFinishPage()

    return (
        <>
            <DefaultLayout title="ご注文が完了しました" breadcrumbList={[]}>
                <Stack spacing={3} direction={'column'} alignItems={'center'}>
                    <img src={giftImage} />
                    <Stack spacing={2}>
                        <Stack>
                            <Typography variant={'body2'} textAlign={'center'}>
                                ご注文ありがとうございます。
                            </Typography>
                            <Typography variant={'body2'} textAlign={'center'}>
                                選択したお供え開始日よりお供えが表示されます。
                            </Typography>
                            <Typography variant={'body2'} textAlign={'center'}>
                                ご注文内容は、送信されたご注文確認メールか、
                                <Link color={'#77618B'} href="/offeringOrder/history">
                                    お供え物注文履歴
                                </Link>
                                よりご確認ください。
                            </Typography>
                        </Stack>
                        <Stack spacing={0.5}>
                            <Typography variant={'body1'} color={'#77618B'}>
                                ※お供え開始日までに入金が完了していない場合は表示されません。
                            </Typography>
                            <Typography variant={'body1'} color={'#77618B'}>
                                ※銀行振込の場合、振込手数料はお客様のご負担となります。
                            </Typography>
                        </Stack>
                    </Stack>
                    <LoadingButton variant="outlined" fullWidth onClick={handleMoveToGraveTop}>
                        お墓Topへ
                    </LoadingButton>
                </Stack>
            </DefaultLayout>
        </>
    )
}
