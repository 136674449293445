import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Chip, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { type FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { CIconUpload } from '~/components/functional/cIconUpload/CIconUpload'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientSponsorFormDtoSchema, clientSponsorFormDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type StateTransferDtoType = {
    bannerImage: Schemas.FileEntities | null
    name: string
    nameKana: string
    email: string
    address: string
    phoneNumber: string
    siteUrl: string | null
    categories: string[]
    prefectures: string[]
    categoryUuids: string[]
    prefectureUuids: string[]
}

export const useSponsorFormIndexPage = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const initialValues = (state as { dto: StateTransferDtoType })?.dto

    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    const { data: sponsorCategoryListResponse } = useQuerySuspense(
        [`sponsorCategory`],
        async () => {
            const r = await apiClient.clientSponsorGetAllSponsorCategories()
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: prefectureListResponse } = useQuerySuspense(
        [`prefecture`],
        async () => {
            const res = await apiClient.clientPrefectureGetAllPrefectures()
            return {
                list: res.list || [],
                count: res.count || 0,
            }
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handleSubmitButton = async (dto: Schemas.ClientCreateSponsorDto) => {
        const categoryUuids = sponsorCategoryListResponse?.list
            .filter((sponsorCategory) => category.includes(sponsorCategory.title))
            .map((sponsorCategory) => sponsorCategory.uuid)
        const prefectureUuids = prefectureListResponse?.list
            .filter((sponsorPrefecture) => prefecture.includes(sponsorPrefecture.title))
            .map((sponsorPrefecture) => sponsorPrefecture.uuid)
        const stateDto: StateTransferDtoType = {
            bannerImage: image,
            name: dto.name,
            nameKana: dto.nameKana,
            email: dto.email,
            address: dto.address,
            phoneNumber: dto.phoneNumber,
            siteUrl: dto.siteUrl || null,
            categories: category,
            prefectures: prefecture,
            categoryUuids: categoryUuids || [],
            prefectureUuids: prefectureUuids || [],
        }
        navigate('/sponsor/form/confirm', { state: { dto: stateDto } })
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        setValue,
    } = useForm<ClientSponsorFormDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientSponsorFormDtoSchema),
    })

    const [category, setCategory] = useState<string[]>(initialValues?.categories || [])
    useEffect(() => {
        setValue('categoriesUuids', category)
    }, [category])

    const [prefecture, setPrefecture] = useState<string[]>(initialValues?.prefectures || [])
    useEffect(() => {
        setValue('coveredPrefectureUuids', prefecture)
    }, [prefecture])

    const [image, setImage] = useState<Schemas.FileEntities | null>(initialValues?.bannerImage || null)

    const handleUploadFile = (value?: Schemas.FileEntities): void => {
        if (value?.uuid) {
            setValue('bannerImageUuid', value.uuid)
            setImage(value)
        }
    }

    const handleDeleteFile = () => {
        setValue('bannerImageUuid', null)
        setImage(null)
    }

    const selectMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '15rem',
            },
        },
    }

    return {
        handleSubmitButton,
        handleUploadFile,
        errors,
        register,
        isSubmitting,
        isValid,
        handleSubmit,
        image,
        sponsorCategoryList: sponsorCategoryListResponse?.list,
        category,
        setCategory,
        prefectureList: prefectureListResponse?.list || [],
        prefecture,
        setPrefecture,
        handleDeleteFile,
        initialValues,
        selectMenuProps,
    }
}

export const SponsorFormIndexPage: FC = () => {
    const {
        handleSubmitButton,
        handleUploadFile,
        errors,
        register,
        isSubmitting,
        isValid,
        handleSubmit,
        image,
        sponsorCategoryList,
        category,
        setCategory,
        prefectureList,
        prefecture,
        setPrefecture,
        handleDeleteFile,
        initialValues,
        selectMenuProps,
    } = useSponsorFormIndexPage()
    return (
        <DefaultLayout title={''} breadcrumbList={[]} isTab={false}>
            <Stack spacing={2}>
                <Stack spacing={2} textAlign={'center'}>
                    <Typography variant="h4">事業者登録フォーム</Typography>
                    <Typography variant="body1">
                        利用者が閲覧できる協賛事業者一覧では、事業者名、バナーURLが表示されます。是非ご登録いただき御社の情報発信ににご活用ください。
                    </Typography>
                </Stack>
                <Box
                    sx={{ display: 'flex', bgcolor: 'white', alignSelf: 'center', width: '100%', p: 3, flexDirection: 'column' }}
                    maxWidth={'528px'}>
                    <Typography variant="h4" mb={3}>
                        事業者情報を追加してください
                    </Typography>
                    <Stack spacing={2} mb={3}>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'バナー画像'} variant="h6" />

                            <Box
                                bgcolor={image ? 'white' : '#F4F0ED'}
                                sx={{
                                    display: 'flex',
                                    minHeight: '112px',
                                    my: 'auto',
                                    justifyContent: 'space-evenly',
                                    flexDirection: 'column',
                                }}>
                                {image ? (
                                    <>
                                        <img
                                            alt={''}
                                            src={mediaUrl(image, true)}
                                            width="240px"
                                            style={{
                                                objectFit: 'cover',
                                                alignSelf: 'center',
                                            }}
                                        />
                                        <Button onClick={() => handleDeleteFile()}>削除</Button>
                                    </>
                                ) : (
                                    <CIconUpload
                                        completionHandler={handleUploadFile}
                                        label={''}
                                        error={!!errors?.bannerImageUuid}
                                        helperText={errors?.bannerImageUuid?.message}
                                    />
                                )}
                            </Box>
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'事業者名（ふりがな)'} required variant="h6" />
                            <TextField
                                id={'name'}
                                required={true}
                                {...register('name')}
                                placeholder={'事業者名'}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                variant={'filled'}
                                defaultValue={initialValues?.name || ''}
                            />
                            <TextField
                                id={'nameFurigana'}
                                required={true}
                                placeholder={'ふりがな'}
                                variant={'filled'}
                                {...register('nameKana')}
                                error={!!errors.nameKana}
                                helperText={errors.nameKana?.message}
                                defaultValue={initialValues?.nameKana || ''}
                            />
                            <Typography variant="body1">
                                ※ふりがなは、団体種別(株式会社：かぶしきがいしゃ 等)を省略して入力してください。
                            </Typography>
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'メールアドレス'} required variant="h6" />
                            <TextField
                                id={'email'}
                                required={true}
                                {...register('email')}
                                placeholder={'メールアドレス'}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                variant={'filled'}
                                defaultValue={initialValues?.email || ''}
                            />
                            <Typography variant="body1">※サイト上に公開されることはありません</Typography>
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'代表者住所'} required variant="h6" />
                            <TextField
                                id={'address'}
                                required={true}
                                placeholder={'代表者住所'}
                                variant={'filled'}
                                {...register('address')}
                                error={!!errors.address}
                                helperText={errors.address?.message}
                                defaultValue={initialValues?.address || ''}
                            />
                            <Typography variant="body1">※サイト上に公開されることはありません</Typography>
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'代表者電話番号'} required variant="h6" />
                            <TextField
                                id={'phoneNumber'}
                                required={true}
                                placeholder={'代表者電話番号'}
                                variant={'filled'}
                                {...register('phoneNumber')}
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber?.message}
                                defaultValue={initialValues?.phoneNumber || ''}
                            />
                            <Typography variant="body1">※サイト上に公開されることはありません</Typography>
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'WebサイトURL'} variant="h6" />
                            <TextField
                                id={'websiteUrl'}
                                required={true}
                                placeholder={'WebサイトURL'}
                                variant={'filled'}
                                {...register('siteUrl')}
                                error={!!errors.siteUrl}
                                helperText={errors.siteUrl?.message}
                                defaultValue={initialValues?.siteUrl || ''}
                            />
                            <Typography variant="body1">
                                ※URLを入力していただくと、協賛企業一覧ページのバナーからご指定のWebサイトへのアクセスが可能になります。
                            </Typography>
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'カテゴリ'} required variant="h6" />
                            <Select
                                id={'category'}
                                label={'カテゴリ'}
                                {...register('categoriesUuids')}
                                multiple
                                MenuProps={selectMenuProps}
                                onChange={(e) => {
                                    setCategory(e.target.value as string[])
                                }}
                                variant="filled"
                                value={category}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((title) => (
                                            <Chip key={title} label={title} />
                                        ))}
                                    </Box>
                                )}>
                                {sponsorCategoryList?.map((it) => (
                                    <MenuItem key={it.uuid} value={it.title} sx={{ margin: '8px' }}>
                                        {it.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography variant="body1">※少なくとも1つのカテゴリーを選択してください。</Typography>
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'都道府県'} required variant="h6" />
                            <Select
                                id={'prefecture'}
                                label={'都道府県'}
                                multiple
                                MenuProps={selectMenuProps}
                                {...register('coveredPrefectureUuids')}
                                onChange={(e) => {
                                    setPrefecture(e.target.value as string[])
                                }}
                                variant="filled"
                                value={prefecture}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((title) => (
                                            <Chip key={title} label={title} />
                                        ))}
                                    </Box>
                                )}>
                                {prefectureList.map((it) => (
                                    <MenuItem key={it.uuid} value={it.title} sx={{ margin: '8px' }}>
                                        {it.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography variant="body1">※少なくとも1つの都道府県を選択してください。</Typography>
                        </Stack>
                    </Stack>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit(handleSubmitButton)}>
                        入力内容の確認
                    </LoadingButton>
                </Box>
            </Stack>
        </DefaultLayout>
    )
}
