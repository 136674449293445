import { Box, CircularProgress } from '@mui/material'
import type { FC } from 'react'

export type CLoadingViewProps = {}

export const CLoadingView: FC<CLoadingViewProps> = () => {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
                <CircularProgress />
            </Box>
        </>
    )
}
