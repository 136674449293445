import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider as JotaiProvider } from 'jotai'
import { SnackbarProvider } from 'notistack'
import React, { type ReactNode, StrictMode, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { useBrowserUniqueId } from '~/store/browser-unique-id'
import { generateUUIDv4 } from '~/utils/common'
import { muiTheme } from '~/utils/mui-theme'

import { GA4 } from './GA4'

const queryClient = new QueryClient()
export const PageShell = ({ children }: { children: ReactNode }) => {
    const title = 'これ終'
    const { browserUuid, setBrowserUuid } = useBrowserUniqueId()

    useEffect(() => {
        if (!browserUuid) setBrowserUuid(generateUUIDv4())
    }, [browserUuid])

    return (
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                    <ThemeProvider theme={muiTheme}>
                        <GA4 trackingCode={process.env.GA4ID} />
                        <CssBaseline />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <JotaiProvider>
                                <SnackbarProvider maxSnack={5} autoHideDuration={6000}>
                                    {children}
                                </SnackbarProvider>
                            </JotaiProvider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </HelmetProvider>
            </QueryClientProvider>
        </StrictMode>
    )
}
