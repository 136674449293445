import { AxiosError } from 'axios'

import { Schemas } from '~/apis/types'
import { createApiClient } from '~/utils/createApiClient'

export const useOfferingOrderRequestState = (connectUuid: string) => {
    const apiClient = createApiClient()
    const token = `${connectUuid}`

    const fetchRequestDto = async (
        retry = true,
    ): Promise<{ request: Schemas.ClientOfferingCreateDto; preview: Schemas.ClientOfferingOrderPreviewDto[] } | undefined> => {
        try {
            console.log('fetchRequestDto token', token)

            const { request, preview } = await apiClient.clientOfferingOrderRequestFindOrder({
                parameter: {
                    token: token!,
                },
            })
            return { request, preview }
        } catch (e) {
            // 注文情報は一定期間更新していないと削除され、404が帰ってくるようになるので、再度初期化を行う
            if (e instanceof AxiosError) {
                if (e?.response?.status === 404 && retry) return await fetchRequestDto(false)
                throw e
            }
        }
    }

    const updateRequestOrderDto = async (requestOrderDto: Schemas.ClientOfferingCreateDto) => {
        console.log('updateRequestOrderDto token', token)

        const { request } = await apiClient.clientOfferingOrderRequestSaveOrder({
            requestBody: requestOrderDto,
            parameter: {
                token: token!,
            },
        })
        return { request }
    }

    return {
        fetchRequestDto,
        updateRequestOrderDto,
    }
}
