import { LoadingButton } from '@mui/lab'
import { Button, Container, Grid, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { AxiosError } from 'axios'
import { uniqBy } from 'lodash-es'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CPaymentBankAccount } from '~/components/functional/payment/cPaymentBankAccount/CPaymentBankAccount'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, getPaymentStatus, getPaymentType, mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useOfferingOrderHistoryDetailPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const orderUuid = params.orderUuid

    // initial fetch
    const { data: order, refetch } = useQuerySuspense(
        [`offeringOrder/history/detail`],
        async () => {
            return await apiClient.clientOfferingOrderGetHistoryDetail({ parameter: { uuid: orderUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const bankTransferCompleted = async () => {
        try {
            if (!orderUuid) return
            const message = '振込完了を通知します。よろしいでしょうか？'
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '振込完了通知',
                    text: message,
                })
            ) {
                await apiClient.clientOfferingOrderBankTransferCompleted({ parameter: { uuid: orderUuid } })
                await refetch()
            }
        } catch (e) {
            let message = '振込完了通知に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const cancelHandler = async () => {
        try {
            if (!orderUuid) return
            const message = 'キャンセルします。よろしいでしょうか？'
            if (
                await queueDialog({
                    type: 'confirm',
                    title: 'キャンセル確認',
                    text: message,
                })
            ) {
                await apiClient.clientOfferingOrderCancelOrder({ parameter: { uuid: orderUuid } })
                await refetch()
            }
        } catch (e) {
            let message = 'キャンセルに失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const handleMoveTopList = () => {
        navigate(`/offeringOrder/history`)
    }

    return {
        order,
        bankTransferCompleted,
        cancelHandler,
        handleMoveTopList,
    }
}

export const OfferingOrderHistoryDetailPage: FC = () => {
    const { order, bankTransferCompleted, handleMoveTopList } = useOfferingOrderHistoryDetailPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="" breadcrumbList={[]}>
                <Stack
                    spacing={2}
                    sx={{
                        fontstyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '150%',
                        color: '#000000',
                    }}>
                    <Stack>
                        <Box bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                            <Stack spacing={1}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">注文番号：</Typography>
                                    <Typography variant="body1">{order?.uuid.split('-')[0]}</Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">注文日：</Typography>
                                    <Typography variant="body1">{datetimeFormat(order?.createdAt)}</Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">決済方法：</Typography>
                                    <Stack direction={'row'} justifyContent={'end'} spacing={2}>
                                        <Typography variant="body1">{getPaymentType(order!.paymentType)}</Typography>
                                        {order!.paymentType === 3 && order!.paymentStatus === 0 ? (
                                            <LoadingButton variant="contained" onClick={bankTransferCompleted} sx={{ py: 0 }}>
                                                振込完了
                                            </LoadingButton>
                                        ) : (
                                            <Typography variant="body1">{getPaymentStatus(order!.paymentStatus)}</Typography>
                                        )}
                                    </Stack>
                                </Stack>
                                {order!.paymentType === 3 && (
                                    <Grid container>
                                        <Grid item xs={3} alignItems={'center'} />
                                        <Grid item xs={9}>
                                            <CPaymentBankAccount fontSize={'16px'} />
                                        </Grid>
                                    </Grid>
                                )}
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">お墓：</Typography>
                                    <Typography variant="body1">{order?.grave?.name}</Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">宛名：</Typography>
                                    <Typography variant="body1">{order?.connect?.name}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box bgcolor={'white'} p={2}>
                            <Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">合計金額：</Typography>
                                    <Typography variant="body1">¥{order!.totalPrice.toLocaleString()}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>

                    {uniqBy(
                        order!.details.map((detail: Schemas.OfferingOrderDetailEntities) => detail.offeringMaster),
                        'uuid',
                    ).map((master) => {
                        const offeringMaster = master as unknown as Schemas.OfferingMasterEntities
                        return (
                            <Stack key={offeringMaster.uuid}>
                                <Box bgcolor={'white'} p={2}>
                                    <Stack direction={'row'} justifyContent={'start'} spacing={2}>
                                        <Stack>
                                            <img
                                                src={mediaUrl(offeringMaster.file)}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    objectFit: 'contain',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Typography variant="h5">{offeringMaster.name}</Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                                {order!.reserve && (
                                    <Box bgcolor={'white'} p={2} borderTop={1} borderColor={'#D9D9D9'}>
                                        <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                            <Typography variant="body1">お供え開始日：</Typography>
                                            <Typography variant="body1">{order!.reservationAt}</Typography>
                                        </Stack>
                                    </Box>
                                )}
                            </Stack>
                        )
                    })}
                </Stack>

                <Container>
                    <Button variant={'outlined'} onClick={() => handleMoveTopList()}>
                        お供え物注文一覧に戻る
                    </Button>
                </Container>
            </DefaultLayout>
        </>
    )
}
