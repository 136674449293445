import { useAtom } from 'jotai'

import { Schemas } from '~/apis/types'
import { atomWithLocalStorage } from '~/utils/atomWithLocalStorage'

export type UserToken = {
    accessToken?: string
    expire?: string
    isMFA?: boolean
}

const tokenInitialValue: UserToken = {
    accessToken: '',
    expire: '',
    isMFA: undefined,
}

const userTokenAtom = atomWithLocalStorage<UserToken>('userToken', tokenInitialValue)

const meAtom = atomWithLocalStorage<Schemas.UserEntities | null>('user', null)
const tutorialAtom = atomWithLocalStorage<boolean>('isTutorial', true)
const tutorialAfterPermitAtom = atomWithLocalStorage<boolean>('isTutorialAfterPermit', true)

export const useAuthState = () => {
    const [token, setToken] = useAtom(userTokenAtom)
    const [me, setMe] = useAtom(meAtom)
    const [isTutorial, setIsTutorial] = useAtom(tutorialAtom)
    const [isTutorialAfterPermit, setIsTutorialAfterPermit] = useAtom(tutorialAfterPermitAtom)

    const clearAuthData = () => {
        setToken(tokenInitialValue)
        setMe(null)
        setIsTutorial(true)
        setIsTutorialAfterPermit(true)
    }

    return {
        me,
        setMe,
        token,
        setToken,
        clearAuthData,
        isTutorial,
        setIsTutorial,
        isTutorialAfterPermit,
        setIsTutorialAfterPermit,
    }
}
