import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { Schemas } from '~/apis/types'
import trash from '~/assets/image/memory/trash-red.svg'
import { mediaUrl } from '~/utils/common'

export type CPersonalHistoryDisplayFileDialogProps = {
    isOpen: boolean
    onClose: () => void
    deleteHandler?: () => void
    imageFile?: Schemas.FileEntities
}

export const useCPersonalHistoryDisplayFileDialog = (props: CPersonalHistoryDisplayFileDialogProps) => {
    const { imageFile, isOpen, deleteHandler } = props
    const [image, setImage] = useState<Schemas.FileEntities | undefined>(imageFile)
    // 初期化
    useEffect(() => {
        setImage(imageFile)
    }, [isOpen, imageFile])

    const handleDelete = () => {
        if (!imageFile) return
        deleteHandler!()
    }

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    return {
        handleDelete,
        image,
        fullScreen,
    }
}

export const CPersonalHistoryDisplayFileDialog: FC<CPersonalHistoryDisplayFileDialogProps> = (props) => {
    const { fullScreen, handleDelete, image } = useCPersonalHistoryDisplayFileDialog(props)
    const { isOpen, onClose } = props
    return (
        <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} sx={{ background: '#000000' }} maxWidth={'sm'}>
            <DialogTitle sx={{ background: '#000000' }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <IconButton sx={{ color: '#FFF' }} onClick={() => onClose()}>
                        <CloseSharpIcon />
                    </IconButton>
                    <Button
                        variant={'text'}
                        color={'error'}
                        startIcon={<img src={trash} color={'#EA5F4C'} alt="" />}
                        onClick={() => handleDelete()}>
                        削除
                    </Button>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ background: '#000000', p: 0, m: 0 }}>
                <Stack direction={'row'} justifyContent={'center'} p={0} m={0}>
                    <img src={mediaUrl(image)} style={{ width: '90%', height: 'auto' }} alt={image?.filename} />
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
