import { AxiosError } from 'axios'
import type { FC } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CHistoryCreateOrEdit } from '~/components/functional/cHistoryCreateOrEdit/CHistoryCreateOrEdit'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type HistoryHistoryIdPageViewProps = {}

export const HistoryHistoryIdPageView: FC<HistoryHistoryIdPageViewProps> = () => {
    const { historyId } = useParams()
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const { data: historyDetail } = useQuerySuspense(
        [`user/history/edit/${historyId}`],
        async () => {
            if (!historyId) throw new Error()

            return await apiClient.clientUserPersonalHistoryGetHistoryDetail({ parameter: { historyUuid: historyId } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const onSubmit = async (data: Schemas.ClientUserPersonalHistoryUpdateDto) => {
        return await apiClient
            .clientUserPersonalHistoryUpdate({
                parameter: { historyUuid: historyDetail?.uuid ?? '' },
                requestBody: data,
            })
            .then(() => navigate(`/user/history/${historyDetail?.uuid}`))
    }

    return (
        <CHistoryCreateOrEdit
            entity={historyDetail}
            onFormSubmit={onSubmit}
            onCancel={() => navigate(`/user/history/${historyDetail?.uuid}`)}
        />
    )
}

export const HistoryHistoryIdPage: FC = () => {
    return <HistoryHistoryIdPageView />
}

export default HistoryHistoryIdPage
