import 'dayjs/locale/ja'

import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOfferingOrderRequestState } from '~/hooks/useOfferingOrderRequestState'
import { useQuerySuspense } from '~/utils/common'

export const useGraveOfferingOrderReservePage = () => {
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid
    const graveUuid = params.graveUuid
    const { fetchRequestDto, updateRequestOrderDto } = useOfferingOrderRequestState(connectUuid!)

    // initial fetch
    const { data: requestOrderDto } = useQuerySuspense(
        [`/grave/offering/reserve/requestOrderDto`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const initDayjs = (): Dayjs => {
        return dayjs().set('hour', 9).set('minute', 0).set('second', 0).set('millisecond', 0).add(1, 'day')
    }

    const [reserve, setReserve] = useState<boolean>(requestOrderDto?.request.reserve || false)
    const [reserveAt, setReserveAt] = useState<Dayjs | null>(
        requestOrderDto && requestOrderDto.request.reservationAt ? dayjs(requestOrderDto?.request.reservationAt) : initDayjs(),
    )

    // 初期化
    useEffect(() => {
        setReserve(requestOrderDto!.request.reserve || false)
        if (requestOrderDto!.request.reservationAt) setReserveAt(dayjs(requestOrderDto!.request.reservationAt))
    }, [requestOrderDto])

    const handleChangeReserve = (event: ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value
        let changeValue: 'true' | 'false' = 'false'
        if (value === 'true') {
            setReserveAt(dayjs)
            changeValue = 'true'
        }

        setReserve(changeValue === 'true')
    }

    // next step
    const handleNextStep = async () => {
        requestOrderDto!.request.reserve = reserve
        if (reserve) requestOrderDto!.request.reservationAt = reserveAt ? reserveAt.toISOString() : null
        else requestOrderDto!.request.reservationAt = null
        await updateRequestOrderDto(requestOrderDto!.request)
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/comment`)
    }

    const handleMoveCart = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/cart`)
    }

    return {
        handleNextStep,
        handleMoveCart,
        handleChangeReserve,
        reserve,
        reserveAt,
        setReserveAt,
    }
}

export const GraveOfferingOrderReservePage: FC = () => {
    const { handleNextStep, handleMoveCart, handleChangeReserve, reserve, reserveAt, setReserveAt } =
        useGraveOfferingOrderReservePage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="お供え日時指定" breadcrumbList={[]}>
                <Stack spacing={2}>
                    <Container maxWidth={'sm'} sx={{ p: 2 }}>
                        <Typography variant={'body2'}>お供え品をいつからお墓にお供えするか指定してください。</Typography>
                    </Container>
                    <Box bgcolor="white" sx={{ p: 2 }}>
                        <Stack spacing={2}>
                            <Stack spacing={1}>
                                <CInputLabel label={'お供えを開始する日を指定'} />
                                <FormControl>
                                    <RadioGroup name={'reserve'} value={reserve} onChange={handleChangeReserve}>
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label={<Typography variant={'body2'}>決済後すぐにお供え</Typography>}
                                        />
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label={<Typography variant={'body2'}>お供えを開始する日を指定</Typography>}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ja'}>
                                    <DateTimePicker
                                        disabled={!reserve}
                                        onChange={(newValue) => setReserveAt(newValue)}
                                        value={reserveAt}
                                        renderInput={(params) => <TextField variant="outlined" {...params} />}
                                        inputFormat={'YYYY年MM月DD日 HH:mm'}
                                        shouldDisableDate={(day: Dayjs) => {
                                            return dayjs().subtract(1, 'day').isAfter(day)
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <Typography variant={'caption'} color={'#77618B'}>
                                ※お供え開始日までに決済を完了してください
                            </Typography>
                        </Stack>
                    </Box>

                    <Container maxWidth={'sm'}>
                        <Stack spacing={2}>
                            <LoadingButton disableElevation variant="contained" onClick={handleNextStep}>
                                コメント登録
                            </LoadingButton>

                            <LoadingButton variant="outlined" onClick={handleMoveCart}>
                                カートに戻る
                            </LoadingButton>
                        </Stack>
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
