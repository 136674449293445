import { Container, Typography } from '@mui/material'
import { FC } from 'react'

import { DefaultLayout } from '~/components/layout/Default'

export const TermsIndexPage: FC = () => {
    return (
        <>
            <DefaultLayout title={'利用規約'} breadcrumbList={[]} isTab={false}>
                <Container maxWidth={'sm'}>
                    <Typography variant={'body1'} component={'p'}>
                        第1条（適用）
                        <br />
                        <br />
                        1.
                        本規約は、株式会社アステール（以下、「当社」といいます。）が提供する終活支援サービス「これ終」（以下、「本サービス」といいます。）の利用条件を定めるものです。
                        <br />
                        2.
                        本サービスをご利用のすべての方（以下、「ユーザー」といいます。）は、本規約に同意したものとみなされます。
                        <br />
                        <br />
                        第2条（定義）
                        <br />
                        <br />
                        1. 本規約で使用する用語の定義は、次のとおりとします。
                        <br />
                        (1)「コンテンツ」：情報、文書、画像、音声、映像、ソフトウェア、コードなど、ユーザーが本サービスを利用するにあたり、当社が提供する一切の情報やサービスを含みます。
                        <br />
                        <br />
                        第3条（利用登録）
                        <br />
                        <br />
                        1.
                        登録希望者が当社の定める登録手続を完了した時点で、利用契約が成立し、ユーザーは、本サービスを利用することができます。
                        <br />
                        <br />
                        第4条（利用料金及び支払方法）
                        <br />
                        <br />
                        1.
                        ユーザーは、本サービスの利用に関して、当社が別途定め、本ウェブサイトに表示する料金を、当社が指定する方法により支払うものとします。
                        <br />
                        <br />
                        第5条（禁止事項）
                        <br />
                        <br />
                        1. ユーザーは、本サービスの利用にあたり、以下の行為をしてはならないものとします。
                        <br />
                        (1)
                        当社または第三者及び他のユーザーの著作権、商標権、意匠権、特許権、実用新案権その他知的財産権を侵害する行為
                        <br />
                        (2)
                        当社または第三者及び他のユーザーの財産権、肖像権、パブリシティ権、人格権、名誉権、プライバシー権等を侵害する行為
                        <br />
                        (3) 公序良俗に反する行為
                        <br />
                        (4) 法令に反する行為
                        <br />
                        (5) 犯罪的行為、犯罪的行為に結びつく行為及び犯罪的行為を助長する行為
                        <br />
                        (6) 児童ポルノ、ポルノ、わいせつ物の頒布等を規制する法令に違反する行為
                        <br />
                        (7) 虚偽の情報を登録ならびに投稿する行為
                        <br />
                        (8) 当社のサーバーに過度の負担を及ぼす行為
                        <br />
                        (9) 本サービスの運営を妨害する行為
                        <br />
                        (10)本サービスを、本サービスの本来のサービス提供目的とは異なる目的で利用する行為
                        <br />
                        (11)本サービスで提供されるコンテンツに施されたセキュリティ、DRM等の技術的保護手段を削除し、回避し、無効化する行為
                        <br />
                        (12)当社又は第三者に不利益を与える行為
                        <br />
                        (13)当社又は第三者に対する誹謗中傷、脅迫、嫌がらせを行う行為
                        <br />
                        (14)第三者および他のユーザになりすます行為
                        <br />
                        (15)第三者を差別又は差別を助長する行為
                        <br />
                        (16)第三者及び他のユーザーの承諾なく個人情報又はプライバシー情報を収集し、公開する行為
                        <br />
                        (17)選挙の事前運動、選挙運動又はこれらに類似する行為及び公職選挙法に抵触する行為
                        <br />
                        (18)ユーザー又は第三者の営業に関する宣伝を目的として本サービスを利用する行為
                        <br />
                        (19)ねずみ講、マルチ商法を勧誘する目的で本サービスを利用する行為
                        <br />
                        (20)当社のサービスに関連または通じて、反社会的勢力に対して直接または間接に利益を供与する行為
                        <br />
                        (21)当社の業務に支障をきたす行為
                        <br />
                        (22)虚偽の情報を本サービスに登録する行為
                        <br />
                        (23)その他、当社が不適切と判断する行為
                        <br />
                        2.
                        前項の禁止行為該当するか否かの判断は、当社の裁量により行うものとし、当社は当社の判断について説明責任を負わないものとします。
                        <br />
                        3.
                        当社はユーザーの行為が、第1項の禁止行為に該当すると判断した場合、事前に通知することなく、次の各号に定めるいずれか又は全ての措置を講じることができるものとします。
                        <br />
                        (1) 本サービスの利用停止
                        <br />
                        (2) ユーザー登録の解除による退会処分
                        <br />
                        (3) その他当社が必要と判断する行為
                        <br />
                        4. 前項の措置によりユーザーに生じた損害について当社は一切の責任を負わないものとします。
                        <br />
                        5.
                        当社はユーザーに対し、本規約に違反する行為により当社に生じた一切の損害、損失、費用の賠償を求めることができるものとします。
                        <br />
                        <br />
                        第6条（本サービスの提供の停止等）
                        <br />
                        <br />
                        1.
                        当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく、本サービスの全部または一部を一時的に停止または中断することができるものとします。
                        <br />
                        (1) 本サービスの保守・修理・点検を行う必要が生じた場合
                        <br />
                        (2) 本サービスの運営に影響を及ぼすような自然災害、人為的事故が生じた場合
                        <br />
                        (3) システムの障害、ネットワークの障害が発生した場合
                        <br />
                        (4) その他、当社が必要と判断した場合
                        <br />
                        <br />
                        第7条（免責事項）
                        <br />
                        <br />
                        1.
                        当社は、本サービスに関して、その完全性、正確性、安全性、具体性、信頼性、その他の事項に関して、いかなる保証も行ないません。
                        <br />
                        2. 当社は、ユーザーが本サービスを利用して得た結果について一切責任を負いません。
                        <br />
                        <br />
                        第8条（第三者への委託）
                        <br />
                        <br />
                        1.
                        当社は、本サービスの業務の一部を、第三者に委託させて行わせることができるものとし、会員はこれにあらかじめ同意するものとします。
                        <br />
                        2.
                        前項の場合において、当社が以下のいずれかの理由で必要と判断した場合、当社は個人情報を第三者に提供することができるものとし、ユーザーはこれにあらかじめ同意するものとします。
                        <br />
                        (1) 本人照会の為
                        <br />
                        (2) 商品の配送の為
                        <br />
                        (3) 代金決済の為
                        <br />
                        (4) ウェブサイト更新の告知の為
                        <br />
                        (5) お客様サポートの為
                        <br />
                        (6) 各種アンケートの実施の為
                        <br />
                        (7) 商品、サービスの案内刊行物の発送の為
                        <br />
                        (8) 不正取引防止等業務遂行に資する為
                        <br />
                        <br />
                        第9条（利用規約の変更）
                        <br />
                        <br />
                        1. 当社は、必要と判断した場合には、ユーザーに通知することなく、いつでも本規約を変更することができます。
                        <br />
                        <br />
                        第10条（準拠法・裁判管轄）
                        <br />
                        <br />
                        1. 本規約の解釈にあたっては、日本法を準拠法とします。
                        <br />
                        2. 本サービスに関して紛争が生じた場合には、当社の本社所在地を管轄する裁判所を専属的合意管轄とします。
                        <br />
                    </Typography>
                </Container>
            </DefaultLayout>
        </>
    )
}
