import { LoadingButton } from '@mui/lab'
import { Box, Link, Stack, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { CConnectLabel } from '~/components/functional/connect/cConnectLabel/CConnectLabel'
import { CGiftCardCard } from '~/components/functional/gift/cGiftCardCard/CGiftCardCard'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOrderRequestState } from '~/hooks/useOrderRequestState'
import { dateFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useGiftOrderConfirmPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    // initial fetch
    const { initToken, token, fetchRequestDto } = useOrderRequestState()
    const { data: requestOrderDto } = useQuerySuspense(
        [`/gift/order/confirm`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handleBack = () => {
        navigate(-1)
    }

    const handleCart = () => {
        navigate(`/gift/order/cart`, { replace: true })
    }

    const [loading, setLoading] = useState<boolean>(false)

    const handleNextStep = async () => {
        console.log(requestOrderDto!.request)
        setLoading(true)
        try {
            const gift = await apiClient.clientGiftOrderCreateOrder({ parameter: { token: token } })
            await initToken()
            navigate(`/gift/order/finish?order=${gift.uuid}`, { replace: true })
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
        setLoading(false)
    }

    return {
        requestOrderDto: requestOrderDto!.request,
        preview: requestOrderDto!.preview,
        handleNextStep,
        handleBack,
        handleCart,
        loading,
    }
}
export type GiftOrderOrderDetailProps = {
    previewDto: Schemas.ClientGiftOrderPreviewDto
}
export const GiftOrderOrderDetail: FC<GiftOrderOrderDetailProps> = ({ previewDto }) => {
    return (
        <>
            <Stack>
                <Box maxWidth={'mb'} bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                    <CGiftCardCard detail={previewDto} />
                </Box>
                <Box maxWidth={'mb'} bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="body1">数量</Typography>
                        <Typography variant="body1">{previewDto.connectList.length.toLocaleString()}</Typography>
                    </Stack>
                </Box>
                <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                    <Stack spacing={2}>
                        {previewDto.connectList.map((c: Schemas.ConnectEntities, index: number) => (
                            <Stack key={`${previewDto.uuid}-${c.uuid}`} spacing={1}>
                                {c.isConnectUser ? (
                                    <>
                                        <Typography variant="subtitle1">現在選択中のお届け先 {index + 1}：</Typography>
                                        <Stack direction={'row'}>
                                            <CConnectLabel connect={c} />
                                        </Stack>
                                        <Typography variant={'body2'}>{`${c.name}${c.sei ? `(${c.sei})` : ''}`}</Typography>
                                        {c.isConnectUserAddress ? (
                                            <Typography variant={'body2'}>ご本人の登録住所に届けられます</Typography>
                                        ) : (
                                            <>
                                                <Typography variant={'body2'}>
                                                    {c.postalCode ? `〒 ${c.postalCode}` : ''}
                                                </Typography>
                                                <Typography variant={'body2'}>
                                                    {c.pref}
                                                    {c.city}
                                                    {c.address}
                                                    {c.building}
                                                </Typography>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="subtitle1">現在選択中のお届け先 {index + 1}：</Typography>
                                        <Typography variant={'body2'}>{`${c.name}${c.sei ? `(${c.sei})` : ''}`}</Typography>
                                        <Typography variant={'body2'}>{c.postalCode ? `〒 ${c.postalCode}` : ''}</Typography>
                                        <Typography variant={'body2'}>
                                            {c.pref}
                                            {c.city}
                                            {c.address}
                                            {c.building}
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}

export const GiftOrderConfirmPage: FC = () => {
    const { requestOrderDto, preview, handleNextStep, handleBack, handleCart, loading } = useGiftOrderConfirmPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="ご注文内容確認" breadcrumbList={[]}>
                <Stack spacing={3}>
                    <Container>
                        <Stack sx={{ mt: 4 }} spacing={2}>
                            <LoadingButton variant="contained" onClick={handleNextStep} loading={loading}>
                                注文を確定する
                            </LoadingButton>
                            <Stack>
                                <Typography variant={'caption'}>
                                    「注文を確定する」ボタンを押してご注文いただくことで、お客様は当サイトの
                                    <Link color={'#77618B'} target="_blank" href="/terms">
                                        サービス利用規約
                                    </Link>
                                    と
                                    <Link color={'#77618B'} target="_blank" href="/privacy">
                                        プライバシーポリシー
                                    </Link>
                                    および当サイト上の販売条件に同意の上、商品をご注文されたことになります。
                                    <br />
                                    価格については必ず商品ページおよびこちらをご確認ください。
                                    <br />
                                    各ギフトには熨斗がつきます。 ご不要の方は注文後にお問い合わせからご連絡ください。
                                </Typography>
                            </Stack>
                        </Stack>
                    </Container>
                    <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'baseline'}>
                            <Typography variant={'h6'}>ご請求額</Typography>
                            <Typography variant={'h2'}>
                                ¥
                                {preview &&
                                    preview
                                        .reduce((a: number, c: Schemas.ClientGiftOrderPreviewDto) => {
                                            return a + c.connectList.length * c.giftMaster.price
                                        }, 0)
                                        .toLocaleString()}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                        <Stack spacing={2}>
                            <Typography sx={{ fontWeight: 'bold' }}>お支払い方法</Typography>
                            {requestOrderDto.paymentType === 0 && (
                                <Typography variant="body1">
                                    {requestOrderDto.payment?.brand} 下4桁 {requestOrderDto.payment?.last4}
                                </Typography>
                            )}
                            {requestOrderDto.paymentType === 3 && <Typography variant={'body2'}>銀行振込</Typography>}
                            <LoadingButton variant="outlined" onClick={handleBack} loading={loading}>
                                お支払い方法を変更へ
                            </LoadingButton>
                        </Stack>
                    </Box>

                    <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                        <Stack spacing={2}>
                            <Typography sx={{ fontWeight: 'bold' }}>お届けのタイミング</Typography>
                            {requestOrderDto.isLastGift ? (
                                <>
                                    <Typography variant={'body2'}>没後に届くプレゼント</Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant={'body2'}>{dateFormat(requestOrderDto.deliveredAt)}</Typography>
                                </>
                            )}
                        </Stack>
                    </Box>

                    {preview &&
                        preview.map((detail: Schemas.ClientGiftOrderPreviewDto) => (
                            <GiftOrderOrderDetail key={detail.uuid} previewDto={detail} />
                        ))}

                    <Container>
                        <Stack spacing={2}>
                            <LoadingButton variant="contained" onClick={handleNextStep} loading={loading}>
                                注文を確定する
                            </LoadingButton>
                            <LoadingButton variant="outlined" onClick={handleCart} loading={loading}>
                                カートに戻る
                            </LoadingButton>
                            <Stack spacing={1}>
                                <Stack>
                                    <Typography variant={'caption'}>
                                        これ終が販売する商品について：「注文を確定する」ボタンをクリックすると、注文を受けたことを通知するメールが送信されます。商品の購入契約は、商品の発送を通知するメールを送信したときに成立します。
                                        <br />
                                        商品の分量の詳細については、商品詳細ページをご確認ください。
                                        <br />
                                        支払時期・方法については、お支払い方法をご覧ください。
                                        <br />
                                        オプションサービス付き商品等、特別な費用が設定されている場合の費用及びソフトウェアサブスクリプションサービスの通常価格等の詳細、それらの支払時期及び方法については、商品詳細ページを併せてご確認ください。
                                        <br />
                                        また、受注生産品や招待制販売品等、申込期限が設定されている場合の申込期間についても、商品詳細ページをご確認ください。
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant={'caption'}>
                                        【商品のキャンセルについて】
                                        <br />
                                        ご注文のキャンセルについて詳しくは、注文のキャンセルをご覧ください。
                                        <br />
                                        予約商品のキャンセルについては、予約注文・お取り寄せについてをご覧ください。
                                        <br />
                                        特別なキャンセル条件又は返品条件が設定されている商品については、商品詳細ページも併せてご確認ください。
                                        <br />
                                        ソフトウェアサブスクリプションサービスのキャンセルについてはゲーム＆PCソフトダウンロード利用規約をご覧ください。
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant={'caption'}>
                                        【これ終が販売する商品の返品について】
                                        <br />
                                        原則として、商品到着後30日以内にご返送いただいた未使用かつ未開封の商品については商品代金の全額をお返しします（返品不可の商品を除く）。詳しくは返品・交換の条件をご覧ください。
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
