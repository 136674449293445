import { Grid, Typography } from '@mui/material'
import type { FC } from 'react'

type CPaymentBankAccountProps = {
    fontSize?: string
}

export const CPaymentBankAccount: FC<CPaymentBankAccountProps> = ({ fontSize = '18px' }) => {
    return (
        <>
            <Grid
                container
                direction={'row'}
                sx={{
                    fontWeight: 400,
                    fontSize: fontSize,
                    lineHeight: '150%',
                    color: '#000000',
                    pt: 2,
                }}>
                <Grid item xs={5}>
                    銀行名
                </Grid>
                <Grid item xs={7}>
                    りそな銀行（132）
                </Grid>

                <Grid item xs={5}>
                    支店名
                </Grid>
                <Grid item xs={7}>
                    城東支店（0010）
                </Grid>

                <Grid item xs={5}>
                    口座番号
                </Grid>
                <Grid item xs={7}>
                    普通 435843
                </Grid>

                <Grid item xs={5}>
                    口座名義
                </Grid>
                <Grid item xs={7}>
                    株式会社 アステール
                </Grid>

                <Grid item xs={12} pt={2}>
                    <Typography variant={'caption'} color={'#77618B'}>
                        上記の口座へ営業日の24時間以内に振り込みをお願いいたします。
                        <br />
                        振込手数料はお客様のご負担となります。
                        <br />
                        振込が確認できない場合はキャンセルとなります。
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}
