import { AxiosError } from 'axios'

import { Schemas } from '~/apis/types'
import { useOrderState } from '~/store/order'
import { createApiClient } from '~/utils/createApiClient'

export const useOrderRequestState = () => {
    const apiClient = createApiClient()
    const { token, setOrderToken } = useOrderState()

    const initToken = async () => {
        const token = await apiClient.clientGiftOrderRequestInit()
        await setOrderToken(token)
    }

    const fetchRequestDto = async (
        retry = true,
    ): Promise<{ request: Schemas.ClientGiftCreateDto; preview: Schemas.ClientGiftOrderPreviewDto[] } | undefined> => {
        try {
            console.log('fetchRequestDto token', token)
            if (!token) await initToken()

            const { request, preview } = await apiClient.clientGiftOrderRequestFindOrder({
                parameter: {
                    token: token!,
                },
            })
            return { request, preview }
        } catch (e) {
            // setToken('')

            // 注文情報は一定期間更新していないと削除され、404が帰ってくるようになるので、再度初期化を行う
            if (e instanceof AxiosError) {
                if (e?.response?.status === 404 && retry) return await fetchRequestDto(false)
                throw e
            }
        }
    }

    const updateRequestOrderDto = async (requestOrderDto: Schemas.ClientGiftCreateDto) => {
        console.log('updateRequestOrderDto token', token)
        if (!token) await initToken()
        const { request } = await apiClient.clientGiftOrderRequestSaveOrder({
            requestBody: requestOrderDto!,
            parameter: {
                token: token!,
            },
        })
        return { request }
    }

    const clearRequestOrder = async () => {
        await apiClient.clientGiftOrderRequestClearOrder()
    }

    return {
        initToken,
        token,
        fetchRequestDto,
        updateRequestOrderDto,
        clearRequestOrder,
    }
}
