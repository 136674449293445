import type { FC } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { CHistoryCreateOrEdit } from '~/components/functional/cHistoryCreateOrEdit/CHistoryCreateOrEdit'
import { createApiClient } from '~/utils/createApiClient'

export type HistoryHistoryCreatePageViewProps = {}

export const HistoryHistoryCreatePageView: FC<HistoryHistoryCreatePageViewProps> = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()

    const onSubmit = async (data: Schemas.ClientUserPersonalHistoryUpdateDto) => {
        return await apiClient
            .clientUserPersonalHistoryCreate({
                requestBody: data,
            })
            .then((res) => navigate(`/user/history/${res?.uuid}`))
    }

    return <CHistoryCreateOrEdit onFormSubmit={onSubmit} onCancel={() => navigate('/user/history')} />
}

export const HistoryHistoryCreatePage: FC = () => {
    return <HistoryHistoryCreatePageView />
}

export default HistoryHistoryCreatePage
