import { Breakpoint, Container } from '@mui/material'
import { FC, ReactNode } from 'react'

import { GHeader } from '../global/gHeader/GHeader'
import { DefaultLayoutContent } from './DefaultContent'

export type BreadcrumbListType = { title: string; link: string }[]

export type DefaultLayoutProps = {
    children: ReactNode
    title?: string
    titleHelperText?: string
    breadcrumbList: BreadcrumbListType
    maxWidth?: Breakpoint
    tabValue?: string
    isTab?: boolean
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({
    children,
    title,
    maxWidth = 'sm',
    tabValue = 'myPage',
    isTab = true,
    breadcrumbList,
    titleHelperText,
}) => {
    return (
        <>
            <GHeader />
            <Container sx={{ bgcolor: 'background.default', p: 0 }}>
                {maxWidth === 'sm' ? (
                    <Container maxWidth={maxWidth} sx={{ py: 8 }}>
                        <DefaultLayoutContent
                            children={children}
                            title={title}
                            titleHelperText={titleHelperText}
                            tabValue={tabValue}
                            isTab={isTab}
                            breadcrumbList={breadcrumbList}
                        />
                    </Container>
                ) : (
                    <Container maxWidth={maxWidth} sx={{ width: '100%', py: 8, px: 0 }}>
                        <DefaultLayoutContent
                            children={children}
                            title={title}
                            titleHelperText={titleHelperText}
                            tabValue={tabValue}
                            isTab={isTab}
                            breadcrumbList={breadcrumbList}
                        />
                    </Container>
                )}
            </Container>
        </>
    )
}
