import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Container, Link, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import decorated from '~/assets/image/decorated.svg'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientUserMobilePhoneTokenRequestDtoSchema, clientUserMobilePhoneTokenRequestDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

export const useUserAccountIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientUserMobilePhoneTokenRequestDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMobilePhoneTokenRequestDtoSchema),
    })

    useEffect(() => {
        reset({
            mobilePhone: '',
            inviteCode: null,
        })
    }, [isSubmitting])

    const sendHandler = async (dto: Schemas.ClientUserMobilePhoneTokenRequestDto) => {
        try {
            await apiClient.clientUserCreateMobilePhoneToken({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: 'メッセージを送信',
                text: '入力された携帯電話へメッセージを送信しました',
            })
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendHandler,
    }
}

export const UserAccountIndexPage: FC = () => {
    const { register, handleSubmit, errors, isSubmitting, isValid, sendHandler } = useUserAccountIndexPage()
    return (
        <DefaultLayout title={'アカウントを作成'} breadcrumbList={[]} isTab={false}>
            <Container maxWidth={'sm'} sx={{}}>
                <Stack spacing={3}>
                    <Typography variant="body2" textAlign={'center'}>
                        携帯電話番号を入力して[アカウント作成]を押してください。当サービスより会員登録招待通知が送信されます。
                    </Typography>
                    <Stack spacing={0.5}>
                        <CInputLabel label="携帯電話番号" required={true} />
                        <Typography variant="caption">ハイフン(-)は除いて入力してください</Typography>
                        <TextField
                            {...register('mobilePhone')}
                            placeholder={'携帯電話番号'}
                            type={'text'}
                            error={!!errors.mobilePhone}
                            helperText={errors.mobilePhone?.message}
                            variant={'outlined'}
                        />
                    </Stack>
                    <LoadingButton
                        variant={'contained'}
                        disabled={!isValid}
                        loading={isSubmitting}
                        onClick={handleSubmit(sendHandler)}>
                        アカウント作成
                    </LoadingButton>
                    <Typography variant={'body1'}>
                        [アカウントを作成]をクリックして、これ終の
                        <Link color={'#77618B'} href="/terms" target="_blank">
                            サービス利用規約
                        </Link>
                        と
                        <Link color={'#77618B'} href="/privacy" target="_blank">
                            プライバシーポリシー
                        </Link>
                        に同意します。
                    </Typography>
                </Stack>
            </Container>
            <Container maxWidth={'sm'} sx={{ my: 3 }}>
                <Stack spacing={2} sx={{ alignItems: 'center' }}>
                    <img src={decorated} />
                    <Stack sx={{ alignItems: 'center' }}>
                        <Typography variant="button">アカウントをお持ちの場合は</Typography>
                        <Link color={'#77618B'} href="/login">
                            ログイン
                        </Link>
                    </Stack>
                    <img src={decorated} />
                    <Link color={'#77618B'} href="/inquiry">
                        お問い合わせ
                    </Link>
                </Stack>
            </Container>
        </DefaultLayout>
    )
}
