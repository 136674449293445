import { List, ListItem } from '@mui/material'
import { FC, ReactNode } from 'react'

export type CHelpCustomListProps = {
    listStyleType?: string
    listItems: ReactNode[]
    fontSize?: string
    paddingBottom?: string
}

export const CHelpCustomList: FC<CHelpCustomListProps> = ({ listStyleType, listItems, fontSize, paddingBottom }) => {
    return (
        <List
            disablePadding
            component={'ol'}
            data-testid={'c-help-custom-list'}
            sx={{ listStyle: listStyleType ?? 'disc', fontSize: fontSize ?? '18px', paddingLeft: '1.5rem' }}>
            {listItems.map((item, index) => (
                <ListItem component={'li'} key={index} sx={{ display: 'list-item', paddingBottom: paddingBottom }}>
                    {item}
                </ListItem>
            ))}
        </List>
    )
}
