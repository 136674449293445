import { AxiosError } from 'axios'
import { type FC } from 'react'
import { useNavigate, useParams } from 'react-router'

import { CHistoryDetail } from '~/components/functional/cHistoryDetail/CHistoryDetail'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type HistoryHistoryDetailPageViewProps = {}

const useHistoryDetailPage = () => {
    const { historyId } = useParams()
    return { historyId }
}

export const HistoryHistoryDetailPageView: FC<HistoryHistoryDetailPageViewProps & ReturnType<typeof useHistoryDetailPage>> = (
    props,
) => {
    const { historyId } = props
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const { data: historyDetail } = useQuerySuspense(
        [`user/${historyId}`],
        async () => {
            if (!historyId) throw new Error()

            return await apiClient.clientUserPersonalHistoryGetHistoryDetail({
                parameter: { historyUuid: historyId },
            })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const onDelete = async () => {
        if (historyDetail && (await queueDialog({ type: 'confirm', text: '削除します。よろしいでしょうか？', title: '削除' }))) {
            return await apiClient
                .clientUserPersonalHistoryRemove({ parameter: { historyUuid: historyDetail.uuid } })
                .then(() => navigate('/user/history'))
        }
    }

    return <CHistoryDetail historyDetail={historyDetail} onHistoryDelete={onDelete} />
}

export const HistoryHistoryDetailPage: FC = () => {
    const hookItems = useHistoryDetailPage()
    return <HistoryHistoryDetailPageView {...hookItems} />
}

export default HistoryHistoryDetailPage
