import { Box, Button, Grid, IconButton, Menu, MenuItem, MenuList, Pagination, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { NavigateFunction, useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import moreHorizontal from '~/assets/image/memory/moreHorizontal.svg'
import noImage from '~/assets/image/memory/noImage.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export const useConnectListConnectUuidMemoryIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const [menuUuid, setMenuUuid] = useState<string | undefined>(undefined)
    const menuButtonHandler = (e: HTMLElement, uuid: string) => {
        setMenuAnchorEl(e)
        setMenuUuid(uuid)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }

    const page = 1
    const limit = 10
    const [queryParams, setQueryParams] = useState<QueryParameters>({ page, limit })

    // initial fetch
    const { data: listResponse, refetch: fetchList } = useQuerySuspense(
        [`/connectList/${connectUuid}/memory`],
        async () => {
            return await apiClient.clientConnectGetConnectMemoryList({ parameter: { ...queryParams, connectUuid: connectUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await fetchList()
    }
    const handleNavigateDetail = async (memory: Schemas.MemoryEntities) => {
        if (!connectUuid) return
        navigate(`/connectList/${connectUuid}/memory/${memory.uuid}`)
    }

    const deleteHandler = async () => {
        try {
            menuCloseHandler()
            const message = '削除します。よろしいでしょうか？'
            if (!menuUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryDelete({ parameter: { memoryUuid: menuUuid } })
                await fetchList()
                setMenuUuid(undefined)
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        navigate,
        connectUuid,
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        queryParams,
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        handlePageChange,
        handleNavigateDetail,
        deleteHandler,
    }
}
export type ConnectListConnectUuidMemoryIndexCardProps = {
    connectUuid: string
    memory: Schemas.MemoryEntities
    menuButtonHandler: (e: HTMLElement, uuid: string) => void
    navigate: NavigateFunction
}

export const ConnectListConnectUuidMemoryIndexCard: FC<ConnectListConnectUuidMemoryIndexCardProps> = ({
    connectUuid,
    memory,
    menuButtonHandler,
    navigate,
}) => {
    return (
        <>
            {!memory.dailyLife && (
                <Box>
                    <Stack direction={'row'}>
                        <img
                            onClick={() => navigate(`/connectList/${connectUuid}/memory/${memory.uuid}`)}
                            src={memory.file ? mediaUrl(memory.file) : noImage}
                            style={{
                                minWidth: '100px',
                                minHeight: '100px',
                                maxWidth: '100px',
                                maxHeight: '100px',
                                objectPosition: 'center',
                                objectFit: 'cover',
                                borderRadius: '10px',
                                cursor: 'pointer',
                            }}
                            alt=""
                        />
                        <Stack
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'flex-start'}
                            pl={1}
                            width={'100%'}
                            onClick={() => navigate(`/connectList/${connectUuid}/memory/${memory.uuid}`)}
                            sx={{ cursor: 'pointer' }}>
                            <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-wrap' }}>
                                {memory.name}
                            </Typography>
                        </Stack>
                        {!memory.dailyLife && (
                            <Stack justifyContent={'flex-end'} alignItems={'end'}>
                                <IconButton onClick={(e) => menuButtonHandler(e.currentTarget, memory.uuid)}>
                                    <img src={moreHorizontal} style={{ cursor: 'pointer', width: '24px' }} />
                                </IconButton>
                            </Stack>
                        )}
                    </Stack>
                </Box>
            )}
        </>
    )
}

export const ConnectListConnectUuidMemoryIndexPage: FC = () => {
    const {
        navigate,
        connectUuid,
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        queryParams,
        list,
        count,
        handlePageChange,
        deleteHandler,
    } = useConnectListConnectUuidMemoryIndexPage()
    return (
        <>
            <DefaultLayout title={'思い出管理'} breadcrumbList={[]} tabValue={'memory'}>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        {list.map((memory: Schemas.MemoryEntities) => (
                            <ConnectListConnectUuidMemoryIndexCard
                                key={memory.uuid}
                                connectUuid={connectUuid!}
                                memory={memory}
                                menuButtonHandler={menuButtonHandler}
                                navigate={navigate}
                            />
                        ))}
                    </Stack>
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pt: 1 }}>
                        <Pagination
                            count={Math.floor(count / queryParams.limit)}
                            page={queryParams.page}
                            onChange={(_, value) => handlePageChange(value)}
                        />
                    </Grid>
                    <Menu
                        open={!!menuAnchorEl}
                        onClose={menuCloseHandler}
                        anchorEl={menuAnchorEl}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                boxShadow: 1,
                                px: 1,
                                placement: 'bottom-end',
                            },
                        }}>
                        <Typography variant={'body2'} textAlign={'center'} sx={{ borderBottom: 1, pb: 1 }}>
                            操作を選択してください
                        </Typography>
                        <MenuList dense>
                            <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => deleteHandler()}>
                                <Button variant={'text'} color={'error'}>
                                    アルバムを削除
                                </Button>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
            </DefaultLayout>
        </>
    )
}
