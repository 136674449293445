import { zodResolver } from '@hookform/resolvers/zod'
import { AppBar, Box, Container, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useLayoutEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import sendImage from '~/assets/image/memory/send.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { InquiryReplyCreateDtoSchema, inquiryReplyCreateDtoSchema } from '~/types/zodScheme'
import { datetimeFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useInquiryInquiryUuidPage = () => {
    const { me } = useAuthState()
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const scrollBottomRef = useRef<HTMLDivElement>(null)

    // initial fetch
    const inquiryUuid = params.inquiryUuid
    const { data: inquiry, refetch: fetch } = useQuerySuspense(
        [`/inquiry/${params.inquiryId}`],
        async () => {
            if (!inquiryUuid) throw new Error()

            return await apiClient.clientInquiryShow({ parameter: { uuid: inquiryUuid } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<InquiryReplyCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(inquiryReplyCreateDtoSchema),
    })

    useEffect(() => {
        reset({
            content: '',
            userUuid: me?.uuid || '',
        })
        scrollBottomRef?.current?.scrollIntoView()
    }, [isSubmitting])

    useLayoutEffect(() => {
        scrollBottomRef?.current?.scrollIntoView()
    }, [])

    const sendHandler = async (dto: Schemas.ClientInquiryReplyCreateDto) => {
        try {
            if (!inquiryUuid) throw new Error()
            await apiClient.clientInquiryStoreReply({
                parameter: { uuid: inquiryUuid },
                requestBody: dto,
            })
            await fetch()
            scrollBottomRef?.current?.scrollIntoView()
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    return {
        inquiry,
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendHandler,
        scrollBottomRef,
    }
}

export type CInquiryCommentProps = {
    comment: string
    datetimeString: string
}

export const CInquiryCommentRight: FC<CInquiryCommentProps> = ({ comment, datetimeString }) => {
    return (
        <>
            <Stack justifyContent={'end'} pl={6}>
                <Box borderRadius={'18px'} bgcolor={'#DFCCEF'} sx={{ padding: '6px 12px' }}>
                    <Typography variant={'body2'} sx={{ whiteSpace: 'pre-wrap' }}>
                        {comment}
                    </Typography>
                </Box>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    spacing={1}
                    sx={{ padding: '0 12px' }}>
                    <Typography variant={'caption'} color={'rgba(60, 60, 67, 0.6)'}>
                        {datetimeString}
                    </Typography>
                </Stack>
            </Stack>
        </>
    )
}

export const CInquiryCommentLeft: FC<CInquiryCommentProps> = ({ comment, datetimeString }) => {
    return (
        <>
            <Stack justifyContent={'start'} pr={6}>
                <Stack direction={'row'} justifyContent={'start'} spacing={1} sx={{ padding: '0 12px' }}>
                    <Typography variant={'caption'} color={'rgba(60, 60, 67, 0.6)'}>
                        これ終 お問い合わせ
                    </Typography>
                    <Typography variant={'caption'} color={'rgba(60, 60, 67, 0.6)'}>
                        {datetimeString}
                    </Typography>
                </Stack>
                <Box borderRadius={'18px'} bgcolor={'#E9E9EB'} sx={{ padding: '6px 12px' }}>
                    <Typography variant={'body2'} sx={{ whiteSpace: 'pre-wrap' }}>
                        {comment}
                    </Typography>
                </Box>
            </Stack>
        </>
    )
}

export const InquiryInquiryUuidPage: FC = () => {
    const { inquiry, register, handleSubmit, sendHandler, scrollBottomRef } = useInquiryInquiryUuidPage()
    return (
        <>
            <DefaultLayout title={''} breadcrumbList={[]} isTab={false}>
                <Stack direction={'column'} spacing={2}>
                    <CInquiryCommentRight
                        comment={`お問い合わせ件名：${inquiry!.title}`}
                        datetimeString={datetimeFormat(inquiry!.createdAt)}
                    />
                    <CInquiryCommentRight comment={inquiry!.content} datetimeString={datetimeFormat(inquiry!.createdAt)} />
                    <Stack justifyContent={'center'}>
                        <Box borderRadius={'18px'} bgcolor={'#EFEAE6'} sx={{ padding: '6px 12px' }}>
                            <Typography textAlign={'center'} sx={{ color: '#42392B', fontSize: '12px' }}>
                                お問い合わせを受け付けました。
                                <br />
                                回答はメールにてお知らせいたします。
                            </Typography>
                        </Box>
                    </Stack>

                    {inquiry &&
                        inquiry!.replies &&
                        inquiry!.replies.map((reply: Schemas.InquiryReplyEntities) => (
                            <Stack key={reply.uuid}>
                                {reply.isAdmin ? (
                                    <CInquiryCommentLeft
                                        comment={reply.content}
                                        datetimeString={`これ終 お問い合わせ ${datetimeFormat(reply!.createdAt)}`}
                                    />
                                ) : (
                                    <CInquiryCommentRight
                                        comment={reply!.content}
                                        datetimeString={datetimeFormat(reply!.createdAt)}
                                    />
                                )}
                            </Stack>
                        ))}
                </Stack>
                <div ref={scrollBottomRef} />
            </DefaultLayout>
            <AppBar
                position="fixed"
                sx={{
                    top: 'auto',
                    bottom: 0,
                    bgcolor: '#B188CA',
                    boxShadow: 'none',
                    display: 'flex',
                    direction: 'row',
                    justifyContent: 'center',
                }}>
                <Container maxWidth={'sm'}>
                    <Stack direction={'row'} justifyContent={'center'} margin={'8px 12px'} spacing={2}>
                        <TextField
                            id={'content'}
                            {...register('content')}
                            variant={'outlined'}
                            sx={{ backgroundColor: 'white', borderRadius: '100px', padding: '-8px' }}
                            fullWidth
                        />
                        <img src={sendImage} onClick={handleSubmit(sendHandler)} style={{ cursor: 'pointer' }} />
                    </Stack>
                </Container>
            </AppBar>
        </>
    )
}
