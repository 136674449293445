import { Typography } from '@mui/material'
import type { FC } from 'react'

import { Schemas } from '~/apis/types'
import connected from '~/assets/image/icon/connected.svg'
import pending from '~/assets/image/icon/pending.svg'
import unapplied from '~/assets/image/icon/unapplied.svg'

export type CConnectLabelProps = {
    connect: Schemas.ConnectEntities
    isBgcolor?: boolean
    isText?: boolean
}

export const CConnectLabel: FC<CConnectLabelProps> = ({ connect, isBgcolor = true, isText = true }) => {
    return (
        <>
            {connect!.isConnectUser ? (
                <>
                    <Typography
                        bgcolor={isBgcolor ? '#EFEAE6' : 'white'}
                        textAlign={'center'}
                        component={'span'}
                        variant={'body2'}
                        sx={{ p: 1, borderRadius: '4px', padding: '2px 16px' }}>
                        <img src={connected} width={'16px'} style={{ position: 'relative', top: 3, marginRight: '8px' }} alt="" />
                        {isText && <>つながっています</>}
                    </Typography>
                </>
            ) : (
                <>
                    {connect!.isSendInvite ? (
                        <>
                            <Typography
                                bgcolor={isBgcolor ? '#EFEAE6' : 'white'}
                                variant="body2"
                                textAlign={'center'}
                                component={'span'}
                                sx={{ p: 1, borderRadius: '4px', padding: '2px 16px' }}>
                                <img
                                    src={pending}
                                    width={'16px'}
                                    style={{ position: 'relative', top: 3, marginRight: '8px' }}
                                    alt=""
                                />
                                {isText && <>つながり承認待ち</>}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography
                                bgcolor={isBgcolor ? '#EFEAE6' : 'white'}
                                variant="body2"
                                textAlign={'center'}
                                component={'span'}
                                sx={{ p: 1, borderRadius: '4px', padding: '2px 16px' }}>
                                <img
                                    src={unapplied}
                                    width={'16px'}
                                    style={{ position: 'relative', top: 3, marginRight: '8px' }}
                                    alt=""
                                />
                                {isText && <>まだつながっていません</>}
                            </Typography>
                        </>
                    )}
                </>
            )}
        </>
    )
}
