import type { FC } from 'react'

import { CHistoryList } from '~/components/functional/cHistoryList/CHistoryList'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type HistoryIndexPageViewProps = {}

export const HistoryIndexPageView: FC<HistoryIndexPageViewProps> = () => {
    const apiClient = createApiClient()

    const { data: historyList } = useQuerySuspense(['/user/history'], async () => {
        return await apiClient.clientUserPersonalHistoryGetPersonalHistoryList()
    })
    return (
        <CHistoryList
            title={'自分史'}
            histories={historyList?.list}
            subtitle={'自分史が登録されていません。 自分史を登録ボタンから自分史を追加しましょう。'}
        />
    )
}

export const HistoryIndexPage: FC = () => {
    return <HistoryIndexPageView />
}

export default HistoryIndexPage
