import { LoadingButton } from '@mui/lab'
import { FC, useEffect, useState } from 'react'

import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'

export type PayJpRes = {
    id: string
    card: { brand: string; last4: string }
    error?: { message: string; status: string }
}

type CPaymentPayJsProps = {
    completeHandler: (res: PayJpRes) => void
}

export const CPaymentPayJs: FC<CPaymentPayJsProps> = ({ completeHandler }) => {
    const { queueDialog } = useConfirmationDialog()

    const [loading, setLoading] = useState<boolean>(false)

    const [numberComplete, setNumberComplete] = useState<boolean>(false)
    const [expireComplete, setExpireComplete] = useState<boolean>(false)
    const [cvcComplete, setCvcComplete] = useState<boolean>(false)

    const [numberElement, setNumberElement] = useState()

    useEffect(() => {
        // @ts-expect-error
        if (typeof globalThis.payjp === 'undefined') {
            // @ts-expect-error
            globalThis.payjp = window.Payjp(process.env.PAYJP_PUBLIC_KEY)
        }
    })

    useEffect(() => {
        // @ts-expect-error
        const elements = globalThis.payjp.elements()
        const numberElementTemp = elements.create('cardNumber')
        setNumberElement(numberElementTemp)
        const expiredElement = elements.create('cardExpiry')
        const cardCvcElement = elements.create('cardCvc')

        numberElementTemp.mount('#number-form')
        expiredElement.mount('#expiry-form')
        cardCvcElement.mount('#cvc-form')

        numberElementTemp.on('change', (event: { complete: boolean }) => {
            setNumberComplete(event.complete)
        })
        expiredElement.on('change', (event: { complete: boolean }) => {
            setExpireComplete(event.complete)
        })
        cardCvcElement.on('change', (event: { complete: boolean }) => {
            setCvcComplete(event.complete)
        })
        // @ts-expect-error
    }, [globalThis.payjp])

    const handleGetToken = async () => {
        // @ts-expect-error
        const res: payjpRes = await globalThis.payjp.createToken(numberElement)
        if (res.error) {
            console.error(res)
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: res.error.message,
            })
            setLoading(false)
            return
        } else {
            console.log({
                cardUuid: undefined,
                token: res.id,
                brand: res.card.brand,
                last4: res.card.last4,
            })
            completeHandler(res)
        }
    }

    return (
        <>
            <div
                id="number-form"
                className="payjs-outer"
                style={{ padding: '8px 14px', border: '1px solid green', borderRadius: '4px' }}
            />
            <div
                id="expiry-form"
                className="payjs-outer"
                style={{ padding: '8px 14px', border: '1px solid green', borderRadius: '4px' }}
            />
            <div
                id="cvc-form"
                className="payjs-outer"
                style={{ padding: '8px 14px', border: '1px solid green', borderRadius: '4px' }}
            />

            <LoadingButton
                variant={'contained'}
                fullWidth
                loading={loading}
                disabled={!(numberComplete && expireComplete && cvcComplete)}
                onClick={handleGetToken}>
                カード情報の確認
            </LoadingButton>
        </>
    )
}
