import { LoadingButton } from '@mui/lab'
import { Card, CardActionArea, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useGraveOfferingMasterIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid
    const graveUuid = params.graveUuid

    // initial fetch
    const { data: list } = useQuerySuspense(
        [`/connectDetail/grave/offering`],
        async () => {
            return await apiClient.clientOfferingMasterIndex({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handleNavigateDetail = (master: Schemas.OfferingMasterEntities) => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/${master.uuid}`)
    }

    const handleCartPage = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/cart`)
    }

    const handleMoveToGraveTop = () => {
        navigate(`/connectList/${connectUuid!}/grave`)
    }

    return {
        list: list || [],
        handleNavigateDetail,
        handleMoveToGraveTop,
        handleCartPage,
    }
}

export const GraveOfferingMasterIndexPage: FC = () => {
    const { list, handleNavigateDetail, handleMoveToGraveTop, handleCartPage } = useGraveOfferingMasterIndexPage()

    return (
        <>
            <DefaultLayout title={'お供え'} breadcrumbList={[]}>
                <Typography variant={'body1'} textAlign={'center'} pb={2}>
                    お墓にお供えをしてお悔やみの気持ちを形にすることができます
                </Typography>
                <Grid container spacing={2} pb={4}>
                    {list.map((master: Schemas.OfferingMasterEntities) => (
                        <Grid item xs={12} sm={6} key={master.uuid}>
                            <Card variant="outlined">
                                <CardActionArea
                                    onClick={() => {
                                        handleNavigateDetail(master)
                                    }}>
                                    <CardMedia
                                        component="img"
                                        height="255"
                                        image={mediaUrl(master.file)}
                                        title={master.name}
                                        alt=""
                                        sx={{ objectFit: 'contain' }}
                                    />
                                    <CardContent>
                                        <Stack>
                                            <Typography variant="h6">{master.name}</Typography>
                                            <Typography variant="h6" mt={0.5}>
                                                ¥{master.price.toLocaleString()}
                                            </Typography>
                                            <Typography variant="body1" mt={1.5}>
                                                {master.content}
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Stack spacing={2}>
                    <LoadingButton variant="outlined" fullWidth onClick={handleCartPage}>
                        カート画面へ
                    </LoadingButton>

                    <LoadingButton variant="outlined" fullWidth onClick={handleMoveToGraveTop}>
                        お墓Topへ
                    </LoadingButton>
                </Stack>
            </DefaultLayout>
        </>
    )
}
