import { useAtom } from 'jotai'

import { atomWithLocalStorage } from '~/utils/atomWithLocalStorage'

const userTokenAtom = atomWithLocalStorage<string>('orderToken', '')

export const useOrderState = () => {
    const [token, setOrderToken] = useAtom(userTokenAtom)
    const clearData = () => {
        setOrderToken('')
    }

    return {
        token,
        setOrderToken,
        clearData,
    }
}
