import '~/styles/globals.css'

import { createRoot, Root } from 'react-dom/client'

import { PageRoutes } from '~/pages/page-routes'
import { PageShell } from '~/renderer/PageShell'

let root: Root
document.addEventListener('DOMContentLoaded', () => {
    if (!root) {
        const container = document.getElementById('root')!
        root = createRoot(container)
    }

    root.render(
        <PageShell>
            <PageRoutes />
        </PageShell>,
    )
})
