import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton, TabContext, TabList } from '@mui/lab'
import { Button, Container, Stack, Tab, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientConnectMessageDtoSchema, clientConnectMessageDtoSchema } from '~/types/zodScheme'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type ConnectListConnectUuidMessagePageProps = {}

export const useConnectListConnectUuidMessagePage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const connectUuid = params.connectUuid
    const navigate = useNavigate()

    // initial fetch
    const { data: connect, refetch } = useQuerySuspense(
        [`/connectList/${connectUuid}/message`],
        async () => {
            if (!connectUuid) throw new Error()

            return await apiClient.clientConnectGetConnect({ parameter: { connectUuid: connectUuid } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientConnectMessageDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientConnectMessageDtoSchema),
    })

    useEffect(() => {
        reset({
            message: connect?.message || '',
        })
    }, [isSubmitting])

    const sendHandler = async (dto: Schemas.ClientConnectMessageDto) => {
        try {
            if (!connect) throw new Error()
            await apiClient.clientConnectUpdateMessage({
                parameter: { connectUuid: connect.uuid },
                requestBody: dto,
            })
            setIsEdit(false)
            await refetch()
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    const [isEdit, setIsEdit] = useState(connect && (!connect.message || connect.message === ''))
    const tabHandler = (value: string) => {
        if (!connectUuid) return
        navigate(`/connectList/${connectUuid}/${value}`, { replace: true })
    }
    const editHandler = () => {
        setIsEdit(true)
    }

    return {
        connectUuid,
        connect,

        register,
        handleSubmit,
        errors,
        isValid,
        isSubmitting,
        sendHandler,
        isEdit,
        tabHandler,
        editHandler,
    }
}

export const ConnectListConnectUuidMessagePage: FC<ConnectListConnectUuidMessagePageProps> = () => {
    const { connect, register, handleSubmit, errors, isValid, isSubmitting, sendHandler, isEdit, tabHandler, editHandler } =
        useConnectListConnectUuidMessagePage()
    return (
        <>
            {connect ? (
                <>
                    <DefaultLayout title={''} maxWidth={'md'} breadcrumbList={[]} tabValue={'connectList'}>
                        <Stack spacing={3}>
                            <TabContext value={'message'}>
                                <TabList variant={'fullWidth'} centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                                    <Tab
                                        label={<Typography variant={'caption'}>私からのメッセージ</Typography>}
                                        value={'message'}
                                        onClick={() => tabHandler('message')}
                                    />
                                    <Tab
                                        label={<Typography variant={'caption'}>相手からのメッセージ</Typography>}
                                        value={'receivedMessage'}
                                        onClick={() => tabHandler('receivedMessage')}
                                    />
                                </TabList>
                            </TabContext>
                            <Container>
                                <Stack spacing={3}>
                                    {isEdit ? (
                                        <>
                                            <Typography variant="body2" textAlign={'center'}>
                                                メッセージを入力しておくと自分の没後に相手にメッセージが送られます。
                                            </Typography>
                                            <Stack spacing={0.5}>
                                                <CInputLabel label="メッセージ" required />
                                                <TextField
                                                    {...register('message')}
                                                    placeholder={'メッセージ'}
                                                    multiline
                                                    minRows={10}
                                                    error={!!errors.message}
                                                    helperText={errors.message?.message}
                                                    required
                                                    variant={'outlined'}
                                                />
                                            </Stack>
                                            <LoadingButton
                                                variant={'contained'}
                                                onClick={handleSubmit(sendHandler)}
                                                disabled={!isValid}
                                                loading={isSubmitting}>
                                                保存
                                            </LoadingButton>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant={'body2'} component={'p'} sx={{ whiteSpace: 'pre' }}>
                                                {connect.message}
                                            </Typography>
                                            <Button variant={'contained'} onClick={editHandler}>
                                                編集
                                            </Button>
                                        </>
                                    )}
                                </Stack>
                            </Container>
                        </Stack>
                    </DefaultLayout>
                </>
            ) : (
                <></>
            )}
        </>
    )
}
