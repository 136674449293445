import { Stack } from '@mui/material'
import type { FC } from 'react'
import { CSSProperties } from 'react'

export type CHelpImageProps = {
    src: string
    styles?: CSSProperties
}

export const CHelpImage: FC<CHelpImageProps> = ({ src, styles }) => {
    return (
        <Stack data-testid={'c-help-image'} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <img src={src} style={{ maxWidth: '20rem', ...styles }} />
        </Stack>
    )
}
