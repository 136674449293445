import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { CProfileBox } from '~/components/functional/cProfileBox/CProfileBox'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { ClientUserMobilePhoneUpdateDtoSchema, clientUserMobilePhoneUpdateDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

export const useUserMobilePhoneIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const { me } = useAuthState()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        setValue,
    } = useForm<ClientUserMobilePhoneUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMobilePhoneUpdateDtoSchema),
    })

    useEffect(() => {
        setValue('mobilePhone', '')
    }, [])

    const [isSend, setIsSend] = useState<boolean>(false)
    const registerHandler = async (dto: Schemas.ClientUserMobilePhoneUpdateDto) => {
        try {
            await apiClient.clientUserUpdateMobilePhone({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: '通知を送信しました',
                text: '携帯電話番号変更の通知を送信しました。通知をご確認ください。',
            })
            setIsSend(true)
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: '送信に失敗しました',
                text: message,
            })
        }
    }

    const resendHandler = async (dto: Schemas.ClientUserMobilePhoneUpdateDto) => {
        try {
            await apiClient.clientUserUpdateMobilePhone({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: '通知を送信しました',
                text: '携帯電話番号変更の通知を送信しました。通知をご確認ください。',
            })
            setIsSend(true)
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    return {
        me,
        register,
        errors,
        handleSubmit,
        registerHandler,
        isSubmitting,
        isValid,
        isSend,
        resendHandler,
    }
}

export const UserMobilePhoneIndexPage: FC = () => {
    const { me, register, errors, handleSubmit, registerHandler, isSubmitting, isValid, isSend, resendHandler } =
        useUserMobilePhoneIndexPage()
    return (
        <DefaultLayout title={'携帯電話番号変更'} breadcrumbList={[]}>
            {!isSend ? (
                <Stack spacing={2}>
                    <Typography variant="body2" textAlign={'center'}>
                        携帯電話を入力して送信ボタンを押してください。当サービスより携帯電話番号変更の通知が送信されます。
                    </Typography>

                    <Stack>
                        <CProfileBox label={'現在の携帯電話番号'} value={[me?.mobilePhone ?? '']} />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label="携帯電話番号" required />
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('mobilePhone')}
                            placeholder={'携帯電話番号'}
                            error={!!errors.mobilePhone}
                            helperText={errors.mobilePhone?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <LoadingButton
                        variant={'contained'}
                        disabled={!isValid}
                        loading={isSubmitting}
                        onClick={handleSubmit(registerHandler)}>
                        この電話番号に送信
                    </LoadingButton>
                </Stack>
            ) : (
                <Stack spacing={3}>
                    <Stack>
                        <Typography variant="body2">
                            携帯電話番号変更の通知を送信しました。通知をご確認ください。
                            <br />
                            この時点ではご登録の携帯電話番号は変更されておりません。
                            <br />
                            変更前の携帯電話番号でログインして、通知に記載しているURLにアクセスしてください。
                        </Typography>
                    </Stack>
                    <LoadingButton variant={'outlined'} onClick={handleSubmit(resendHandler)}>
                        電話番号へ再送信
                    </LoadingButton>
                </Stack>
            )}
        </DefaultLayout>
    )
}
