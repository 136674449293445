import { Link } from '@mui/material'
import type { FC, ReactNode } from 'react'

export type CHelpCommonLinkProps = {
    onClick: () => void
    children: ReactNode
}

export const CHelpCommonLink: FC<CHelpCommonLinkProps> = ({ onClick, children }) => {
    return (
        <Link
            data-testid={'c-help-common-link'}
            onClick={onClick}
            fontFamily={'Noto Sans JP'}
            fontWeight={'700'}
            fontSize={'1.125rem'}
            color={'#77618B'}
            sx={{ cursor: 'pointer' }}>
            {children}
        </Link>
    )
}
