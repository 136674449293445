import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Container, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOrderRequestState } from '~/hooks/useOrderRequestState'
import { ClientGiftOrderCreateDtoSchema, clientGiftOrderCreateDtoSchema } from '~/types/zodScheme'
import { useQuerySuspense } from '~/utils/common'

type SelectParam = {
    value: number
    label: string
}

export const useGiftOrderSpecifiedPage = () => {
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const { fetchRequestDto, updateRequestOrderDto } = useOrderRequestState()

    // initial fetch
    const { data: requestOrderDto } = useQuerySuspense(
        [`/gift/order/specified/requestOrderDto`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const [isLastGift, setIsLastGift] = useState<number>(requestOrderDto?.request?.isLastGift ? 1 : 0)
    const isLastGiftTypes: SelectParam[] = [
        { value: 1, label: '没後に届くギフト' },
        { value: 0, label: 'お届け日を指定' },
    ]
    const onChangeIsLastGift = (val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!requestOrderDto) return
        if (!requestOrderDto.request) return
        requestOrderDto.request.isLastGift = Boolean(parseInt(val.target.value))
        setIsLastGift(parseInt(val.target.value))
    }

    const {
        formState: { errors },
        reset,
        watch,
        control,
    } = useForm<ClientGiftOrderCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientGiftOrderCreateDtoSchema),
    })
    useEffect(() => {
        if (!requestOrderDto) return
        if (!requestOrderDto.request) return
        reset({
            deliveredAt: requestOrderDto.request.deliveredAt ?? null,
        })
        const subscription = watch((value) => {
            requestOrderDto.request.deliveredAt = value.deliveredAt
            // return console.log(value.deliveredAt)
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const handleBack = () => {
        navigate(-1)
    }
    // next step
    const handleNextStep = async () => {
        try {
            if (!requestOrderDto!.request.isLastGift) {
                if (!requestOrderDto!.request.deliveredAt || requestOrderDto!.request.deliveredAt === '')
                    throw new AxiosError('お届け日を指定してください')
            }

            await updateRequestOrderDto(requestOrderDto!.request)
            navigate(`/gift/order/payment`)
        } catch (e) {
            let message = 'データ更新に失敗しました'
            console.log(e)
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: message,
                text: '',
            })
        }
    }

    return {
        handleBack,
        handleNextStep,
        isLastGift,
        isLastGiftTypes,
        onChangeIsLastGift,
        errors,
        control,
    }
}

export const GiftOrderSpecifiedPage: FC = () => {
    const { handleBack, handleNextStep, isLastGift, isLastGiftTypes, onChangeIsLastGift, errors, control } =
        useGiftOrderSpecifiedPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="お届け日を指定" breadcrumbList={[]}>
                <Stack spacing={2}>
                    <Stack>
                        <Box maxWidth={'mb'} bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                            <Stack spacing={0.5}>
                                <CInputLabel label={'お届けのタイミング'} />
                                <TextField
                                    id={'isLastGift'}
                                    required={true}
                                    select={true}
                                    variant={'outlined'}
                                    onChange={(val) => onChangeIsLastGift(val)}
                                    value={isLastGift}>
                                    {isLastGiftTypes.map((o: SelectParam) => (
                                        <MenuItem key={o.value} value={o.value}>
                                            {o.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        </Box>
                        <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                            {isLastGift ? (
                                <>
                                    <Typography variant={'body1'}>
                                        自分が亡くなった後、お世話になった方へ発送される感謝の気持ちを伝えるギフトです。
                                    </Typography>
                                </>
                            ) : (
                                <Stack spacing={2}>
                                    <Stack spacing={0.5}>
                                        <CInputLabel label={'お届け日を指定'} required />
                                        <CDatePicker
                                            type={'date'}
                                            label={'お届け日を指定'}
                                            control={control}
                                            name={'deliveredAt'}
                                            error={!!errors.deliveredAt}
                                            helperText={errors.deliveredAt?.message}
                                            minDate={dayjs().add(10, 'days').toDate()}
                                        />
                                    </Stack>
                                    <Typography variant={'body1'}>
                                        命日など気持ちを届けたいタイミングに合わせてギフトを送ることができます。
                                        <br />
                                        ※お届けの日は10日後から指定が可能です。
                                    </Typography>
                                </Stack>
                            )}
                        </Box>
                    </Stack>

                    <Container>
                        <Stack spacing={2}>
                            <LoadingButton variant="contained" onClick={handleNextStep}>
                                お支払い方法を選択
                            </LoadingButton>
                            <LoadingButton variant="outlined" onClick={handleBack}>
                                お届け先を指定に戻る
                            </LoadingButton>
                        </Stack>
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
