import { LoadingButton } from '@mui/lab'
import { Grid, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { AxiosError } from 'axios'
import { uniqBy } from 'lodash-es'
import { FC } from 'react'
import { useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CPaymentBankAccount } from '~/components/functional/payment/cPaymentBankAccount/CPaymentBankAccount'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import {
    dateFormat,
    datetimeFormat,
    getPaymentStatus,
    getPaymentType,
    getShippingStatus,
    mediaUrl,
    useQuerySuspense,
} from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useGiftHistoryGiftUuidPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const giftUuid = params.giftUuid

    // initial fetch
    const { data: gift, refetch } = useQuerySuspense(
        [`gift/history/${giftUuid}`],
        async () => {
            return await apiClient.clientGiftOrderGetHistoryDetail({ parameter: { giftUuid: giftUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const bankTransferCompleted = async () => {
        try {
            if (!giftUuid) return
            const message = '振込完了を通知します。よろしいでしょうか？'
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '振込完了通知',
                    text: message,
                })
            ) {
                await apiClient.clientGiftOrderBankTransferCompleted({ parameter: { giftUuid: giftUuid } })
                await refetch()
            }
        } catch (e) {
            let message = '振込完了通知に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const cancelHandler = async () => {
        try {
            if (!giftUuid) return
            const message = 'キャンセルします。よろしいでしょうか？'
            if (
                await queueDialog({
                    type: 'confirm',
                    title: 'キャンセル確認',
                    text: message,
                })
            ) {
                await apiClient.clientGiftOrderCancelOrder({ parameter: { giftUuid: giftUuid } })
                await refetch()
            }
        } catch (e) {
            let message = 'キャンセルに失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        gift,
        bankTransferCompleted,
        cancelHandler,
    }
}

export const GiftHistoryGiftUuidPage: FC = () => {
    const { gift, bankTransferCompleted } = useGiftHistoryGiftUuidPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="" breadcrumbList={[]}>
                <Stack
                    spacing={2}
                    sx={{
                        fontstyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '150%',
                        color: '#000000',
                    }}>
                    <Stack>
                        <Box bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                            <Stack spacing={1}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">注文番号：</Typography>
                                    <Typography variant="body1">{gift?.uuid.split('-')[0]}</Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">注文日：</Typography>
                                    <Typography variant="body1">{datetimeFormat(gift?.createdAt)}</Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                    <Typography variant="body1">お届け日：</Typography>
                                    <Stack direction={'row'} justifyContent={'end'} spacing={2}>
                                        {gift?.isLastGift ? (
                                            <>
                                                <Typography variant="body1">没後に届くプレゼント</Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Typography variant="body1">お届け日を指定</Typography>
                                                <Typography variant="body1">{dateFormat(gift?.deliveredAt)}</Typography>
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">決済方法：</Typography>
                                    <Stack direction={'row'} justifyContent={'end'} spacing={2}>
                                        <Typography variant="body1">{getPaymentType(gift!.paymentType)}</Typography>
                                        {gift!.paymentType === 3 && gift!.paymentStatus === 0 ? (
                                            <LoadingButton variant="contained" onClick={bankTransferCompleted} sx={{ py: 0 }}>
                                                振込完了
                                            </LoadingButton>
                                        ) : (
                                            <Typography variant="body1">{getPaymentStatus(gift!.paymentStatus)}</Typography>
                                        )}
                                    </Stack>
                                </Stack>
                                {gift!.paymentType === 3 && (
                                    <Grid container>
                                        <Grid item xs={3} alignItems={'center'} />
                                        <Grid item xs={9}>
                                            <CPaymentBankAccount fontSize={'16px'} />
                                        </Grid>
                                    </Grid>
                                )}
                            </Stack>
                        </Box>
                        <Box bgcolor={'white'} p={2}>
                            <Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="body1">合計金額：</Typography>
                                    <Typography variant="body1">¥{gift!.totalPrice.toLocaleString()}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>

                    {uniqBy(
                        gift!.details.map((detail: Schemas.GiftDetailEntities) => detail.giftMaster),
                        'uuid',
                    ).map((gMaster) => {
                        const giftMaster = gMaster as unknown as Schemas.GiftMasterEntities
                        const filter = gift!.details.filter(
                            (d: Schemas.GiftDetailEntities) => d.giftMaster.uuid === giftMaster.uuid,
                        )
                        return (
                            <Stack key={giftMaster.uuid}>
                                <Box bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                                    <Stack direction={'row'} justifyContent={'start'} spacing={2}>
                                        <Stack>
                                            <img
                                                src={mediaUrl(giftMaster.files[0])}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    objectFit: 'contain',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Typography variant="h5">{giftMaster.name}</Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Box bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                        <Typography variant="body1">数量：</Typography>
                                        <Typography variant="body1">{filter.length}</Typography>
                                    </Stack>
                                </Box>
                                {filter.map((detail: Schemas.GiftDetailEntities, index: number) => (
                                    <Box
                                        key={detail.uuid}
                                        bgcolor={'white'}
                                        p={2}
                                        borderBottom={filter.length - 1 > index ? 1 : 0}
                                        borderColor={'#D9D9D9'}>
                                        <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                            <Typography variant="body1">お届け先 ：</Typography>
                                            <Typography variant="body1">
                                                {detail.connect.name}
                                                {detail.connect.sei ? `(${detail.connect.sei})` : ''}
                                            </Typography>
                                        </Stack>
                                        <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                            <Typography variant="body1">配送状況：</Typography>
                                            <Typography variant="body1">{getShippingStatus(detail.shippingStatus)}</Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        )
                    })}
                </Stack>
            </DefaultLayout>
        </>
    )
}
