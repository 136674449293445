import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuthState } from '~/store/auth'

type Props = {
    component: React.ReactNode
}
export const RouteAuthGuard: React.FC<Props> = ({ component }) => {
    const { me } = useAuthState()
    const allowRoute = !!me
    if (!allowRoute) return <Navigate to={'/'} state={{ from: useLocation() }} replace={false} />

    return <>{component}</>
}
