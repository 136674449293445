import { useDisclosure } from '@chakra-ui/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Chip, Stack, TextField } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { CSwitch } from '~/components/common/cSwitch/CSwitch'
import { CMemberDialog } from '~/components/functional/cMember/CMemberDialog'
import { CMemoryUpload } from '~/components/functional/cMemoryUpload/CMemoryUpload'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientMemoryCreateDtoSchema, clientMemoryCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useMemorySelfMemoryUuidEditPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const memoryUuid = params.memoryUuid
    const navigate = useNavigate()

    // initial fetch
    const { data: memory } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/detail`],
        async () => {
            if (!memoryUuid) new Error()
            console.log(memoryUuid)
            if (memoryUuid === 'new') return { uuid: '', connects: [], publish: false } as unknown as Schemas.MemoryEntities
            return await apiClient.clientMemoryGet({ parameter: { memoryUuid: memoryUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
        control,
    } = useForm<ClientMemoryCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientMemoryCreateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: memory?.name || '',
            category: memory?.category || 'memory',
            content: memory?.content || null,
            startAt: memory?.startAt || null,
            endAt: memory?.endAt || null,
            publish: memory?.publish || true,
            dailyLife: memory?.dailyLife || false,
            fileUuid: memory?.file?.uuid || null,
            connectUuids: memory?.connects.map((c: Schemas.ConnectEntities) => c.uuid) || [],
        })
        setImage(memory?.file || null)
        setConnectUuids(memory?.connects.map((c: Schemas.ConnectEntities) => c.uuid) || [])
        setConnects(memory?.connects || [])
    }, [memory])

    const { data: listResponse } = useQuerySuspense(
        [`/memory/self/${memoryUuid}/connectList`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientConnectGetConnectListNotLimit({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const submitHandler = async (dto: Schemas.ClientMemoryCreateDto) => {
        try {
            if (!memoryUuid) return
            if (memoryUuid === 'new') await apiClient.clientMemoryCreate({ requestBody: dto })
            else await apiClient.clientMemoryUpdate({ parameter: { memoryUuid: memoryUuid }, requestBody: dto })
            cancelHandler()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const cancelHandler = () => {
        if (!memoryUuid) return
        if (memoryUuid === 'new') navigate!('/memory/self', { replace: true })
        else navigate!(`/memory/self/${memoryUuid}`, { replace: true })
    }

    // ファイルアップロードモーダルの表示状態
    const [image, setImage] = useState<Schemas.FileEntities | null>(memory?.file || null)
    const handleUploadFile = (value?: Schemas.FileEntities, error?: unknown) => {
        console.log(value, error)
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }
    const handleDeleteFile = () => {
        setValue('fileUuid', null)
        setImage(null)
    }

    // メンバーモーダルの表示状態
    const [connects, setConnects] = useState<Schemas.ConnectEntities[]>([])
    const [connectUuids, setConnectUuids] = useState<string[]>([])
    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const memberButtonHandler = () => {
        updateModalOnOpen()
    }
    const memberDialogSubmitHandler = async (uuids: string[]) => {
        setConnectUuids(uuids)
        setValue('connectUuids', uuids)
        const newConnects = uuids
            .map((uuid: string) => {
                return listResponse?.find((connect: Schemas.ConnectEntities) => connect.uuid === uuid)
            })
            .filter(Boolean) as Schemas.ConnectEntities[]
        setConnects(newConnects)
        editDialogOnClose()
    }
    const MemberDialog = (
        <CMemberDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={memberDialogSubmitHandler}
            connects={listResponse || []}
            uuids={connectUuids}
            title={'共有メンバーを選択'}
        />
    )

    return {
        memory,

        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        submitHandler,

        image,
        handleUploadFile,

        connects,
        memberButtonHandler,
        MemberDialog,

        list: listResponse,

        handleDeleteFile,
        cancelHandler,
    }
}

export const MemorySelfMemoryUuidEditPage: FC = () => {
    const {
        memory,

        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        submitHandler,

        image,
        handleUploadFile,

        connects,
        memberButtonHandler,
        MemberDialog,

        handleDeleteFile,
        cancelHandler,
    } = useMemorySelfMemoryUuidEditPage()
    return (
        <>
            <DefaultLayout title={`思い出アルバムを${memory?.uuid ? '編集' : '作成'}`} breadcrumbList={[]} tabValue={'memory'}>
                <Stack spacing={2}>
                    <Stack spacing={2}>
                        <Stack direction={'column'} alignItems={'center'}>
                            {image ? (
                                <>
                                    <img
                                        alt={''}
                                        src={mediaUrl(image, true)}
                                        style={{ width: 240, height: 240, objectFit: 'cover', borderRadius: '10px' }}
                                    />
                                    <Button onClick={() => handleDeleteFile()}>削除</Button>
                                </>
                            ) : (
                                <>
                                    <CMemoryUpload
                                        completionHandler={handleUploadFile}
                                        label={''}
                                        error={!!errors.fileUuid}
                                        helperText={errors.fileUuid?.message}
                                    />
                                </>
                            )}
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'アルバムのタイトル'} required />
                            <TextField
                                id={'name'}
                                required={true}
                                {...register('name')}
                                placeholder={'アルバムのタイトル'}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'アルバムの説明'} required />
                            <TextField
                                id={'content'}
                                {...register('content')}
                                placeholder={'アルバムの説明'}
                                error={!!errors.content}
                                helperText={errors.content?.message}
                                multiline
                                rows={3}
                                variant={'outlined'}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'共有するメンバー'} />
                            <Box>
                                {connects.map((connect: Schemas.ConnectEntities) => (
                                    <Chip key={connect.uuid} label={connect.name} variant="outlined" sx={{ mr: 0.5 }} />
                                ))}
                            </Box>
                            <Stack sx={{ mb: 2 }}>
                                <Button variant={'contained'} onClick={() => memberButtonHandler()}>
                                    共有するメンバーを選択
                                </Button>
                            </Stack>
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'開始日時'} />
                            <CDatePicker
                                type={'date'}
                                label={'開始日時'}
                                control={control}
                                name={'startAt'}
                                error={!!errors.startAt}
                                helperText={errors.startAt?.message}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'終了日時'} />
                            <CDatePicker
                                type={'date'}
                                label={'終了日時'}
                                control={control}
                                name={'endAt'}
                                error={!!errors.endAt}
                                helperText={errors.endAt?.message}
                            />
                        </Stack>

                        <CSwitch name={'publish'} control={control} label={'公開する'} />

                        <LoadingButton
                            variant={'contained'}
                            loading={isSubmitting}
                            disabled={!isValid}
                            onClick={handleSubmit!(submitHandler)}>
                            {memory?.uuid ? '保存' : '作成'}
                        </LoadingButton>
                        <Button variant="outlined" onClick={() => cancelHandler!()}>
                            キャンセル
                        </Button>
                    </Stack>
                </Stack>
            </DefaultLayout>
            {MemberDialog}
        </>
    )
}
