import { Chip } from '@mui/material'
import type { FC } from 'react'

export const CRequiredChip: FC = () => {
    return (
        <Chip
            label={'必須'}
            size={'small'}
            sx={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '130%',
                backgroundColor: '#AF985A',
                ml: 1,
            }}
        />
    )
}
