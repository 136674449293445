import { FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material'
import { Control, Controller, Path } from 'react-hook-form'

export type CSwitchProps<T> = {
    required?: boolean
    name: Path<T>
    control: Control<T>
    label: string
}

export const CSwitch = <T,>({ required, name, control, label }: CSwitchProps<T>) => {
    return (
        <FormControl required={required}>
            <FormGroup>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => {
                        return (
                            <FormControlLabel
                                control={<Switch />}
                                label={label}
                                onChange={field.onChange}
                                checked={field.value as boolean}
                            />
                        )
                    }}
                />
            </FormGroup>
        </FormControl>
    )
}
