import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent } from '@mui/material'
import { Stack } from '@mui/system'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { ClientMemoryAssetCreateDtoSchema, clientMemoryAssetCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'

export type CMemoryFileDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.ClientMemoryAssetCreateDto, uuid?: string) => void
    entity?: Schemas.FileEntities
}

export const useCMemoryFileDialog = (isOpen: boolean, entity?: Schemas.FileEntities) => {
    const {
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<ClientMemoryAssetCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientMemoryAssetCreateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            fileUuid: entity?.uuid || undefined,
        })
        setImage(entity || undefined)
    }, [isOpen, entity])

    const [image, setImage] = useState<Schemas.FileEntities | undefined>(entity || undefined)

    const handleUploadFile = (value?: Schemas.FileEntities, error?: unknown) => {
        console.log(value, error)
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }

    return {
        handleSubmit,
        errors,
        isSubmitting,
        isValid,

        handleUploadFile,
        image,
    }
}

export const CMemoryFileDialog: FC<CMemoryFileDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const {
        handleSubmit,
        errors,
        isSubmitting,

        handleUploadFile,
        image,
    } = useCMemoryFileDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogContent>
                    <Stack spacing={2}>
                        <CFileUpload
                            completionHandler={handleUploadFile}
                            label={'画像アップロード'}
                            error={!!errors.fileUuid}
                            helperText={errors.fileUuid?.message}
                        />

                        {image?.path && (
                            <img
                                src={mediaUrl(image)}
                                style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    objectPosition: 'center',
                                    objectFit: 'cover',
                                }}
                                alt={image.filename}
                            />
                        )}
                        <Stack direction={'row'} justifyContent={'center'} spacing={1}>
                            <Button variant="outlined" onClick={onClose} fullWidth>
                                キャンセル
                            </Button>
                            <LoadingButton
                                variant={'contained'}
                                loading={isSubmitting}
                                disabled={!image}
                                fullWidth
                                onClick={handleSubmit((dto) => {
                                    onSubmit(dto, entity?.uuid)
                                })}>
                                保存
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
