import { LoadingButton } from '@mui/lab'
import { Box, Container, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { CGraveOfferingCardCard } from '~/components/functional/offering/CGraveOfferingCardCard'
import { CGraveOfferingSpecifyPosition } from '~/components/functional/offering/CGraveOfferingSpecifyPosition'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOfferingOrderRequestState } from '~/hooks/useOfferingOrderRequestState'
import { useQuerySuspense } from '~/utils/common'

export const useGraveOfferingOrderCartPage = () => {
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid
    const graveUuid = params.graveUuid
    const { fetchRequestDto, updateRequestOrderDto } = useOfferingOrderRequestState(connectUuid!)

    // initial fetch
    const { data: requestOrderDto, refetch: fetchRequest } = useQuerySuspense(
        [`/grave/offering/oder/cart`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handleRemoveOrder = async (findIndex: number) => {
        if (!requestOrderDto || !requestOrderDto.request || !requestOrderDto.request.details) return
        requestOrderDto?.request?.details?.splice(findIndex, 1)
        await updateRequestOrderDto(requestOrderDto.request)
        await fetchRequest()
    }

    const handleNextStep = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/reserve`)
    }

    const handleMoveSelectOffering = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering`)
    }

    const handleMoveToGraveTop = () => {
        navigate(`/connectList/${connectUuid!}/grave`)
    }

    // お供え物の描画
    const GraveOfferingSpecifyPosition = (
        <CGraveOfferingSpecifyPosition connectUuid={connectUuid!} details={requestOrderDto?.preview || []} />
    )

    const [totalPrice, setTotalPrice] = useState<number>(0)
    useEffect(() => {
        if (!requestOrderDto) setTotalPrice(0)
        const totalPrice = requestOrderDto!.preview.reduce((total, current) => total + current.offeringMaster.price, 0)
        setTotalPrice(totalPrice)
    }, [requestOrderDto])

    return {
        handleRemoveOrder,
        handleNextStep,
        handleMoveSelectOffering,
        preview: requestOrderDto!.preview,
        GraveOfferingSpecifyPosition,
        handleMoveToGraveTop,
        totalPrice,
    }
}

export const GraveOfferingOrderCartPage: FC = () => {
    const {
        handleRemoveOrder,
        handleNextStep,
        handleMoveSelectOffering,
        preview,
        GraveOfferingSpecifyPosition,
        handleMoveToGraveTop,
        // totalPrice, : 合計金額が必要になったら使用する
    } = useGraveOfferingOrderCartPage()
    return (
        <>
            <DefaultLayout maxWidth="md" title="カート" breadcrumbList={[]}>
                <Stack spacing={2}>
                    {!preview || preview.length === 0 ? (
                        <Container maxWidth={'sm'}>
                            <Typography variant={'body2'}>選択した御供物がありません</Typography>
                        </Container>
                    ) : (
                        <Stack spacing={1}>
                            {preview.map((detail, index) => (
                                <Box key={index} bgcolor={'white'} p={2}>
                                    <CGraveOfferingCardCard
                                        detail={detail}
                                        handleRemove={handleRemoveOrder}
                                        index={index}
                                        isDeleteIcon
                                    />
                                </Box>
                            ))}
                        </Stack>
                    )}

                    <Box sx={{ mt: 2 }}>{GraveOfferingSpecifyPosition}</Box>

                    <Container>
                        <Stack spacing={2}>
                            <LoadingButton
                                variant="contained"
                                sx={{ mt: 4 }}
                                disabled={!(preview && preview.length > 0)}
                                onClick={handleNextStep}>
                                お供えする日時を指定する
                            </LoadingButton>

                            <LoadingButton variant="outlined" onClick={handleMoveSelectOffering}>
                                もう一度お供え物を選ぶ
                            </LoadingButton>

                            <LoadingButton variant="outlined" onClick={handleMoveToGraveTop}>
                                お墓Topへ
                            </LoadingButton>
                        </Stack>
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
