import { LoadingButton } from '@mui/lab'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import noImage from '~/assets/image/noImage.png'
import { CGraveOfferingSpecifyPosition } from '~/components/functional/offering/CGraveOfferingSpecifyPosition'
import { COfferingMasterDescription } from '~/components/functional/offering/COfferingMasterDescription'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOfferingOrderRequestState } from '~/hooks/useOfferingOrderRequestState'
import { displayTimeFormat, mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useGraveOfferingMasterDetailPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const masterUuid = params.masterUuid
    const connectUuid = params.connectUuid
    const graveUuid = params.graveUuid

    const { fetchRequestDto, updateRequestOrderDto } = useOfferingOrderRequestState(connectUuid!)

    // お供え物マスタ取得
    const { data: master } = useQuerySuspense(
        [`/grave/offering/master`],
        async () => {
            return await apiClient.clientOfferingMasterShow({ parameter: { uuid: masterUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    // カート情報取得
    const { data: requestOrderDto, refetch: refetchRequestOrderDto } = useQuerySuspense(
        [`/grave/offering/master/requestOrderDto`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    /**
     * カートに入れてカート画面に遷移する
     * @param offeringMaster
     */
    const handleNextStep = async (offeringMaster: Schemas.OfferingMasterEntities) => {
        if (!requestOrderDto || !requestOrderDto.request || !requestOrderDto.request.details) {
            const newRequestDto: Schemas.ClientOfferingCreateDto = {
                paymentType: 0,
                connectUuid: connectUuid!,
                graveUuid: graveUuid,
                reserve: false,
                reservationAt: null,
                details: [
                    {
                        offeringMasterUuid: offeringMaster.uuid,
                        positionX: positionX,
                        positionY: positionY,
                    },
                ],
            }
            await updateRequestOrderDto(newRequestDto)
        } else {
            const dto: Schemas.ClientOfferingDetailCreateDto = {
                offeringMasterUuid: offeringMaster.uuid,
                positionX: positionX,
                positionY: positionY,
            }
            requestOrderDto.request.details.push(dto)
            await updateRequestOrderDto(requestOrderDto.request)
        }
        await refetchRequestOrderDto()
        handleCartPage()
    }

    /**
     * カート画面に遷移する
     */
    const handleCartPage = () => {
        navigate(`/connectList/${connectUuid}/grave/${graveUuid}/offering/cart`)
    }

    /**
     * お供え物一覧に遷移する
     */
    const handleMoveSelectOffering = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering`)
    }

    const [positionX, setPositionX] = useState(0)
    const [positionY, setPositionY] = useState(0)
    const specifyPosition = (x: number, y: number) => {
        setPositionX(x)
        setPositionY(y)
    }

    // お供え物の描画位置指定
    const GraveOfferingSpecifyPosition = (
        <CGraveOfferingSpecifyPosition
            connectUuid={connectUuid!}
            master={master!}
            completeHandler={specifyPosition}
            details={requestOrderDto?.preview || []}
        />
    )

    return {
        master,
        handleNextStep,
        handleCartPage,
        handleMoveSelectOffering,
        GraveOfferingSpecifyPosition,
        positionX,
        positionY,
    }
}

export const GraveOfferingMasterDetailPage: FC = () => {
    const {
        master,
        handleNextStep,
        handleCartPage,
        handleMoveSelectOffering,
        GraveOfferingSpecifyPosition,
        positionX,
        positionY,
    } = useGraveOfferingMasterDetailPage()

    return (
        <>
            <DefaultLayout title={master?.name} breadcrumbList={[]}>
                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                            {master && master.file ? (
                                <Box
                                    component="img"
                                    sx={{
                                        maxHeight: 255,
                                        maxWidth: '100%',
                                        marginRight: 'auto',
                                        marginLeft: 'auto',
                                    }}
                                    src={mediaUrl(master.file)}
                                />
                            ) : (
                                <Box
                                    component="img"
                                    sx={{
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                    src={noImage}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                <Typography variant={'h5'}>{master!.name}</Typography>
                                <Typography variant={'h6'}>表示時間: {displayTimeFormat(master!.displayTime)}</Typography>
                                <Typography variant={'h6'}>{master!.price.toLocaleString()}円</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center', mt: 2 }} variant="subtitle1">
                            お供えする場所をタップしてください
                        </Typography>
                        <Typography sx={{ textAlign: 'center', mb: 2 }} variant="body1">
                            タップすると表示される枠は実際には表示されません
                        </Typography>
                        {GraveOfferingSpecifyPosition}
                    </Box>

                    <LoadingButton
                        variant="contained"
                        onClick={() => handleNextStep(master!)}
                        disabled={positionX === 0 && positionY === 0}>
                        カートに入れる
                    </LoadingButton>

                    <Stack sx={{ mt: 4 }} direction={'column'} spacing={2}>
                        <LoadingButton variant="outlined" onClick={handleCartPage}>
                            カート画面へ
                        </LoadingButton>
                        <LoadingButton variant="outlined" onClick={handleMoveSelectOffering}>
                            他のお供え物を選ぶ
                        </LoadingButton>
                    </Stack>

                    {((master && master.description?.length) || 0 > 0) && (
                        <COfferingMasterDescription descriptions={master!.description || []} />
                    )}
                </Stack>
            </DefaultLayout>
        </>
    )
}
