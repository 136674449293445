import { Box, Grid, Pagination, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'

import informationIcon from '~/assets/image/informationIcon.svg'
import { CInformationItem } from '~/components/functional/information/CInformationItem'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export const useGiftHistoryIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const [queryParams, setQueryParams] = useState<QueryParameters>({ page: 1, limit: 10 })

    const { data: listResponse, refetch: fetchList } = useQuerySuspense(
        ['/gift/history', queryParams],
        async () => {
            return await apiClient.clientNotificationBoardIndex({ parameter: queryParams })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await fetchList()
    }

    return {
        queryParams,
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        handlePageChange,
    }
}

export const InformationIndexPage: FC = () => {
    const { queryParams, list, count, handlePageChange } = useGiftHistoryIndexPage()

    return (
        <>
            <DefaultLayout title="" breadcrumbList={[]}>
                <Box sx={{ backgroundColor: 'white' }}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        <img src={informationIcon} alt="" width={'52px'} style={{ position: 'relative', top: '-12px' }} />
                        <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
                            お知らせ一覧
                        </Typography>
                    </Stack>

                    {list.length === 0 && (
                        <Typography sx={{ px: 2, py: 1 }} variant={'body2'}>
                            お知らせはありません
                        </Typography>
                    )}
                    {list.length > 0 && list.map((item) => <CInformationItem key={item.uuid} item={item} />)}
                </Box>

                {list.length > 0 && (
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pt: 3 }}>
                        <Pagination
                            count={Math.floor(count / queryParams.limit) + (count % queryParams.limit === 0 ? 0 : 1)}
                            page={queryParams.page}
                            onChange={(_, value) => handlePageChange(value)}
                        />
                    </Grid>
                )}
            </DefaultLayout>
        </>
    )
}
