import { Button, Stack, Typography } from '@mui/material'
import { type FC, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import addIcon from '~/assets/image/icon/add.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { JapanesePeriod } from '~/utils/common'

export type CHistoryListViewProps = {
    title: string
    subtitle?: string
    histories?: Schemas.UserPersonalHistoryEntities[]
    connectUuid?: string
    tabValue?: string
    isConnect?: boolean
}

const useCHistoryList = (props: CHistoryListViewProps) => {
    const navigate = useNavigate()

    const { histories, isConnect, connectUuid } = props

    const historyRows = useMemo(() => {
        if (!histories?.length) {
            return (
                <Typography variant={'subtitle2'} textAlign={'center'}>
                    自分史がありません
                </Typography>
            )
        }
        return histories.map((i) => {
            return (
                <HistoryRow
                    key={i.uuid}
                    month={String(i.month)}
                    year={String(i.year)}
                    title={i.title}
                    japaneseEra={i.japaneseEra}
                    isJapaneseYear={i.isJapaneseCalender}
                    onClick={() =>
                        navigate(isConnect ? `/connectList/${connectUuid}/history/${i.uuid}` : `/user/history/${i.uuid}`)
                    }
                />
            )
        })
    }, [histories])
    return { historyRows, navigate }
}

export const CHistoryListView: FC<CHistoryListViewProps & ReturnType<typeof useCHistoryList>> = (props) => {
    const { title, historyRows, subtitle, navigate, isConnect, tabValue, histories } = props
    return (
        <DefaultLayout title={title} data-testid="c-history-list" breadcrumbList={[]} tabValue={tabValue}>
            <Stack spacing={2}>
                {!isConnect && !histories?.length && (
                    <Typography variant={'body1'} textAlign={'center'}>
                        {subtitle}
                    </Typography>
                )}
                {!isConnect && (
                    <Stack>
                        <Button
                            onClick={() => navigate('/user/history/create')}
                            variant={'contained'}
                            startIcon={<img alt="" src={addIcon} />}>
                            自分史を登録
                        </Button>
                    </Stack>
                )}
                <Stack>{historyRows}</Stack>
            </Stack>
        </DefaultLayout>
    )
}

export const CHistoryList: FC<CHistoryListViewProps> = (props) => {
    const hookItems = useCHistoryList(props)
    return <CHistoryListView {...props} {...hookItems} />
}

type RowProps = {
    title: string
    month: string
    year: string
    onClick: () => void
    japaneseEra: typeof JapanesePeriod[number]['value'] | null
    isJapaneseYear?: boolean
}

export const HistoryRow = (props: RowProps) => {
    const { title, month, year, onClick, japaneseEra, isJapaneseYear } = props
    return (
        <Stack onClick={onClick} spacing={1} py={2} borderTop={'1px #AF985A solid'} sx={{ cursor: 'pointer' }}>
            <Typography variant={'subtitle2'}>
                {`${japaneseEra !== null && isJapaneseYear ? JapanesePeriod[japaneseEra].text : ''} ${year}年${month}月`}
            </Typography>
            <Typography variant={'subtitle2'}>{title}</Typography>
        </Stack>
    )
}
