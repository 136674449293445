import QRCode from 'qrcode'
import React, { useEffect, useMemo, useRef } from 'react'

export type Colors = {
    dark?: string
    light?: string
}

export type Options = {
    type?: string
    quality?: number
    level?: string
    margin?: number
    scale?: number
    width?: number
    color?: Colors
}

export type IQRCode = {
    text: string
    options?: Options
}

function useImageComponent() {
    const ImageComponent = <T extends HTMLImageElement>({ text, options }: IQRCode) => {
        const inputRef = useRef<T>(null)

        useEffect(() => {
            if (inputRef && inputRef.current) {
                // @ts-expect-error
                QRCode.toDataURL(text, options, (error?: Error, url: string) => {
                    if (error) throw error

                    if (inputRef.current instanceof HTMLImageElement) inputRef.current.src = url
                })
            }
        }, [text, options, inputRef])

        return <img ref={inputRef} />
    }

    return useMemo(() => ImageComponent, [])
}

function useCanvasComponent() {
    const CanvasComponent = <T extends HTMLCanvasElement>({ text, options }: IQRCode) => {
        const inputRef = useRef<T>(null)

        useEffect(() => {
            if (inputRef && inputRef.current) {
                // @ts-expect-error
                QRCode.toCanvas(inputRef.current, text, options, (error: Error) => {
                    if (error) throw error
                })
            }
        }, [text, options, inputRef])

        return <canvas ref={inputRef} />
    }

    return useMemo(() => CanvasComponent, [])
}

export function useQRCode() {
    const Image = useImageComponent()
    const Canvas = useCanvasComponent()

    return {
        Image,
        Canvas,
    }
}
