import { mdiPlay } from '@mdi/js'
import { Icon } from '@mdi/react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Pagination,
    Stack,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material'
import { AxiosError } from 'axios'
import { type FC, useMemo, useState } from 'react'

import { Schemas } from '~/apis/types'
import triangleButton from '~/assets/image/sponsorList/triangleButton.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useBrowserUniqueId } from '~/store/browser-unique-id'
import { sponsorMediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    searchWord: string // 検索キーワード
    page: number // ページ番号
    limit: number // 表示制限数
}

// 選択状態を管理するカスタムフック
const useSetSelection = <T,>(initialValues: T[]) => {
    const [selectedItems, setSelectedItems] = useState<Set<T>>(new Set(initialValues))

    // アイテムの選択をトグルする関数
    const toggleItem = (item: T) => {
        setSelectedItems((prevSelected) => {
            const newSelected = new Set(prevSelected)
            if (newSelected.has(item)) newSelected.delete(item)
            else newSelected.add(item)

            return newSelected
        })
    }

    const selectedArray = Array.from(selectedItems)

    // 選択されたアイテムをすべて削除する関数
    const removeAll = () => {
        setSelectedItems(new Set([]))
    }

    return [selectedArray, toggleItem, removeAll] as const
}

// 協賛一覧ページのロジックを管理するカスタムフック
export const useSponsorListPage = () => {
    const apiClient = createApiClient() // APIクライアントを作成
    const { queueDialog } = useConfirmationDialog() // ダイアログのキューを取得
    const { browserUuid } = useBrowserUniqueId()

    // カテゴリの選択状態を管理するカスタムフック
    const [selectedCategories, setSelectedCategories, removeAllSelectedCategories] = useSetSelection<string>([])
    // 都道府県の選択状態を管理するカスタムフック
    const [selectedPrefecture, setSelectedPrefecture, removeAllSelectedPrefecture] = useSetSelection<string>([])
    const [openAccordions, setOpenAccordions] = useState([false, false])

    const [queryParams, setQueryParams] = useState<QueryParameters>({
        page: 1,
        limit: 20,
        searchWord: '',
    })

    // APIエラーを処理する関数
    const handleApiError = async (e: unknown) => {
        let message = 'データ取得に失敗しました'
        if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
        await queueDialog({
            type: 'alert',
            title: 'エラーが発生しました',
            text: message,
        })
    }

    const { data: sponsorList, refetch } = useQuerySuspense(
        ['sponsorList', queryParams.page],
        async () =>
            await apiClient.clientSponsorGetAllSponsors({
                parameter: { ...queryParams, categoryUuids: selectedCategories, prefectureUuids: selectedPrefecture },
            }),
        { onError: handleApiError },
    )

    // カテゴリ一覧のデータを取得
    const { data: categoriesList } = useQuerySuspense(
        ['categoriesList'],
        async () => await apiClient.clientSponsorGetAllSponsorCategories(),
        {
            onError: handleApiError,
        },
    )

    // 都道府県一覧のデータを取得
    const { data: prefectureList } = useQuerySuspense(
        ['prefectureList'],
        async () => await apiClient.clientPrefectureGetAllPrefectures(),
        {
            onError: handleApiError,
        },
    )

    // エリアタイプに応じて都道府県を絞り込む関数
    const filterPrefectureByAreaType = (type: number, list?: Schemas.PrefectureEntities[]) => {
        if (!list) return []
        return list.filter((i) => i.regionType === type)
    }

    // エリアタイプごとに都道府県の絞り込みを行う
    const areaTypePrefecture = [
        { title: '北海道・東北', prefectureCheckboxes: filterPrefectureByAreaType(0, prefectureList?.list) },
        { title: '中部', prefectureCheckboxes: filterPrefectureByAreaType(1, prefectureList?.list) },
        { title: '関東', prefectureCheckboxes: filterPrefectureByAreaType(2, prefectureList?.list) },
        { title: '近畿', prefectureCheckboxes: filterPrefectureByAreaType(3, prefectureList?.list) },
        { title: '中国', prefectureCheckboxes: filterPrefectureByAreaType(4, prefectureList?.list) },
        { title: '四国', prefectureCheckboxes: filterPrefectureByAreaType(5, prefectureList?.list) },
        { title: '九州', prefectureCheckboxes: filterPrefectureByAreaType(6, prefectureList?.list) },
    ]

    // カテゴリのチェックボックスを生成する関数
    const categoriesCheckboxes = useMemo(() => {
        if (!categoriesList?.list) return <></>
        // Check if "その他" category is present
        const otherCategory = categoriesList.list.find((category) => category.title === 'その他')
        // Filter out "その他" category from the list
        const filteredCategories = categoriesList.list.filter((category) => category.title !== 'その他')
        if (otherCategory) filteredCategories.push(otherCategory)

        // Create checkboxes for each category
        return filteredCategories.map((category) => (
            <FormControlLabel
                key={category.uuid}
                label={category.title}
                checked={selectedCategories.includes(category.uuid)}
                onChange={() => setSelectedCategories(category.uuid)}
                sx={{
                    '.MuiFormControlLabel-label': { fontSize: '18px' },
                }}
                control={
                    <Checkbox
                        icon={
                            <SvgIcon>
                                <svg focusable="false" aria-hidden="true" data-testid="CheckBoxBlankIcon">
                                    <path
                                        d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5"
                                        fill="white"
                                        stroke="#B188CA"
                                        strokeWidth={'1px'}
                                        strokeLinejoin="round"
                                        paintOrder={'stroke'}
                                    />
                                </svg>
                            </SvgIcon>
                        }
                    />
                }
            />
        ))
    }, [selectedCategories])

    // 都道府県のチェックボックスを生成する関数
    const prefectureCheckboxes = useMemo(() => {
        return areaTypePrefecture.map((i) => (
            <Box display={'flex'} flexDirection={'column'} gap={2} key={i.title}>
                <Box mx={'-6px'} display={'flex'} alignItems={'center'}>
                    <Icon path={mdiPlay} color={'#B188CA'} size={1} />
                    <Typography color={'#77618B'} variant={'subtitle1'}>
                        {i.title}
                    </Typography>
                </Box>
                <Box>
                    {i.prefectureCheckboxes.map((q) => (
                        <FormControlLabel
                            key={q.uuid}
                            checked={selectedPrefecture.includes(q.uuid)}
                            onChange={() => setSelectedPrefecture(q.uuid)}
                            label={q.title}
                            sx={{
                                '.MuiFormControlLabel-label': { fontSize: '18px' },
                            }}
                            control={
                                <Checkbox
                                    icon={
                                        <SvgIcon>
                                            <svg focusable="false" aria-hidden="true" data-testid="CheckBoxBlankIcon">
                                                <path
                                                    d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5"
                                                    fill="white"
                                                    stroke="#B188CA"
                                                    strokeWidth={'1px'}
                                                    strokeLinejoin="round"
                                                    paintOrder={'stroke'}
                                                />
                                            </svg>
                                        </SvgIcon>
                                    }
                                />
                            }
                        />
                    ))}
                </Box>
            </Box>
        ))
    }, [selectedCategories])

    // アコーディオンをトグルする関数
    const toggleAccordionAtIndex = (index: number) => {
        setOpenAccordions((prevAccordions) => {
            const updatedAccordions = [...prevAccordions]
            updatedAccordions[index] = !updatedAccordions[index]
            return updatedAccordions
        })
    }

    // すべてのアコーディオンを閉じる関数
    const setAllAccordionsToFalse = () => {
        setOpenAccordions((prevAccordions) => prevAccordions.map(() => false))
    }

    // 検索を実行する関数
    const onSearch = async () => {
        setAllAccordionsToFalse()
        return refetch()
    }

    // ページが変更されたときに実行される関数
    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await refetch()
    }

    // フィルターが適用されているかどうかを判定する
    const isFiltersApplied = useMemo(() => {
        return Boolean(selectedCategories.length || selectedPrefecture.length || queryParams.searchWord.length)
    }, [selectedCategories, selectedPrefecture, queryParams.searchWord])

    const onClickSponsor = async (siteUrl: string, sponsorUuid: string) => {
        await apiClient.clientSponsorClickCount({ parameter: { sponsorUuid, browserUuid } }).then(() => {
            window.open(siteUrl, '_blank', 'noopener,noreferrer')
        })
    }

    const SponsorCard: FC<{ item: Schemas.SponsorEntities }> = ({ item }) => {
        const { name: title, siteUrl, bannerImage } = item

        const handleCardClick = async () => {
            if (siteUrl) await onClickSponsor(siteUrl, item.uuid)
        }

        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                onClick={handleCardClick}
                sx={{ maxWidth: '9.906rem', height: 'auto', cursor: siteUrl ? 'pointer' : 'default' }}>
                <img
                    src={sponsorMediaUrl(bannerImage)}
                    style={{ width: '100%', objectFit: 'contain', height: 'auto' }}
                    alt={title}
                />
                <Typography sx={{ textDecoration: 'underline' }} mt={'0.25rem'} fontSize={'1rem'} fontWeight={'500'}>
                    {title}
                </Typography>
            </Box>
        )
    }

    // スポンサーカードのリストを生成する関数
    const sponsorCardList = useMemo(() => {
        if (!sponsorList?.list) return <></>
        return sponsorList.list.map((i) => <SponsorCard key={i.uuid} item={i} />)
    }, [sponsorList])

    // 検索条件をクリアする関数
    const onClear = async () => {
        removeAllSelectedCategories()
        removeAllSelectedPrefecture()
        setQueryParams({ page: 1, limit: 20, searchWord: '' })
        await Promise.resolve().then(async () => await refetch())
    }

    return {
        categoriesCheckboxes,
        prefectureCheckboxes,
        handlePageChange,
        queryParams,
        sponsorList,
        sponsorCardList,
        setQueryParams,
        onSearch,
        onClear,
        isFiltersApplied,
        openAccordions,
        toggleAccordionAtIndex,
    }
}

export const SponsorListIndexPageView: FC<ReturnType<typeof useSponsorListPage>> = (props) => {
    const {
        categoriesCheckboxes,
        prefectureCheckboxes,
        handlePageChange,
        queryParams,
        sponsorList,
        sponsorCardList,
        setQueryParams,
        onSearch,
        onClear,
        isFiltersApplied,
        openAccordions,
        toggleAccordionAtIndex,
    } = props
    return (
        <DefaultLayout title={'協賛事業者一覧'} titleHelperText={'※50音順'} breadcrumbList={[]} isTab={false}>
            {/* 検索ボックス */}
            <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                bgcolor={'#EFEAE6'}
                py={'1.5rem'}
                px={'1rem'}
                borderRadius={'1rem'}>
                <Stack spacing={'0.5rem'}>
                    <Typography variant={'subtitle2'}>フリーワード検索</Typography>
                    <Box display={'flex'} gap={1} maxHeight={'2.75rem'} alignItems={'center'}>
                        <TextField
                            value={queryParams.searchWord}
                            onChange={(e) => setQueryParams({ ...queryParams, searchWord: e.target.value })}
                            fullWidth
                            variant={'outlined'}
                        />
                        <Button
                            disabled={!isFiltersApplied}
                            onClick={() => onSearch()}
                            sx={{ minWidth: 'fit-content', maxHeight: '2.75rem' }}
                            variant={'contained'}>
                            検索
                        </Button>
                    </Box>
                </Stack>
                {/* カテゴリと都道府県の絞り込み */}
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <Box py={0.5} sx={{ borderBottomColor: '#AF985A', borderBottomWidth: '1px', borderBottomStyle: 'solid' }}>
                        {/* カテゴリで絞り込むアコーディオン */}
                        <Accordion
                            expanded={openAccordions[0]}
                            onChange={() => toggleAccordionAtIndex(0)}
                            variant={'outlined'}
                            sx={{ bgcolor: '#EFEAE6', border: 'none' }}>
                            <AccordionSummary
                                expandIcon={<img src={triangleButton} alt="" />}
                                sx={{
                                    px: 0,
                                    '&.Mui-expanded': {
                                        minHeight: '48px',
                                    },
                                    '& .MuiAccordionSummary-content': {
                                        '&.Mui-expanded': {
                                            margin: 'unset',
                                        },
                                    },
                                }}>
                                <Typography variant={'subtitle1'}>カテゴリで絞り込む</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ px: 0 }}>{categoriesCheckboxes}</AccordionDetails>
                        </Accordion>
                    </Box>
                    <Box py={0.5} sx={{ borderBottomColor: '#AF985A', borderBottomWidth: '1px', borderBottomStyle: 'solid' }}>
                        {/* 都道府県で絞り込むアコーディオン */}
                        <Accordion
                            expanded={openAccordions[1]}
                            onChange={() => toggleAccordionAtIndex(1)}
                            variant={'outlined'}
                            sx={{ bgcolor: '#EFEAE6', border: 'none' }}>
                            <AccordionSummary
                                expandIcon={<img src={triangleButton} alt="" />}
                                sx={{
                                    px: 0,
                                    '&.Mui-expanded': {
                                        minHeight: '48px',
                                    },
                                    '& .MuiAccordionSummary-content': {
                                        '&.Mui-expanded': {
                                            margin: 'unset',
                                        },
                                    },
                                }}>
                                <Typography variant={'subtitle1'}>都道府県で絞り込む</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ px: 0 }}>
                                <Box display={'flex'} flexDirection={'column'} gap={2}>
                                    {prefectureCheckboxes}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
                {/* 検索ボタンと条件クリアボタン */}
                <Box display={'flex'} flexDirection={'column'} gap={2} mt={'1rem'}>
                    <Button disabled={!isFiltersApplied} onClick={() => onSearch()} fullWidth variant={'contained'}>
                        検索
                    </Button>
                    {isFiltersApplied && (
                        <Button
                            onClick={onClear}
                            sx={{ bgcolor: 'white', ':hover': { backgroundColor: 'white' } }}
                            fullWidth
                            variant={'contained'}>
                            検索条件をクリア
                        </Button>
                    )}
                </Box>
            </Box>
            {/* 検索結果表示 */}
            <Box mt={'1.875rem'}>
                {sponsorList && <Typography variant={'subtitle1'}>検索結果</Typography>}
                {sponsorList && sponsorList?.count !== 0 && (
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'1rem'} gap={2}>
                        <Box
                            display={'grid'}
                            width={'100%'}
                            gridTemplateColumns={{ sm: 'repeat(3, 1fr)', xs: 'repeat(2, 1fr)' }}
                            gap={2}>
                            {sponsorCardList}
                        </Box>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Pagination
                                count={Math.ceil((sponsorList?.count || 0) / queryParams.limit)}
                                page={queryParams.page}
                                onChange={(_, value) => handlePageChange(value)}
                            />
                        </Grid>
                    </Box>
                )}
                {sponsorList && sponsorList?.count === 0 && (
                    <Box mt={'1rem'} borderRadius={1} py={1} px={2} bgcolor={'#EFEAE6'} color={'#77618B'}>
                        検索結果がありません。条件を変更して再検索してください。
                    </Box>
                )}
                {sponsorList && (
                    <Box mt={'0.625rem'}>
                        <CustomDivider />
                    </Box>
                )}
            </Box>
        </DefaultLayout>
    )
}

const CustomDivider: FC = () => {
    return (
        <Box display={'flex'} gap={0.5} alignItems={'center'}>
            <Box width={'0.25rem'} height={'0.25rem'} borderRadius={'0.09375rem'} bgcolor={'textFieldBorderColor.main'} />
            <Box width={'100%'} height={'0.0625rem'} bgcolor={'textFieldBorderColor.main'} />
            <Box width={'0.25rem'} height={'0.25rem'} borderRadius={'0.09375rem'} bgcolor={'textFieldBorderColor.main'} />
        </Box>
    )
}

export const SponsorListIndexPage: FC = () => {
    const hookItems = useSponsorListPage()
    return <SponsorListIndexPageView {...hookItems} />
}

export default SponsorListIndexPage
