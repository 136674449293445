import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Link, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import decorated from '~/assets/image/decorated.svg'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { useOrderState } from '~/store/order'
import {
    UserLoginUserDtoSchema,
    userLoginUserDtoSchema,
    UserLoginUserMobilePhoneDtoSchema,
    userLoginUserMobilePhoneDtoSchema,
} from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'
// import { useLogin } from '~/components/functional/cLogin/useLogin'

export const useLoginIndexPage = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { queueDialog } = useConfirmationDialog()
    const { setToken, setMe } = useAuthState()
    const { setOrderToken } = useOrderState()
    const location = useLocation()
    console.log(location)

    const [isSendCreatePassword, setIsSendCreatePassword] = useState(false)
    const [sendAccount, setSendAccount] = useState('mobilePhone')
    const sendAccountTypes = [
        { value: 'mobilePhone', label: '携帯電話' },
        { value: 'mail', label: 'メールアドレス' },
    ]
    const onChangeSendAccount = (val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSendAccount(val.target.value)
        setIsSendCreatePassword(false)
    }

    /**
     * 携帯電話でログイン
     */
    const {
        register: createPasswordRegister,
        handleSubmit: handleSubmitCreatePassword,
        formState: { errors: errorsCreatePassword, isSubmitting: isSubmittingCreatePassword, isValid: isValidCreatePassword },
        setValue: setValueCreatePassword,
    } = useForm<UserLoginUserMobilePhoneDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(userLoginUserMobilePhoneDtoSchema),
    })
    useEffect(() => {
        setValueCreatePassword('mobilePhone', '')
        setValueCreatePassword('password', '')
        setValueCreatePassword('email', '')
    }, [sendAccount])
    const sendHandler = async (dto: Schemas.ClientUserMobilePhonePasswordRequestDto) => {
        try {
            await apiClient.clientUserCreatePassword({ requestBody: dto })
            setIsSendCreatePassword(true)
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const loginHandlerByMobilePhone = async (dto: Schemas.ClientLoginUserDto) => {
        try {
            if (!dto.mobilePhone) return
            const requestDto: Schemas.ClientLoginUserDto = {
                email: dto.mobilePhone,
                password: dto.password,
            }
            const res = await apiClient.clientAuthLogin({ requestBody: requestDto })
            setToken(res)
            const me = await apiClient.clientAuthUserMe()
            setMe(me)
            await apiClient.clientGiftOrderRequestInit({})
            setOrderToken(me.uuid)
            navigate('/user')
        } catch (e) {
            let message = 'ログインに失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    /**
     * メールアドレスでログイン
     */
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        setValue,
    } = useForm<UserLoginUserDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(userLoginUserDtoSchema),
    })

    useEffect(() => {
        setValue('email', '')
        setValue('password', '')
    }, [sendAccount])

    const [isError, setIsError] = useState<boolean>(false)
    const loginHandler = async (dto: Schemas.ClientLoginUserDto) => {
        try {
            const res = await apiClient.clientAuthLogin({ requestBody: dto })
            setToken(res)
            const me = await apiClient.clientAuthUserMe()
            setMe(me)
            await apiClient.clientGiftOrderRequestInit({})
            setOrderToken(me.uuid)
            navigate('/user', { replace: true })
        } catch (e) {
            setIsError(true)
        }
    }

    return {
        isSendCreatePassword,
        setIsSendCreatePassword,
        sendAccount,
        sendAccountTypes,
        onChangeSendAccount,

        createPasswordRegister,
        handleSubmitCreatePassword,
        errorsCreatePassword,
        isSubmittingCreatePassword,
        isValidCreatePassword,
        sendHandler,
        loginHandlerByMobilePhone,

        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        isError,
        loginHandler,
    }
}

export const LoginIndexPage: FC = () => {
    const {
        isSendCreatePassword,
        setIsSendCreatePassword,
        sendAccount,
        sendAccountTypes,
        onChangeSendAccount,

        createPasswordRegister,
        handleSubmitCreatePassword,
        errorsCreatePassword,
        isSubmittingCreatePassword,
        isValidCreatePassword,
        sendHandler,
        loginHandlerByMobilePhone,

        register,
        errors,
        handleSubmit,
        loginHandler,
        isSubmitting,
        isValid,
        isError,
    } = useLoginIndexPage()
    return (
        <>
            <DefaultLayout
                title={isSendCreatePassword ? 'ワンタイムパスワード入力' : 'ログイン'}
                breadcrumbList={[]}
                isTab={false}>
                <Container maxWidth={'sm'} sx={{}}>
                    <Stack spacing={3}>
                        {isSendCreatePassword ? (
                            <>
                                <Typography variant="body2" textAlign={'center'}>
                                    携帯電話番号に送信されたSMSに記載されているワンタイムパスワードを入力してください。ワンタイムパスワードの有効期限は5分です。
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="body2" textAlign={'center'}>
                                    携帯電話を入力して送信ボタンを押してください。当サービスよりワンタイムパスワードが送信されます。
                                </Typography>
                            </>
                        )}
                        {process.env.STAGE !== 'prod' && (
                            <TextField
                                id={'sendAccount'}
                                required={true}
                                label={'ログイン方法(携帯電話/メールアドレス)'}
                                select={true}
                                onChange={(val) => {
                                    onChangeSendAccount(val)
                                }}
                                value={sendAccount}>
                                {sendAccountTypes.map((nt) => (
                                    <MenuItem key={nt.value} value={nt.value}>
                                        {nt.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        {sendAccount === 'mobilePhone' ? (
                            <>
                                {isSendCreatePassword ? (
                                    <>
                                        <Stack spacing={0.5}>
                                            <CInputLabel label="ワンタイムパスワード" />
                                            <TextField
                                                {...createPasswordRegister('password')}
                                                placeholder={'ワンタイムパスワード'}
                                                type={'password'}
                                                error={!!errorsCreatePassword.mobilePhone}
                                                helperText={errorsCreatePassword.mobilePhone?.message}
                                                variant={'outlined'}
                                            />
                                            {isError ? (
                                                <Typography variant="caption" color={'error'}>
                                                    入力したパスワードが間違っています。携帯番号に届いたワンタイムパスワードを確認して再度入力してください。
                                                </Typography>
                                            ) : (
                                                <Typography variant="caption">
                                                    入力していただいた携帯電話番号に6桁のワンタイムパスワードが送信されます。
                                                </Typography>
                                            )}
                                        </Stack>
                                        <LoadingButton
                                            variant={'contained'}
                                            disabled={!isValidCreatePassword}
                                            loading={isSubmittingCreatePassword}
                                            onClick={handleSubmitCreatePassword(loginHandlerByMobilePhone)}>
                                            ログイン
                                        </LoadingButton>
                                        <LoadingButton
                                            variant={'outlined'}
                                            disabled={!isValidCreatePassword}
                                            loading={isSubmittingCreatePassword}
                                            onClick={handleSubmitCreatePassword(sendHandler)}>
                                            ワンタイムパスワードを再送信
                                        </LoadingButton>
                                    </>
                                ) : (
                                    <>
                                        <Stack spacing={0.5}>
                                            <CInputLabel label="携帯電話番号" />
                                            <Typography variant="caption">ハイフン(-)は除いて入力してください</Typography>
                                            <TextField
                                                {...createPasswordRegister('mobilePhone')}
                                                placeholder={'携帯電話番号'}
                                                type={'text'}
                                                error={!!errorsCreatePassword.mobilePhone}
                                                helperText={errorsCreatePassword.mobilePhone?.message}
                                                variant={'outlined'}
                                            />
                                            <Typography variant="caption">
                                                入力していただいた携帯電話番号に6桁のワンタイムパスワードが送信されます。
                                            </Typography>
                                        </Stack>
                                        <LoadingButton
                                            variant={'contained'}
                                            disabled={!isValidCreatePassword}
                                            loading={isSubmittingCreatePassword}
                                            onClick={handleSubmitCreatePassword(sendHandler)}>
                                            送信
                                        </LoadingButton>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <TextField
                                    {...register('email')}
                                    label={'メールアドレス'}
                                    type={'email'}
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                />
                                <TextField
                                    {...register('password')}
                                    label={'パスワード'}
                                    type={'password'}
                                    error={!!errors.password}
                                    helperText={errors.password?.message}
                                />
                                <LoadingButton
                                    variant={'contained'}
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                    onClick={handleSubmit(loginHandler)}>
                                    ログイン
                                </LoadingButton>
                            </>
                        )}
                    </Stack>
                </Container>
                <Container maxWidth={'sm'} sx={{ my: 3 }}>
                    <Stack spacing={3}>
                        <img src={decorated} width={'100%'} />
                        <Stack spacing={2} sx={{ alignItems: 'center', width: '100%' }}>
                            <Stack sx={{ alignItems: 'center' }}>
                                <Typography variant="button">アカウントを持っていない場合は</Typography>
                                <Link color={'#77618B'} href="/user/account">
                                    アカウントを作成
                                </Link>
                            </Stack>
                        </Stack>
                        {isSendCreatePassword && (
                            <Stack sx={{ alignItems: 'center' }}>
                                <Button variant={'contained'} fullWidth onClick={() => setIsSendCreatePassword(false)}>
                                    電話番号入力画面へもどる
                                </Button>
                            </Stack>
                        )}
                        <Stack spacing={2} sx={{ alignItems: 'center' }}>
                            <img src={decorated} width={'100%'} />
                            <Link color={'#77618B'} href="/inquiry">
                                お問い合わせ
                            </Link>
                        </Stack>
                    </Stack>
                </Container>
            </DefaultLayout>
        </>
    )
}
