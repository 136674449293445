import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, FormControlLabel, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { AxiosError } from 'axios'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { CPaymentBankAccount } from '~/components/functional/payment/cPaymentBankAccount/CPaymentBankAccount'
import { CPaymentPayJs, PayJpRes } from '~/components/functional/payment/CPaymentPayJs'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOfferingOrderRequestState } from '~/hooks/useOfferingOrderRequestState'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useGraveOfferingOrderPaymentPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid
    const graveUuid = params.graveUuid
    const { fetchRequestDto, updateRequestOrderDto } = useOfferingOrderRequestState(connectUuid!)

    // initial fetch
    const { data: paymentCard } = useQuerySuspense(
        [`/grave/offering/payment/cart`],
        async () => {
            return await apiClient.clientUserPaymentGetAllList()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: requestOrderDto } = useQuerySuspense(
        [`/grave/offering/payment/requestOrderDto`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    // paymentType
    const [paymentType, setPaymentType] = useState<number>(requestOrderDto!.request.paymentType ?? 0)
    const paymentTypeTypes = [
        { value: 0, label: 'クレジットカード' },
        { value: 3, label: '銀行振込' },
    ]
    const onChangePaymentType = (val: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        requestOrderDto!.request.paymentType = parseInt(val.target.value)
        setPaymentType(parseInt(val.target.value))
    }
    const [card, setCard] = useState<string>(
        requestOrderDto!.request.payment?.cardUuid
            ? requestOrderDto!.request.payment?.cardUuid
            : paymentCard?.find((card) => card.isDefault)?.uuid ?? 'new',
    )
    const handleChangeCard = (val: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCard(val.target.value as string)
    }
    const [isSaveCard, setIsSaveCard] = useState(requestOrderDto?.request.payment?.isSaveCard ?? true)
    const handleChangeIsSaveCard = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSaveCard(event.target.checked)
    }

    const [loading, setLoading] = useState<boolean>(false)
    // next step
    const handleNextStep = async () => {
        setLoading(true)
        if (!requestOrderDto!.request.payment) requestOrderDto!.request.payment = { cardUuid: undefined, token: undefined }
        if (paymentType === 0) {
            if (card === 'new') {
                if (!newCardToken) return
                requestOrderDto!.request.payment = {
                    cardUuid: undefined,
                    token: newCardToken.id,
                    brand: newCardToken.card.brand,
                    last4: newCardToken.card.last4,
                }
            } else {
                //
                const selectedUserPayment = paymentCard?.find((p) => p.uuid === card)
                if (!selectedUserPayment) return
                requestOrderDto!.request.payment = {
                    cardUuid: selectedUserPayment.uuid,
                    token: undefined,
                    brand: selectedUserPayment.brand,
                    last4: selectedUserPayment.cardNum,
                }
            }
            if (!requestOrderDto!.request.payment.cardUuid && !requestOrderDto!.request.payment.token) return
        }

        // TODO 入力内容のチェック

        await updateRequestOrderDto(requestOrderDto!.request)
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/confirm`)
        setLoading(false)
    }

    const handlePrevStep = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/comment`)
    }

    const [newCardToken, setNewCardToken] = useState<PayJpRes | undefined>(undefined)
    const handleGetToken = (res: PayJpRes) => {
        setNewCardToken(res)
    }
    const deleteNewCard = () => {
        setNewCardToken(undefined)
    }

    const [isValidPayment, setIsValidPayment] = useState<boolean>(false)
    useEffect(() => {
        if (paymentType === 3) {
            setIsValidPayment(true)
            return
        }
        if (paymentType === 0) {
            if (card === 'new') {
                if (newCardToken) {
                    setIsValidPayment(true)
                    return
                }
                setIsValidPayment(false)
                return
            } else if (card !== '') {
                setIsValidPayment(true)
                return
            }
        }
        setIsValidPayment(false)
    }, [paymentType, card, newCardToken])

    return {
        paymentCard,
        handleNextStep,
        handlePrevStep,
        paymentType,
        paymentTypeTypes,
        onChangePaymentType,

        card,
        handleChangeCard,
        isValidPayment,
        loading,
        handleGetToken,
        newCardToken,
        deleteNewCard,
        isSaveCard,
        handleChangeIsSaveCard,
    }
}

export const GraveOfferingOrderPaymentPage: FC = () => {
    const {
        paymentCard,
        handleNextStep,
        handlePrevStep,
        paymentType,
        paymentTypeTypes,
        onChangePaymentType,

        card,
        handleChangeCard,
        isValidPayment,
        loading,
        handleGetToken,
        newCardToken,
        deleteNewCard,
        isSaveCard,
        handleChangeIsSaveCard,
    } = useGraveOfferingOrderPaymentPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="お支払い方法の選択" breadcrumbList={[]}>
                <Stack spacing={3}>
                    <Stack>
                        <Box maxWidth={'mb'} bgcolor={'white'} p={2} borderBottom={1} borderColor={'#D9D9D9'}>
                            <Stack spacing={0.5}>
                                <CInputLabel label={'決済方法'} required />
                                <TextField
                                    id={'paymentType'}
                                    required={true}
                                    select={true}
                                    variant="outlined"
                                    onChange={(val) => onChangePaymentType(val)}
                                    value={paymentType}>
                                    {paymentTypeTypes.map((nt) => (
                                        <MenuItem key={nt.value} value={nt.value}>
                                            {nt.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        </Box>
                        <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                            {paymentType === 0 ? (
                                <>
                                    <Stack spacing={2}>
                                        <Stack spacing={0.5}>
                                            <CInputLabel label={'登録したカード'} />
                                            <TextField
                                                id={'paymentType'}
                                                required={true}
                                                select={true}
                                                variant="outlined"
                                                onChange={(val) => handleChangeCard(val)}
                                                value={card}>
                                                {paymentCard &&
                                                    paymentCard.map((card: Schemas.UserPaymentEntities) => (
                                                        <MenuItem key={card.uuid} value={card.uuid}>
                                                            {card.cardNum}
                                                        </MenuItem>
                                                    ))}
                                                <MenuItem key={'new'} value={'new'}>
                                                    カードを登録
                                                </MenuItem>
                                            </TextField>
                                        </Stack>
                                        {card === 'new' && newCardToken && (
                                            <>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant={'body2'}>
                                                        {newCardToken.card.brand} 下四桁 {newCardToken.card.last4}
                                                    </Typography>
                                                    <IconButton edge="end" aria-label="delete" onClick={deleteNewCard}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isSaveCard}
                                                                onChange={handleChangeIsSaveCard}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="このカード情報を登録する"
                                                    />
                                                </Box>
                                            </>
                                        )}
                                        {card === 'new' && !newCardToken && <CPaymentPayJs completeHandler={handleGetToken} />}
                                    </Stack>
                                </>
                            ) : (
                                <></>
                            )}
                            {paymentType === 3 && (
                                <>
                                    <CPaymentBankAccount />
                                </>
                            )}
                        </Box>
                    </Stack>

                    <Container maxWidth={'sm'}>
                        <Stack spacing={2}>
                            <LoadingButton
                                variant="contained"
                                loading={loading}
                                disabled={!isValidPayment}
                                onClick={handleNextStep}>
                                注文内容を確認
                            </LoadingButton>

                            <LoadingButton variant="outlined" onClick={handlePrevStep}>
                                コメント登録に戻る
                            </LoadingButton>
                        </Stack>
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
