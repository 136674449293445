import { Box, Button, Container, Grid, Pagination, Stack, Typography } from '@mui/material'
import { FC } from 'react'

import { Schemas } from '~/apis/types'
import { DefaultLayout } from '~/components/layout/Default'
import { useInquiryListPage } from '~/pages/inquiry/useInquiry'
import { datetimeFormat } from '~/utils/common'

export const InquiryListPage: FC = () => {
    const { navigate, list, count, queryParams, handlePageChange, handleNavigateDetail } = useInquiryListPage()
    return (
        <DefaultLayout maxWidth="md" title={'お問い合わせ'} breadcrumbList={[]} isTab={false}>
            <Stack spacing={2}>
                <Container maxWidth={'sm'}>
                    <Stack spacing={3}>
                        <Typography variant={'body2'} textAlign={'center'}>
                            内容によっては回答をさしあげるのにお時間をいただくこともございます。
                            <br />
                            また、休業日は翌営業日以降の対応となりますのでご了承ください。
                        </Typography>
                        <Button variant={'contained'} fullWidth onClick={() => navigate('/inquiry')}>
                            新規お問い合わせ
                        </Button>
                    </Stack>
                </Container>
                <Stack>
                    {list &&
                        list.map((inquiry: Schemas.InquiryEntities) => (
                            <Box
                                key={inquiry.uuid}
                                bgcolor={'white'}
                                p={2}
                                sx={{ borderBottom: '0.5px solid #C6C6C8' }}
                                onClick={() => handleNavigateDetail(inquiry)}>
                                <Typography variant={'body2'} sx={{ whiteSpace: 'pre-wrap' }}>
                                    {inquiry.title}
                                </Typography>
                                <Typography variant={'body1'} textAlign={'end'}>
                                    {datetimeFormat(inquiry.createdAt)}
                                </Typography>
                            </Box>
                        ))}
                </Stack>
                <Container maxWidth={'sm'}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Pagination
                            count={Math.floor(count / queryParams.limit) + 1}
                            page={queryParams.page}
                            onChange={(_, value) => handlePageChange(value)}
                        />
                    </Grid>
                </Container>
            </Stack>
        </DefaultLayout>
    )
}
