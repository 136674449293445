import { Button, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

import giftImage from '~/assets/image/gift/gift.svg'
import { DefaultLayout } from '~/components/layout/Default'

export const useGiftOrderFinishPage = () => {
    const navigate = useNavigate()

    const search = useLocation().search
    const query = new URLSearchParams(search)
    const giftUuid = query.get('order')

    // initial fetch

    const handleIndex = () => {
        navigate('/user', { replace: true })
    }

    const handleHistory = () => {
        navigate('/gift/history', { replace: true })
    }

    return {
        giftUuid,
        handleIndex,
        handleHistory,
    }
}

export const GiftOrderFinishPage: FC = () => {
    const { giftUuid, handleIndex, handleHistory } = useGiftOrderFinishPage()

    return (
        <>
            <DefaultLayout title="ご注文が確定しました" breadcrumbList={[]}>
                <Stack spacing={3} direction={'column'} alignItems={'center'}>
                    <img src={giftImage} />
                    <Stack>
                        <Typography textAlign={'center'}>ご注文ありがとうございます。</Typography>
                        <Typography textAlign={'center'}>
                            ご注文内容は、配信されたご注文確認メールか、注文履歴
                            {giftUuid ? `（ご注文番号：${giftUuid.split('-')[0]}）` : ''}
                            よりご確認ください。
                        </Typography>
                    </Stack>
                    <Button fullWidth variant={'contained'} disableElevation onClick={handleIndex}>
                        マイページに戻る
                    </Button>
                    <Button fullWidth variant={'contained'} disableElevation onClick={handleHistory}>
                        注文履歴を見る
                    </Button>
                </Stack>
            </DefaultLayout>
        </>
    )
}
