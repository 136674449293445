import { LoadingButton } from '@mui/lab'
import {
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { Schemas } from '~/apis/types'

export const useCMemberDialog = (isOpen: boolean, uuids: string[]) => {
    const [connectUuids, setConnectUuids] = useState<string[]>(uuids || [])
    const handleSelected = (uuid: string) => {
        const index = connectUuids.findIndex((c) => c === uuid)
        if (index > -1) {
            connectUuids.splice(index, 1)
            setConnectUuids([...connectUuids])
        } else setConnectUuids([...connectUuids, uuid])
    }
    useEffect(() => {
        setConnectUuids(uuids)
    }, [isOpen, uuids])
    return {
        connectUuids,
        handleSelected,
    }
}

export type CMemberDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (uuids: string[]) => void
    connects: Schemas.ConnectEntities[]
    uuids: string[]
    title: string
}

export const CMemberDialog: FC<CMemberDialogProps> = ({ isOpen, onClose, onSubmit, connects, uuids, title }) => {
    const { connectUuids, handleSelected } = useCMemberDialog(isOpen, uuids)
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle textAlign={'center'} sx={{ fontWeight: 700 }}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {connects &&
                            connects.map((connect: Schemas.ConnectEntities) => (
                                <ListItem key={connect.uuid} disablePadding>
                                    <ListItemButton role={undefined} onClick={() => handleSelected(connect.uuid)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={!!connectUuids.find((c) => c === connect.uuid)}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={connect.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Container maxWidth={'xs'}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Button variant="outlined" fullWidth onClick={onClose}>
                                キャンセル
                            </Button>
                            <LoadingButton variant={'contained'} fullWidth onClick={() => onSubmit(connectUuids)}>
                                決定
                            </LoadingButton>
                        </Stack>
                    </Container>
                </DialogActions>
            </Dialog>
        </>
    )
}
