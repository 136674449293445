import { Card, CardActionArea, CardContent, CardMedia, Grid, Pagination, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export const useGiftIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const page = 1
    const limit = 10
    const [queryParams, setQueryParams] = useState<QueryParameters>({ page, limit })

    // initial fetch
    const { data: listResponse, refetch: fetchList } = useQuerySuspense(
        [`giftList`, queryParams],
        async () => {
            const r = await apiClient.clientGiftGetList({ parameter: queryParams })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await fetchList()
    }
    const handleNavigateDetail = async (gift: Schemas.GiftMasterEntities) => {
        navigate(`/gift/${gift.uuid}`)
    }

    return {
        queryParams,
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        handlePageChange,
        handleNavigateDetail,
    }
}

export const GiftIndexPage: FC = () => {
    const { queryParams, list, count, handlePageChange, handleNavigateDetail } = useGiftIndexPage()

    return (
        <>
            <DefaultLayout title={'ギフト'} breadcrumbList={[]}>
                <Typography variant={'body2'} textAlign={'center'} pb={2}>
                    お悔やみの気持ちや自分の没後に届く大切な人へ日頃の感謝の気持ちを込めて、ギフトを送りませんか？
                </Typography>
                <Grid container spacing={2}>
                    {list.map((gift) => (
                        <Grid item xs={12} sm={6} key={gift.uuid}>
                            <Card variant="outlined">
                                <CardActionArea
                                    onClick={() => {
                                        handleNavigateDetail(gift)
                                    }}>
                                    <CardMedia component="img" image={mediaUrl(gift.files[0])} title={gift.name} />
                                    <CardContent>
                                        <Stack>
                                            <Typography variant="h6">{gift.name}</Typography>
                                            <Typography variant="h6" mt={0.5}>
                                                ¥{gift.price.toLocaleString()}
                                            </Typography>
                                            <Typography variant="body1" mt={1.5}>
                                                {gift.content}
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pt: 3 }}>
                    <Pagination
                        count={Math.floor(count / queryParams.limit) + 1}
                        page={queryParams.page}
                        onChange={(_, value) => handlePageChange(value)}
                    />
                </Grid>
            </DefaultLayout>
        </>
    )
}
