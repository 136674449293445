import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { InquiryCreateDtoSchema, inquiryCreateDtoSchema } from '~/types/zodScheme'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export const useInquiryListPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const page = 1
    const limit = 10
    const [queryParams, setQueryParams] = useState<QueryParameters>({ page, limit })

    // initial fetch
    const { data: listResponse, refetch: fetchList } = useQuerySuspense(
        ['/inquiry/list', queryParams],
        async () => {
            const r = await apiClient.clientInquiryIndex({ parameter: queryParams })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const handleNavigateDetail = (inquiry: Schemas.InquiryEntities) => {
        navigate(`/inquiry/${inquiry.uuid}`)
    }

    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await fetchList()
    }
    return {
        navigate,
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        queryParams,
        handlePageChange,
        handleNavigateDetail,
    }
}

export const useInquiryIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const { me } = useAuthState()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<InquiryCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(inquiryCreateDtoSchema),
    })

    useEffect(() => {
        reset({
            category: '',
            title: '',
            content: '',
            name: me?.name || '',
            email: me?.email || '',
            tel: '',
            userUuid: me?.uuid || '',
        })
    }, [isSubmitting, me])

    const sendHandler = async (dto: Schemas.ClientInquiryCreateDto) => {
        try {
            await apiClient.clientInquiryStore({ requestBody: dto })
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }
    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendHandler,
    }
}
