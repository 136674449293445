import React, { type FC, Component, ErrorInfo, ReactNode, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { CLoadingView } from '~/components/common/cLoadingView/CLoadingView'
import { GConfirmationDialog } from '~/components/global/gConfirmationDialog/GConfirmationDialog'
import { DefaultLayout } from '~/components/layout/Default'
// import { GHeader } from '~/components/global/gHeader/GHeader'
import { ConnectListIndexPage } from '~/pages/connectList'
import { ConnectListConnectUuidIndexPage } from '~/pages/connectList/[connectUuid]'
import { ConnectListConnectUuidEditPage } from '~/pages/connectList/[connectUuid]/edit'
import { ConnectListConnectUuidGravePage } from '~/pages/connectList/[connectUuid]/grave'
import { GraveOfferingMasterIndexPage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering'
import { GraveOfferingMasterDetailPage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering/[masterUuid]'
import { GraveOfferingOrderCartPage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering/cart'
import { GraveOfferingOrderCommentPage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering/comment'
import { GraveOfferingOrderConfirmPage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering/confirm'
import { GraveOfferingOrderFinishPage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering/finish'
import { GraveOfferingOrderPaymentPage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering/payment'
import { GraveOfferingOrderReservePage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering/reserve'
import { ConnectListConnectUuidMemoryIndexPage } from '~/pages/connectList/[connectUuid]/memory'
import { ConnectListConnectUuidMemoryMemoryUuidPage } from '~/pages/connectList/[connectUuid]/memory/[memoryUuid]'
import { ConnectListConnectUuidMemoryMemoryUuidCommentPage } from '~/pages/connectList/[connectUuid]/memory/[memoryUuid]/comment'
import { ConnectListConnectUuidMessagePage } from '~/pages/connectList/[connectUuid]/message'
import { ConnectListConnectUuidReceivedMessagePage } from '~/pages/connectList/[connectUuid]/receivedMessage'
import { ConnectListManagementPage } from '~/pages/connectList/management'
// import { FamilyTreeIndexPage } from '~/pages/familyTree'
import { GiftIndexPage } from '~/pages/gift'
import { GiftGiftUuidPage } from '~/pages/gift/[giftUuid]'
import { GiftHistoryIndexPage } from '~/pages/gift/history'
import { GiftHistoryGiftUuidPage } from '~/pages/gift/history/[giftUuid]'
import { GiftOrderCartPage } from '~/pages/gift/order/cart'
import { GiftOrderConfirmPage } from '~/pages/gift/order/confirm'
import { GiftOrderFinishPage } from '~/pages/gift/order/finish'
import { GiftOrderOrderPage } from '~/pages/gift/order/order'
import { GiftOrderPaymentPage } from '~/pages/gift/order/payment'
import { GiftOrderSpecifiedPage } from '~/pages/gift/order/specified'
import { GraveIndexPage } from '~/pages/grave'
import { GraveEditPage } from '~/pages/grave/edit'
import { GraveUploadPage } from '~/pages/grave/upload'
import { TopPage } from '~/pages/index'
import { InformationIndexPage } from '~/pages/information'
import { InquiryIndexPage } from '~/pages/inquiry'
import { InquiryInquiryUuidPage } from '~/pages/inquiry/[inquiryUuid]'
import { InquiryListPage } from '~/pages/inquiry/list'
import { InviteInviteCodePage } from '~/pages/invite/[inviteCode]'
import { LoginIndexPage } from '~/pages/login'
import { MemorySelfIndexPage } from '~/pages/memory/self'
import { MemorySelfMemoryUuidPage } from '~/pages/memory/self/[memoryUuid]'
import { MemorySelfMemoryUuidCommentPage } from '~/pages/memory/self/[memoryUuid]/comment'
import { MemorySelfMemoryUuidEditPage } from '~/pages/memory/self/[memoryUuid]/edit'
import { MemorySelfMemoryUuidMemberPage } from '~/pages/memory/self/[memoryUuid]/member'
import { MemoryShareIndexPage } from '~/pages/memory/share'
import { MemoryShareMemoryUuidPage } from '~/pages/memory/share/[memoryUuid]'
import { MemoryShareMemoryUuidCommentPage } from '~/pages/memory/share/[memoryUuid]/comment'
import { NotFoundPage } from '~/pages/not-found'
import { OfferingOrderHistoryIndexPage } from '~/pages/offeringOrder/history'
import { OfferingOrderHistoryDetailPage } from '~/pages/offeringOrder/history/[orderUuid]'
import { PrivacyIndexPage } from '~/pages/privacy'
import { SpecifiedCommercialTransactionIndexPage } from '~/pages/specifiedCommercialTransaction'
import { TermsIndexPage } from '~/pages/terms'
import { UserAccountIndexPage } from '~/pages/user/account'
import { UserAccountTokenPage } from '~/pages/user/account/[token]'
import { UserAccountTokenGravePage } from '~/pages/user/account/[token]/grave'
import { UserCardIndexPage } from '~/pages/user/card'
import { UserConnectionApplyPage } from '~/pages/user/connection/apply'
import { UserConnectionInvitePage } from '~/pages/user/connection/invite'
import { UserConnectTagIndexPage } from '~/pages/user/connectTag'
import { UserEditPage } from '~/pages/user/edit'
import { UserIndexPage } from '~/pages/user/index'
import { UserMailIndexPage } from '~/pages/user/mail'
import { UserMailCredentialPage } from '~/pages/user/mail/[credential]'
import { UserMobilePhoneIndexPage } from '~/pages/user/mobilePhone'
import { UserMobilePhoneCredentialPage } from '~/pages/user/mobilePhone/[credential]'
import { RouteAuthGuard } from '~/utils/routeAuthGuard'

import ConnectHistoryIndexPage from './connectList/[connectUuid]/history'
import ConnectHistoryHistoryDetailPage from './connectList/[connectUuid]/history/[historyId]'
import { HelpPage } from './help'
import { SponsorFormConfirmPage } from './sponsor/form/confirm'
import { SponsorFormIndexPage } from './sponsor/form/index'
import { SponsorFormRegisteredPage } from './sponsor/form/registered'
import SponsorListIndexPage from './sponsorList'
import { HistoryIndexPage } from './user/history'
import HistoryHistoryDetailPage from './user/history/[historyId]'
import HistoryHistoryCreatePage from './user/history/create'
import HistoryHistoryIdPage from './user/history/edit/[historyId]'

export const PageRoutes: FC = () => {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <Suspense fallback={<CLoadingView />}>
                    <Routes>
                        <Route path={'/'} element={<TopPage />} />
                        <Route path={'/login'} element={<LoginIndexPage />} />
                        <Route path={'/user/account'} element={<UserAccountIndexPage />} />
                        <Route path={'/user/account/:token'} element={<UserAccountTokenPage />} />
                        <Route path={'/invite/:inviteCode'} element={<InviteInviteCodePage />} />

                        <Route path={'/user'} element={<RouteAuthGuard component={<UserIndexPage />} />} />
                        {/* アカウント作成後の墓選択 */}
                        <Route
                            path={'/user/account/:token/grave'}
                            element={<RouteAuthGuard component={<UserAccountTokenGravePage />} />}
                        />

                        {/* アカウント関連 */}
                        <Route path={'/user/edit'} element={<RouteAuthGuard component={<UserEditPage />} />} />
                        <Route path={'/user/card'} element={<RouteAuthGuard component={<UserCardIndexPage />} />} />
                        <Route path={'/user/connectTag'} element={<RouteAuthGuard component={<UserConnectTagIndexPage />} />} />
                        <Route
                            path={'/user/connection/apply'}
                            element={<RouteAuthGuard component={<UserConnectionApplyPage />} />}
                        />
                        <Route
                            path={'/user/connection/invite'}
                            element={<RouteAuthGuard component={<UserConnectionInvitePage />} />}
                        />
                        <Route path={'/user/mail'} element={<RouteAuthGuard component={<UserMailIndexPage />} />} />
                        <Route
                            path={'/user/mail/:credential'}
                            element={<RouteAuthGuard component={<UserMailCredentialPage />} />}
                        />
                        <Route path={'/user/mobilePhone'} element={<RouteAuthGuard component={<UserMobilePhoneIndexPage />} />} />
                        <Route
                            path={'/user/mobilePhone/:credential'}
                            element={<RouteAuthGuard component={<UserMobilePhoneCredentialPage />} />}
                        />
                        {/* アカウント関連 自分史 */}
                        <Route path="/user/history" element={<RouteAuthGuard component={<HistoryIndexPage />} />} />
                        <Route
                            path="/user/history/:historyId"
                            element={<RouteAuthGuard component={<HistoryHistoryDetailPage />} />}
                        />
                        <Route
                            path="/user/history/create"
                            element={<RouteAuthGuard component={<HistoryHistoryCreatePage />} />}
                        />
                        <Route
                            path="/user/history/edit/:historyId"
                            element={<RouteAuthGuard component={<HistoryHistoryIdPage />} />}
                        />

                        {/* 自分の墓 */}
                        <Route path={'/grave'} element={<RouteAuthGuard component={<GraveIndexPage />} />} />
                        <Route path={'/grave/edit'} element={<RouteAuthGuard component={<GraveEditPage />} />} />
                        <Route path={'/grave/upload'} element={<RouteAuthGuard component={<GraveUploadPage />} />} />

                        {/* つながり */}
                        <Route path={'/connectList'} element={<RouteAuthGuard component={<ConnectListIndexPage />} />} />
                        <Route
                            path={'/connectList/:connectUuid'}
                            element={<RouteAuthGuard component={<ConnectListConnectUuidIndexPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/edit'}
                            element={<RouteAuthGuard component={<ConnectListConnectUuidEditPage />} />}
                        />
                        {/* つながり お墓参り */}
                        <Route
                            path={'/connectList/:connectUuid/grave'}
                            element={<RouteAuthGuard component={<ConnectListConnectUuidGravePage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/grave/:graveUuid/offering'}
                            element={<RouteAuthGuard component={<GraveOfferingMasterIndexPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/grave/:graveUuid/offering/:masterUuid'}
                            element={<RouteAuthGuard component={<GraveOfferingMasterDetailPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/grave/:graveUuid/offering/cart'}
                            element={<RouteAuthGuard component={<GraveOfferingOrderCartPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/grave/:graveUuid/offering/reserve'}
                            element={<RouteAuthGuard component={<GraveOfferingOrderReservePage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/grave/:graveUuid/offering/comment'}
                            element={<RouteAuthGuard component={<GraveOfferingOrderCommentPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/grave/:graveUuid/offering/payment'}
                            element={<RouteAuthGuard component={<GraveOfferingOrderPaymentPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/grave/:graveUuid/offering/confirm'}
                            element={<RouteAuthGuard component={<GraveOfferingOrderConfirmPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/grave/:graveUuid/offering/finish'}
                            element={<RouteAuthGuard component={<GraveOfferingOrderFinishPage />} />}
                        />

                        {/* つながり 思い出管理 */}
                        <Route
                            path={'/connectList/:connectUuid/memory'}
                            element={<RouteAuthGuard component={<ConnectListConnectUuidMemoryIndexPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/memory/:memoryUuid'}
                            element={<RouteAuthGuard component={<ConnectListConnectUuidMemoryMemoryUuidPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/memory/:memoryUuid/comment'}
                            element={<RouteAuthGuard component={<ConnectListConnectUuidMemoryMemoryUuidCommentPage />} />}
                        />
                        {/* つながり メッセージを贈る */}
                        <Route
                            path={'/connectList/:connectUuid/message'}
                            element={<RouteAuthGuard component={<ConnectListConnectUuidMessagePage />} />}
                        />
                        {/* つながり メッセージをみる */}
                        <Route
                            path={'/connectList/:connectUuid/receivedMessage'}
                            element={<RouteAuthGuard component={<ConnectListConnectUuidReceivedMessagePage />} />}
                        />
                        {/* つながり 管理を託されている */}
                        <Route
                            path={'/connectList/management'}
                            element={<RouteAuthGuard component={<ConnectListManagementPage />} />}
                        />
                        {/* つながり 自分史 */}
                        <Route
                            path={'/connectList/:connectUuid/history'}
                            element={<RouteAuthGuard component={<ConnectHistoryIndexPage />} />}
                        />
                        <Route
                            path={'/connectList/:connectUuid/history/:historyId'}
                            element={<RouteAuthGuard component={<ConnectHistoryHistoryDetailPage />} />}
                        />

                        {/* 家系図 */}
                        {/* <Route path={'/familyTree'} element={<RouteAuthGuard component={<FamilyTreeIndexPage />} />} /> */}

                        {/* 自分の思い出 */}
                        <Route path={'/memory/self'} element={<RouteAuthGuard component={<MemorySelfIndexPage />} />} />
                        <Route
                            path={'/memory/self/:memoryUuid'}
                            element={<RouteAuthGuard component={<MemorySelfMemoryUuidPage />} />}
                        />
                        <Route
                            path={'/memory/self/:memoryUuid/edit'}
                            element={<RouteAuthGuard component={<MemorySelfMemoryUuidEditPage />} />}
                        />
                        <Route
                            path={'/memory/self/:memoryUuid/comment'}
                            element={<RouteAuthGuard component={<MemorySelfMemoryUuidCommentPage />} />}
                        />
                        <Route
                            path={'/memory/self/:memoryUuid/member'}
                            element={<RouteAuthGuard component={<MemorySelfMemoryUuidMemberPage />} />}
                        />
                        {/* 共有された思い出 */}
                        <Route path={'/memory/share'} element={<RouteAuthGuard component={<MemoryShareIndexPage />} />} />
                        <Route
                            path={'/memory/share/:memoryUuid'}
                            element={<RouteAuthGuard component={<MemoryShareMemoryUuidPage />} />}
                        />
                        <Route
                            path={'/memory/share/:memoryUuid/comment'}
                            element={<RouteAuthGuard component={<MemoryShareMemoryUuidCommentPage />} />}
                        />

                        {/* ギフト */}
                        <Route path={'/gift'} element={<RouteAuthGuard component={<GiftIndexPage />} />} />
                        <Route path={'/gift/:giftUuid'} element={<RouteAuthGuard component={<GiftGiftUuidPage />} />} />

                        {/* ギフト購入 */}
                        <Route path={'/gift/order/cart'} element={<RouteAuthGuard component={<GiftOrderCartPage />} />} />
                        <Route path={'/gift/order/order'} element={<RouteAuthGuard component={<GiftOrderOrderPage />} />} />
                        <Route
                            path={'/gift/order/specified'}
                            element={<RouteAuthGuard component={<GiftOrderSpecifiedPage />} />}
                        />
                        <Route path={'/gift/order/payment'} element={<RouteAuthGuard component={<GiftOrderPaymentPage />} />} />
                        <Route path={'/gift/order/finish'} element={<RouteAuthGuard component={<GiftOrderFinishPage />} />} />
                        <Route path={'/gift/order/confirm'} element={<RouteAuthGuard component={<GiftOrderConfirmPage />} />} />

                        {/* ギフト購入履歴 */}
                        <Route path={'/gift/history'} element={<RouteAuthGuard component={<GiftHistoryIndexPage />} />} />
                        <Route
                            path={'/gift/history/:giftUuid'}
                            element={<RouteAuthGuard component={<GiftHistoryGiftUuidPage />} />}
                        />

                        {/* お供え購入履歴 */}
                        <Route
                            path={'/offeringOrder/history'}
                            element={<RouteAuthGuard component={<OfferingOrderHistoryIndexPage />} />}
                        />
                        <Route
                            path={'/offeringOrder/history/:orderUuid'}
                            element={<RouteAuthGuard component={<OfferingOrderHistoryDetailPage />} />}
                        />

                        {/* お知らせ */}
                        <Route path={'/information'} element={<RouteAuthGuard component={<InformationIndexPage />} />} />

                        {/*お問い合わせ*/}
                        <Route path={'/inquiry'} element={<InquiryIndexPage />} />
                        <Route path={'/inquiry/list'} element={<RouteAuthGuard component={<InquiryListPage />} />} />
                        <Route path={'/inquiry/:inquiryUuid'} element={<InquiryInquiryUuidPage />} />

                        {/*利用規約・プライバシーポリシー・特定商取引法表記*/}
                        <Route path={'/terms'} element={<TermsIndexPage />} />
                        <Route path={'/privacy'} element={<PrivacyIndexPage />} />
                        <Route path={'/specifiedCommercialTransaction'} element={<SpecifiedCommercialTransactionIndexPage />} />

                        <Route path={'/sponsor/form'} element={<SponsorFormIndexPage />} />
                        <Route path={'/sponsor/form/confirm'} element={<SponsorFormConfirmPage />} />
                        <Route path={'/sponsor/form/registered'} element={<SponsorFormRegisteredPage />} />

                        {/*協賛一覧 */}
                        <Route path="/sponsorList" element={<SponsorListIndexPage />} />

                        {/*ヘルプページ*/}
                        <Route path="/help" element={<HelpPage />} />

                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Suspense>
            </ErrorBoundary>
            <GConfirmationDialog />
        </BrowserRouter>
    )
}

interface Props {
    children?: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo)
    }

    public render() {
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.hasError) {
            return (
                <DefaultLayout breadcrumbList={[]}>
                    <p>エラーが発生しました</p>
                </DefaultLayout>
            )
        }

        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children
    }
}
