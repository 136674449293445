import { useAtom } from 'jotai'

import { atomWithLocalStorage } from '~/utils/atomWithLocalStorage'

const browserUuidAtom = atomWithLocalStorage<string>('browserUuid', '')

// ユーザー、非ユーザーに関係なく、各ブラウザに一意な ID を持つために使用される。used to have a unique id for each browser irrespective of users or non-users
export const useBrowserUniqueId = () => {
    const [browserUuid, setBrowserUuid] = useAtom(browserUuidAtom)

    return {
        browserUuid,
        setBrowserUuid,
    }
}
