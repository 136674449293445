import { useAtom } from 'jotai'

import { atomWithLocalStorage } from '~/utils/atomWithLocalStorage'

const helpSectionNameAtom = atomWithLocalStorage<string>('helpSectionName', '')

export const useHelpSourceState = () => {
    const [sectionName, setSectionName] = useAtom(helpSectionNameAtom)

    const clearSectionName = () => {
        setSectionName('')
    }

    return {
        sectionName,
        setSectionName,
        clearSectionName,
    }
}
