import { LoadingButton } from '@mui/lab'
import { Box, Container, Link, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CGraveOfferingCardCard } from '~/components/functional/offering/CGraveOfferingCardCard'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useOfferingOrderRequestState } from '~/hooks/useOfferingOrderRequestState'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useGraveOfferingOrderConfirmPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid
    const graveUuid = params.graveUuid

    // initial fetch
    const { fetchRequestDto } = useOfferingOrderRequestState(connectUuid!)
    const { data: requestOrderDto } = useQuerySuspense(
        [`/grave/offering/confirm`],
        async () => {
            return await fetchRequestDto()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handleNextStep = async () => {
        try {
            // 決済実行API
            await apiClient.clientOfferingOrderCreateOrder({ parameter: { token: connectUuid! } })
            // 空になったハズのカートを取得する
            await fetchRequestDto()
            // 決済完了画面に遷移する
            navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/finish`, { replace: true })
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const handleMovePayment = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/payment`)
    }

    const handleMoveCart = () => {
        navigate(`/connectList/${connectUuid!}/grave/${graveUuid}/offering/cart`)
    }

    // カートの内容を同じ商品でまとめる
    type reduceOrderType = {
        name: string
        uuid: string
        count: number
        price: number
        file?: Schemas.FileEntities | null
    }
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [reduceOrderDetails, setReduceOrderDetails] = useState<reduceOrderType[]>([])
    useEffect(() => {
        const result = requestOrderDto!.preview.reduce(
            (result: reduceOrderType[], current: Schemas.ClientOfferingOrderPreviewDto) => {
                const element = result.find((p) => p.uuid === current.offeringMaster.uuid)
                if (element) {
                    element.count++
                    element.price += current.offeringMaster.price
                } else {
                    result.push({
                        name: current.offeringMaster.name,
                        uuid: current.offeringMaster.uuid,
                        count: 1,
                        price: current.offeringMaster.price,
                        file: current.offeringMaster.file,
                    })
                }
                return result
            },
            [],
        )

        const totalPrice = result.reduce((prev, current) => prev + current.price, 0)
        setTotalPrice(totalPrice)
        setReduceOrderDetails(result)
    }, [requestOrderDto])

    return {
        requestOrderDto: requestOrderDto!.request,
        handleNextStep,
        handleMoveCart,
        handleMovePayment,
        totalPrice,
        reduceOrderDetails,
        preview: requestOrderDto!.preview,
    }
}

export const GraveOfferingOrderConfirmPage: FC = () => {
    const { requestOrderDto, handleNextStep, handleMoveCart, handleMovePayment, totalPrice, preview } =
        useGraveOfferingOrderConfirmPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} title="ご注文内容確認" breadcrumbList={[]}>
                <Stack spacing={3}>
                    <Container maxWidth={'sm'}>
                        <Stack spacing={2}>
                            <LoadingButton variant="contained" fullWidth onClick={handleNextStep}>
                                注文を確定する
                            </LoadingButton>

                            <Typography variant={'caption'} sx={{ mt: 2 }}>
                                「注文を確定する」ボタンを押してご注文いただくことで、お客様は当サイトの
                                <Link color={'#77618B'} href="/terms" target="_blank">
                                    各規約
                                </Link>
                                、
                                <Link color={'#77618B'} href="/privacy" target="_blank">
                                    プライバシー規約
                                </Link>
                                および当サイト上の販売条件に同意の上、商品をご注文されたことになります。価格については必ず商品ページをご確認ください。
                            </Typography>
                        </Stack>
                    </Container>

                    <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'baseline'}>
                            <Typography variant={'h6'}>ご請求金額</Typography>
                            <Typography variant={'h2'}>&yen;{totalPrice.toLocaleString()}</Typography>
                        </Stack>
                    </Box>

                    <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                        <Stack spacing={2} sx={{ p: 2 }}>
                            <Typography variant={'h6'}>お支払い方法</Typography>
                            {requestOrderDto.paymentType === 0 && (
                                <Typography variant={'body2'}>
                                    {requestOrderDto.payment?.brand} 下4桁 {requestOrderDto.payment?.last4}
                                </Typography>
                            )}
                            {requestOrderDto.paymentType === 3 && <Typography variant={'body2'}>銀行振込</Typography>}
                            <LoadingButton variant="outlined" onClick={handleMovePayment}>
                                お支払い方法を変更
                            </LoadingButton>
                        </Stack>
                    </Box>

                    <Box maxWidth={'mb'} bgcolor={'white'} p={2}>
                        <Stack spacing={2} sx={{ p: 2 }}>
                            <Typography variant={'h6'}>お供え開始日</Typography>
                            <Typography variant={'body1'}>
                                {requestOrderDto.reserve
                                    ? dayjs(requestOrderDto.reservationAt).format('YYYY年MM月DD日 HH時mm分') + 'にお供え'
                                    : '決済後すぐにお供え'}
                            </Typography>
                        </Stack>
                    </Box>

                    {preview.map((detail, index) => (
                        <Box key={index} bgcolor={'white'} p={2}>
                            <CGraveOfferingCardCard detail={detail} index={index} />
                        </Box>
                    ))}

                    <Container maxWidth={'sm'}>
                        <Stack spacing={2}>
                            <LoadingButton disableElevation variant={'contained'} onClick={handleNextStep}>
                                注文を確定する
                            </LoadingButton>

                            <LoadingButton variant={'outlined'} onClick={handleMoveCart}>
                                カートに戻る
                            </LoadingButton>

                            <Typography variant={'caption'}>
                                これ終が販売する商品について：「注文を確定する」ボタンをクリックすると、注文を受けたことを通知するEメールが送信されます。商品の購入契約は、商品の発送を通知するEメールを送信したときに成立します。
                                <br />
                                商品の分量の詳細については、商品詳細ページをご確認ください。
                                <br />
                                支払時期・方法については、お支払い方法をご覧ください。
                                <br />
                                オプションサービス付き商品等、特別な費用が設定されている場合の費用及びソフトウェアサブスクリプションサービスの通常価格等の詳細、それらの支払時期及び方法については、商品詳細ページを併せてご確認ください。
                                <br />
                                また、受注生産品や招待制販売品等、申込期限が設定されている場合の申込期間についても、商品詳細ページをご確認ください。
                                <br />
                                <br />
                                【商品のキャンセルについて】 ご注文のキャンセルについて詳しくは、注文のキャンセルをご覧ください。
                                <br />
                                予約商品のキャンセルについては、予約注文・お取り寄せについてをご覧ください。
                                <br />
                                特別なキャンセル条件又は返品条件が設定されている商品については、商品詳細ページも併せてご確認ください。
                                <br />
                                ソフトウェアサブスクリプションサービスのキャンセルについてはゲーム＆PCソフトダウンロード利用規約をご覧ください。
                                <br />
                                【これ終が販売する商品の返品について】
                                <br />
                                原則として、商品到着後30日以内にご返送いただいた未使用かつ未開封の商品については商品代金の全額をお返しします（返品不可の商品を除く）。詳しくは返品・交換の条件をご覧ください。
                            </Typography>
                        </Stack>
                    </Container>
                </Stack>
            </DefaultLayout>
        </>
    )
}
