import { mdiArrowLeft } from '@mdi/js'
import { Icon } from '@mdi/react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { type FC, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import editIcon from '~/assets/image/icon/edit.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { JapanesePeriod, mediaUrl } from '~/utils/common'

export type CHistoryDetailViewProps = {
    connectUuid?: string
    onHistoryDelete?: () => {}
    historyDetail?: Schemas.UserPersonalHistoryEntities
    tabValue?: string
} & CHistoryDetailProps

export const CHistoryDetailView: FC<CHistoryDetailViewProps> = (props) => {
    const { connectUuid, tabValue, historyDetail, onHistoryDelete } = props

    const isConnectUser = useMemo(() => {
        return !!connectUuid
    }, [connectUuid])

    const historyImages = useMemo(() => {
        return historyDetail?.files.map((i) => {
            return <img key={i.uuid} src={mediaUrl(i)} width={'327px'} height={'auto'} alt="" />
        })
    }, [historyDetail])

    const youtubeLinks = useMemo(() => {
        return historyDetail?.videoUrls?.map((i) => {
            return (
                <Stack key={i.title} spacing={0.5} alignItems={'start'} direction={'row'}>
                    <Typography fontSize={'1rem'} fontWeight={'700'}>
                        動画：
                    </Typography>
                    <Typography
                        fontSize={'1rem'}
                        fontWeight={'700'}
                        sx={{ textDecoration: 'underline', color: '#77618B', cursor: 'pointer' }}
                        onClick={() => window.open(i.url, '_blank', 'noopener,noreferrer')}>
                        {i.title}
                    </Typography>
                </Stack>
            )
        })
    }, [historyDetail])

    const navigate = useNavigate()

    return (
        <DefaultLayout breadcrumbList={[]} data-testid="c-history-detail" tabValue={tabValue}>
            <Stack spacing={3}>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={0.5}
                    width={'fit-content'}
                    onClick={() => (!isConnectUser ? navigate('/user/history') : navigate(`/connectList/${connectUuid}/history`))}
                    sx={{ cursor: 'pointer' }}>
                    <Icon path={mdiArrowLeft} size={1} />
                    <Typography variant={'subtitle1'}>{'自分史一覧へ戻る'}</Typography>
                </Box>
                <Stack spacing={1} py={2} borderTop={'1px #AF985A solid'}>
                    <Typography variant={'subtitle2'}>
                        {`${
                            historyDetail?.japaneseEra !== null && historyDetail?.isJapaneseCalender
                                ? JapanesePeriod[historyDetail.japaneseEra].text
                                : ''
                        } ${historyDetail?.year}年${historyDetail?.month}月`}
                    </Typography>
                    <Typography variant={'subtitle2'}>{historyDetail?.title}</Typography>
                    <Typography variant={'body2'}>{historyDetail?.content}</Typography>
                </Stack>
                {historyImages!.length > 0 && (
                    <Stack spacing={1} alignItems={'center'}>
                        {historyImages}
                    </Stack>
                )}
                {youtubeLinks!.length > 0 && <Stack spacing={3}>{youtubeLinks}</Stack>}
                {!isConnectUser && (
                    <>
                        <Button
                            variant={'contained'}
                            startIcon={<img src={editIcon} alt="" />}
                            onClick={() => navigate(`/user/history/edit/${historyDetail?.uuid}`)}>
                            編集
                        </Button>
                        <Button onClick={onHistoryDelete} variant={'contained'} color="error">
                            削除
                        </Button>
                    </>
                )}
            </Stack>
        </DefaultLayout>
    )
}

export type CHistoryDetailProps = {}

export const CHistoryDetail: FC<CHistoryDetailViewProps> = (props) => {
    return <CHistoryDetailView {...props} />
}
