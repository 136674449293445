import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { ClientConnectTagCreateDtoSchema, clientConnectTagCreateDtoSchema } from '~/types/zodScheme'

export const useCConnectTagDialog = (isOpen: boolean, entity?: Schemas.ConnectTagEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
    } = useForm<ClientConnectTagCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientConnectTagCreateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '',
            sort: entity?.sort || 1,
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
    }
}

export type CConnectTagDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.ClientConnectTagCreateDto, uuid?: string) => void
    entity?: Schemas.ConnectTagEntities
}

export const CConnectTagDialog: FC<CConnectTagDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid } = useCConnectTagDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>
                    <Typography textAlign={'center'} variant={'h6'}>
                        タグを{entity?.uuid ? '編集' : '追加'}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'タグ名'} />
                            <TextField
                                id={'name'}
                                required={true}
                                {...register('name')}
                                placeholder={'タグ名'}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                variant={'outlined'}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Container maxWidth={'xs'}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Button variant="outlined" fullWidth onClick={onClose}>
                                キャンセル
                            </Button>
                            <LoadingButton
                                variant={'contained'}
                                fullWidth
                                loading={isSubmitting}
                                disabled={!isValid}
                                onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                                登録
                            </LoadingButton>
                        </Stack>
                    </Container>
                </DialogActions>
            </Dialog>
        </>
    )
}
