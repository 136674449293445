import { NumberInput, NumberInputField } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, FormControlLabel, MenuItem, Radio, Select, Stack, TextField, Typography } from '@mui/material'
import { type FC, ChangeEvent, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import addIcon from '~/assets/image/icon/add.svg'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { ClientUserPersonalHistoryCreate, clientUserPersonalHistoryCreate } from '~/types/zodScheme'
import { JapanesePeriod, mediaUrl } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

import { CPersonalHistoryDisplayFileDialog } from '../CPersonalHistoryFileDialog/CPersonalHistoryFileDialog'

export type CHistoryCreateOrEditViewProps = {
    onFormSubmit: (data: Schemas.ClientUserPersonalHistoryUpdateDto) => void
    entity?: Schemas.UserPersonalHistoryEntities
    onCancel: () => void
}
type YoutubeLink = { title: string; url: string }

const useHistoryCreateOrEdit = (props: CHistoryCreateOrEditViewProps) => {
    const { entity, onFormSubmit } = props

    const apiClient = createApiClient()

    const [uploadedImages, setUploadedImages] = useState<Schemas.FileEntities[]>([])

    const [datePeriod, setDatePeriod] = useState<{ value: number; text: string } | undefined>(undefined)

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors, isValid },
    } = useForm<ClientUserPersonalHistoryCreate>({
        mode: 'all',
        resolver: zodResolver(clientUserPersonalHistoryCreate),
        defaultValues: { japaneseYear: true, japaneseEra: undefined },
    })

    const [dialogOpen, setDialogOpen] = useState(false)

    const [links, setLinks] = useState<YoutubeLink[]>([])

    const [image, setImage] = useState<Schemas.FileEntities | undefined>(undefined)

    const [url, setUrl] = useState('')
    const [title, setTitle] = useState('')

    const menuOption = useMemo(() => {
        return JapanesePeriod.map((it) => (
            <MenuItem key={it.value} value={it.value} sx={{ margin: '0.5rem' }}>
                {it.text}
            </MenuItem>
        ))
    }, [JapanesePeriod])

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (!files || files.length === 0) return

        Object.keys(files).forEach(async (_, i) => {
            const formData = new FormData()
            formData.append('files', files[i] as Blob)
            // @ts-expect-error
            return await apiClient.clientFileUploadUpload({ requestBody: formData }).then((uploadedFiles) => {
                setUploadedImages((q) => [...q, ...uploadedFiles])
            })
        })
    }

    const onSubmit: SubmitHandler<ClientUserPersonalHistoryCreate> = async (data) => {
        const requestBody: Schemas.ClientUserPersonalHistoryUpdateDto = {
            title: data.title,
            content: data.content,
            month: parseInt(data.month),
            year: parseInt(data.year),
            files: [...uploadedImages.map((i) => i.uuid)],
            videoUrls: links,
            isJapaneseCalender: getValues('japaneseYear'),
            japaneseEra: datePeriod?.value,
        }
        onFormSubmit(requestBody)
    }

    const onFileDialogOpen = (i: Schemas.FileEntities) => {
        setImage(i)
        setDialogOpen(true)
    }

    const onFileDialogClose = () => {
        setImage(undefined)
        setDialogOpen(false)
    }

    const onFileDialogImageDelete = () => {
        setUploadedImages((q) => q.filter((x) => x.uuid !== image?.uuid))
        setImage(undefined)
        setDialogOpen(false)
    }

    const addedImages = useMemo(() => {
        return uploadedImages.map((i) => {
            return (
                <img key={i.uuid} src={mediaUrl(i)} alt="" width={'86px'} height={'86px'} onClick={() => onFileDialogOpen(i)} />
            )
        })
    }, [uploadedImages])

    const onLinkRemoved = (i: string) => {
        setLinks((q) => {
            return q.filter((x) => x.title !== i)
        })
    }

    const addedLinks = useMemo(() => {
        return links.map((i, k) => {
            return (
                <Box
                    display={'flex'}
                    key={i.title}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    gap={1}
                    {...(k !== links.length - 1 && { borderBottom: '1px #AF985A solid' })}
                    py={'10px'}>
                    <Box display={'flex'} flexWrap={'wrap'} sx={{ cursor: 'pointer' }}>
                        <Typography
                            fontSize={'1rem'}
                            fontWeight={'700'}
                            color={'#77618B'}
                            sx={{ textDecoration: 'underline' }}
                            onClick={() => window.open(i.url, '_blank', 'noopener,noreferrer')}>
                            {i.title}
                        </Typography>
                    </Box>
                    <Button onClick={() => onLinkRemoved(i.title)} sx={{ bgcolor: '#EA5F4C', color: 'white' }}>
                        <Typography fontWeight={'bold'} color={'white'}>
                            削除
                        </Typography>
                    </Button>
                </Box>
            )
        })
    }, [links])

    const isJapaneseDate = useMemo(() => {
        return getValues('japaneseYear')
    }, [getValues('japaneseYear')])

    useEffect(() => {
        if (entity && entity.files && entity.videoUrls) {
            reset({
                title: entity.title,
                month: String(entity.month),
                year: String(entity.year),
                content: entity.content ?? '',
                japaneseYear: entity.isJapaneseCalender,
                japaneseEra: entity.japaneseEra ?? 0,
            })
            const japaneseEra = JapanesePeriod.find((i) => entity.japaneseEra === i.value)
            setDatePeriod(japaneseEra)
            setUploadedImages(entity.files)
            setLinks(entity.videoUrls as YoutubeLink[])
        }
    }, [entity])
    return {
        setValue,
        isJapaneseDate,
        menuOption,
        onChange,
        addedImages,
        uploadedImages,
        addedLinks,
        links,
        onFileDialogClose,
        onFileDialogImageDelete,
        dialogOpen,
        image,
        register,
        errors,
        handleSubmit,
        onSubmit,
        url,
        setUrl,
        setLinks,
        setDatePeriod,
        datePeriod,
        getValues,
        reset,
        isValid,
        title,
        setTitle,
    }
}

export const CHistoryCreateOrEditView: FC<CHistoryCreateOrEditViewProps & ReturnType<typeof useHistoryCreateOrEdit>> = (
    props,
) => {
    const {
        getValues,
        setValue,
        isJapaneseDate,
        menuOption,
        onChange,
        addedImages,
        uploadedImages,
        addedLinks,
        links,
        onFileDialogClose,
        onFileDialogImageDelete,
        dialogOpen,
        image,
        register,
        errors,
        handleSubmit,
        onSubmit,
        url,
        setUrl,
        setLinks,
        setDatePeriod,
        datePeriod,
        reset,
        entity,
        isValid,
        title,
        setTitle,
        onCancel,
    } = props
    return (
        <>
            <DefaultLayout
                title={`自分史を${entity ? '編集' : '登録'}`}
                breadcrumbList={[]}
                data-testid="c-history-create-or-edit">
                <Stack spacing={3}>
                    <Stack spacing={0.5}>
                        <CInputLabel label={'できごとタイトル'} required />
                        <TextField
                            {...register('title')}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                            placeholder={'入力してください'}
                            variant={'outlined'}
                        />
                    </Stack>
                    <Stack spacing={0.5}>
                        <CInputLabel label={'年号・月'} required />
                        <Stack>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={isJapaneseDate}
                                        onChange={() => {
                                            reset({ year: '', month: '', japaneseEra: undefined })
                                            setDatePeriod(undefined)
                                            setValue('japaneseYear', true, { shouldValidate: true })
                                        }}
                                    />
                                }
                                label={'和暦'}
                            />
                            <Stack spacing={1}>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Select
                                        placeholder={'選択'}
                                        value={String(getValues('japaneseEra'))}
                                        disabled={!isJapaneseDate}
                                        variant={'outlined'}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                height: '1.5rem',
                                                paddingY: 0.78,
                                                borderRadius: '0.5rem',
                                                backgroundColor: !isJapaneseDate ? '#f9f1f1' : 'white',
                                                opacity: !isJapaneseDate ? 0.5 : 1,
                                            },
                                            '& .MuiSvgIcon-root': { color: '#B188CA', opacity: !isJapaneseDate ? 0.5 : 1 },
                                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                            height: '2.5rem',
                                            width: '6.438rem',
                                            borderRadius: '0.5rem',
                                        }}
                                        defaultValue=""
                                        onChange={(e) => {
                                            const japaneseEra = JapanesePeriod.find((i) => i.value === Number(e.target.value))
                                            if (!japaneseEra) return
                                            setValue('japaneseEra', japaneseEra?.value, {
                                                shouldValidate: true,
                                            })
                                            setDatePeriod(japaneseEra)
                                        }}
                                        displayEmpty
                                        renderValue={() => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, color: 'black' }}>
                                                {datePeriod ? datePeriod?.text : '選択'}
                                            </Box>
                                        )}>
                                        {menuOption}
                                    </Select>
                                    <NumberInput
                                        {...(isJapaneseDate && { value: getValues('year') })}
                                        {...(isJapaneseDate && {
                                            onChange: (e) => setValue('year', e, { shouldValidate: true }),
                                        })}
                                        isDisabled={!isJapaneseDate || !datePeriod}
                                        min={1}
                                        max={99}
                                        keepWithinRange={true}>
                                        <NumberInputField
                                            height={'40px'}
                                            border={'2px #AF985A solid'}
                                            sx={{
                                                _disabled: { bgColor: 'white', opacity: 0.5 },
                                            }}
                                            fontSize={'16px'}
                                            px={'14px'}
                                            py={'8.5px'}
                                            borderRadius={'8px'}
                                            _focus={{ borderColor: '#B188CA' }}
                                            _focusVisible={{ outline: 'none' }}
                                            maxLength={2}
                                        />
                                    </NumberInput>
                                    <Typography sx={{ opacity: !isJapaneseDate ? 0.5 : 1 }} variant={'body2'}>
                                        年
                                    </Typography>
                                    <NumberInput
                                        {...(isJapaneseDate && { value: getValues('month') })}
                                        {...(isJapaneseDate && {
                                            onChange: (e) => setValue('month', e, { shouldValidate: true }),
                                        })}
                                        isDisabled={!isJapaneseDate || !datePeriod}
                                        min={1}
                                        max={12}
                                        keepWithinRange={true}>
                                        <NumberInputField
                                            height={'40px'}
                                            fontSize={'16px'}
                                            px={'14px'}
                                            sx={{
                                                _disabled: { bgColor: 'white', opacity: 0.5 },
                                            }}
                                            py={'8.5px'}
                                            borderRadius={'8px'}
                                            border={'2px #AF985A solid'}
                                            _focus={{ borderColor: '#B188CA' }}
                                            _focusVisible={{ outline: 'none' }}
                                            maxLength={2}
                                        />
                                    </NumberInput>
                                    <Typography sx={{ opacity: !isJapaneseDate ? 0.5 : 1 }} variant={'body2'}>
                                        月
                                    </Typography>
                                </Stack>
                                {!!errors.month && isJapaneseDate && (
                                    <Typography variant={'caption'} color={'#EA5F4C'}>
                                        {errors.month?.message}
                                    </Typography>
                                )}
                            </Stack>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={!isJapaneseDate}
                                        onChange={() => {
                                            reset({ year: '', month: '', japaneseEra: undefined })
                                            setDatePeriod(undefined)
                                            setValue('japaneseYear', false, { shouldValidate: true })
                                        }}
                                    />
                                }
                                label={'西暦'}
                            />
                            <Stack spacing={1}>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <NumberInput
                                        {...(!isJapaneseDate && { value: getValues('year') })}
                                        {...(!isJapaneseDate && {
                                            onChange: (e) => setValue('year', e, { shouldValidate: true }),
                                        })}
                                        css={{ ':focus': { border: '2px black solid' } }}
                                        isDisabled={isJapaneseDate}
                                        keepWithinRange>
                                        <NumberInputField
                                            height={'40px'}
                                            border={'2px #AF985A solid'}
                                            fontSize={'16px'}
                                            sx={{
                                                _disabled: { bgColor: 'white', opacity: 0.5 },
                                            }}
                                            px={'14px'}
                                            py={'8.5px'}
                                            borderRadius={'8px'}
                                            _focus={{ borderColor: '#B188CA' }}
                                            _focusVisible={{ outline: 'none' }}
                                            maxLength={4}
                                        />
                                    </NumberInput>
                                    <Typography sx={{ opacity: isJapaneseDate ? 0.5 : 1 }} variant={'body2'}>
                                        年
                                    </Typography>
                                    <NumberInput
                                        {...(!isJapaneseDate && { value: getValues('month') })}
                                        {...(!isJapaneseDate && {
                                            onChange: (e) => setValue('month', e, { shouldValidate: true }),
                                        })}
                                        isDisabled={isJapaneseDate}
                                        min={1}
                                        max={12}
                                        keepWithinRange>
                                        <NumberInputField
                                            height={'40px'}
                                            fontSize={'16px'}
                                            sx={{
                                                _disabled: { bgColor: 'white', opacity: 0.5 },
                                            }}
                                            px={'14px'}
                                            py={'8.5px'}
                                            border={'2px #AF985A solid'}
                                            borderRadius={'8px'}
                                            _focus={{ borderColor: '#B188CA' }}
                                            _focusVisible={{ outline: 'none' }}
                                            maxLength={2}
                                        />
                                    </NumberInput>
                                    <Typography sx={{ opacity: isJapaneseDate ? 0.5 : 1 }} variant={'body2'}>
                                        月
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'詳細'} />
                            <TextField
                                {...register('content')}
                                error={!!errors.content}
                                helperText={errors.content?.message}
                                placeholder={'入力してください'}
                                multiline
                                variant={'outlined'}
                                sx={{ '& .MuiInputBase-input': { minHeight: '160px' } }}
                            />
                        </Stack>
                    </Stack>
                    <Stack py={'1.5rem'} spacing={1} borderTop={'1px solid #AF985A'} borderBottom={'1px solid #AF985A'}>
                        <Typography variant={'subtitle2'}>添付ファイル</Typography>
                        <Typography variant={'body1'}>画像を添付することができます。</Typography>
                        <Stack
                            spacing={2}
                            direction={'row'}
                            bgcolor={'#F4F0ED'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            py={'34px'}
                            borderRadius={'10px'}>
                            <LoadingButton
                                sx={{ fontWeight: 'normal' }}
                                variant={'text'}
                                startIcon={<img alt="" src={addIcon} />}
                                component={'label'}>
                                ファイルを追加
                                <input hidden type={'file'} accept=".jpg,.png,.svg,.jpeg" multiple onChange={onChange} />
                            </LoadingButton>
                        </Stack>
                    </Stack>
                    {uploadedImages.length > 0 && (
                        <Stack p={2} bgcolor={'#F4F0ED'} borderRadius={'10px'} spacing={'10px'}>
                            <Typography variant={'body1'}>追加した画像</Typography>
                            <Box display={'flex'} flexWrap={'wrap'} gap={2}>
                                {addedImages}
                            </Box>
                        </Stack>
                    )}
                    <Stack spacing={1}>
                        <Typography variant={'subtitle2'}>動画URL</Typography>
                        <Typography variant={'body1'}>
                            YouTubeにアップロードしている動画を載せることができます。動画タイトルとURLを入力してください。
                        </Typography>
                        <Stack spacing={1}>
                            <Stack spacing={0.5}>
                                <Typography variant={'body1'}>動画タイトル</Typography>
                                <TextField
                                    placeholder={'動画タイトルを入力'}
                                    variant={'outlined'}
                                    fullWidth
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Stack>
                            <Stack spacing={0.5}>
                                <Typography variant={'body1'}>動画URL</Typography>
                                <TextField
                                    placeholder={'http://'}
                                    fullWidth
                                    variant={'outlined'}
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                            </Stack>
                            <Stack width={'100%'} alignItems={'center'}>
                                <Button
                                    disabled={!url.length || !title.length}
                                    variant={'contained'}
                                    fullWidth
                                    sx={{ fontSize: '1.2rem', height: '4.063rem' }}
                                    startIcon={
                                        <img
                                            src={addIcon}
                                            alt=""
                                            style={{ ...((!url.length || !title.length) && { opacity: 0.5 }) }}
                                        />
                                    }
                                    onClick={() => {
                                        setLinks((q) => [...q, { title, url }])
                                        setUrl('')
                                        setTitle('')
                                    }}>
                                    追加
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    {links.length > 0 && (
                        <Stack p={2} bgcolor={'#F4F0ED'} borderRadius={'10px'} spacing={'10px'}>
                            <Typography variant={'body1'}>追加した動画</Typography>
                            <Box display={'flex'} flexWrap={'wrap'} gap={2}>
                                {addedLinks}
                            </Box>
                        </Stack>
                    )}
                    <Stack spacing={2} borderTop={'1px solid #AF985A'} pt={'2rem'}>
                        <Button onClick={handleSubmit(onSubmit)} variant={'contained'} fullWidth disabled={!isValid}>
                            登録
                        </Button>

                        <Button variant={'outlined'} fullWidth sx={{ fontSize: '1.2rem', height: '4.063rem' }} onClick={onCancel}>
                            キャンセル
                        </Button>
                    </Stack>
                </Stack>
            </DefaultLayout>
            {image && (
                <CPersonalHistoryDisplayFileDialog
                    isOpen={dialogOpen}
                    deleteHandler={onFileDialogImageDelete}
                    onClose={onFileDialogClose}
                    imageFile={image}
                />
            )}
        </>
    )
}

export const CHistoryCreateOrEdit: FC<CHistoryCreateOrEditViewProps> = (props) => {
    const hookItems = useHistoryCreateOrEdit(props)
    return <CHistoryCreateOrEditView {...props} {...hookItems} />
}
