import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Avatar, Button, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { CIconUpload } from '~/components/functional/cIconUpload/CIconUpload'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientConnectCreateDtoSchema, clientConnectCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useConnectListConnectUuidEditPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const connectUuid = params.connectUuid
    const navigate = useNavigate()

    // initial fetch
    const { data: connect } = useQuerySuspense(
        [`/connectList/${connectUuid}`],
        async () => {
            if (!connectUuid) throw new Error()
            if (connectUuid === 'new') return { uuid: '', tags: [] } as unknown as Schemas.ConnectEntities
            return await apiClient.clientConnectGetConnect({
                parameter: { connectUuid: connectUuid },
            })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: tagListResponse } = useQuerySuspense(
        [`/connectDetail/${connectUuid}/tagList`],
        async () => {
            return await apiClient.clientConnectTagGetList({ parameter: {} })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const [image, setImage] = useState<Schemas.FileEntities | null>(connect?.file || null)
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        setValue,
        reset,
        control,
    } = useForm<ClientConnectCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientConnectCreateDtoSchema),
    })
    const [tags, setTags] = useState<string[]>(connect?.tags.map((o: Schemas.ConnectTagEntities) => o.uuid) || [])

    // 初期化
    useEffect(() => {
        reset({
            name: connect?.name || '',
            ruby: connect?.ruby || null,
            email: connect?.email || null,
            sort: connect?.sort || 1,
            uploadPermit: connect?.uploadPermit || 0,
            afterPermit: connect?.afterPermit || 0,

            message: connect?.message || null,
            birthday: connect?.birthday || null,
            deathDay: connect?.deathDay || null,

            sei: connect?.sei || null,
            mei: connect?.mei || null,

            postalCode: connect?.postalCode || null,
            pref: connect?.pref || null,
            city: connect?.city || null,
            address: connect?.address || null,
            building: connect?.building || null,
            tel: connect?.tel || null,
            mobilePhone: connect?.mobilePhone || null,
            memo: connect?.memo || null,

            tags: connect?.tags.map((o: Schemas.ConnectTagEntities) => o.uuid) || [],
            fileUuid: connect?.file?.uuid || null,
        })
        setTags(connect?.tags.map((o: Schemas.ConnectTagEntities) => o.uuid) || [])
        setImage(connect?.file || null)
        setPostalCode(connect?.postalCode || null)
    }, [connect])

    const handleChange = (event: SelectChangeEvent<typeof tags>) => {
        const {
            target: { value },
        } = event
        console.log('value', value)
        setTags([...value])
        setValue('tags', [...value])
    }

    const afterPermitTypes = [
        { value: 0, label: '権限なし' },
        { value: 1, label: '権限あり' },
    ]
    const onChangeAfterPermit = (val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue('afterPermit', parseInt(val.target.value))
    }

    const handleUploadFile = (value?: Schemas.FileEntities): void => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }
    const handleDeleteFile = () => {
        setValue('fileUuid', null)
        setImage(null)
    }

    const editSubmitHandler = async (dto: Schemas.ClientConnectCreateDto) => {
        try {
            if (dto.deathDay) {
                const confirm = await queueDialog({
                    type: 'confirm',
                    title: '',
                    text: '命日が入力されています。本当に登録してよろしいですか',
                })
                if (!confirm) return
            }

            if (connectUuid && connectUuid === 'new') {
                await apiClient.clientConnectCreate({
                    requestBody: dto,
                })
                navigate!('/connectList', { replace: true })
            } else {
                await apiClient.clientConnectUpdate({
                    requestBody: dto,
                    parameter: { connectUuid: connectUuid! },
                })
                navigate!(`/connectList/${connectUuid}`, { replace: true })
            }
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const cancelHandler = () => {
        if (!connectUuid) return
        if (connectUuid === 'new') navigate!('/connectList', { replace: true })
        else navigate!(`/connectList/${connectUuid}`, { replace: true })
    }

    const [findZipLoading, setFindZipLoading] = useState(false)
    const [postalCode, setPostalCode] = useState(connect?.postalCode || null)
    const getZipCodeAddress = async () => {
        if (!postalCode || postalCode === '') {
            await queueDialog({
                type: 'alert',
                title: '郵便番号を入力してください',
                text: '',
            })
            return
        }
        setFindZipLoading(true)
        try {
            const result: Schemas.ClientZipCodeDto[] = await apiClient.clientCommonGetZipCodeAddress({
                parameter: { zipCode: postalCode },
            })
            if (result.length > 0) {
                setValue('pref', result[0].prefecture_name)
                setValue('city', result[0].city_name)
                setValue('address', result[0].town_name)
            } else {
                await queueDialog({
                    type: 'alert',
                    title: '一致する郵便番号が見つかりませんでした',
                    text: '',
                })
            }
        } catch (e) {
            let message = '取得に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        } finally {
            setFindZipLoading(false)
        }
    }

    return {
        navigate,
        connectUuid,
        connect,
        tagList: tagListResponse?.list || [],

        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,

        tags,
        handleChange,

        afterPermitTypes,
        onChangeAfterPermit,

        image,
        handleUploadFile,
        handleDeleteFile,
        editSubmitHandler,
        cancelHandler,

        findZipLoading,
        getZipCodeAddress,
        postalCode,
        setPostalCode,
    }
}

export const ConnectListConnectUuidEditPage: FC = () => {
    const {
        connect,
        tagList,

        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,

        tags,
        handleChange,

        afterPermitTypes,
        onChangeAfterPermit,

        image,
        handleUploadFile,
        handleDeleteFile,
        editSubmitHandler,
        cancelHandler,

        findZipLoading,
        getZipCodeAddress,
        postalCode,
        setPostalCode,
    } = useConnectListConnectUuidEditPage()
    return (
        <>
            <DefaultLayout title={''} breadcrumbList={[]} tabValue={'connectList'}>
                <Stack spacing={2}>
                    <Typography variant={'body2'}>
                        お世話になった人を登録すると、没後にメッセージやギフトを送付することができます。
                    </Typography>

                    <Stack direction={'column'} alignItems={'center'}>
                        <Avatar alt={''} src={mediaUrl(image, true)} sx={{ width: 152, height: 152 }} />
                        {image ? (
                            <Button onClick={() => handleDeleteFile()}>削除</Button>
                        ) : (
                            <CIconUpload
                                completionHandler={handleUploadFile!}
                                label={''}
                                error={!!errors?.fileUuid}
                                helperText={errors?.fileUuid?.message}
                            />
                        )}
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'ニックネーム'} required />
                        <TextField
                            id={'name'}
                            required={true}
                            {...register!('name')}
                            placeholder={'ニックネーム'}
                            error={!!errors?.name}
                            helperText={errors?.name?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'フリガナ'} />
                        <TextField
                            id={'ruby'}
                            {...register!('ruby')}
                            placeholder={'フリガナ'}
                            error={!!errors?.ruby}
                            helperText={errors?.ruby?.message}
                            variant={'outlined'}
                        />
                        <Typography variant={'caption'}>※ 自分のアドレス帳に表示される名前です</Typography>
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'宛名'} />
                        <TextField
                            id={'sei'}
                            {...register!('sei')}
                            placeholder={'宛名'}
                            error={!!errors?.sei}
                            helperText={errors?.sei?.message}
                            variant={'outlined'}
                        />
                        <Typography variant={'caption'}>ギフトを贈る際に利用される宛名です</Typography>
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'タグ'} />
                        <Select
                            id={'tags'}
                            variant={'outlined'}
                            multiple
                            value={tags}
                            onChange={handleChange}
                            sx={{ borderWidth: '4px', backgroundColor: 'white' }}>
                            {tagList &&
                                tagList.map((tag: Schemas.ConnectTagEntities) => (
                                    <MenuItem key={tag.uuid} value={tag.uuid}>
                                        {tag.name}
                                    </MenuItem>
                                ))}
                        </Select>
                        <Typography variant={'caption'}>交友関係を表すタグをつけて分類することができます。</Typography>
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'携帯電話番号'} />
                        <Typography variant={'caption'}>※ ハイフン(-)は除いて入力してください</Typography>
                        <TextField
                            id={'mobilePhone'}
                            {...register!('mobilePhone')}
                            placeholder={'携帯電話番号'}
                            error={!!errors?.mobilePhone}
                            helperText={errors?.mobilePhone?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'電話番号'} />
                        <Typography variant={'caption'}>※ ハイフン(-)は除いて入力してください</Typography>
                        <TextField
                            id={'tel'}
                            {...register!('tel')}
                            placeholder={'電話番号'}
                            error={!!errors?.tel}
                            helperText={errors?.tel?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'メールアドレス'} />
                        <TextField
                            id={'email'}
                            {...register!('email')}
                            placeholder={'メールアドレス'}
                            error={!!errors?.email}
                            helperText={errors?.email?.message}
                            type={'email'}
                            variant={'outlined'}
                        />
                        <Typography variant={'caption'}>
                            ※ メールアドレスを登録することで、これ終に招待してつながることができるようになります
                        </Typography>
                    </Stack>

                    {connect?.isConnectUser && (
                        <Stack spacing={0.5}>
                            <CInputLabel label={'没後管理権限'} />
                            <TextField
                                id={'afterPermit'}
                                required={true}
                                {...register!('afterPermit')}
                                placeholder={'没後管理権限'}
                                variant={'outlined'}
                                select={true}
                                onChange={(val) => {
                                    onChangeAfterPermit(val)
                                }}
                                defaultValue={connect?.afterPermit ?? 0}>
                                {afterPermitTypes?.map((nt) => (
                                    <MenuItem key={nt.value} value={nt.value}>
                                        {nt.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                    )}

                    <Stack spacing={0.5}>
                        <CInputLabel label={'生年月日'} />
                        <CDatePicker
                            type={'date'}
                            label={'生年月日'}
                            control={control!}
                            name={'birthday'}
                            error={!!errors?.birthday}
                            helperText={errors?.birthday?.message}
                        />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'命日'} />
                        <CDatePicker
                            type={'date'}
                            label={'命日'}
                            control={control!}
                            name={'deathDay'}
                            error={!!errors?.deathDay}
                            helperText={errors?.deathDay?.message}
                        />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'郵便番号'} />
                        <Typography variant={'caption'}>※ ハイフン(-)は除いて入力してください</Typography>
                        <Stack direction={'row'} justifyContent={'start'} width={'100%'} spacing={1}>
                            <TextField
                                id={'postalCode'}
                                {...register('postalCode')}
                                label={'郵便番号'}
                                error={!!errors.postalCode}
                                helperText={errors.postalCode?.message}
                                variant={'outlined'}
                                sx={{ backgroundColor: 'white', width: '50%' }}
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                            />
                            <LoadingButton
                                variant={'contained'}
                                loading={findZipLoading}
                                onClick={getZipCodeAddress}
                                sx={{ width: '160px', p: 0 }}>
                                郵便番号検索
                            </LoadingButton>
                        </Stack>

                        <CInputLabel label={'都道府県'} />
                        <TextField
                            id={'pref'}
                            {...register!('pref')}
                            placeholder={'都道府県'}
                            error={!!errors?.pref}
                            helperText={errors?.pref?.message}
                            variant={'outlined'}
                        />

                        <CInputLabel label={'市区町村'} />
                        <TextField
                            id={'city'}
                            {...register!('city')}
                            placeholder={'市区町村'}
                            error={!!errors?.city}
                            helperText={errors?.city?.message}
                            variant={'outlined'}
                        />

                        <CInputLabel label={'番地・その他'} />
                        <TextField
                            id={'address'}
                            {...register!('address')}
                            placeholder={'番地・その他'}
                            error={!!errors?.address}
                            helperText={errors?.address?.message}
                            variant={'outlined'}
                        />

                        <CInputLabel label={'ビル名・部屋番号'} />
                        <TextField
                            id={'building'}
                            {...register!('building')}
                            placeholder={'ビル名・部屋番号'}
                            error={!!errors?.building}
                            helperText={errors?.building?.message}
                            variant={'outlined'}
                        />
                        <Typography variant={'caption'}>
                            ※ 住所を登録すると、相手にギフトを贈ることができるようになります。
                        </Typography>
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'メモ'} />
                        <TextField
                            id={'memo'}
                            {...register!('memo')}
                            placeholder={'メモ'}
                            multiline
                            rows={3}
                            error={!!errors?.memo}
                            helperText={errors?.memo?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit!(editSubmitHandler!)}>
                        登録
                    </LoadingButton>
                    <Button variant="outlined" onClick={() => cancelHandler!()}>
                        キャンセル
                    </Button>
                </Stack>
            </DefaultLayout>
        </>
    )
}
