import { AxiosError } from 'axios'
import { type FC } from 'react'
import { useParams } from 'react-router'

import { CHistoryDetail } from '~/components/functional/cHistoryDetail/CHistoryDetail'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type ConnectHistoryHistoryDetailPageViewProps = {}

const useHistoryDetailPage = () => {
    const apiClient = createApiClient()

    const { connectUuid, historyId } = useParams()
    const { queueDialog } = useConfirmationDialog()

    const { data: historyDetail } = useQuerySuspense(
        [`connectUser/${historyId}`],
        async () => {
            if (!connectUuid || !historyId) throw new Error()

            return await apiClient.clientConnectGetOneConnectPersonalHistory({
                parameter: { connectUuid: connectUuid, historyUuid: historyId },
            })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    return { historyDetail, connectUuid }
}

export const ConnectHistoryHistoryDetailPageView: FC<
    ConnectHistoryHistoryDetailPageViewProps & ReturnType<typeof useHistoryDetailPage>
> = (props) => {
    const { historyDetail, connectUuid } = props
    return <CHistoryDetail connectUuid={connectUuid} historyDetail={historyDetail} tabValue={'connectList'} />
}

export const ConnectHistoryHistoryDetailPage: FC = () => {
    const hookItems = useHistoryDetailPage()
    return <ConnectHistoryHistoryDetailPageView {...hookItems} />
}

export default ConnectHistoryHistoryDetailPage
