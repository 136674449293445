import { Box, Stack, Typography } from '@mui/material'
import { type FC, type ReactNode } from 'react'

import albumCreation from '~/assets/image/help/mypage/memory/albumCreation.png'
import albumCreationTwo from '~/assets/image/help/mypage/memory/albumCreationTwo.png'
import comments from '~/assets/image/help/mypage/memory/comments.png'
import commentsThree from '~/assets/image/help/mypage/memory/commentsThree.png'
import commentsTwo from '~/assets/image/help/mypage/memory/commentsTwo.png'
import shareAlbum from '~/assets/image/help/mypage/memory/shareAlbum.png'
import shareAlbumTwo from '~/assets/image/help/mypage/memory/shareAlbumTwo.png'
import sharedMemories from '~/assets/image/help/mypage/memory/sharedMemories.png'
import { CHelpImage } from '~/components/functional/help/cHelpImage/CHelpImage'

import { CHelpCommonLink } from '../cHelpCommonLink/CHelpCommonLink'
import { CHelpCustomList } from '../cHelpCustomList/CHelpCustomList'
import { CHelpLayout } from '../cHelpLayout/CHelpLayout'

type MemoryHelpItems = {
    heading: string
    content: ReactNode
    image?: string
}
type MemorySectionItems = {
    id: string
    title: string
    body?: ReactNode
    children?: ReactNode
}

export const useCMemoryHelp = (onLinkClick: (sectionName: string, pathName: string, newTab: boolean) => void) => {
    const shareAlbumsItems: MemoryHelpItems[] = [
        {
            heading: '確認方法',
            content: (
                <>
                    作成済みのアルバムを選択し、アルバムトップ画面中央の <b>コメント</b> タブから確認できます。
                </>
            ),
            image: comments,
        },
        {
            heading: 'コメントを追加',
            content: (
                <>
                    <b>コメント</b> タブ内の画面下部にあるコメント入力欄にコメントを入力してください。
                    <br />
                    コメントを入力し終えたら、入力欄右のボタンをタップし、送信するとコメントが送信されます。
                </>
            ),
            image: commentsTwo,
        },
        {
            heading: '公開範囲',
            content: (
                <>
                    コメントは、そのアルバムの共有メンバー全員に公開されます。
                    <br />
                    作成済みのアルバムを選択し、アルバムトップ画面中央の <b>メンバー</b>
                    タブを開くと、そのアルバムの共有メンバーを確認することができます。
                </>
            ),
            image: commentsThree,
        },
    ]

    const sharedMemoryItems: MemoryHelpItems[] = [
        {
            heading: '確認方法',
            content: (
                <>
                    <b>共有された思い出</b>タブを選択します。
                    <br />
                    共有されているアルバムがある場合は、このページにアルバムが表示されます。
                </>
            ),
            image: sharedMemories,
        },
        {
            heading: '追加、編集、削除方法',
            content: (
                <>
                    アルバムへの画像・コメントの追加方法は自分が作成したのアルバムの操作と同じですが、削除は自分が作成した画像やコメントのみ可能です。
                    <br />
                    また、アルバム自体の削除もできません。
                    <br />
                    ※他の人が作成した画像やコメントは削除できません。
                </>
            ),
        },
    ]

    const shareAlbumsList = shareAlbumsItems.map((it, idx) => {
        return (
            <Stack key={idx} spacing={'0.625rem'} pt={'1rem'}>
                <Typography variant={'subtitle1'}>{it.heading}</Typography>
                <Typography variant={'body2'}>{it.content}</Typography>
                {it.image && <CHelpImage src={it.image} />}
            </Stack>
        )
    })

    const sharedMemoryList = sharedMemoryItems.map((it, idx) => {
        return (
            <Stack key={idx} spacing={'0.625rem'} pt={'1rem'}>
                <Typography variant={'subtitle1'}>{it.heading}</Typography>
                <Typography variant={'body2'}>{it.content}</Typography>
                {it.image && <CHelpImage src={it.image} />}
            </Stack>
        )
    })

    const memorySectionItems: MemorySectionItems[] = [
        {
            id: 'albumCreation',
            title: 'アルバム作成',
            body: <>日常や旅行先での写真を登録し、思い出を見返すことができます。つながっている人とも共有できます。</>,
            children: (
                <>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>日常アルバムを使ってみましょう</Typography>
                        <Typography variant={'body2'}>
                            日常アルバムは、あなただけが見ることができるアルバムです。ご自由にご利用ください。
                        </Typography>
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>アルバム追加方法</Typography>
                        <CHelpCustomList
                            listStyleType={'disc'}
                            listItems={[
                                <>
                                    <b>思い出アルバムを作成</b>を押します。
                                </>,
                                <>
                                    アルバムの表紙に設定したい画像を<b>表紙画像を追加</b>から追加してください。
                                </>,
                                <>
                                    <b>アルバムのタイトル、アルバムの説明、共有するメンバーを選択</b>を設定します。
                                </>,
                                <>
                                    <b>作成</b>を押すとアルバムが作成されます。
                                </>,
                            ]}
                        />
                        <Typography variant={'body2'}>
                            ※<b>共有するメンバー</b>を設定し、<b>公開する</b>を<b>オン</b>
                            にした状態で作成すると、共有メンバーに対してアルバムが公開されます。
                            <br />※<b>公開する</b>を<b>オフ</b>
                            にして公開すると、アルバムは他のユーザーには公開されず、あなただけが見ることができます。
                        </Typography>
                        <CHelpImage src={albumCreation} />
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>アルバムの編集・削除</Typography>
                        <Typography variant={'body2'}>
                            作成したアルバムの右下にある…ボタンをタップすると、<b>写真の追加・アルバムの編集・アルバムの削除</b>
                            を行うことができます。
                            <br />
                            ※日常アルバムに対してこの操作はできません。
                        </Typography>
                        <CHelpImage src={albumCreationTwo} />
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>公開範囲</Typography>
                        <Typography variant={'body2'}>
                            共有メンバーを設定することで、アルバムを指定したユーザーと共有することがきます。
                            <br />
                            共有メンバーを設定しない場合は誰にも公開されません。詳しくは
                            <CHelpCommonLink onClick={() => onLinkClick('shareAlbums', 'memory', false)}>
                                アルバムの共有
                            </CHelpCommonLink>
                            をご覧ください。
                        </Typography>
                    </Stack>
                </>
            ),
        },
        {
            id: 'shareAlbums',
            title: 'アルバムの共有',
            children: (
                <>
                    <Stack spacing={'0.625rem'}>
                        <Typography variant={'body2'}>
                            アルバムを共有すると、共有されたユーザーは以下のことが可能になります。
                        </Typography>

                        <Box>
                            <Typography variant={'body2'}>できること</Typography>
                            <CHelpCustomList
                                listStyleType={'disc'}
                                listItems={[
                                    <>アルバムの閲覧</>,
                                    <>コメントの閲覧</>,
                                    <>アルバムへの写真の追加</>,
                                    <>本人が追加した画像の削除</>,
                                    <>本人が追加した画像の削除</>,
                                    <>コメント追加</>,
                                    <>本人のコメントの削除</>,
                                ]}
                            />
                        </Box>

                        <Box>
                            <Typography variant={'body2'}>できないこと</Typography>
                            <CHelpCustomList
                                listStyleType={'disc'}
                                listItems={[
                                    <>共有されていないアルバムの閲覧</>,
                                    <>他のユーザーが追加した写真の削除</>,
                                    <>他のユーザーが追加した写真の削除</>,
                                ]}
                            />
                        </Box>
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>共有ユーザーの追加・編集</Typography>
                        <CHelpCustomList
                            listStyleType={'decimal'}
                            paddingBottom={'12px'}
                            listItems={[
                                <>
                                    アルバム作成時に<b>共有するメンバー</b>ボタンを押下し、共有したいユーザーを選択してください。
                                    <CHelpImage src={shareAlbum} />
                                </>,
                                <>
                                    作成済みのアルバムを選択し、アルバムトップ画面中央の<b>メンバー</b>タブを開き、
                                    <b>共有メンバーを編集</b>ボタンから共有したいユーザーを選択してください。
                                    <CHelpImage src={shareAlbumTwo} />
                                </>,
                            ]}
                        />
                    </Stack>
                </>
            ),
        },
        {
            id: 'comments',
            title: 'コメント機能',
            body: (
                <Typography variant={'body2'}>
                    アルバムにはそれぞれ好きなタイミングでコメントを追加できます。
                    <br />
                    共有ユーザーがいる場合、お互いのコメントが相手にも見えるようになります。
                </Typography>
            ),
            children: <Stack pt={'0.5rem'}>{shareAlbumsList}</Stack>,
        },
        {
            id: 'sharedMemories',
            title: '共有された思い出',
            body: (
                <Typography variant={'body2'}>
                    共有された思い出を、共有メンバーみんなで編集できます。お互いのコメントが相手にも見えるようになります。
                </Typography>
            ),
            children: <Stack pt={'0.5rem'}>{sharedMemoryList}</Stack>,
        },
    ]
    return { memorySectionItems }
}

export type CMemoryHelpProps = {
    onLinkClick: (sectionName: string, pathName: string) => void
}

export const CMemoryHelp: FC<CMemoryHelpProps> = ({ onLinkClick }) => {
    const { memorySectionItems } = useCMemoryHelp(onLinkClick)
    return (
        <Stack spacing={'2.5rem'} mt={'2.5rem'} data-testid="c-memory-help">
            {memorySectionItems.map((it, idx) => (
                <CHelpLayout key={idx} {...it} />
            ))}
        </Stack>
    )
}
