import { AppBar, Avatar, Badge, Button, IconButton, Link, Menu, MenuItem, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import cart from '~/assets/image/header/cart.svg'
import headerLeft from '~/assets/image/header/left.png'
import logo from '~/assets/image/header/logo.svg'
import menu from '~/assets/image/header/menu.svg'
import pcHeader from '~/assets/image/header/pc_header.png'
import rectangle from '~/assets/image/header/rectangle.png'
import headerRight from '~/assets/image/header/right.png'
import { useAuthState } from '~/store/auth'
import { useOrderState } from '~/store/order'
import { mediaUrl } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useGHeader = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { me, clearAuthData } = useAuthState()
    const { token, setOrderToken } = useOrderState()

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const menuButtonHandler = (e: HTMLElement) => {
        setMenuAnchorEl(e)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }

    const signOut = async () => {
        try {
            await apiClient.clientAuthLogout()
        } catch (e) {
            console.error(e)
        }
        // api の成功の是非に関わらずクリアする
        clearAuthData()
        menuCloseHandler()
        navigate('/login')
    }
    const clickLogin = () => {
        navigate('/login')
    }
    const clickTop = () => {
        if (me) navigate('/user')
        else navigate('/')
    }
    const clickPageChange = (path: string) => {
        menuCloseHandler()
        navigate(path)
    }

    const error401 = async (e: unknown) => {
        if (e instanceof AxiosError && e.response?.status === 401) {
            await clearAuthData()
            window.location.href = '/login'
            return
        }
        throw e
    }

    const [badgeCount, setBadgeCount] = useState<number>(0)
    const [badgeInvite, setBadgeInvite] = useState<boolean>(false)

    const { data: requestOrder } = useQuery(
        ['header/request/order', dayjs().format('HH:mm:ss')],
        async () => {
            if (!me) return null
            if (!token) setOrderToken(me.uuid)
            return await apiClient.clientGiftOrderRequestFindOrder({
                parameter: {
                    token: token!,
                },
            })
        },
        {
            onError: (e) => error401(e),
            enabled: !!me,
        },
    )
    useEffect(() => {
        if (!requestOrder) {
            setBadgeCount(0)
            return
        }
        const req = requestOrder?.request || undefined
        setBadgeCount(req && req.details ? req.details.length : 0)
    }, [requestOrder])

    // initial fetch
    const { data: invitedUsers } = useQuery(
        ['/header/invitedUsers', dayjs().format('HH:mm')],
        async () => {
            return await apiClient.clientInviteGetList({ parameter: { isInvitedUser: false } })
        },
        {
            onError: (e) => error401(e),
            enabled: !!me,
        },
    )
    const { data: applicationUsers } = useQuery(
        ['/header/applicationUsers', dayjs().format('HH:mm')],
        async () => {
            return await apiClient.clientInviteGetList({ parameter: { isInvitedUser: true } })
        },
        {
            onError: (e) => error401(e),
            enabled: !!me,
        },
    )

    useEffect(() => {
        setBadgeInvite(
            !!(
                invitedUsers &&
                applicationUsers &&
                ((invitedUsers.count && invitedUsers.count > 0) || (applicationUsers.count && applicationUsers.count > 0))
            ),
        )
    }, [invitedUsers, applicationUsers])

    return {
        me,
        clickPageChange,
        signOut,
        menuButtonHandler,
        menuAnchorEl,
        menuCloseHandler,
        badgeCount,
        badgeInvite,
        clickLogin,
        clickTop,
    }
}

export const GHeader: FC = () => {
    const { me, clickPageChange, signOut, menuButtonHandler, menuAnchorEl, menuCloseHandler, badgeCount, badgeInvite, clickTop } =
        useGHeader()
    return (
        <>
            <AppBar sx={{ backgroundColor: 'background.default', boxShadow: 'none' }}>
                <Stack component="div">
                    <img src={rectangle} style={{ height: '4px', width: '100%' }} alt="" />
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        component="div"
                        sx={{ display: { xs: 'flex', sm: 'none' } }}>
                        <img src={headerLeft} style={{ height: '44px', width: '100%' }} alt="" />
                        <img src={headerRight} style={{ height: '44px', width: '100%' }} alt="" />
                    </Stack>
                    <Stack component="div" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <img src={pcHeader} alt="" style={{ height: '50px' }} />
                    </Stack>
                </Stack>
                <Stack direction={'row'} justifyContent={'center'} sx={{ width: '100%', position: 'absolute', top: '8px' }}>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'flex-end'}
                        sx={{ px: 2, width: '100%', maxWidth: { xs: 'sm', md: 'md' } }}>
                        {me ? (
                            <>
                                <Link sx={{ cursor: 'pointer' }} onClick={clickTop}>
                                    <img src={logo} alt="logo-image" />
                                </Link>
                                <Stack spacing={0} direction={'row'} justifyContent={'end'} alignItems={'end'}>
                                    <IconButton color="inherit" href="/gift/order/cart">
                                        <Badge badgeContent={badgeCount} color="error">
                                            <img src={cart} alt="cart-image" style={{ width: '24px', height: '24px' }} />
                                        </Badge>
                                    </IconButton>

                                    <IconButton
                                        edge="end"
                                        aria-label="account of current user"
                                        color="inherit"
                                        onClick={(e) => menuButtonHandler(e.currentTarget)}>
                                        <Badge badgeContent={badgeInvite ? '!' : undefined} color="error">
                                            <img src={menu} alt="menu-image" />
                                        </Badge>
                                    </IconButton>
                                </Stack>
                            </>
                        ) : (
                            <>
                                <Link sx={{ cursor: 'pointer', paddingTop: '8px' }} onClick={clickTop}>
                                    <img src={logo} alt="logo-image" />
                                </Link>
                                <Stack spacing={0} direction={'row'} justifyContent={'end'} alignItems={'end'}>
                                    <IconButton color="inherit" />
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Stack>
                <Menu open={!!menuAnchorEl} onClose={menuCloseHandler} anchorEl={menuAnchorEl}>
                    <Stack
                        direction={'row'}
                        justifyContent={'flex-start'}
                        sx={{ paddingLeft: '32px', paddingTop: '32px', width: '300px' }}
                        spacing={1}>
                        <Avatar alt={me?.name} src={mediaUrl(me?.file, true)} sx={{ width: '60px', height: '60px' }} />
                        <Stack direction={'column'}>
                            <Typography variant={'body1'}>{me?.name}</Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '150%', color: '#A09C9A' }}>
                                {me?.mobilePhone}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack justifyContent={'flex-start'} sx={{ paddingLeft: '32px', paddingTop: '32px' }}>
                        <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '150%', color: '#A09C9A', pb: '6px' }}>
                            アカウントサービス
                        </Typography>
                        <MenuItem onClick={() => clickPageChange('/gift/history')}>ギフト購入履歴</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/offeringOrder/history')}>お供え物注文履歴</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/user/connection/apply')}>
                            つながり申請
                            <Badge badgeContent={badgeInvite ? '!' : undefined} color="error" sx={{ pt: 1.5, pl: 1.5 }} />
                        </MenuItem>
                        <MenuItem onClick={() => clickPageChange('/connectList/management')}>管理中のお墓</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/user/mobilePhone')}>携帯電話番号変更</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/user/mail')}>メールアドレス登録</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/user/card')}>クレジットカード情報</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/inquiry/list')}>お問い合わせ</MenuItem>
                        <MenuItem onClick={signOut}>ログアウト</MenuItem>
                    </Stack>

                    <Stack justifyContent={'flex-start'} sx={{ paddingLeft: '32px', paddingTop: '24px' }}>
                        <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '150%', color: '#A09C9A', pb: '6px' }}>
                            これ終について
                        </Typography>
                        <MenuItem onClick={() => clickPageChange('/terms')}>利用規約</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/privacy')}>プライバシーポリシー</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/specifiedCommercialTransaction')}>特定商取引法表記</MenuItem>
                        <MenuItem onClick={() => clickPageChange('/sponsorList')}>協賛事業者一覧</MenuItem>
                    </Stack>
                    <Stack
                        justifyContent={'flex-start'}
                        sx={{ paddingLeft: '32px', paddingTop: '60px', paddingRight: '32px', paddingBottom: '32px' }}>
                        <Button variant={'contained'} onClick={menuCloseHandler}>
                            閉じる
                        </Button>
                    </Stack>
                </Menu>
            </AppBar>
        </>
    )
}
