import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material'
import type { ChangeEvent, FC } from 'react'
import { useState } from 'react'

import { Schemas } from '~/apis/types'
import { createApiClient } from '~/utils/createApiClient'

const useCFileUpload = (completionHandler: (value?: Schemas.FileEntities, error?: unknown) => void) => {
    const apiClient = createApiClient()

    const [uploading, setUploading] = useState(false)

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (!files || files.length === 0) return

        setUploading(true)

        const formData = new FormData()
        formData.append('files', files[0] as Blob)

        try {
            // @ts-expect-error
            const uploadedFiles = await apiClient.clientFileUploadUpload({ requestBody: formData })
            completionHandler(uploadedFiles.at(0))
        } catch (e) {
            completionHandler(undefined, e)
        }

        setUploading(false)
    }

    return {
        onChange,
        uploading,
    }
}

export type CFileUploadProps = {
    label: string
    error?: boolean
    helperText?: string
    completionHandler: (value?: Schemas.FileEntities, error?: unknown) => void
    required?: boolean
}

export const CFileUpload: FC<CFileUploadProps> = ({ label, error, completionHandler, required }) => {
    const { onChange, uploading } = useCFileUpload(completionHandler)

    return (
        <>
            <FormControl error={error}>
                <Stack spacing={2}>
                    <Typography variant={'h6'}>{`${label}${required ? '*' : ''}`}</Typography>
                    <Box sx={{ direction: 'column', justifyContent: 'center' }}>
                        <FormHelperText>
                            <Typography variant={'caption'} color={'error'}>
                                {error ? '画像が選択されていません' : ''}
                            </Typography>
                        </FormHelperText>
                        <LoadingButton variant={'contained'} loading={uploading} fullWidth component={'label'}>
                            アップロード
                            <input hidden type={'file'} onChange={onChange} />
                        </LoadingButton>
                    </Box>
                </Stack>
            </FormControl>
        </>
    )
}
