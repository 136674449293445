import { createTheme, experimental_sx as sx } from '@mui/material/styles'

declare module '@mui/material/styles' {
    interface Palette {
        accent: {
            main: string
        }
        textFieldBorderColor: {
            main: string
        }
        commentColor: {
            main: string
        }
    }
    interface PaletteOptions {
        accent?: {
            main?: string
        }
        textFieldBorderColor?: {
            main?: string
        }
        commentColor?: {
            main: string
        }
    }
}

export const muiTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: '#AB8CC6',
            contrastText: '#000',
        },
        secondary: {
            main: '#DFCCEF',
            contrastText: '#000',
        },
        error: {
            main: '#EA5F4C',
            contrastText: '#FFF',
        },
        background: {
            default: '#F9F5F2',
        },
        accent: {
            main: '#DFCCEF',
        },
        textFieldBorderColor: {
            main: '#AF985A',
        },
        commentColor: {
            main: 'rgba(60, 60, 67, 0.6)',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
                size: 'small',
            },
            styleOverrides: {
                root: sx({
                    '& .MuiOutlinedInput-root': {
                        '& > fieldset': {
                            border: '2px solid #AF985A',
                            borderRadius: '0.5rem',
                        },
                        '& > error': {
                            border: '2px solid #AF985A',
                        },
                    },
                    backgroundColor: '#f9f5f2',
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: '#AF985A',
                },
                root: sx({
                    backgroundColor: 'white',
                }),
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: sx({
                    border: '2px solid #AF985A',
                }),
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: sx({
                    minHeight: '36px',
                    paddingLeft: 0,
                    paddingY: '8px',
                    fontWeight: 400,
                    fontSize: '18px',
                    lineHeight: '150%',
                    color: '#42392B',
                }),
            },
        },
        MuiListItem: {
            styleOverrides: {
                gutters: sx({
                    padding: 0,
                }),
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: sx({
                    padding: 0,
                }),
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: sx({
                    minWidth: 0,
                }),
            },
        },
        MuiButton: {
            defaultProps: {
                size: 'small',
                disableElevation: true,
            },
            styleOverrides: {
                root: sx({
                    fontSize: '18px',
                    fontWeight: 700,
                    padding: '14px',
                    borderRadius: '10px',
                    textTransform: 'none',
                }),
                contained: sx({
                    color: '#42392B',
                    backgroundColor: '#DFCCEF',
                    border: '2px solid #DFCCEF',
                    ':hover': {
                        color: '#42392B',
                        backgroundColor: '#CFA7F1',
                        border: '2px solid #CFA7F1',
                    },
                    ':disabled': {
                        color: '#A09C9A',
                        backgroundColor: '#D2BCDD',
                        border: '2px solid #D2BCDD',
                    },
                }),
                containedError: sx({
                    color: '#FFFFFF',
                    backgroundColor: '#EA5F4C',
                    border: '2px solid #EA5F4C',
                    ':hover': {
                        color: '#FFFFFF',
                        backgroundColor: '#F14129',
                        border: '2px solid #F14129',
                    },
                    ':disabled': {
                        color: '#FFFFFF',
                        backgroundColor: '#A09C9A',
                        border: '2px solid #F7BFB7',
                    },
                }),
                outlined: sx({
                    color: '#42392B',
                    backgroundColor: 'white',
                    border: '2px solid #DFCCEF',
                    ':hover': {
                        border: '2px solid #DFCCEF',
                    },
                    ':disabled': {
                        color: '#A09C9A',
                        backgroundColor: '#F5F2F0',
                        border: '2px solid #D2BCDD',
                    },
                }),
                outlinedError: sx({
                    color: '#42392B',
                    border: '2px solid #EA5F4C',
                    ':hover': {
                        color: '#42392B',
                        border: '2px solid #F14129',
                    },
                    ':disabled': {
                        color: '#A09C9A',
                        backgroundColor: '#F5F2F0',
                        border: '2px solid #F7BFB7',
                    },
                }),
            },
        },
    },
    typography: {
        h2: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '130%',
            color: '#77618B',
        },
        h3: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '130%',
            color: '#2B2B2B',
        },
        h4: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '110%',
            color: '#2B2B2B',
        },
        h5: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '110%',
            color: '#2B2B2B',
        },
        h6: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '110%',
            color: '#2B2B2B',
        },
        subtitle1: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '110%',
            color: '#2B2B2B',
        },
        subtitle2: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '120%',
            color: '#2B2B2B',
        },
        body1: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '130%',
            color: '#2B2B2B',
        },
        body2: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '150%',
            color: '#2B2B2B',
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '130%',
            color: '#2B2B2B',
        },
    },
})
