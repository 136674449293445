import { LoadingButton } from '@mui/lab'
import { Container, Stack, TextField, Typography } from '@mui/material'
import type { FC } from 'react'

import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useInquiryIndexPage } from '~/pages/inquiry/useInquiry'

export const InquiryIndexPage: FC = () => {
    const { register, handleSubmit, errors, isSubmitting, isValid, sendHandler } = useInquiryIndexPage()
    return (
        <DefaultLayout title={'お問い合わせ'} breadcrumbList={[]} isTab={false}>
            <Container maxWidth={'sm'}>
                <Typography variant={'body2'} textAlign={'center'}>
                    内容によっては回答をさしあげるのにお時間をいただくこともございます。
                    <br />
                    また、休業日は翌営業日以降の対応となりますのでご了承ください。
                </Typography>
                <Stack spacing={3}>
                    <Stack spacing={0.5}>
                        <CInputLabel label={'氏名'} required />
                        <TextField
                            id={'name'}
                            {...register('name')}
                            placeholder={'氏名'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'メールアドレス'} required />
                        <TextField
                            id={'email'}
                            {...register('email')}
                            placeholder={'メールアドレス'}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'タイトル'} required />
                        <TextField
                            id={'title'}
                            {...register('title')}
                            placeholder={'タイトル'}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                            variant={'outlined'}
                        />
                    </Stack>

                    <Stack spacing={0.5}>
                        <CInputLabel label={'内容'} required />
                        <TextField
                            id={'content'}
                            {...register('content')}
                            placeholder={'内容'}
                            error={!!errors.content}
                            helperText={errors.content?.message}
                            variant={'outlined'}
                            multiline
                            minRows={3}
                        />
                    </Stack>

                    <LoadingButton
                        variant={'contained'}
                        disabled={!isValid}
                        loading={isSubmitting}
                        onClick={handleSubmit(sendHandler)}>
                        送信
                    </LoadingButton>
                </Stack>
            </Container>
        </DefaultLayout>
    )
}
