import { useDisclosure } from '@chakra-ui/hooks'
import { Avatar, Box, Button, Container, IconButton, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import connectImage from '~/assets/image/connect/connectImage.svg'
import helpIcon from '~/assets/image/connect/helpIcon.svg'
import connectAdd from '~/assets/image/icon/add.svg'
import connectQr from '~/assets/image/icon/qr.svg'
import connectTag from '~/assets/image/icon/tag.svg'
import { CConnectLabel } from '~/components/functional/connect/cConnectLabel/CConnectLabel'
import { CUserQrCodeDialog } from '~/components/functional/user/cUserQrCodeDialog/CUserQrCodeDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type TypeConnectList = {
    label: string
    values: string[]
    list: Schemas.ConnectEntities[]
}

export type ConnectListIndexPageProps = {}

export const useConnectListIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const { me } = useAuthState()

    // initial fetch
    const { data: connectList } = useQuerySuspense(
        ['/connect/list'],
        async () => {
            const connectList: TypeConnectList[] = [
                {
                    label: 'あ',
                    values: [
                        'あ',
                        'い',
                        'う',
                        'え',
                        'お',
                        'ぁ',
                        'ぃ',
                        'ぅ',
                        'ぇ',
                        'ぉ',
                        'ア',
                        'イ',
                        'ウ',
                        'エ',
                        'オ',
                        'ｱ',
                        'ｲ',
                        'ｳ',
                        'ｴ',
                        'ｵ',
                        'ァ',
                        'ィ',
                        'ゥ',
                        'ェ',
                        'ォ',
                        'ｧ',
                        'ｨ',
                        'ｩ',
                        'ｪ',
                        'ｫ',
                    ],
                    list: [],
                },
                {
                    label: 'か',
                    values: [
                        'か',
                        'き',
                        'く',
                        'け',
                        'こ',
                        'が',
                        'ぎ',
                        'ぐ',
                        'げ',
                        'ご',
                        'カ',
                        'キ',
                        'ク',
                        'ケ',
                        'コ',
                        'ガ',
                        'ギ',
                        'グ',
                        'ゲ',
                        'ゴ',
                        'ｶﾞ',
                        'ｷﾞ',
                        'ｸﾞ',
                        'ｹﾞ',
                        'ｺﾞ',
                    ],
                    list: [],
                },
                {
                    label: 'さ',
                    values: [
                        'さ',
                        'し',
                        'す',
                        'せ',
                        'そ',
                        'ざ',
                        'じ',
                        'ず',
                        'ぜ',
                        'ぞ',
                        'サ',
                        'シ',
                        'ス',
                        'セ',
                        'ソ',
                        'ザ',
                        'ジ',
                        'ズ',
                        'ゼ',
                        'ゾ',
                        'ｻﾞ',
                        'ｼﾞ',
                        'ｽﾞ',
                        'ｾﾞ',
                        'ｿﾞ',
                    ],
                    list: [],
                },
                {
                    label: 'た',
                    values: [
                        'た',
                        'ち',
                        'つ',
                        'て',
                        'と',
                        'だ',
                        'ぢ',
                        'づ',
                        'で',
                        'ど',
                        'タ',
                        'チ',
                        'ツ',
                        'テ',
                        'ト',
                        'ダ',
                        'ヂ',
                        'ヅ',
                        'デ',
                        'ド',
                        'ﾀﾞ',
                        'ﾁﾞ',
                        'ﾂﾞ',
                        'ﾃﾞ',
                        'ﾄﾞ',
                    ],
                    list: [],
                },
                {
                    label: 'な',
                    values: ['な', 'に', 'ぬ', 'ね', 'の', 'ナ', 'ニ', 'ヌ', 'ネ', 'ノ', 'ﾅ', 'ﾆ', 'ﾇ', 'ﾈ', 'ﾉ'],
                    list: [],
                },
                {
                    label: 'は',
                    values: [
                        'は',
                        'ひ',
                        'ふ',
                        'へ',
                        'ほ',
                        'ば',
                        'び',
                        'ぶ',
                        'べ',
                        'ぼ',
                        'ぱ',
                        'ぴ',
                        'ぷ',
                        'ぺ',
                        'ぽ',
                        'ハ',
                        'ヒ',
                        'フ',
                        'ヘ',
                        'ホ',
                        'ﾊ',
                        'ﾋ',
                        'ﾌ',
                        'ﾍ',
                        'ﾎ',
                        'バ',
                        'ビ',
                        'ブ',
                        'ベ',
                        'ボ',
                        'ﾊﾞ',
                        'ﾋﾞ',
                        'ﾌﾞ',
                        'ﾍﾞ',
                        'ﾎﾞ',
                        'パ',
                        'ピ',
                        'プ',
                        'ペ',
                        'ポ',
                        'ﾊﾟ',
                        'ﾋﾟ',
                        'ﾌﾟ',
                        'ﾍﾟ',
                        'ﾎﾟ',
                    ],
                    list: [],
                },
                {
                    label: 'ま',
                    values: ['ま', 'み', 'む', 'め', 'も', 'マ', 'ミ', 'ム', 'メ', 'モ', 'ﾏ', 'ﾐ', 'ﾑ', 'ﾒ', 'ﾓ'],
                    list: [],
                },
                {
                    label: 'や',
                    values: [
                        'や',
                        'ゆ',
                        'よ',
                        'ヤ',
                        'ユ',
                        'ヨ',
                        'ﾔ',
                        'ﾕ',
                        'ﾖ',
                        'ゃ',
                        'ゅ',
                        'ょ',
                        'ャ',
                        'ュ',
                        'ョ',
                        'ｬ',
                        'ｭ',
                        'ｮ',
                    ],
                    list: [],
                },
                {
                    label: 'ら',
                    values: ['ら', 'り', 'る', 'れ', 'ろ', 'ラ', 'リ', 'ル', 'レ', 'ロ', 'ﾗ', 'ﾘ', 'ﾙ', 'ﾚ', 'ﾛ'],
                    list: [],
                },
                {
                    label: 'わ',
                    values: ['わ', 'ゐ', 'ゑ', 'を', 'ゎ', 'ワ', 'ヮ', 'ヰ', 'ヱ', 'ヲ', 'ｦ'],
                    list: [],
                },
                {
                    label: 'ん',
                    values: ['ん', 'ン', 'ﾝ'],
                    list: [],
                },
                {
                    label: '#',
                    values: [],
                    list: [],
                },
            ]
            const connectResponse = await apiClient.clientConnectGetConnectListNotLimit({})
            if (connectResponse && connectResponse.length > 0) {
                for (let i = 0; i < connectList.length; i++) {
                    const element = connectList[i]
                    for (let j = 0; j < connectResponse.length; j++) {
                        const c = connectResponse[j]
                        const find = connectList.some((t) => {
                            return t.list.find((connect) => c.uuid === connect.uuid)
                        })
                        if (find) continue
                        const firstName = c.ruby ? c.ruby.substring(0, 1) : ''
                        if (element.values.length > 0) {
                            const e = element.values.find((v: string) => v === firstName)
                            if (e) element.list.push(c)
                        } else element.list.push(c)
                    }
                }
            }
            return connectList as TypeConnectList[]
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const detailHandler = async (connect: Schemas.ConnectEntities) => {
        navigate(`/connectList/${connect.uuid}`)
    }

    const tagHandler = () => {
        console.log('tagHandler')
        navigate(`/user/connectTag`)
    }

    const addButtonHandler = () => {
        navigate('/connectList/new/edit')
    }

    // QRコード
    const { isOpen: qrCodeDialogIsOpen, onOpen: qrCodeModalOnOpen, onClose: qrCodeDialogOnClose } = useDisclosure()
    const openQrCodeHandler = () => {
        qrCodeModalOnOpen()
    }
    const QRCodeDialog = (
        <CUserQrCodeDialog
            isOpen={qrCodeDialogIsOpen}
            onClose={qrCodeDialogOnClose}
            text={`${process.env.BASE_URL}/invite/${me!.invite}`}
        />
    )

    return {
        connectList: connectList || [],
        detailHandler,
        tagHandler,
        addButtonHandler,
        QRCodeDialog,
        openQrCodeHandler,
    }
}

export const ConnectListIndexPage: FC<ConnectListIndexPageProps> = () => {
    const { connectList, detailHandler, tagHandler, addButtonHandler, QRCodeDialog, openQrCodeHandler } =
        useConnectListIndexPage()
    return (
        <>
            <DefaultLayout maxWidth={'md'} breadcrumbList={[]} tabValue={'connectList'}>
                <Stack spacing={2}>
                    <Container maxWidth={'sm'}>
                        <Stack direction={'row'} justifyContent={'end'} sx={{ pb: 2 }}>
                            <IconButton component={Link} to="/help?source=connectList">
                                <img src={helpIcon} />
                            </IconButton>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <Typography variant="h3" sx={{ textAlign: 'center', paddingY: '32px' }}>
                                つながり
                            </Typography>
                        </Stack>
                    </Container>
                </Stack>

                <Container maxWidth={'sm'}>
                    <Stack spacing={2}>
                        <Container maxWidth={'sm'}>
                            <Stack spacing={1}>
                                <Button variant={'contained'} onClick={addButtonHandler} startIcon={<img src={connectAdd} />}>
                                    つながりを登録
                                </Button>
                                <Stack direction={'row'} spacing={1}>
                                    <Button
                                        variant={'contained'}
                                        fullWidth
                                        startIcon={<img src={connectTag} />}
                                        onClick={tagHandler}>
                                        タグ管理
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        fullWidth
                                        startIcon={<img src={connectQr} />}
                                        onClick={openQrCodeHandler}>
                                        マイQR
                                    </Button>
                                </Stack>
                            </Stack>
                        </Container>
                        <Stack spacing={3} sx={{ pt: 3 }}>
                            {connectList.length > 0 && (
                                <>
                                    {connectList
                                        .filter((typeConnect: TypeConnectList) => typeConnect.list.length > 0)
                                        .map((typeConnect: TypeConnectList) => (
                                            <Stack key={typeConnect.label}>
                                                <Typography variant={'subtitle2'} sx={{ my: 1, ml: 2 }}>
                                                    {typeConnect.label}
                                                </Typography>

                                                {typeConnect.list.map((connect: Schemas.ConnectEntities) => (
                                                    <Box
                                                        key={connect.uuid}
                                                        bgcolor={'white'}
                                                        sx={{ borderBottom: '0.5px solid #C6C6C8', py: 2, px: 2 }}
                                                        onClick={() => detailHandler(connect)}>
                                                        <Stack
                                                            direction={'row'}
                                                            justifyContent={'space-between'}
                                                            alignItems={'center'}>
                                                            <Stack direction={'row'} justifyContent={'start'} spacing={1}>
                                                                <Avatar
                                                                    alt={connect!.name}
                                                                    src={mediaUrl(connect!.file, true)}
                                                                    sx={{ width: 32, height: 32 }}
                                                                />

                                                                <Typography variant={'body2'} noWrap>
                                                                    {connect.name}
                                                                </Typography>
                                                            </Stack>
                                                            <CConnectLabel connect={connect} isText={false} isBgcolor={false} />
                                                        </Stack>
                                                    </Box>
                                                ))}
                                            </Stack>
                                        ))}
                                </>
                            )}

                            {connectList.reduce((a: number, c: TypeConnectList) => {
                                return a + c.list.length
                            }, 0) < 1 && (
                                <>
                                    <Container maxWidth={'sm'}>
                                        <Stack direction={'column'} spacing={2} sx={{ pt: 4 }}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <img src={connectImage} />
                                            </Stack>
                                            <Stack spacing={1} direction={'column'} alignItems={'center'}>
                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                    <img src={connectAdd} style={{ width: 16 }} />
                                                    <Typography
                                                        variant="subtitle1"
                                                        textAlign={'center'}
                                                        sx={{ color: '#77618B' }}>
                                                        つながりを登録 から
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle1" textAlign={'center'} sx={{ color: '#77618B' }}>
                                                    新しく登録してみましょう！
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Container>
                                </>
                            )}
                        </Stack>
                    </Stack>
                </Container>
            </DefaultLayout>
            {QRCodeDialog}
        </>
    )
}
